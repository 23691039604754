import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';
import { FormBuilder,Validators } from '@angular/forms';
import { ActivatedRoute ,Router} from '@angular/router';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-cancel-subscription',
  templateUrl: './cancel-subscription.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css','./cancel-subscription.component.scss']
})
export class CancelSubscriptionComponent implements OnInit {
  routeParam;
  subId;
  subscriptionData;
  cancelSubscriptionForm;
  response;
  postDataJsonPrepare;
  selectRefundType :boolean = true;
  showRefundOption : boolean = true;
  memberId;
  paymentMethod:any;
  isLoading : boolean = false;
  constructor(public sharedService:SharedService,public formBuilder : FormBuilder,public _crud : CommonCrudService,
              public activeRoute:ActivatedRoute,public router:Router,public modalService: NgbModal) { 
    this.formInit();  
  }

  ngOnInit(): void {
    this.routeParam = this.activeRoute.snapshot.params;
    console.log("params-----",this.routeParam.subId);
    this.subId = this.routeParam.subId;
    this.memberId = this.routeParam.memberId; 
    this.paymentMethod=this.routeParam.paymentName;
    console.log("data",this.paymentMethod);
    
    this.sharedService.fetchPathData('cancel','cancel-subscription');
    let params = {
      url: "frontend-site/member-subscription/get-cancellation-refund-amount/"+ this.subId,
  }
  this._crud.getData(params).subscribe(res => {
      this.subscriptionData = res['data'];
      if(this.subscriptionData.AdvanceAmount == 0){
        this.showRefundOption = false;
        this.cancelSubscriptionForm.controls.RefundType.setValue('0');
      }
      
     });
  }
  formInit(){
    this.cancelSubscriptionForm = this.formBuilder.group({
      RefundType: ['2', Validators.compose([Validators.required])],
    });
  }
  changeRefundType(){
    this.selectRefundType = true;
  }
  cancelSubscription(){
    if(this.cancelSubscriptionForm.valid){
      this.isLoading = true;
      this.response = { location:"subscription-cancel",message: "Are you sure you want to cancel this subscription"}
      const modalRef = this.modalService.open(AlertPopupComponent, {size: "md"});
      modalRef.componentInstance.data = this.response;
      modalRef.result.then(
        result => {
          // console.log("result-----modal",result)
            if (result.data) {
                //calling cancel api
                let params = {};
                params['url'] = 'frontend-site/member-subscription/cancel/' + this.subId;
                params['params'] = this.RequestPostParams();
                this._crud.postData(params).subscribe(res=>{
                  this.isLoading = false;
                  // console.log("cancl response----->",res);
                  this.successPopup(res);
                })
            }else{
              this.isLoading = false;
            }
        });
    }
    else{
      this.selectRefundType = false;
    }
  }
  RequestPostParams(){
    this.postDataJsonPrepare = {};
    this.postDataJsonPrepare['SubscriptionDetailId'] = this.subId;
    this.postDataJsonPrepare['RefundType'] = this.cancelSubscriptionForm.get('RefundType').value;
    //proces refund 
    if (this.cancelSubscriptionForm.get('RefundType').value == 1) {
        this.postDataJsonPrepare['RefundProcessType'] = (this.subscriptionData?.IsSingleElectronicTransaction!=0)? 1: 2;
    }
    //process refund with manual method
    // if(this.cancelSubscriptionForm.get('RefundType').value == 1 && this.subscriptionData?.IsSingleElectronicTransaction == 0){
    //   this.postDataJsonPrepare['RefundType'] = "0";
    //   this.postDataJsonPrepare['RefundProcessType'] = "";
    //   this.cancelSubscriptionForm.controls.RefundType.setValue('0');
    // }
    if (this.cancelSubscriptionForm.get('RefundType').value != 0) {
      this.postDataJsonPrepare['RefundAmount'] = this.subscriptionData.AdvanceAmount;
    }
    if(this.subscriptionData?.AvailalbleNonElectronicPaymentMethods==0){
      this.postDataJsonPrepare['PaymentProcessParams'] = {};
            this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields'] = {};
            this.postDataJsonPrepare['PaymentProcessParams']['PaymentMethodId'] = '';
    }
    return this.postDataJsonPrepare;

  }

  successPopup(data) {
    this.isLoading = false;
    let respo = {title: data['status'],message: data['msg']}
     
     //   this.modalService.show(SuccessPopupComponent, {initialState})
     const modalRef = this.modalService.open(SuccessPopupComponent, {size: "md"});
     modalRef.componentInstance.user = respo;
     modalRef.result.then(
      result => {
        // console.log("result-----modal",result)
          if (result.result && data['status'] == 'success') {
              // /clearing all forms.
              this.router.navigateByUrl('/memberDashboard/mysubscription/'+this.memberId)
              
          }
      });
  }

}
