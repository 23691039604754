<div class="susbscription-content my_event">
    <div class="bx-white table-min-ht">
        <div class="top-strip d-flex">
            <!-- <figure class="mb-0">
                <img src="./assets/member/images/icon-office.svg" alt="Shalom">
            </figure>
            <h5 class="ml-3 mb-0 width-1">My Event Registrations</h5> -->
            <div class="btn-right-holder width-1 ">
                <button type="button" class="btn-common btn-width-3" (click)="selectEvents()" [disabled]="addRegdisabled">Add Registration</button>
            </div>
            <div class="d-flex pagination-wrp width-2">
                <div class="pagination-list">
                    <mat-paginator [pageSizeOptions]="[10]" [hidePageSize]="true" [ngClass]="'pagination-holder'">
                    </mat-paginator>
                </div>
            </div>
        </div>

        <!-- Datatable starts here -->
        <div class="table-responsive-wrp ">
            <mat-table #table [dataSource]="dataSource">
                <ng-container matColumnDef="PrimaryCountryTime">
                    <mat-header-cell *matHeaderCellDef> Reg. Date </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.PrimaryCountryTime}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="EventType">
                    <mat-header-cell *matHeaderCellDef> Event </mat-header-cell>
                    <!-- <mat-cell *matCellDef="let element"> {{element.EventType}} </mat-cell> -->
                    <mat-cell *matCellDef="let element">
                        <div class="desc-content-sec" [ngClass]="{'causeClass':element.EventType.length > 13}">
                            <h5 class="heading-restrict">{{element.EventType}}</h5>
                            <div class="hover-bx">
                                <h5>{{element.EventType}}</h5>
                            </div>
                        </div>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="EventLocation">
                    <mat-header-cell *matHeaderCellDef> Location </mat-header-cell>
                    <!-- <mat-cell *matCellDef="let element"> {{element.EventTitle}} </mat-cell> -->
                    <mat-cell *matCellDef="let element">
                        <div class="desc-content-sec" [ngClass]="{'causeClass':element.EventTitle.length > 7}">
                            <h5 class="heading-restrict">{{element.EventTitle}}</h5>
                            <div class="hover-bx">
                                <h5>{{element.EventTitle}}</h5>
                            </div>
                        </div>

                    </mat-cell>
                </ng-container>
                <!-- <ng-container matColumnDef="CountryName">
                    <mat-header-cell *matHeaderCellDef> Country </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.CountryName}} </mat-cell>
                </ng-container> -->
                <ng-container matColumnDef="ParticipantNames">
                    <mat-header-cell *matHeaderCellDef> Participants </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="desc-content-sec" [ngClass]="{'causeClass':element.ParticipantNames.length > 9}">
                            <h5 class="heading-restrict">{{element.ParticipantNames}}</h5>
                            <div class="hover-bx">
                                <h5>{{element.ParticipantNames}}</h5>
                            </div>
                        </div>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="RegistrationFee">
                    <mat-header-cell *matHeaderCellDef> Reg. Fee </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <!-- <ng-container *ngIf="element.RegistrationFee !== 0">
                            {{element.RegistrationFee}} {{element.CurrencyCode}}
                        </ng-container> -->
                        <div class="desc-content-sec" [ngClass]="{'causeClass':element.RegistrationFee.length > 5}" *ngIf="element.RegistrationFee !== 0">
                            <h5 class="heading-restrict">{{element.RegistrationFee}} {{element.CurrencyCode}}</h5>
                            <div class="hover-bx">
                                <h5>{{element.RegistrationFee}} {{element.CurrencyCode}}</h5>
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="PaidAmount">
                    <mat-header-cell *matHeaderCellDef> Amount Paid </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <!-- <ng-container *ngIf="element.PaidAmount !== 0">{{element.PaidAmount}} {{element.CurrencyCode}}</ng-container> -->
                        <div class="desc-content-sec" [ngClass]="{'causeClass': element.PaidAmount.length > 5}" *ngIf="element.PaidAmount !== 0">
                            <h5 class="heading-restrict">{{element.PaidAmount}} {{element.CurrencyCode}}</h5>
                            <div class="hover-bx">
                                <h5>{{element.PaidAmount}} {{element.CurrencyCode}}</h5>
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="PendingAmount">
                    <mat-header-cell *matHeaderCellDef> Due </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <!-- <ng-container *ngIf="element.PendingAmount !== 0">
                            {{element.PendingAmount}} {{element.CurrencyCode}}
                        </ng-container> -->
                        <div class="desc-content-sec" [ngClass]="{'causeClass':element.PendingAmount.length > 10}" *ngIf="element.PendingAmount !== 0">
                            <h5 class="heading-restrict"> {{element.PendingAmount}} {{element.CurrencyCode}}</h5>
                            <div class="hover-bx">
                                <h5> {{element.PendingAmount}} {{element.CurrencyCode}}</h5>
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <!-- <ng-container matColumnDef="CurrencyCode">
                    <mat-header-cell *matHeaderCellDef> Currency </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.CurrencyCode}} </mat-cell>
                </ng-container> -->
                <ng-container matColumnDef="PaymentMethodName">
                    <mat-header-cell *matHeaderCellDef> Payment Method </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.LastFourDigit}}<img
                            [src]="element.PaymentMethodImageUrl" alt=""> </mat-cell>
                </ng-container>
                <ng-container matColumnDef="RegistrationStatusText">
                    <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element"> <span>{{element.RegistrationStatusText}}</span>
                        <!-- old html code -->
                        <!-- <div class="dropdown-wrp">
                            <a href="javascript:void(0)" data-toggle="dropdown" class="status-icon" aria-expanded="false"><img src="./assets/member/images/icon-status.svg" alt="Shalom"></a>
                            <ul class="dropdown-menu">
                                <li><a href="#">Make Payment</a></li>
                                <li><a href="#">Cancel Registration</a></li>
                            </ul>
                        </div> -->
                        <!-- new code -->
                        <div class="dropdown-wrp" *ngIf="element.RegistrationStatusText != 'Cancelled'">
                            <a href="#" class="status-icon" data-toggle="dropdown" aria-expanded="false">
                                <img src="./assets/member/images/icon-status.svg" alt="Shalom">
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end"
                                style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-87px, 33px, 0px);">
                                <li *ngIf="element.bShowMakePaymentButton"><a
                                        routerLink="make-eventpayment/{{element.RecordID}}">Make Payment</a></li>
                                <li><a (click)="payment(element)" style="cursor: pointer;">Cancel Registration</a>
                                    
                                </li>
                               
                                <!-- <li *ngIf="element.AttachmentDetails != null"
                                    (click)="downloadFlyer(element.AttachmentDetails?element.AttachmentDetails.FileName:null)">
                                    <a>Download</a></li> -->
                                    <li *ngIf="element.AttachmentDetails.length > 0"
                                    (click)="attachmentDetailsPopup(element.AttachmentDetails?element.AttachmentDetails:null)">
                                    <a style="cursor: pointer;">Attachments</a></li>

                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <!-- <app-cancel-event-registration *ngIf="paymentMethod != 'Credit/Debit'" [paymentMethod]="paymentMethod"></app-cancel-event-registration> -->
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

            </mat-table>
        </div>
    </div>
    <!-- <div class="bx-white">
        <div class="row pagination-wrp p-3">
            <div class="pagination-list col-md-7">
                <mat-paginator [pageSizeOptions]="[7]" [hidePageSize]="true" [ngClass]="'pagination-holder'"></mat-paginator>
            </div>
            <div class=" col-md-5 d-flex pagination-btn">
                <button type="button" class="btn-common btn-width-3" (click)="selectEvents()">ADD REGISTRATION</button>
            </div>
        </div>
    </div> -->
</div>