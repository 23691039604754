<section class="member-wrp">
    <div class="bg-body">
        <figure class="logo-top">
            <img src="./assets/member/images/page-logo.svg" alt="Shalom">
        </figure>
        <div class="login-wrp ">
            <div class="login-form">

                <form [formGroup]="loginForm">
                    <div class="form-wrp">
                        <h1>Login to MyShalom Account</h1>
                        <div class="form-inner">
                            <div class="form-group focus-label" id="userName">
                                <label class="form-label-of" for="Email">Email</label>
                                <input autocomplete="off" formControlName="Email" id="Email" type="email" class="" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Email">
                                    <span class="error-message"
                                        *ngIf="loginForm.get('Email').hasError(validation.type) && (loginForm.get('Email').dirty || loginForm.get('Email')?.touched)">
                                        {{validation.message}}
                                    </span>
                                </span>
                            </div>
                            <div class="form-group focus-label" id="passWord">
                                <label class="form-label-of" for="Password">Password</label>
                                <input autocomplete="off" formControlName="Password" id="Password" type="password" class="" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Password">
                                    <span class="error-message"
                                        *ngIf="loginForm.get('Password').hasError(validation.type) && (loginForm.get('Password').dirty || loginForm.get('Password')?.touched)">
                                        {{validation.message}}
                                    </span>
                                </span>
                            </div>
                            <div *ngIf="errorShow" class="error-message">
                                <span>{{errorMsg}}</span>
                            </div>
                            <div class="captcha-holder mb-3">
                                <re-captcha formControlName="captcha" (resolved)="resolved($event)" (error)="onError($event)" errorMode="handled" required siteKey="6LeVUbkcAAAAAJhTC5Z-1Gi6purkrQ9OF09ro6YM"></re-captcha>
                                <span class="m-form__help" *ngIf="notcaptcha">
                                <span class="errorShow"  style="color: #f4516c;">please check</span>
                                </span>
                            </div>

                            <div class="d-flex remember-me-wrp pb-3">
                                <div class="checkbx-wrp">
                                    <input class="" formControlName="RemeberMe" id="remember" type="checkbox" value="">
                                    <label class="form-check-label" for="remember">Remember me</label>
                                </div>
                                <button type="submit" class="btn-common btn-blue-login" (click)="loginSubmit()">Login <i *ngIf="isloading" class="spinner-border text-primary"></i></button>
                            </div>
                            <p>Create Your <a routerLink="/memberregister">MyShalom Account</a></p>
                            <!-- <a href="javascript:void(0)" class="underline-txt">Trouble Logging In?</a> -->
                            <a routerLink="/memberforgotpassword" class="underline-txt">  Forgot Password?</a>
                        </div>
                    </div>
                </form>

            </div>
        </div>
        <ul class="nav-menu-btm">
            <li><a href="javascript:void(0)">Privacy Notice</a></li>
            <li><a href="javascript:void(0)">Conditions of Use</a></li>
            <!-- <li><a href="javascript:void(0)">Help</a></li> -->
            <li><a>Help</a></li>
            <li><a href="javascript:void(0)">Learn more about when to create an account</a></li>
        </ul>
    </div>
</section>