<div class="payment-wrp">
    <div class="bx-white" [hidden]="TabActiveIndex !=0">
        <div class="row">
            <div class="payment-left-img col-md-6 text-center">
                <figure>
                    <img src="./assets/member/images/img-donation-left.svg" alt="">
                </figure>
                <h3>Help us to transform a life by sharing the love of God</h3>
                <p>Your generous gift creates a lasting impact!</p>
            </div>
            <div class="payment-right-form col-md-6">
                <div class="payment-first-section">
                    <div class="choose-type-handle">
                        <h5 class="text-center">Please choose your donation type</h5>
                        <div class="d-flex radio-btn-donation-wrp">
                            <!-- <div class="mob-mb amnt-box-radio form-group pr-2 radio-width1">
                                <div class="form-check amnt-radio-wrp pl-0">
                                    <input checked="" class="form-check-input" id="amount2" name="amountSelect" type="radio" value="amount2">
                                    <label class="form-check-label" for="amount2">
                                    Give Monthly
                                </label>
                                </div>
                            </div>
                            <div class="mob-mb amnt-box-radio form-group pr-2 radio-width1">
                                <div class="form-check amnt-radio-wrp pl-0">
                                    <input checked="" class="form-check-input" id="amount3" name="amountSelect" type="radio" value="amount3">
                                    <label class="form-check-label" for="amount3">
                                    Give Once
                                </label>
                                </div>
                            </div> -->
                            <div class="mob-mb amnt-box-radio form-group radio-width1"
                                *ngFor="let frequency of frequencyList; let i=index;">
                                <input type="hidden" [formControl]="donationForm.controls.CausePaymentOptionValueId"
                                    value="{{frequencyOptions?.PaymentOptionValueId}}" />
                                <div class="form-check amnt-radio-wrp pl-0" *ngIf="!oneTimeHide">
                                    <input type="radio" class="form-check-input"
                                        [formControl]="donationForm.controls.Frequency" id="{{frequency?.Id}}"
                                        value="{{frequency?.Id}}" required
                                        (click)="getFrequencyOptions({id: $event.target.id, checked:false})">
                                    <label class="form-check-label" for="{{frequency?.Id}}">
                                        {{frequency?.Name}}
                                    </label>
                                </div>
                                <div class="form-check amnt-radio-wrp pl-0" *ngIf="oneTimeHide">
                                    <input type="radio" class="form-check-input"
                                        [formControl]="donationForm.controls.Frequency" id="{{frequency?.Id}}"
                                        value="{{frequency?.Id}}" required [checked]="i == 0"
                                        (click)="getFrequencyOptions({id: $event.target.id, checked:false})">
                                    <label class="form-check-label" for="{{frequency?.Id}}" style="width: 200%;">
                                        {{frequency?.Name}}
                                    </label>
                                </div>

                            </div>
                            <!-- <div class="mob-mb amnt-box-radio form-group pr-2 radio-width1" *ngIf="oneTimeHide" >
                                <div class="form-check amnt-radio-wrp pl-0">
                                    <input type="radio" class="form-check-input" [formControl]="donationForm.controls.Frequency" id="123456" value="645464654" disabled [checked]="i == 0" >
                                    <label class="form-check-label onetimeDisable" for="123456">
                                        Onetime
                                    </label>
                                </div>
                            </div> -->
                        </div>
                        <p class="bg-icon-heart-text text-center">Thank you for opening your heart to make a difference
                        </p>
                    </div>
                    <div class="payment-form-wrp">
                        <div class="common-field-each">
                            <div class="form-group focus-label">
                                <!-- <input> -->
                                <ng-select [formControl]="donationForm.controls.Member" [items]="memberList"
                                    placeholder="Member" bindValue="Id" bindLabel="Name"
                                    [(ngModel)]="selectedMemberId" (change)="onMemberChange($event)">
                                </ng-select>
                            </div>
                            <!-- <h5>My donation is for:</h5> -->
                            <div class="form-group focus-label cause_sel">
                                <!-- <input> -->
                                <ng-select [formControl]="donationForm.controls.causeId" [items]="causeList"
                                    placeholder="I want to support" bindValue="Id" bindLabel="Name"
                                    [(ngModel)]="selectedCauseId" (change)="onCauseChange($event)">
                                    <ng-template ng-option-tmp let-item="item">
                                        <span class="full-name">{{ item.Name }}</span>
                                        <span class="text-mutted">{{ item.Description }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div class="common-field-each mb-3">
                            <h5>Donation Amount</h5>
                            <div class="d-flex  radio-amountbx-wrp" *ngIf="isCauseSelected">
                                <!-- <div class="mob-mb amnt-box-radio form-group pr-2">
                                    <div class="form-check amnt-radio-wrp pl-0">
                                        <input formcontrolname="Amount" name="amountSelect" type="radio" id="type1">
                                        <label class="form-check-label" for="type1"> 100 CAD </label>
                                    </div>
                                </div>
                                <div class="mob-mb amnt-box-radio form-group pr-2">
                                    <div class="form-check amnt-radio-wrp pl-0">
                                        <input formcontrolname="Amount" name="amountSelect" type="radio" id="type2">
                                        <label class="form-check-label" for="type2"> 200 CAD </label>
                                    </div>
                                </div>
                                <div class="mob-mb amnt-box-radio form-group pr-2">
                                    <div class="form-check amnt-radio-wrp pl-0">
                                        <input formcontrolname="Amount" name="amountSelect" type="radio" id="type3">
                                        <label class="form-check-label" for="type3"> 500 CAD </label>
                                    </div>
                                </div> -->
                                <div class="mob-mb amnt-box-radio form-group pr-2"
                                    *ngFor="let amount of frequencyOptions; let i=index;">
                                    <div class="form-check amnt-radio-wrp pl-0">
                                        <input [formControl]="donationForm.controls.Amount" type="radio"
                                            name="donation-radio" id="{{amount?.PaymentOptionValueId}}"
                                            value="{{amount?.Amount}}" [attr.data-amountValue]='amount?.Amount'
                                            [checked]="i == 0"
                                            (click)="getAmount($event.target.attributes['data-amountValue'].value)">
                                        <label class="form-check-label" for="{{amount?.PaymentOptionValueId}}"
                                            *ngIf="i < 3"> {{amount?.PaymentOptionText}} </label>
                                    </div>
                                </div>
                                <div class="mob-mb amnt-box-radio form-group  amnt-currency-wrp">
                                    <!-- <input type="text" id="other_amount_home" autocomplete="off" placeholder="Other"> -->
                                    <!-- <label class="form-label-of" for="other_amount">Other Amount</label> -->
                                    <div class="d-flex">
                                        <input [hidden]="AcceptAnyAmount == 0"
                                            [formControl]="donationForm.controls.OtherAmount" placeholder="Other"
                                            type="text" id="other_amount" autocomplete="off"
                                            (input)="upfunction($event)" (focus)="animateLabel($event.target)"
                                            (focusout)="roundOtherAmount()">
                                        <!-- <span class="error-message danger alert" *ngIf="otheramoutValidation">
                                        Please enter an amount of at least {{minAmount == null ? 1 : minAmount}} {{Currency}}
                                </span>
                                <span class="error-message danger alert" *ngIf="maxOtherAmount">
                                        Please enter an amount of maximum 12 characters
                                </span> -->
                                        <select [formControl]="donationForm.controls.CurrencyISOCode" id="currency"
                                            [(ngModel)]="Currency" (change)="getCurrencyOptions($event.target.value)">
                                            <option hidden></option>
                                            <option *ngFor="let Currency of currencyList"
                                                value="{{Currency.CurrencyCode}}">
                                                {{ Currency.CurrencyCode }}</option>
                                        </select>
                                    </div>
                                    <span class="error-message danger alert amount-error" *ngIf="otheramoutValidation">
                                        Please enter an amount of at least {{minAmount == null ? 1 : minAmount}}
                                        {{Currency}}
                                    </span>
                                    <span class="error-message danger alert amount-error" *ngIf="decimalVal">Please
                                        enter an amount less than one hundred million</span>
                                    <span class="error-message danger alert amount-error" *ngIf="decimalError">Please
                                        enter a valid amount</span>
                                    <!-- <select id="currency">
                                        <option  value="CAD" > CAD</option>
                                        <option  value="EUR"> EUR</option>
                                        <option  value="GBP" > GBP</option>
                                        <option  value="INR"> INR</option>
                                    </select> -->

                                </div>
                            </div>
                        </div>
                        <div class="common-field-each" *ngIf="selectedFrequencyName != 'Onetime'">
                            <!-- <h5>Recurring Date:</h5> -->
                            <div class="form-group focus-label">
                                <!-- <input> placeholder="Recurring Date" -->
                                <ng-select placeholder="Recurring Date" [formControl]="donationForm.controls.Date"
                                    id="numberPicker" [items]="recurringDay" [searchable]="false" [clearable]="false"
                                    bindValue="Id" bindLabel="Name" (change)="checkPayNow($event)">
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-lg-12 d-flex align-items-center amount-chckbx mt-3 mb-3 " *ngIf="showPayNow==1">
                            <div class="checkbox-wrp d-flex align-items-center">
                                <input type="checkbox" class="form-check-input filled-in" name="PayNow" id="PayNow"
                                    type="checkbox" value='{{PayNow}}' [checked]="showPayNow"
                                    (click)="setPaynow($event.target.checked)">
                                <label *ngIf="isCauseSelected" class="form-check-label" for="PayNow">Process this
                                    month's donation today</label>
                            </div>
                        </div>
                    </div>
                    <!-- <button type="submit" class="btn-common mt-2" (click)="nextStep()"
                    [disabled]="!isCauseSelected">Continue</button> -->
                    <button type="submit" class="btn-common mt-2" (click)="nextStep()" [disabled]="isContinue"><span class="span-loader-padding">Continue</span> <i *ngIf="isContinue" class="spinner-border"></i></button>
                </div>
            </div>
        </div>
    </div>

    <div class="bx-white" [hidden]="TabActiveIndex != 1">
        <div class="row">
            <div class="payment-left-img col-md-6 text-center">
                <figure>
                    <img src="./assets/member/images/img-donation-left.svg" alt="">
                </figure>
                <h3>Help us to transform a life by sharing the love of God</h3>
                <p>Your generous gift creates a lasting impact!</p>
            </div>
            <div class="payment-right-form col-md-6">
                <div class="payment-second-section">
                    <div class="thank-msg-area text-center">
                        <h3>Thank you for the donation</h3>
                        <div class="donated-amnt-bx">
                            <!-- <span>$500</span> -->
                            <span>{{Currency}} {{donationAmount}}</span>

                            <h6>{{selectedFrequencyName}} Donation</h6>
                        </div>
                    </div>
                    <div class="delivery-addres mb-3" *ngIf="!hideBillingAddress">
                        <h4>Billing Address</h4>
                        <!-- <div class="form-group focus-label" id="Add1"
                            [ngClass]="donationForm.controls.BillingAddress.controls.Address1 ? 'is-focused' : ''"> -->
                            <div class="form-group focus-label" [ngClass]="donationForm.controls.BillingAddress.controls.Address1 != '' ? 'is-focused' : ''" id="Add1">
                            <!-- <label for="add-msg" class="form-label-of">Street Address</label>
                            <textarea class="form-control" id="add-msg" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"></textarea> -->
                            <label for="add-msg" class="form-label-of">Street Address</label>
                            <!-- <textarea class="form-control" id="add-msg" [formControl]="donationForm.controls.BillingAddress.controls.Address1" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"></textarea> -->
                            <input class="form-control" type="text" id="BillingAddress-Address1"
                                [formControl]="donationForm.controls.BillingAddress.controls.Address1"
                                (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            <span class="m-form__help" *ngFor="let validation of ValidationMessage.Address1">
                                <span class="error-message danger alert"
                                    *ngIf="(donationForm.controls['BillingAddress'].get('Address1')?.hasError(validation.type) && (donationForm.controls['BillingAddress'].get('Address1')?.dirty || donationForm.controls['BillingAddress'].get('Address1')?.touched)) && donationForm.controls['BillingAddress'].get('Address1')?.invalid">
                                    {{ validation.message }}
                                </span>
                            </span>
                        </div>
                        <div class="two-col-wrp d-flex">
                            <!-- <div class="form-group focus-label mr-1" id="Add2"
                                [ngClass]="donationForm.controls.BillingAddress.controls.Address2 ? 'is-focused' : ''"> -->
                                <div class="form-group focus-label mr-1" [ngClass]="donationForm.controls.BillingAddress.controls.Address2 != '' ? 'is-focused' : ''" id="Add2">
                                <!-- <label for="add-code" class="form-label-of">Apt, Suite, Bldg</label>
                                <input class="form-control" id="add-code" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <label for="add-code" class="form-label-of">Apt, Suite, Bldg</label>
                                <input class="form-control" id="add-code"
                                    [formControl]="donationForm.controls.BillingAddress.controls.Address2"
                                    (focus)="animateLabel($event.target)"
                                    (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Address2">
                                    <span class="error-message danger alert"
                                        *ngIf="(donationForm.controls['BillingAddress'].get('Address2')?.hasError(validation.type) && (donationForm.controls['BillingAddress'].get('Address2')?.dirty || donationForm.controls['BillingAddress'].get('Address2')?.touched)) && donationForm.controls['BillingAddress'].get('Address2')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                            <!-- <div class="form-group focus-label ml-1" id="City"
                                [ngClass]="donationForm.controls.BillingAddress.controls.City ? 'is-focused' : ''"> -->
                                <div class="form-group focus-label ml-1" [ngClass]="donationForm.controls.BillingAddress.controls.City != '' ? 'is-focused' : ''" id="City">
                                <!-- <label for="city" class="form-label-of">City</label>
                                <input class="form-control" id="city" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <label for="city" class="form-label-of">City</label>
                                <input class="form-control" id="city"
                                    [formControl]="donationForm.controls.BillingAddress.controls.City"
                                    (focus)="animateLabel($event.target)"
                                    (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.City">
                                    <span class="error-message danger alert"
                                        *ngIf="(donationForm.controls['BillingAddress'].get('City')?.hasError(validation.type) && (donationForm.controls['BillingAddress'].get('City')?.dirty || donationForm.controls['BillingAddress'].get('City')?.touched)) && donationForm.controls['BillingAddress'].get('City')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="two-col-wrp d-flex">
                            <div class="form-group focus-label mr-1">
                                <!-- <input> -->
                                <ng-select [formControl]="donationForm.controls.BillingAddress.controls.StateId"
                                    [items]="StatesList" id="BillingAddress-StateId" placeholder="State"
                                    bindValue="StateId" bindLabel="StateName" [(ngModel)]="state" [clearable]="false">
                                </ng-select>
                                <span class="error-message alert danger"
                                    *ngIf="(donationForm.controls['BillingAddress'].get('StateId')?.dirty || donationForm.controls['BillingAddress'].get('StateId')?.touched) && donationForm.controls['BillingAddress'].get('StateId')?.invalid">
                                    Please select your state
                                </span>
                            </div>
                            <!-- <div class="form-group focus-label ml-1" id="Code"
                                [ngClass]="donationForm.controls.BillingAddress.controls.Zip ? 'is-focused' : ''"> -->
                                <div class="form-group focus-label ml-1" [ngClass]="donationForm.controls.BillingAddress.controls.Zip != '' ? 'is-focused' : ''" id="Code">
                                <!-- <label for="postal-code" class="form-label-of">Postal code</label>
                                <input class="form-control" id="postal-code" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <label for="postal-code" class="form-label-of">Postal code</label>
                                <input class="form-control" id="postal-code"
                                    [formControl]="donationForm.controls.BillingAddress.controls.Zip"
                                    (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"
                                    (input)="billingZipValidation($event.target.value)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Zip">
                                    <span class="error-message danger alert"
                                        *ngIf="(donationForm.controls['BillingAddress'].get('Zip')?.hasError(validation.type) && (donationForm.controls['BillingAddress'].get('Zip')?.dirty || donationForm.controls['BillingAddress'].get('Zip')?.touched)) && donationForm.controls['BillingAddress'].get('Zip')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>

                    </div>
                    <div class="payment-method-list">
                        <h4>Choose payment method:</h4>
                        <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing provident.</p> -->
                        <ul class="payment-radio-choose">
                            <!-- <li class="d-flex active">
                                <input type="radio" id="test1" name="radio-group" checked>
                                <label for="test1">Credit/Debit</label>
                            </li>
                            <li class="d-flex">
                                <input type="radio" id="test2" name="radio-group">
                                <label for="test2">Bank Transfer</label>
                            </li>
                            <li class="d-flex">
                                <input type="radio" id="test3" name="radio-group">
                                <label for="test3">UPI Payment</label>
                            </li> -->
                            <li class="d-flex" *ngFor="let payment of PaymentMethods; let i= index;">
                                <input type="radio" id="{{payment.PaymentMethod}}" name="radio-group"
                                    (change)="onPaymentMethodChange($event.target.id)">
                                <label for="{{payment.PaymentMethod}}">
                                    <img *ngIf="isSpfMember"
                                        src="../../assets/images/icon-{{payment.UniqueName}}-purple.svg" alt=""
                                        style="width:50px; height: 20px;">
                                    <img *ngIf="singleDonationExist == true && isSpfMember == false"
                                        src="../../assets/images/icon-{{payment.UniqueName}}-green.svg" alt=""
                                        style="width:50px; height: 20px;">
                                    <img *ngIf="singleDonationExist == false && isSpfMember == false"
                                        src="../../assets/images/icon-{{payment.UniqueName}}-brown.svg" alt=""
                                        style="width:50px; height: 20px;">
                                    {{payment.text}}
                                </label>

                            </li>
                        </ul>
                        <span class="error-message alert danger" *ngIf="paymentMethodError">
                            Please choose payment method
                        </span>
                    </div>
                    <div class="btn-wrp d-flex">
                        <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                        <button type="submit" class="btn-common mt-2 ml-3" (click)="nextStep()">Continue</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bx-white" [hidden]="TabActiveIndex != 2">
        <div class="row">
            <div class="payment-left-img col-md-6 text-center">
                <figure>
                    <img src="./assets/member/images/img-donation-left.svg" alt="">
                </figure>
                <h3>Help us to transform a life by sharing the love of God</h3>
                <p>Your generous gift creates a lasting impact!</p>
            </div>
            <div class="payment-right-form col-md-6">
                <div class="payment-third-section">
                    <div class="row payment-method-field">
                        <div class="col-7 payment-method-head-left">
                            <h3>Payment Method</h3>
                            <span>{{selectedPaymenttext}}</span>
                            <!-- <span> Credit/ Debit card</span> -->
                        </div>
                        <div class="col-5 text-right payment-method-head-right">
                            <h3>Amount</h3>
                            <span>{{Currency}} {{donationAmount}}</span>
                        </div>
                    </div>
                    <!-- <div class="bx-add-card">
                        <h6 class="text-center">Add a new card</h6>
                    </div>
                    <div id="payment" class="col-lg-12" [hidden]="selectedPaymentMethod == ''">
                        <div class="row m-0 mob-row-margin payment-sec-wrp">
                            <div class="col-lg-12 title-area mt-3 mb-3 px-0" [hidden]="selectedPaymentMethod != 'ach'">
                                <h4>Payment Information</h4>
                                <div class="btn-add text-right">
                                    <button type="button" class="btn-common" *ngIf="IsSavedAchPresent" (click)="AddNewAchAccount()">+New Account</button>
                                    <button type="button" class="btn-common" *ngIf="!IsSavedAchPresent && savedAchCardButtonShow" (click)="listsavedAchAccount()">Use Existing Account</button>
                                    <hr>
                                </div>
                            </div>
                            <div class="col-lg-12 title-area mt-3  mb-3 px-0" [hidden]="selectedPaymentMethod != 'credit_or_debt'">
                                
                            </div>

                            <div [hidden]="selectedPaymentMethod != 'ach'" class="w-100 ach">
                                <span class="col-sm-12 m-form__help">
                                    <span class="error-message alert danger"
                                        *ngIf="( this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.dirty || this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.touched) && this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.invalid">
                                        Please select your account
                                    </span>
                                </span>
                                <div class="row mob-no-margin" *ngIf="IsSavedAchPresent">
                                    <div class="col-lg-6 form-check radio-btn-wrp mob-padding" *ngFor="let option of savedCards; let i=index">
                                        <div class="bx-radio-btn-each">
                                            <input [formControl]="donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.ChooseCard" type="radio" class="form-check-input" value="{{option.PayMethodToken}}" id="{{option.PayMethodToken}}" (change)="onSelectedSavedcardChange($event.target.id)">
                                            <label for="{{option.PayMethodToken}}">
                                                <span class="d-block">
                                                    {{option.AccountHolder}}
                                                </span>
                                                <span>
                                                    {{option.MaskedAccountNumber}}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 px-0 choose-radio-wrp" *ngIf="showNewCardOption">
                                    <div class="row">
                                        <div class="col-md-12 col-lg-12 d-flex mob-mb acnt-radio-type">
                                            <span class="radio-label">Account Type</span>
                                            <div class="form-check radio-btn-wrp" *ngFor="let accounttype of accounttypeList;let i = index;">
                                                <input type="radio" class="form-check-input" id="{{accounttype.value}}" [formControl]="donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountType" value="{{accounttype.value}}">
                                                <label class="form-check-label" for="{{accounttype.value}}">
                                                    {{accounttype.name}}
                                                </label>
                                            </div>

                                        </div>
                                        <span class="col-sm-12 m-form__help">
                                            <span class="error-message alert danger"
                                                *ngIf="( this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.dirty || this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.touched) && this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.invalid">
                                                Please select your account type
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-12 px-0 mt-3" *ngIf="!IsSavedAchPresent || showNewCardOption">
                                    <div class="row">
                                        <div class="col-md-4 col-lg-4 pr-0 mob-pr">
                                            <div class="form-group focus-label">
                                                <label class="form-label-of" for="ach_account_number">Account
                                                    Number</label>
                                                <input [formControl]="donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountNumber" type="text" name="ach_account_number" id="ach_account_number" class=" required" data-rule-number="true" (focus)="animateLabel($event.target)"
                                                    (focusout)="animateLabelRemove($event.target)" />
                                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.AccountNumber">
                                                    <span class="error-message danger alert"
                                                        *ngIf="(donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.hasError(validation.type) && (donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.dirty || donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.touched)) && donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.invalid">
                                                        {{ validation.message }}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-lg-4">
                                            <div class="form-group focus-label">
                                                <label class="form-label-of" for="ach_routing_number">Routing
                                                    Number</label>
                                                <input [formControl]="donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.RoutingNumber" type="text" class="required" autocomplete="off" name="ach_routing_number" id="ach_routing_number" data-rule-number="true" (focus)="animateLabel($event.target)"
                                                    (focusout)="animateLabelRemove($event.target)">
                                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.RoutingNumber">
                                                    <span class="error-message danger alert"
                                                        *ngIf="(donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.hasError(validation.type) && (donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.dirty || donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.touched)) && donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.invalid">
                                                        {{ validation.message }}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-lg-4 pl-0 mob-pl">
                                            <div class="form-group focus-label">
                                                <label class="form-label-of" for="accName">Name on Account</label>
                                                <input [formControl]="donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountHolder" type="text" autocomplete="off" name="ach_person_name" id="ach_person_name" class="required" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"
                                                    maxlength="100">
                                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.AccountHolder">
                                                    <span class="error-message danger alert"
                                                        *ngIf="(donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.hasError(validation.type) && (donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.dirty || donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.touched)) && donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.invalid">
                                                        {{ validation.message }}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="checkbox-wrp ml-3" *ngIf="isSavedCard && selectedFrequencyName == 'Onetime'">
                                            <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" id="save-id">
                                            <label for="save-id">Save Account for Future</label>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div [hidden]="selectedPaymentMethod != 'manual_payment'" class="p-0 ach col-xs-12">
                                <div class="row">

                                    <div class="col-md-12 col-lg-12">
                                        <span class="total-amnt-bold">BankAccount Details : </span>
                                        <br>
                                        <span style="white-space: pre-line;">{{text}}</span>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div> -->
                    <!-- ach saved card list -->
                    <i *ngIf="loaderAchSaved" class="spinner-border common-loader"></i>

                    <div class="saved-card-wrp mt-2" style="text-align: center;"
                        *ngIf="selectedPaymentMethod == 'ach' && IsSavedAchPresent">
                        <h6>Saved Accounts</h6>
                        <span class="col-sm-12 m-form__help">
                            <span class="error-message alert danger"
                                *ngIf="( this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.dirty || this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.touched) && this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.invalid">
                                Please select your account
                            </span>
                        </span>
                        <ul class="payment-radio-choose">
                            <li class="d-flex active" *ngFor="let option of savedCards; let i=index">
                                <input type="radio" name="ChooseCard" checked=""
                                    [formControl]="donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.ChooseCard"
                                    value="{{option.PayMethodToken}}" id="{{option.PayMethodToken}}"
                                    (change)="onSelectedSavedcardChange($event.target.id)">
                                <label for="{{option.PayMethodToken}}" class="d-flex">
                                    <img src="./assets/member/images/ach.png" alt="" style="width:50px; height: 20px;">
                                    <span>{{option.MaskedAccountNumber}}</span>{{option.AccountHolder}}
                                </label>
                            </li>

                        </ul>
                        <div class="mob-mb  single-radio form-group mb-3"
                            *ngIf="selectedPaymentMethod == 'ach' && IsSavedAchPresent">
                            <div class="form-check  pl-0">
                                <input class="form-check-input" [formControl]="donationForm.controls.AddNewCard"
                                    type="checkbox" value="" id="acc" (change)="isAddNewCard($event.target.checked)">
                                <label class="form-check-label" for="acc"> Add new account </label>
                            </div>
                        </div>
                        <div class="btn-wrp d-flex" *ngIf="!addNewCard">
                            <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                            <button type="submit" class="btn-common mt-2 ml-3" (click)="submitForm('savedCard')"
                                [disabled]="buttonStatus"><span class="span-loader-padding">Make Payment</span> <i
                                    *ngIf="isLoading" class="spinner-border"></i></button>
                        </div>
                    </div>

                    <!-- ach new card -->
                    <div class="bx-add-card mt-4" *ngIf="selectedPaymentMethod == 'ach' && addNewCard">
                        <h6 class="text-center">Add a new account</h6>
                        <div class="radio-choose-type mt-3 mb-2 d-flex align-item-center">
                            <h5 class="mb-0 mr-3 mt-1">Account Type</h5>
                            <ul class="d-flex">
                                <li *ngFor="let accounttype of accounttypeList;let i = index;">
                                    <input type="radio" class="form-check-input" id="{{accounttype.name}}"
                                        [formControl]="donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountType"
                                        value="{{accounttype.value}}">
                                    <label class="form-check-label" for="{{accounttype.name}}">
                                        {{accounttype.name}}
                                    </label>
                                </li>
                            </ul>
                            <span class="col-sm-12 m-form__help">
                                <span class="error-message alert danger"
                                    *ngIf="( this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.dirty || this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.touched) && this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.invalid">
                                    Please select your account type
                                </span>
                            </span>
                        </div>
                        <div class="two-col-row d-flex">
                            <div class="form-group focus-label mr-1" id="account_number">
                                <label for="ac-num" class="form-label-of">Account Number</label>
                                <input id="ac-num" class="form-control"
                                    [formControl]="donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountNumber"
                                    (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"
                                    data-rule-number="true">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.AccountNumber">
                                    <span class="error-message danger alert"
                                        *ngIf="(donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.hasError(validation.type) && (donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.dirty || donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.touched)) && donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                            <div class=" form-group focus-label ml-1" id="ach_routing_number">
                                <label for="route-num" class="form-label-of">Routing Number</label>
                                <input id="route-num" class="form-control"
                                    [formControl]="donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.RoutingNumber"
                                    (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"
                                    data-rule-number="true">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.RoutingNumber">
                                    <span class="error-message danger alert"
                                        *ngIf="(donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.hasError(validation.type) && (donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.dirty || donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.touched)) && donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                            <div class="form-group focus-label mr-1">
                                <label for="acnt-name" class="form-label-of">Name on Account</label>
                                <input id="acnt-name"
                                    [formControl]="donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountHolder"
                                    class="form-control" (focus)="animateLabel($event.target)"
                                    (focusout)="animateLabelRemove($event.target)" maxlength="100">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.AccountHolder">
                                    <span class="error-message danger alert"
                                        *ngIf="(donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.hasError(validation.type) && (donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.dirty || donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.touched)) && donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                            <!-- <div class="form-group checkbx-handle ml-1">
                                <input type="checkbox" id="html" [formControl]="donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.SaveCardForFuture">
                                <label for="html">Save Account for Future</label>
                            </div> -->
                        </div>
                    </div>
                    <div class="bx-add-card" *ngIf="selectedPaymentMethod == 'manual_payment'">
                        <div class="col-md-12 col-lg-12">
                            <span class="total-amnt-bold">BankAccount Details: </span>
                            <br>
                            <span style="white-space: pre-line;">{{text}}</span>
                        </div>
                    </div>
                    <div class="btn-wrp d-flex" *ngIf="selectedPaymentMethod == 'manual_payment'">
                        <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                        <button type="submit" class="btn-common mt-2 ml-3" (click)="submitForm()"
                            [disabled]="buttonStatus"><span class="span-loader-padding">Make Payment</span> <i
                                *ngIf="isLoading && !savedCardLoding" class="spinner-border"></i></button>
                    </div>
                    <i *ngIf="loaderCreditsaved" class="spinner-border common-loader"></i>
                    <div class="" *ngIf="selectedPaymentMethod == 'credit_or_debt' && shownewCreditcardOption">
                        <h6 class="text-center" style="text-align: center;">Saved Cards </h6>
                        <span class="col-sm-12 m-form__help">
                            <span class="error-message alert danger"
                                *ngIf="( this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.dirty || this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.touched) && this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.invalid">
                                Please select your account
                            </span>
                        </span>
                        <ul class="payment-radio-choose">
                            <li class="d-flex active" *ngFor="let option of savedCreditCards; let i=index">
                                <input type="radio" name="ChooseCard" checked=""
                                    [formControl]="donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.ChooseCard"
                                    value="{{option.PaymentGatewayToken}}" id="{{option.PaymentGatewayToken}}"
                                    (change)="onSelectedSavedcardChange($event.target.id)">
                                <label for="{{option.PaymentGatewayToken}}" class="d-flex" style="padding-left: 10px;">
                                    <img src="{{option.CreditCardImageUrl}}" alt="" style="width:50px; height: 20px;">
                                    <span>xxx {{option.Last4}}</span>{{option.CardHolderName}}
                                </label>
                            </li>

                        </ul>
                        <div class="mob-mb  single-radio form-group mb-3"
                            *ngIf="selectedPaymentMethod == 'credit_or_debt' && shownewCreditcardOption">
                            <div class="form-check  pl-0">
                                <input class="form-check-input" [formControl]="donationForm.controls.AddNewCard"
                                    type="checkbox" value="" id="acc" (change)="isAddNewCard($event.target.checked)">
                                <label class="form-check-label" for="acc"> Add New Card </label>
                            </div>
                        </div>
                        <i *ngIf="loaderCreditNew" class="spinner-border common-loader"></i>

                        <div class="btn-wrp d-flex" *ngIf="!addNewCard">
                            <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                            <button type="submit" class="btn-common mt-2 ml-3" (click)="submitForm('savedCreditcard')"
                                [disabled]="buttonStatus"><span class="span-loader-padding">Make Payment</span> <i
                                    *ngIf="isLoading" class="spinner-border"></i></button>
                        </div>
                    </div>

                    <div class="bx-add-card" *ngIf="selectedPaymentMethod == 'credit_or_debt' && addNewCard">
                        <h6 class="text-center">Add a new card</h6>
                        <div *ngIf="selectedPaymentMethod == 'credit_or_debt'"
                            class="p-0 credit_or_debt clearfix col-xs-12">
                            <!-- hosted fields -->
                            <div class="row" style="margin-top: -15px;">
                                <div class="field-container col-md-12 col-lg-12">
                                    <!-- Begin hosted fields section -->
                                    <label class="hosted-field--label" for="card-number">
                                    </label>
                                    <div class="hosted-field" id="card-number"></div>
                                    <span class="m-form__help" *ngIf="noFieldsCardNumber == 1">
                                        <span class="error-message danger alert">
                                            Please enter a valid card number
                                        </span>
                                    </span>
                                    <!-- End hosted fields section -->
                                </div>
                                <!-- <div class="textfield--float-label">
    <input type="text" class="account_holder" placeholder="Account Holder">
</div> -->

                            </div>
                            <div class="row" style=" margin-top: -10px;">
                                <div class="field-container col-md-6 col-lg-6" style="padding-right: 5px;">
                                    <!-- Begin hosted fields section -->
                                    <label class="hosted-field--label" for="expiration-date">
                                        <!-- Expiration Date -->
                                    </label>
                                    <div class="hosted-field" id="expiration-date"></div>
                                    <span class="m-form__help" *ngIf="noFieldsDate == 1">
                                        <span class="error-message danger alert">
                                            Please enter a valid card expiration date
                                        </span>
                                    </span>
                                    <!-- <div id="expiration-date" class="hosted-field"></div> -->
                                    <!-- End hosted fields section -->
                                </div>
                                <div class="field-container col-md-6 col-lg-6" style="padding-left: 5px;" *ngIf="!isCvvhide">
                                    <!-- Begin hosted fields section -->
                                    <label class="hosted-field--label" for="cvv">
                                        <!-- CVV -->
                                    </label>
                                    <!-- End hosted fields section -->
                                    <div class="hosted-field" id="cvv"></div>
                                    <span class="m-form__help" *ngIf="noFieldsCvv == 1">
                                        <span class="error-message danger alert">
                                            Please enter a valid card security code (CVV)
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <!-- <div class="textfield--float-label"> -->
                            <!-- Begin hosted fields section -->
                            <!-- <label id="postal-code" class="hosted-field--label" for="postal-code"> -->
                            <!-- Postal Code -->
                            <!-- </label> -->
                            <!-- End hosted fields section -->
                            <!-- </div> -->
                            <div style="color:#ff5a5a; padding: 0px;" *ngIf="brainTreeError != ''" class="alert danger">
                                <p>{{brainTreeError}}</p>
                            </div>
                            <div style="color:#ff5a5a; padding: 0px;" *ngIf="paymentGatewayErrorMsg !=null"
                                class="alert danger">
                                <p>{{paymentGatewayErrorMsg}}</p>
                            </div>
                            <!-- <button class="pay-button">Pay</button> -->
                        </div>
                    </div>
                   
                    <div class="btn-wrp d-flex" *ngIf="((addNewCard && selectedPaymentMethod != 'gpay') && (addNewCard && selectedPaymentMethod != 'paypal'))">
                        <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                        <button type="submit" class="btn-common mt-2 ml-3" (click)="submitForm()"
                            [disabled]="buttonStatus"><span class="span-loader-padding">Make Payment</span> <i
                                *ngIf="isLoading && !savedCardLoding" class="spinner-border"></i></button>
                    </div>
                    <!-- <div class="mob-mb  single-radio form-group mb-3"
                    *ngIf="selectedPaymentMethod == 'gpay'"></div> -->

                    <div class="btn-wrp d-flex mt-2"  *ngIf="((selectedPaymentMethod == 'gpay') || (selectedPaymentMethod == 'paypal'))">
                        <button type="submit" class="btn-border mr-3" (click)="prevStep()">Back</button>
                        <div class="gpay-wrap  ml-3" *ngIf="selectedPaymentMethod == 'gpay'">
                            <google-pay-button
                            class="span-loader-padding"
                                environment="TEST"
                            
                                [buttonColor]="buttonColor"
                                [buttonType]="buttonType"
                                [buttonSizeMode]="isCustomSize ? 'fill' : 'static'"
                                [paymentRequest]="paymentRequest"
                                [style.width.px]="buttonWidth"
                                [style.height.px]="buttonHeight"
                                [clickCallback]="onClickPreventDefault"
                                (loadpaymentdata)="onLoadPaymentData($event)"
                                [paymentAuthorizedCallback]="onPaymentDataAutherized"
                                
                                >
                                
                            </google-pay-button>
                            <div class="loader" *ngIf="loadingGpay && selectedPaymentMethod == 'gpay'" >
                                <div class="loading">
                                </div>
                            </div>
                        </div>
                        <div *ngIf="selectedPaymentMethod == 'paypal'">
                            <div id="paypal-button"></div>
                            <span class="spinner-border common-loader" *ngIf="paypalLoad" style="margin-top: 25px;
                            margin-left: 10px;"></span>
                        </div>
                       
                    </div>
                  
                </div>
            </div>
        </div>
    </div>
</div>