<div class="payment-wrp">
    <form [formGroup]="makePaymentForm">
        <div class="bx-white" [hidden]="TabActiveIndex == 1">
            <div class="row">
                <div class="payment-left-img col-md-6 text-center">
                    <figure>
                        <img src="./assets/member/images/img-eventreg-left.svg" alt="">
                    </figure>
                    <h3>Come, celebrate and live a life in Christ!</h3>
                    <p>Have a friend who would enjoy this event? Invite them too!</p>
                </div>
                <div class="payment-right-form col-md-6">
                    <div class="payment-second-section">
                        <div class="thank-msg-area text-center">
                            <h3>You are almost there...</h3>
                            <div class="donated-amnt-bx">
                                <!-- <span>$500</span> -->
                                <span>{{eventDetails?.PendingAmount}} {{eventDetails?.CurrencyISOCode}}</span>
                                <h6>Balance Amount</h6>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="form-group focus-label" id="balAmount">
                                <label class="form-label-of" for="Amount">Amount to Pay</label>
                                <input formControlName="Amount" type="text" id="Amount"
                                    (focus)="animateLabel($event.target)" [(ngModel)]="PendingAmount"
                                    (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Amount">
                                    <span class="error-message"
                                        *ngIf="makePaymentForm.get('Amount').hasError(validation.type) && (makePaymentForm.get('Amount').dirty || makePaymentForm.get('Amount')?.touched)">
                                        {{validation.message}}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="payment-method-list" formGroupName="PaymentProcessParams">
                            <h4>Choose payment method:</h4>
                            <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing provident.</p> -->
                            <ul class="payment-radio-choose">
                                <li *ngFor="let payment of PaymentMethods; let i= index;"
                                    class="d-flex active paymntList">
                                    <input type="radio" class="form-check-input" id="{{payment.PaymentMethod}}"
                                        formControlName="PaymentMethodId" value="{{payment.PaymentMethodId}}"
                                        (change)="onPaymentMethodChange($event.target.id)" required [checked]="false">
                                    <label for="{{payment.PaymentMethod}}">
                                        <img *ngIf="isSpfMember"
                                            src="../../assets/images/icon-{{payment.UniqueName}}-purple.svg" alt=""
                                            style="width:50px; height: 20px;">
                                        <img *ngIf="singleDonationExist == true && isSpfMember == false"
                                            src="../../assets/images/icon-{{payment.UniqueName}}-green.svg" alt=""
                                            style="width:50px; height: 20px;">
                                        <img *ngIf="singleDonationExist == false && isSpfMember == false"
                                            src="../../assets/images/icon-{{payment.UniqueName}}-brown.svg" alt=""
                                            style="width:50px; height: 20px;">
                                        {{payment.PaymentMethod}}
                                    </label>
                                </li>
                            </ul>
                            <span class="error-message alert danger" *ngIf="paymentMethodError">
                                Please choose payment method
                            </span>
                        </div>
                        <div class="btn-wrp d-flex">
                            <!-- <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button> -->
                            <button type="submit" class="btn-common mt-2" (click)="nextStep()">Continue</button>
                            <!-- <button type="submit" *ngIf="zeroAmount == 1" class="btn-common mt-2 ml-3" (click)="tokenizeUserDetails()">Make Payment <i *ngIf="isLoading" class="spinner-border"></i></button> -->

                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="bx-white" [hidden]="TabActiveIndex == 0">
            <div class="row">
                <div class="payment-left-img col-md-6 text-center">
                    <figure>
                        <!-- <img src="./assets/member/images/img-subscription-left.svg" alt=""> -->
                        <img src="./assets/member/images/img-eventreg-left.svg" alt="">
                    </figure>
                    <h3>Come, celebrate and live a life in Christ!</h3>
                    <p>Have a friend who would enjoy this event? Invite them too!</p>
                </div>
                <div class="payment-right-form col-md-6" formGroupName="PaymentProcessParams">
                    <div class="payment-third-section" formGroupName="PaymentFields">
                        <div class="row payment-method-field" *ngIf="selectedPaymentMethod == 'credit_or_debt'">
                            <div class="col-7 payment-method-head-left">
                                <h3>Payment method</h3>
                                <span>Credit/ Debit card</span>
                            </div>
                            <div class="col-5 text-right payment-method-head-right">
                                <h3>Amount</h3>
                                <span>{{PendingAmount}} {{Currency}}</span>
                            </div>
                        </div>
                        <i *ngIf="loaderCreditsaved" class="spinner-border common-loader"></i>
                        <div class="" style="text-align: center;"
                            *ngIf="selectedPaymentMethod == 'credit_or_debt' && listSavedCreditCard">
                            <h6>Saved Cards </h6>
                            <span class="col-sm-12 m-form__help">
                                <span class="error-message alert danger"
                                    *ngIf="( this.makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.dirty || this.makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.touched) && this.makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.invalid">
                                    Please select your account
                                </span>
                            </span>
                            <ul class="payment-radio-choose mb-3">
                                <li class="d-flex active" *ngFor="let option of savedCreditCards; let i=index">
                                    <input type="radio" name="ChooseCard" checked="" formControlName="ChooseCard"
                                        value="{{option.PaymentGatewayToken}}" id="{{option.PaymentGatewayToken}}"
                                        (change)="onSelectedSavedcardChange($event.target.id)">
                                    <label for="{{option.PaymentGatewayToken}}" class="d-flex"
                                        style="padding-left: 10px;">
                                        <img src="{{option.CreditCardImageUrl}}" alt=""
                                            style="width: 50px;height: 20px;">
                                        <span>{{option.Last4}}</span>{{option.CardHolderName}}
                                    </label>
                                </li>

                            </ul>
                            <div class="mob-mb  single-radio form-group mb-3"
                                *ngIf="selectedPaymentMethod == 'credit_or_debt' && listSavedCreditCard">
                                <div class="form-check  pl-0">
                                    <input class="form-check-input" [formControl]="makePaymentForm.controls.AddNewCard"
                                        type="checkbox" value="" id="acc"
                                        (change)="isAddNewCard($event.target.checked)">
                                    <label class="form-check-label" for="acc"> Add New Card </label>
                                </div>
                            </div>
                            <i *ngIf="loaderCreditNew" class="spinner-border common-loader"></i>

                            <div class="btn-wrp d-flex" *ngIf="!addNewCard">
                                <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                                <button type="submit" class="btn-common mt-2 ml-3"
                                    (click)="tokenizeUserDetails('savedCreditcard')"><span
                                        class="span-loader-padding">Make Payment</span> <i *ngIf="isLoading"
                                        class="spinner-border"></i></button>
                            </div>
                            <!-- <div class="btn-wrp d-flex">
                        <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                        <button type="submit" class="btn-common mt-2 ml-3" (click)="tokenizeUserDetails('savedCreditcard')">Make Payment<i *ngIf="isLoading && savedCardLoding" class="spinner-border"></i></button>
                    </div> -->
                        </div>
                        <div class="bx-add-card" *ngIf="selectedPaymentMethod == 'credit_or_debt' && addNewCard">
                            <h6 class="text-center">Add a new card</h6>
                            <div class="p-0 credit_or_debt clearfix col-xs-12">
                                <div class="row" style="margin-top: -15px;">
                                    <div class="field-container col-md-12 col-lg-12">
                                        <!-- Begin hosted fields section -->
                                        <label class="hosted-field--label" for="card-number">
                                        </label>
                                        <div class="hosted-field" id="card-number"></div>
                                        <span class="m-form__help" *ngIf="noFieldsCardNumber == 1">
                                            <span class="error-message danger alert">
                                                Please enter a valid card number
                                            </span>
                                        </span>
                                        <!-- End hosted fields section -->
                                    </div>
                                    <!-- <div class="textfield--float-label">
                            <input type="text" class="account_holder" placeholder="Account Holder">
                        </div> -->
                                </div>
                                <div class="row" style="margin-top: -10px;">
                                    <div class="field-container col-md-6 col-lg-6" style="padding-right: 5px;">
                                        <!-- Begin hosted fields section -->
                                        <label class="hosted-field--label" for="expiration-date">
                                            <!-- Expiration Date -->
                                        </label>
                                        <div class="hosted-field" id="expiration-date"></div>
                                        <span class="m-form__help" *ngIf="noFieldsDate == 1">
                                            <span class="error-message danger alert">
                                                Please enter a valid card expiration date
                                            </span>
                                        </span>
                                        <!-- <div id="expiration-date" class="hosted-field"></div> -->
                                        <!-- End hosted fields section -->
                                    </div>
                                    <div class="field-container col-md-6 col-lg-6" style="padding-left: 5px;"
                                        *ngIf="!isCvvhide">
                                        <!-- Begin hosted fields section -->
                                        <label class="hosted-field--label" for="cvv">
                                            <!-- CVV -->
                                        </label>
                                        <!-- End hosted fields section -->
                                        <div class="hosted-field" id="cvv"></div>
                                        <span class="m-form__help" *ngIf="noFieldsCvv == 1">
                                            <span class="error-message danger alert">
                                                Please enter a valid card security code (CVV)
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div style="color:#ff5a5a; padding: 0px;" *ngIf="brainTreeError != ''"
                                    class="alert danger">
                                    <p>{{brainTreeError}}</p>
                                </div>
                                <div style="color:#ff5a5a; padding: 0px;" *ngIf="paymentGatewayErrorMsg !=null"
                                    class="alert danger">
                                    <p>{{paymentGatewayErrorMsg}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="btn-wrp d-flex" *ngIf="selectedPaymentMethod == 'credit_or_debt' && addNewCard">
                            <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                            <button type="submit" class="btn-common mt-2 ml-3" (click)="tokenizeUserDetails()"><span
                                    class="span-loader-padding">Make Payment</span> <i *ngIf="isLoading"
                                    class="spinner-border"></i></button>
                        </div>
                        <!--GPAY -->
                        <div class="row payment-method-field" *ngIf="selectedPaymentMethod == 'gpay'">
                            <div class="col-7 payment-method-head-left">
                                <h3>Payment method</h3>
                                <span>Google Pay</span>
                            </div>
                            <div class="col-5 text-right payment-method-head-right">
                                <h3>Amount</h3>
                                <span>{{PendingAmount}} {{Currency}}</span>
                            </div>
                        </div>
                        <!--PAYPAL -->
                        <div class="row payment-method-field" *ngIf="selectedPaymentMethod == 'paypal'">
                            <div class="col-7 payment-method-head-left">
                                <h3>Payment method</h3>
                                <span>PayPal</span>
                            </div>
                            <div class="col-5 text-right payment-method-head-right">
                                <h3>Amount</h3>
                                <span>{{PendingAmount}} {{Currency}}</span>
                            </div>
                        </div>
                        <!-- ACH -->
                        <div class="row payment-method-field" *ngIf="selectedPaymentMethod == 'ach'">
                            <div class="col-7 payment-method-head-left">
                                <h3>Payment method</h3>
                                <span>ACH</span>
                            </div>
                            <div class="col-5 text-right payment-method-head-right">
                                <h3>Amount</h3>
                                <span>{{PendingAmount}} {{Currency}}</span>
                            </div>
                        </div>
                        <!-- ach saved card list -->
                        <i *ngIf="loaderAchSaved" class="spinner-border common-loader"></i>

                        <div class="saved-card-wrp mt-2" style="text-align: center;"
                            *ngIf="selectedPaymentMethod == 'ach' && IsSavedAchPresent">
                            <h6>Saved Accounts</h6>
                            <span class="col-sm-12 m-form__help">
                                <span class="error-message alert danger"
                                    *ngIf="( this.makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.dirty || this.makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.touched) && this.makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.invalid">
                                    Please select your account
                                </span>
                            </span>
                            <ul class="payment-radio-choose">
                                <li class="d-flex active" *ngFor="let option of savedCards; let i=index">
                                    <input type="radio" name="ChooseCard" checked=""
                                        [formControl]="makePaymentForm.controls.PaymentProcessParams.controls.PaymentFields.controls.ChooseCard"
                                        value="{{option.PayMethodToken}}" id="{{option.PayMethodToken}}"
                                        (change)="onSelectedSavedcardChange($event.target.id)">
                                    <label for="{{option.PayMethodToken}}" class="d-flex">
                                        <img src="./assets/member/images/ach.png" alt=""
                                            style="width:50px; height: 20px;">
                                        <span>{{option.MaskedAccountNumber}}</span>{{option.AccountHolder}}
                                    </label>
                                </li>

                            </ul>
                            <div class="mob-mb  single-radio form-group mb-3"
                                *ngIf="selectedPaymentMethod == 'ach' && IsSavedAchPresent">
                                <div class="form-check  pl-0">
                                    <input class="form-check-input" [formControl]="makePaymentForm.controls.AddNewCard"
                                        type="checkbox" value="" id="acc"
                                        (change)="isAddNewCard($event.target.checked)">
                                    <label class="form-check-label" for="acc"> Add new account </label>
                                </div>
                            </div>
                            <div class="btn-wrp d-flex" *ngIf="!addNewCard">
                                <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                                <button type="submit" class="btn-common mt-2 ml-3"
                                    (click)="tokenizeUserDetails('savedcard')"><span class="span-loader-padding">Make
                                        Payment</span> <i *ngIf="isLoading" class="spinner-border"></i></button>
                            </div>
                            <!-- <div class="btn-wrp d-flex" >
                            <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                            <button type="submit" class="btn-common mt-2 ml-3" (click)="tokenizeUserDetails('savedcard')">Make Payment<i *ngIf="isLoading && savedCardLoding" class="spinner-border"></i></button>
                        </div> -->
                        </div>
                        <!-- ach new card -->
                        <div class="bx-add-card mt-4" *ngIf="selectedPaymentMethod == 'ach' && addNewCard">
                            <h6 class="text-center">Add a new account</h6>
                            <div class="radio-choose-type mt-3 mb-2 d-flex align-item-center">
                                <h5 class="mb-0 mr-3 mt-1">Account Type</h5>
                                <ul class="d-flex">
                                    <li *ngFor="let accounttype of accounttypeList;let i = index;">
                                        <input type="radio" class="form-check-input" id="{{accounttype.name}}"
                                            formControlName="AccountType" value="{{accounttype.value}}">
                                        <label class="form-check-label" for="{{accounttype.name}}">
                                            {{accounttype.name}}
                                        </label>
                                    </li>
                                    <!-- <div class="form-check radio-btn-wrp" *ngFor="let accounttype of accounttypeList;let i = index;">
                                    <input type="radio" class="form-check-input" id="{{accounttype.value}}" formControlName="AccountType" value="{{accounttype.value}}">
                                    <label class="form-check-label" for="{{accounttype.value}}">
                                    {{accounttype.name}}
                                    </label>
                                </div> -->
                                    <!-- <li>
                                    <input type="radio" id="type2" name="radio-group">
                                    <label for="type2">Savings</label>
                                </li> -->
                                </ul>
                                <span class="col-sm-12 m-form__help">
                                    <span class="error-message alert danger"
                                        *ngIf="( this.makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.dirty || this.makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.touched) && this.makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.invalid">
                                        Please select your account type
                                    </span>
                                </span>
                            </div>
                            <div class="two-col-row d-flex">
                                <div class="form-group focus-label mr-1" id="account_number">
                                    <label for="ac-num" class="form-label-of">Account Number</label>
                                    <input id="ac-num" class="form-control" formControlName="AccountNumber"
                                        (focus)="animateLabel($event.target)"
                                        (focusout)="animateLabelRemove($event.target)" data-rule-number="true">
                                    <span class="m-form__help"
                                        *ngFor="let validation of ValidationMessage.AccountNumber">
                                        <span class="error-message danger alert"
                                            *ngIf="(makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.hasError(validation.type) && (makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.dirty || makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.touched)) && makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.invalid">
                                            {{ validation.message }}
                                        </span>
                                    </span>
                                </div>
                                <div class=" form-group focus-label ml-1" id="ach_routing_number">
                                    <label for="route-num" class="form-label-of">Routing Number</label>
                                    <input id="route-num" class="form-control" formControlName="RoutingNumber"
                                        (focus)="animateLabel($event.target)"
                                        (focusout)="animateLabelRemove($event.target)" data-rule-number="true">
                                    <span class="m-form__help"
                                        *ngFor="let validation of ValidationMessage.RoutingNumber">
                                        <span class="error-message danger alert"
                                            *ngIf="(makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.hasError(validation.type) && (makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.dirty || makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.touched)) && makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.invalid">
                                            {{ validation.message }}
                                        </span>
                                    </span>
                                </div>
                                <div class="form-group focus-label mr-1">
                                    <label for="acnt-name" class="form-label-of">Name on Account</label>
                                    <input id="acnt-name" formControlName="AccountHolder" class="form-control"
                                        (focus)="animateLabel($event.target)"
                                        (focusout)="animateLabelRemove($event.target)" maxlength="100">
                                    <span class="m-form__help"
                                        *ngFor="let validation of ValidationMessage.AccountHolder">
                                        <span class="error-message danger alert"
                                            *ngIf="(makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.hasError(validation.type) && (makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.dirty || makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.touched)) && makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.invalid">
                                            {{ validation.message }}
                                        </span>
                                    </span>
                                </div>
                                <!-- <div class="form-group checkbx-handle ml-1">
                                <input type="checkbox" id="html" formControlName="SaveCardForFuture">
                                <label for="html">Save Account for Future</label>
                            </div> -->
                            </div>
                        </div>
                        <div class="btn-wrp d-flex" *ngIf="selectedPaymentMethod == 'ach' && addNewCard">
                            <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                            <button type="submit" class="btn-common mt-2 ml-3" (click)="tokenizeUserDetails()"><span
                                    class="span-loader-padding">Make Payment</span> <i
                                    *ngIf="isLoading && !savedCardLoding" class="spinner-border"></i></button>
                        </div>
                        <!-- manual payment -->
                        <div class="row payment-method-field" *ngIf="selectedPaymentMethod == 'manual_payment'">
                            <div class="col-7 payment-method-head-left">
                                <h3>Payment method</h3>
                                <span>{{selectedPaymentname}}</span>
                            </div>
                            <div class="col-5 text-right payment-method-head-right">
                                <h3>Amount</h3>
                                <span>{{totalPrice}} {{Currency}}</span>
                            </div>
                        </div>
                        <div class="bx-add-card" *ngIf="selectedPaymentMethod == 'manual_payment'">
                            <div class="col-md-12 col-lg-12">
                                <span class="total-amnt-bold">BankAccount Details : </span>
                                <br>
                                <span style="white-space: pre-line;">{{text}}</span>
                            </div>
                        </div>

                        <div class="btn-wrp d-flex" *ngIf="selectedPaymentMethod == 'manual_payment'">
                            <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                            <button type="submit" class="btn-common mt-2 ml-3" (click)="tokenizeUserDetails()"><span
                                    class="span-loader-padding">Make Payment</span> <i
                                    *ngIf="isLoading && !savedCardLoding" class="spinner-border"></i></button>
                        </div>
                        <div class="btn-wrp d-flex mt-2"
                            *ngIf="((selectedPaymentMethod == 'gpay') || (selectedPaymentMethod == 'paypal'))">
                            <button type="submit" class="btn-border  mr-3 " (click)="prevStep()">Back</button>
                            <div class="gpay-wrap  ml-3" *ngIf="selectedPaymentMethod == 'gpay'">
                                <google-pay-button *ngIf="selectedPaymentMethod == 'gpay'" class="span-loader-padding"
                                    environment="TEST" [buttonColor]="buttonColor" [buttonType]="buttonType"
                                    [buttonSizeMode]="isCustomSize ? 'fill' : 'static'"
                                    [paymentRequest]="paymentRequest" [style.width.px]="buttonWidth"
                                    [style.height.px]="buttonHeight" [clickCallback]="onClickPreventDefault"
                                    (loadpaymentdata)="onLoadPaymentData($event)"
                                    [paymentAuthorizedCallback]="onPaymentDataAutherized">

                                </google-pay-button>
                                <div class="loader" *ngIf="loadingGpay && selectedPaymentMethod == 'gpay'">
                                    <div class="loading">
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="selectedPaymentMethod == 'paypal'">
                                <div id="paypal-button"></div>
                                <span class="spinner-border common-loader" *ngIf="paypalLoad" style="margin-top: 25px;
                            margin-left: 10px;"></span>
                            </div>
                        </div>

                    <!-- </div> -->

                </div>
            </div>
        </div>
</div>
</form>
</div>