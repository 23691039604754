import { Component, OnInit } from '@angular/core';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidator } from '../../../../_services/custom-validation/CustomValidator.validator';
import { SuccessPopupComponent } from '../../main/success-popup/success-popup.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare let $: any;

@Component({
	selector: 'app-member-resetpassword',
	templateUrl: './member-resetpassword.component.html',
	styleUrls: ['./member-resetpassword.component.scss']
})
export class MemberResetpasswordComponent implements OnInit {

	resetPasswordForm;
	password;
	newpwd = "password";
	confirmpwd = "password"
	tokenPasswordReset;
	tokenErrorMsg: any = '';
	postDataJsonPrepare;
	totalChar: boolean = false;
	atleaatAlpha: boolean = false;
	atleastDigit: boolean = false;
	isloading: boolean = false;
	newshow: boolean = true;
	confirmshow: boolean = true
	ValidationMessage: any = {
		newPassword: [{ type: "required", message: "New Password is required" },
		{ type: "pattern", message: "Password didn't meet required criteria" },
		{ type: "minlength", message: "Please enter valid password " }

		],
		confirmPassword: [{ type: "required", message: "Confirm Password is required" },
			// {type: "pattern",message: "Numbers,space and special characters not allowed"},
			// { type: "minlength", message: "Please enter valid confirm password " }
		]
	};

	constructor(public formBuilder: FormBuilder, public _crud: CommonCrudService,
		public router: Router, public _route: ActivatedRoute, public modalService: NgbModal) {
		this.formInit();
	}

	ngOnInit(): void {
		this.tokenPasswordReset = this._route.snapshot.queryParams['token'] || '';
		// console.log(this.tokenPasswordReset, 'dddsf');
		//tab switch toggle password
		$('input[name=cpwd]').on('keydown', function (evt) {
			console.log(evt.originalEvent.key,'evey')
			if (evt.originalEvent.key === 'Tab') {
				evt.preventDefault();
				if ($('.dropdown').find('.dropdown-menu').is(":hidden")) {
					$('.dropdown-toggle').dropdown('toggle');
				}
			}
		});

		this.verifyTokern();
	}
	formInit() {
		this.resetPasswordForm = this.formBuilder.group({
			// newPassword : ['',Validators.compose([Validators.required,Validators.minLength(6),Validators.pattern("^[a-zA-Z-']+")])],
			// confirmPassword : ['',Validators.compose([Validators.required,Validators.minLength(6),Validators.pattern("^[a-zA-Z-']+")])]
			newPassword: ['', Validators.compose([Validators.required])],
			confirmPassword: ['', Validators.compose([Validators.required])]
		});

	}
	animateLabel(event) {
		event.parentNode.classList.add('is-focused');
	}

	animateLabelRemove(e) {
		// console.log(e.value, "AHGKND");
		if (e.value == '') {
			e.parentNode.classList.remove('is-focused');
		}
	}
	checkPassword(cpassword = null) {
		// console.log("inside confirm pasword-----",cpassword);
		// CustomValidator.passwordsMatch(this.password,cpassword);
		if (cpassword) {
			// this.resetPasswordForm.get('confirmPassword').setValidators(Validators.compose([Validators.required, CustomValidator.passwordsMatch(this.password,cpassword).bind(this)]));
			// this.resetPasswordForm.get('confirmPassword').updateValueAndValidity();
			// this.ValidationMessage.confirmPassword = [{ type: "passwordsMatch", message: "password not match" }];
			this.resetPasswordForm.get('confirmPassword').setValidators(Validators.compose([Validators.required, CustomValidator.passwordsMatch(this.password, cpassword).bind(this)]));
			this.resetPasswordForm.get('confirmPassword').updateValueAndValidity();
			this.ValidationMessage.confirmPassword = [{ type: "passwordsMatch", message: "The passwords you entered do not match" }];
		}

	}
	// passwordFetch(pass){
	//   this.password = pass;
	//   this.checkPassword(this.resetPasswordForm.controls['confirmPassword'].value);
	//   // console.log("new pass-----",this.password);
	// }
	passwordFetch(pass) {
		//show toogle box check
		if ($('.dropdown').find('.dropdown-menu').is(":hidden")) {
			$('.dropdown-toggle').dropdown('toggle');
		}
		this.password = pass;
		//atleast 8 or more characters
		var regex1 = /^(?=.{8,}$).*$/
		//  var regex1 = /^(?=.*\w).{8,}$/
		var isValid1 = regex1.test(this.password);
		if (!isValid1) {
			this.totalChar = false;
			this.resetPasswordForm.controls['newPassword'].setErrors({ 'pattern': true });
			console.log("not 8 or more Characters.");
		} else {
			this.totalChar = true;
			// console.log("Dcontains contain at least 8 Characters.");
		}
		//upper case and lower case characters
		var regex2 = /^(?=.*[a-z]).*$/
		var regex3 = /^(?=.*[A-Z]).*$/
		var isValid2 = (regex2.test(this.password) && regex3.test(this.password));
		if (!isValid2) {
			this.atleaatAlpha = false;
			this.resetPasswordForm.controls['newPassword'].setErrors({ 'pattern': true });
			console.log("not upper and lower case.");
		} else {
			this.atleaatAlpha = true;
			// console.log("yes upper and lower case.");
		}
		//atleast one digit
		var regex4 = /^(?=.*[0-9]).*$/
		var isValid3 = regex4.test(this.password);
		if (!isValid3) {
			this.atleastDigit = false;
			this.resetPasswordForm.controls['newPassword'].setErrors({ 'pattern': true });
			console.log("not one digit");
		} else {
			this.atleastDigit = true;
			// console.log("yes one digit");
		}
		this.checkPassword(this.resetPasswordForm.controls['confirmPassword'].value);
		// console.log("new pass-----",this.password);
	}
	Submit() {
		let Step2Fields = [
			this.resetPasswordForm.controls['newPassword'],
			this.resetPasswordForm.controls['confirmPassword'],

		]
		const invalid = []
		const controls = Step2Fields;
		let i: any;
		for (i in controls) {
			controls[i].markAsDirty();
			if (controls[i].invalid) {
				invalid.push(i);
			}
		}
		if (invalid[0] == undefined) {
			this.isloading = true;
			let param = {};
			param['params'] = {};
			param['url'] = "frontend-site/member/reset-password";
			param['params'] = this.RequestPostParams();
			this._crud.postData(param).subscribe(res => {
				console.log("res---", res);
				this.isloading = false;
				this.successPopup(res);
			})
		}
		// this.isloading = true;
		// let param = {};
		// param['params'] = {};
		// param['url'] = "frontend-site/member/reset-password";
		// param['params'] = this.RequestPostParams();
		// this._crud.postData(param).subscribe(res => {
		// 	// console.log("res---",res);
		// 	this.successPopup(res);

		// })

	}
	verifyTokern() {
		let params = {
			url: "frontend-site/member/verify-reset-password-token",
			params: { Token: this.tokenPasswordReset }
		}
		this._crud.getData(params).subscribe(res => {
			if (res['status'] = "error") {
				this.tokenErrorMsg = res['msg'];
			}
		})
	}
	RequestPostParams() {
		this.postDataJsonPrepare = {};
		this.postDataJsonPrepare.Password = this.resetPasswordForm.controls['newPassword'].value;
		this.postDataJsonPrepare.ConfirmPassword = this.resetPasswordForm.controls['confirmPassword'].value;
		this.postDataJsonPrepare.Token = this.tokenPasswordReset;
		return this.postDataJsonPrepare;
	}
	successPopup(data) {
		let response = { title: data['status'], message: data['msg'] }
		const modalRef = this.modalService.open(SuccessPopupComponent, { size: "md" });
		modalRef.componentInstance.user = response;
		modalRef.result.then(
			result => {
				this.isloading = false;
				// console.log("result-----modal",result)
				if (result.result && data['status'] == 'success') {
					this.router.navigateByUrl('/member-login');
					modalRef.close({ 'result': true });
				}
			});
	}

	newpasswordType(){
		if (this.newpwd === 'password') {
		  this.newpwd = 'text';
		  this.newshow = false;
		} else {
		  this.newpwd = 'password';
		  this.newshow = true;
		}  }

		confirmpasswordType() {
			if (this.confirmpwd === 'password') {
				this.confirmpwd = 'text';
				this.confirmshow = false;
			  } else {
				this.confirmpwd = 'password';
				this.confirmshow = true;
			  }
		}

}
