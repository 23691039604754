<div class="d-flex inner-page-wrp">
    <div class="inner-page-content">
        <div class="setting-content">
            <!-- <a class="nav-link " routerLink="../../home/{{memberId}}"><i class="la la-arrow-left"></i>Dashboard</a> -->
            <div class="bx-white pb-0 ">
                <div class="row welcome-wrp">
                    <div class="col-md-7 welcome-left">
                        <!-- <h2>Welcome, John Samual</h2> -->
                        <h2>Welcome, {{memberProfile.FirstName}} {{memberProfile.LastName}}</h2>


                        <p>Get the latest update with the latest info – keep your profile updated  </p>
                    </div>
                    <div class="col-md-5 welcome-right">
                        <figure class="mb-0">
                            <img src="./assets/member/images/img-welcome.svg" alt="Shalom">
                        </figure>
                    </div>
                </div>
            </div>
            <div class="bx-white">
                <div class="top-strip d-flex">
                    <div class="d-flex btn-right-holder width-1 ">
                        <figure class="mb-0">
                            <img src="./assets/member/images/icon-profile-setting.svg" alt="Shalom">
                        </figure>
                        <div class="top-strip-titledesc width-1 pl-3">
                            <h4 class="mb-0" style="margin-top: 7px;">My Profile</h4>
                            <!-- <p class="mb-0">Mauris eget erat aliquam.</p> -->
                        </div>
                    </div>
                    <a href="javascript:void(0)" class="width-2" (click)="changePassword()">Change Password</a>
                </div>
                <div class="row contact-address-wrp mb-3">
                    <div class="col-md-6 contact-info pl-0">

                        <div class="d-flex ">
                            <h5>Personal Info</h5>
                            <figure>
                                <img src="./assets/member/images/icon-edit.svg" alt="Shalom" style="cursor: pointer;" (click)="editContactInfo()">
                            </figure>
                        </div>
                        <div class="prof-info-list">
                            <ul>
                                <li>
                                    <div class="desc-content-sec" [ngClass]="{'causeClass':memberName.length > 45}">
                                        <span class="heading-restrict">{{memberProfile.Salutation}} {{memberProfile.FirstName}} {{memberProfile.LastName}}</span>
                                        <div class="hover-bx">
                                            <span>{{memberProfile.Salutation}} {{memberProfile.FirstName}} {{memberProfile.LastName}}</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <span><a href="mailto:johnmaccarthy@gmail.com">{{memberProfile.Email}}</a></span>
                                </li>

                                <li>
                                    <!-- <span *ngIf="!secondNum" class="d-flex">Phone:<a href="tel:+91 9876442273">{{memberProfile.MobileNumber}}</a></span>
                                    <span *ngIf="secondNum" class="d-flex">Phone:<a href="tel:+91 9876442273">{{memberProfile.MobileNumber}}, {{memberProfile.SecondaryNumber}}</a></span> -->
                                    <span *ngIf="!secondNum" class="d-flex">Phone: {{memberProfile.MobileNumber}}</span>
                                    <span *ngIf="secondNum" class="d-flex">Phone: {{memberProfile.MobileNumber}}, {{memberProfile.SecondaryNumber}}</span>
                                </li>
                                <li>
                                    <span *ngIf="memberProfile.Dob">Date of Birth: {{memberProfile.Dob | date:'d MMM y'}} </span>
                                </li>
                                <li>
                                    <span *ngIf="preferedCommuLength">Preferred Language:{{lang}}</span>
                                </li>
                                <li>
                                    <span *ngIf="preferedContactInfoLength >= 1">Communication Preference:{{contact}}</span>
                                </li>
                            </ul>
                        </div>
                        <!-- <div class="row">
                            <div class="col-md-6">
                                <ul>
                                    <li>
                                        <h6>Name</h6>
                                        <span>{{memberProfile.FirstName}} {{memberProfile.LastName}}</span>
                                    </li>
                                    <li>
                                        <h6>Phone</h6>
                                        <span><a href="tel:+91 9876442273">{{memberProfile.MobileNumber}}</a></span>
                                    </li>
                                    <li>
                                        <h6>Communication Preference</h6>
                                        <span *ngIf="preferedContactInfoLength >= 1">{{contact}}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-6">
                                <ul>
                                    <li>
                                        <h6>Email</h6>
                                        <span><a href="mailto:johnmaccarthy@gmail.com">{{memberProfile.Email}}</a></span>
                                    </li>
                                    <li>
                                        <h6>Preferred Languages</h6>
                                        <span>{{lang}}</span>
                                    </li>
                                    <li>
                                        <h6>Optout Options</h6>
                                        <span>Lorem ipsum</span>
                                    </li>
                                </ul>
                            </div>
                        </div> -->

                    </div>
                    <div class="col-md-6 address-info px-0">
                        <div class="billing-address-wrp mb-2">
                            <div class="d-flex ">
                                <h5>Billing Address</h5>
                                <figure>
                                    <img src="./assets/member/images/icon-edit.svg" alt="Shalom" (click)="editBillingAddress(memberProfile.BillingAddress,memberProfile.CountryId)">
                                </figure>
                            </div>
                            <span *ngIf="memberProfile.BillingAddress"><span *ngIf="address2">{{memberProfile.BillingAddress.Address1}}, {{memberProfile.BillingAddress.Address2}}</span>
                            <span *ngIf="!address2">{{memberProfile.BillingAddress.Address1}}</span>
                            <span *ngIf="zip">{{memberProfile.BillingAddress.City}}, {{memberProfile.BillingAddress.StateName}}, {{memberProfile.BillingAddress.Zip}}</span>

                            <span *ngIf="!zip">{{memberProfile.BillingAddress.City}}, {{memberProfile.BillingAddress.StateName}}</span>
                            </span>
                            <span *ngIf="memberProfile.BillingAddress == null" style="white-space: nowrap;">
                                We don't have an address on file
                            </span>
                        </div>
                        <div class="shipping-address-wrp">
                            <div class="d-flex ">
                                <h5>Mailing Address</h5>
                                <figure>
                                    <img src="./assets/member/images/icon-edit.svg" alt="Shalom" (click)="editShippingAddress(memberProfile.ShippingAddress,memberProfile.CountryId)">
                                </figure>
                            </div>
                            <span *ngIf="memberProfile.ShippingAddress"><span *ngIf="address2Ship">{{memberProfile.ShippingAddress.Address1}}, {{memberProfile.ShippingAddress.Address2}}</span>
                            <span *ngIf="!address2Ship">{{memberProfile.ShippingAddress.Address1}}</span>
                            <span *ngIf="zipShip"> {{memberProfile.ShippingAddress.City}}, {{memberProfile.ShippingAddress.StateName}}, {{memberProfile.ShippingAddress.Zip}}</span>
                            <span *ngIf="!zipShip"> {{memberProfile.ShippingAddress.City}}, {{memberProfile.ShippingAddress.StateName}}</span>

                            </span>
                            <span *ngIf="memberProfile.ShippingAddress == null" style="white-space: nowrap;">
                                We don't have an address on file
                            </span>
                        </div>
                    </div>
                    <!-- <div class="col-md-6 address-info px-0">
                        <div class="d-flex ">
                            <h5>Address</h5>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <ul>
                                    <li>
                                        <div class="d-flex address-edit-sec">
                                            <h6>Billing Address</h6>
                                            <figure>
                                                <img src="./assets/member/images/icon-edit.svg" alt="Shalom" (click)="editBillingAddress(memberProfile.BillingAddress,memberProfile.CountryId)">
                                            </figure>
                                        </div>
                                        <span *ngIf="memberProfile.BillingAddress">{{memberProfile.BillingAddress.AddressText}}</span>
                                    </li>
                                    <li>
                                        <div class="d-flex address-edit-sec">
                                            <h6>Shipping Address</h6>
                                            <figure>
                                                <img src="./assets/member/images/icon-edit.svg" alt="Shalom" (click)="editShippingAddress(memberProfile.ShippingAddress,memberProfile.CountryId)">
                                            </figure>
                                        </div>
                                        <span *ngIf="memberProfile.ShippingAddress">{{memberProfile.ShippingAddress.AddressText}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div> -->
                </div>
            </div>
            <div class="bx-white">
                <div class="top-strip d-flex">
                    <div class="d-flex btn-right-holder width-1 ">
                        <figure class="mb-0">
                            <img src="./assets/member/images/icon-active-donation.svg" alt="Shalom">
                        </figure>
                        <div class="top-strip-titledesc width-1 pl-3">
                            <h4 class="mb-0" style="margin-top: 7px;">Active Donations</h4>
                            <!-- <p class="mb-0">Mauris eget erat aliquam.</p> -->
                        </div>
                    </div>
                    <div class="d-flex pagination-wrp width-2">
                        <div class="pagination-list">
                            <mat-paginator [pageSizeOptions]="[5]" [hidePageSize]="true" [ngClass]="'pagination-holder'"></mat-paginator>
                        </div>
                    </div>
                </div>


                <!-- datatable starts here------ -->
                <div class="table-responsive-wrp ">
                    <mat-table #table [dataSource]="dataSource">
                        <ng-container matColumnDef="Donation">
                            <mat-header-cell *matHeaderCellDef> Cause </mat-header-cell>
                            <!-- <mat-cell *matCellDef="let element"> {{element.CauseName}} </mat-cell> -->
                            <mat-cell *matCellDef="let element"> 
                                <div class="desc-content-sec" [ngClass]="{'causeClass':element.CauseName.length > 15}">
                                    <h5 class="heading-restrict caseName"> {{element.CauseName}}  </h5>
                                    <div class="hover-bx">
                                        <h5> {{element.CauseName}} </h5>
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="MemberName">
                            <mat-header-cell *matHeaderCellDef> Member </mat-header-cell>
                            <!-- <mat-cell *matCellDef="let element"> {{element.MemberName}} </mat-cell> -->
                            <mat-cell *matCellDef="let element"> 
                                <div class="desc-content-sec" [ngClass]="{'causeClass':element.MemberName.length > 15}">
                                    <h5 class="heading-restrict caseName"> {{element.MemberName}}  </h5>
                                    <div class="hover-bx">
                                        <h5> {{element.MemberName}} </h5>
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="Amount">
                            <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
                            <!-- <mat-cell *matCellDef="let element"> {{element.Amount}}</mat-cell> -->
                            <mat-cell *matCellDef="let element"> 
                                <div class="desc-content-sec" [ngClass]="{'causeClass':element.Amount.length > 15}">
                                    <h5 class="heading-restrict"> {{element.Amount}}  </h5>
                                    <div class="hover-bx">
                                        <h5> {{element.Amount}} </h5>
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="RecurringFrequency">
                            <mat-header-cell *matHeaderCellDef> Frequency </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.RecurringFrequency}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="RecurringDate">
                            <mat-header-cell *matHeaderCellDef> Rec. Date </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.RecurringDate}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="PaymentMethod">
                            <mat-header-cell *matHeaderCellDef> Pmt. Method </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.LastFourDigit}} <img [src]="element.PaymentMethodUrl" alt=""></mat-cell>
                        </ng-container>
                        <!-- <ng-container matColumnDef="CreditTypeText">
                            <mat-header-cell *matHeaderCellDef> Card Type </mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.LastFourDigit}} {{element.AccountType}}
                              
                            </mat-cell>
                        </ng-container> -->

                        <ng-container matColumnDef="Expiry">
                            <mat-header-cell *matHeaderCellDef> Expiry </mat-header-cell>
                            <mat-cell *matCellDef="let element"> <span>{{element.ExpiryDate}}</span>
                                <div class="dropdown-wrp">
                                    <a href="javascript:void(0)" data-toggle="dropdown" class="status-icon"><img src="./assets/member/images/icon-status.svg" alt="Shalom"></a>
                                    <ul class="dropdown-menu">
                                        <li><a style="cursor: pointer;" (click)="donationCancel()">Cancel</a></li>
                                        <li *ngIf="element.RecurringFrequency != 'Onetime'&&element.PaymentMethod != 'ACH'"><a routerLink="update-recurring-donation/{{element.DonationId}}">Update Payment</a></li>
                                    </ul>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                </div>

            </div>
            <!-- <div class="mt-3 desc-btm ">
                <p>It was popularised in the 1960s with the release of Letraset <a>Terms & Conditions</a> Ipsum passages, and more recently with <a>Privacy Policy</a> software like Aldus versions of Lorem Ipsum.</p>
            </div> -->
            <!-- <div class="bx-white pagination-min-ht">
        <div class="row pagination-wrp p-3">
            <div class="pagination-list col-md-12">
                <mat-paginator [pageSizeOptions]="[5]" [hidePageSize]="true" [ngClass]="'pagination-holder'"></mat-paginator>
            </div>
        </div>
    </div> -->
        </div>
    </div>
    <div class="home-right pl-0 ">
        <div class="bx-white connect-bx">
            <h4 class="text-center mb-3 mt-3">Connect with Us</h4>
            <div class="connect-btn-wrp">
                <figure class="text-center mb-2" *ngIf="isSpfMember">
                    <img src="./assets/member/images/img-connect-purple.svg " alt="Shalom ">
                </figure>
                <figure class="text-center mb-2" *ngIf="singleDonationExist == true && isSpfMember == false">
                    <img src="./assets/member/images/img-connect-green.svg " alt="Shalom ">
                </figure>
                <figure class="text-center mb-2" *ngIf="singleDonationExist == false && isSpfMember == false">
                    <img src="./assets/member/images/img-connect-brown.svg " alt="Shalom ">
                </figure>
                <ul class="button-list">
                    <li class="d-flex">
                        <a (click)="modalOpen('prayer')">Prayer Request</a>
                    </li>
                    <li class="d-flex">
                        <a (click)="modalOpen('testimony')">Testimony</a>
                    </li>
                    <li class="d-flex">
                        <a (click)="modalOpen('contact')">Contact Us</a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- <div class="bx-white live-desc-bx">
        <button type="button" class="btn-live mb-2">Live Now</button>
        <div class="live-desc ">
            <h4>Excepteur sint occaecat cupidatat non</h4>
            <p>itation ullamco laboris nisi ut aliquip ex ea of a comm odo consequat.</p>
        </div>
    </div> -->
    </div>
</div>