import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SharedService } from '../../../../_services/shared.service';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { CommonCrudService } from '../../../../_services/common-crud.service';
import * as braintree from 'braintree-web';
import { map } from 'rxjs/operators'
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';
import { SavedcardlistComponent } from '../savedcardlist/savedcardlist.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Helpers } from 'src/app/helpers';

declare let $: any;
declare let paypal: any;
@Component({
	selector: 'app-subscription-payment',
	templateUrl: './subscription-payment.component.html',
	styleUrls: ['./subscription-payment.component.scss']
})
export class SubscriptionPaymentComponent implements OnInit {
	@ViewChild(SavedcardlistComponent) child: SavedcardlistComponent;
	subscriptionForm;
	selectedMemberId;
	selectedCauseId;
	memberData;
	paymentName: any
	countryPaymentId
	paypal: any
	FamilyMembersList = [];
	memberList = [];
	Subscriptions = [];
	SubscriptionList;
	subscription;
	subscribefor;
	subType: string;
	multiselect: boolean = true;
	allDurations;
	totalPrice;
	Publications;
	countryId
	publicationarr;
	selectedPublicationsArray;
	PublicationsList;
	SelectedPublicationIds: any[] = [];
	subscriptionOptions;
	Currency;
	threeDValue;
	errorRenewal: boolean = false;
	selectpublication;
	renewItems;
	renewalSubscription;
	renewalOptions;
	selectedSubscriptionOption;
	duration;
	copiesValidation: boolean = false;
	PaymentMethods;
	text;
	InitialMethodId;
	userLocation;
	countryName;
	hostedFieldsInstance: braintree.HostedFields;
	accounttypeList: { value: string; name: string; }[];
	brainTreeToken: string;
	selectedPaymentMethod: string;
	brainTreeError: string;
	cardholdersName: string;
	noFieldsCardNumber: number;
	noFieldsDate: number;
	noFieldsCvv: number;
	IsSavedAchPresent: boolean = false;
	savedCards;
	showNewCardOption: boolean = false;
	type;
	brainTreeReInit: boolean = true;
	postDataJsonPrepare;
	selectedSavedCard: boolean = false;
	state;
	StatesList;
	isGift: boolean = false;
	SalutationList: any;
	selectedGiftMemberId;
	giftMemberForm: boolean = true;
	newGiftMember: boolean = true;
	routeParam;
	memberId;
	queryParam;
	selectedSubTypeItem;
	renewSubId;
	renewLName;
	paymentMethodList;
	errorShow: boolean = false;
	errorMsg;
	isLoading: boolean = false;
	selectedSalutaion;
	savedAchCardButtonShow: boolean;
	paymentSettingId;
	savedCreditCardButtonShow: boolean;
	selectedMemberName;
	paymentMethodError: boolean = false;
	listSavedCreditCard: boolean = false;
	disableMakePayment: boolean = false;
	addNewCard: boolean = false;
	hideBillingAddress: boolean = true;
	askBilling: boolean = true;
	braintreeNonce = "";
	buttonColor = "black";
    buttonType = "donate";
    isCustomSize = false;
    buttonWidth = 90;
    buttonHeight = 40;
    tokenizationkey:any;
    merchantId:any;
    nonce:any
    paymentRequest:google.payments.api.PaymentDataRequest
    loadingGpay: boolean = false;
	ValidationMessage: any = {
		'Salutation': [
			{ type: 'required', message: 'Salutation is required' }
		],
		'FirstName': [
			{ type: 'required', message: 'Please enter your first name' },
			{ type: 'maxlength', message: 'First name can only have a maximum of 100 characters' },
			{ type: "hasnuber", message: "Numbers not allowed" },
			{ type: "hasspecl", message: "Special charecters not allowed" },
			{ type: "hasnumSpcl", message: "Numbers & special charecters not alowed" },


		],
		'LastName': [
			{ type: 'required', message: 'Please enter your last name' },
			{ type: 'maxlength', message: 'Last name can only have a maximum of 100 characters' },
			{ type: "hasnuber", message: "Numbers not allowed" },
			{ type: "hasspecl", message: "Special charecters not allowed" },
			{ type: "hasnumSpcl", message: "Numbers & special charecters not alowed" }
		],
		'Email': [
			{ type: 'required', message: 'Please enter your email address' },
			{ type: 'pattern', message: 'Please enter a valid email address' }
		],
		"PrimaryPhone": [
			{ type: 'required', message: 'Please enter your Phone number' },
			{ type: 'pattern', message: 'Please enter a valid phone number using only numbers' }
		],
		'SecondaryPhone': [
			{ type: 'pattern', message: 'Please enter a valid phone number using only numbers' }
		],
		'Address1': [
			{ type: 'required', message: 'Please enter your street address' },
			{ type: 'maxlength', message: 'Street address can only have a maximum of 100 characters' },
		],
		'Address2': [
			{ type: 'maxlength', message: 'Apt, Suite, Bldg can only have a maximum of 100 characters' },
		],
		'City': [
			{ type: 'required', message: 'Please enter your city' },
			{ type: 'pattern', message: 'Please enter your city without special characters' },
			{ type: 'maxlength', message: 'City can only have a maximum of 100 characters' },
		],
		'State': [
			{ type: 'required', message: 'Please select your state' }
		],
		"Zip": [],
		'AccountNumber': [
			{ type: "required", message: 'Please enter your bank account number' },
			{ type: 'pattern', message: 'Please enter a valid account number' }
		],
		'RoutingNumber': [],
		'AccountHolder': [
			{ type: "required", message: 'Please enter the name on your bank account' },
			{ type: 'pattern', message: 'Please enter a valid name on account' }
		],
		'BankName': [
			{ type: "required", message: 'Please enter the name of your bank' },
			{ type: 'maxlength', message: 'Bank name can only have a maximum of 100 characters' }
		],
		'NoOfCopiesRequired': [
			// { type: 'required', message: 'Please enter the number of copies' },
			// {type: 'min' , message: 'min value 2'},
			// {type: 'max' , message: 'max value is one lakh'},
			// { type: 'pattern', message: 'Please enter a valid number using only numbers' }
		],
		'SponsorshipFor': [
			{ type: "hasnuber", message: "Numbers not allowed" },
			{ type: "hasspecl", message: "Special charecters not allowed" },
			{ type: "hasnumSpcl", message: "Numbers & special charecters not alowed" }
		]
	}
	step2Fields: any[];
	formResult: any;
	public response: { title: any; message: any; };
	PublicationsfromHome: any;
	publicationIdFromHome = [];
	flag: boolean = true;
	validPub: boolean;
	shownewCreditcardOption: boolean = false;
	showSavedCreditcardOption: boolean = true;
	addrsSet: boolean = true;
	hidePaymentMethod: boolean = false;
	TabActiveIndex: number = 0;
	selectedPaymentname;
	paymentGatewayErrorMsg: string;
	savedCreditCards: any;
	selectedsubType;
	loaderCreditNew: boolean;
	loaderCreditsaved: boolean;
	loaderAchSaved: boolean;
	isSpfMember: boolean;
	singleDonationExist: boolean = false;
	bulkSubMinSlab: any;
	bulkSubMaxSlab: any;
	issueList: any;
	selectedIssueId: any;
	cancelStatus: boolean = false;
	threeDSecure: braintree.threeDSecure;
	threedCountryList: any;
	primaryCountryId: string;
	clientToken: any;
	isCvvhide: any;
	paypalLoad: boolean;
	constructor(public sharedService: SharedService,
		public formBuilder: FormBuilder, public _crud: CommonCrudService,
		public activeRoute: ActivatedRoute,
		public modalService: NgbModal,
		private router: Router,
		private el: ElementRef) {
		this.formInit();
	}

	ngOnInit(): void {
		
		this.countryId = localStorage.getItem('countryId')
		this.getCvvhide(this.countryId);
		this.getThreedsecureCountries();
		this.accounttypeList = [
			{
				value: 'checking',
				name: 'Checking'
			},
			{
				value: 'savings',
				name: 'Savings'
			}
		];
		/*----Placeholder Up---*/
		$(".member-wrp input").on('focus', function () {
			$(this).parent(".focus-label").addClass("is-focused");
		});
		$(".member-wrp input").focusout(function () {
			if ($(this).val() == "") {
				$(this).parents(".focus-label").removeClass("is-focused");
			}
		});

		this.routeParam = this.activeRoute.snapshot.params;
		this.queryParam = this.activeRoute.snapshot.queryParams;
		// console.log("queryParam-----",this.queryParam);
		if (this.queryParam.publicationId) {
			this.publicationIdFromHome[0] = this.queryParam.publicationId
		}
		this.subscription = parseInt(this.queryParam.subType);
		this.renewSubId = this.queryParam.renewId;
		this.renewLName = this.queryParam.renewName;
		// console.log("subscription-----",this.subscription);
		if (this.subscription == 3) {
			this.addrsSet = false;
		} else {
			this.addrsSet = true;
		}
		// console.log("params-----",this.routeParam);
		this.memberId = this.routeParam.memberId;
		// this.selectedMemberId = this.memberId;
		if (this.queryParam['memberId']) {
			// console.log("44444",this.queryParam['memberId']);
			this.selectedMemberId = this.queryParam['memberId'];

		}
		this.getFamilyMembers();
		// this.getSubscriptionList();
		// this.getPublicationList();
		this.getAllPaymentMethods(this.countryId);
		// this.getUserLocation();

		this.primaryCountryId = localStorage.getItem('primaryCountryId')
		this.getAllStates(this.countryId);
		this.getSalutation();
		this.sharedService.fetchPathData('payment', 'subscription');
		if (this.subscription != null) {
			// avoiding renew sub: from sub: list
			if (this.subscription != 1) {
				this.SubscriptionList = [
					{
						SubscriptionId: 0,
						SubscriptionName: "New Subscription"
					},
					{
						SubscriptionId: 2,
						SubscriptionName: "Bulk Subscription"
					}
					,
					// {
					//     SubscriptionId: 3,
					//     SubscriptionName: "Gift Subscription"
					// }
				]
			} else {
				this.SubscriptionList = [
					{
						SubscriptionId: 0,
						SubscriptionName: "New Subscription"
					},
					{
						SubscriptionId: 1,
						SubscriptionName: "Renew Subscription"
					},
					{
						SubscriptionId: 2,
						SubscriptionName: "Bulk Subscription"
					},
					{
						SubscriptionId: 3,
						SubscriptionName: "Gift Subscription"
					}
				]
			}
			// this.threeDSecurecheck();
			this.getSubscriptionList();
			this.selectedSubTypeItem = this.SubscriptionList.find(i => i.SubscriptionId === this.subscription);
			// console.log("selectedSubTypeItem-----",this.selectedSubTypeItem);
			this.selectedsubType = { "Id": this.subscription, "Name": this.selectedSubTypeItem.SubscriptionName };
			// console.log("selcted subType-----",subType);
			this.changeSubType(this.selectedsubType);
			this.isSpfMember = (localStorage.getItem('IsSpfMember') == 'true' ? true : false);
			let currentUser = JSON.parse(localStorage.getItem("currentUser"));
			this.singleDonationExist = currentUser.singleDonationExist;
		}

	}

	getCvvhide(countryId) {
        let params = {};
        params["url"] = `frontend-site/member/check-cvv-required/${countryId}`;
        this._crud.getData(params).subscribe(data => {
            this.isCvvhide = data['data'];
        });
    }
	animateLabel(event) {
		event.parentNode.classList.add('is-focused');
	}

	animateLabelRemove(e) {
		// console.log(e.value, "AHGKND");
		if (e.value == '') {
			e.parentNode.classList.remove('is-focused');
		}
	}
	nextStep() {
		if (this.TabActiveIndex < 3) {
			let validity = this.validateFileds();
			console.log("invalid array,", this.TabActiveIndex, validity[0], this.InitialMethodId);
			// if(this.TabActiveIndex == 0){
			// 	if (validity[0] == 0 && this.InitialMethodId != undefined) {
			// 		this.TabActiveIndex++;
			// 	}

			// }
			if (this.TabActiveIndex == 0) {
				if (validity[0] == undefined) {
					this.TabActiveIndex++;
				}


			} else
				if (this.TabActiveIndex == 1) {
					console.log("invalid array tab 1,", this.TabActiveIndex, validity, this.InitialMethodId);
					if (validity[0] != undefined && this.InitialMethodId == undefined) {
						this.paymentMethodError = true;
					} else {
						this.paymentMethodError = false;
					}
					if (validity[0] == undefined && this.InitialMethodId != undefined) {
						this.TabActiveIndex++;
					}
				} else {
					if (validity[0] == undefined) {
						this.TabActiveIndex++;

					}
				}
		}
		if (this.subscriptionForm.controls['IsBillingAddressIsDifferent'].value == true) {

			this.subscriptionForm.controls.BillingAddress.controls.CountryId.setValue(this.countryId);
			// document.getElementById("BAdd1").classList.add("is-focused");
			if (this.subscriptionForm.controls.BillingAddress.controls.Address2.value) {
				document.getElementById("BAdd2").classList.add("is-focused");
			} else {
				document.getElementById("BAdd2").classList.remove("is-focused");
			}

			// document.getElementById("BCity").classList.add("is-focused");
			// document.getElementById("BCode").classList.add("is-focused");

		}
	}
	prevStep() {
		if (this.TabActiveIndex < 3 && this.TabActiveIndex != 0) {
			this.TabActiveIndex--;
		}
	}
	formInit() {
		// console.log("inside form init-")
		this.subscriptionForm = this.formBuilder.group({
			Member: [this.selectedMemberId],
			// CountryId: [this.countryId],
			IsGiftTo: [0],
			SponsorshipFor: [null],
			Subscription: this.getSubscriptionOptions(),
			// SubscriberInfo: this.getPersonalDetails(),
			PaymentProcessParams: this.getPaymentParams(),
			NoOfCopiesRequired: [''],
			// Publication: [{ value: null, disabled: false }],
			Publication: [{ value: this.publicationIdFromHome, disabled: false }, Validators.required],

			SubscribeFor: [this.subscribefor, Validators.required],
			// SubscriptionType: [ { value: this.subscription, disabled: false }, Validators.required],
			SubscriptionType: [this.subscription, Validators.required],
			DeliveryAddress: this.getDeliveryAddress(),
			IsBillingAddressIsDifferent: [0],
			BillingAddress: this.getBillingAddress(),
			GiftTo: this.getGiftToDetails(),
			AddNewCard: [false],
			StartIssueId: [{ value: null, disabled: true }],
			IsThreeDSecure: [null]


		});
		if (this.subscription == 3) {
			// callling change subtype funation after form init
			this.changeSubType(this.selectedsubType);
		}
		this.zipValidation();
	}
	getSubscriptionOptions(): FormGroup {
		return this.formBuilder.group({
			RenewalSubscriptionId: [this.renewSubId],
			LName: [this.renewLName],
			PublicationComboId: [''],
			ComboPriceId: [null],
			PriceOptionId: [null],
			Format: [''],
			Type: [''],
			DurationType: [''],
			Duration: [''],
		})
	}
	getPaymentParams(): FormGroup {
		return this.formBuilder.group({
			SubscriptionAmount: [''],
			InvoiceId: [null],
			CurrencyISOCode: [this.Currency],
			NetAmountToPay: [''],
			DiscountAmount: [null],
			CouponCode: [null],
			PaymentMethodId: ['', Validators.required],
			ProceedWithoutPaymentProcess: [null],
			PaymentFields: this.getPaymentFields()
		})
	}
	getPaymentFields() {
		return this.formBuilder.group({
			AccountNumber: [''],
			RoutingNumber: [''],
			AccountHolder: [''],
			BankName: [null],
			AccountType: [null],
			PayNow: [null],
			// BillingDate: [this.activeRecurringDate],
			Nonce: [''],
			ChooseCard: [''],
			SaveCardForFuture: ["1"]
		})
	}
	getDeliveryAddress(): FormGroup {
		let fbGroup = this.formBuilder.group({
			AddressId: [''],
			// Address1: [''],
			// Address2: [''],
			// City: [''],
			// StateId: [this.state],
			// CountryId: [this.countryId],
			// Zip: [''],
			Address1: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
			Address2: ['', Validators.maxLength(100)],
			City: ['', Validators.compose([Validators.required, Validators.pattern("[a-zA-Z0-9&' ,.\-]*$"), Validators.maxLength(100)])],
			StateId: [this.state, Validators.required],
			CountryId: [this.countryId, Validators.required],
			Zip: ['', Validators.compose([Validators.required, Validators.pattern('^[A-Za-z0-9 -]+$'), Validators.maxLength(10), Validators.minLength(3)])],
		})
		return fbGroup;

	}
	getBillingAddress(): FormGroup {
		let fbGroup = this.formBuilder.group({
			AddressId: [''],
			Address1: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
			Address2: ['', Validators.maxLength(100)],
			City: ['', Validators.compose([Validators.required, Validators.pattern("[a-zA-Z0-9&' ,.\-]*$"), Validators.maxLength(100)])],
			// StateName: ['', Validators.required],
			StateId: [this.state, Validators.required],
			CountryId: [this.countryId, Validators.required],
			Zip: [''],
		})
		return fbGroup;
	}

	getGiftToDetails() {
		return this.formBuilder.group({
			GiftMember: [this.selectedGiftMemberId],
			SalutationId: [this.selectedSalutaion],
			FirstName: [''],
			LastName: [''],
			Email: [''],
			PrimaryPhone: [''],
			SecondaryPhone: [''],
			// ShippingAddress: this.getGiftRecipientAddress(),
		})
	}
	// getGiftRecipientAddress(): FormGroup {
	//   return this.formBuilder.group({
	//       Address1: [''],
	//       Address2: [''],
	//       City: [''],
	//       StateId: [this.state],
	//       CountryId: [this.countryId],
	//       Zip: ['']
	//   })
	// }
	getSalutation() {
		let params = {}
		params["url"] = "frontend-site/donation/fetch-salutation";
		this._crud.getData(params).subscribe(data => {
			// console.log('salutation', data);
			this.SalutationList = data['data'];
		})
	}
	getAllStates(countryId) {
		let params = {}
		params["params"] = {
			CountryId: countryId
		}
		params["url"] = "frontend-site/fetch-states/" + countryId;
		this._crud.getData(params).subscribe(data => {
			// console.log('func3');
			// console.log('data3', data['data']);
			this.StatesList = data['data'];
		})

	}
	getFamilyMembers() {
		let param = {
			url: "frontend-site/list-family-members-with-primary-member", params: { PrimaryMemberId: this.memberId }
		}
		this._crud.getData(param).subscribe(res => {
			this.memberData = res['data'];
			if (this.selectedMemberId == undefined) {
				this.selectedMemberId = res['data'][0]['MemberId'];
				this.selectedMemberName = res['data'][0]['MemberName'];
			}
			// console.log("donatuon data----",this.memberData);
			this.getSelectedMemberAddress(this.selectedMemberId);
			this.memberData.forEach(element => {
				this.FamilyMembersList.push({ Id: element.MemberId, Name: element.MemberName });
				// console.log("family member-----",this.FamilyMembersList);
			});
			this.memberList = this.FamilyMembersList;
			// console.log("family member-----",this.memberList);

		});
	}
	getSelectedMemberAddress(memberId) {
		console.log()
		let param = {
			url: "frontend-site/member-subscription/fetch-address", params: { MemberId: memberId }
		}
		this._crud.getData(param).subscribe(res => {

			// console.log("member addres data----",res['data']);
			if (res['data']['Address1'] && this.addrsSet) {
				// console.log("setting addrs values")
				this.subscriptionForm.controls.DeliveryAddress.controls.AddressId.setValue(res['data']['AddressId']);
				this.subscriptionForm.controls.DeliveryAddress.controls.Address1.setValue(res['data']['Address1']);
				this.subscriptionForm.controls.DeliveryAddress.controls.Address2.setValue(res['data']['Address2']);
				this.subscriptionForm.controls.DeliveryAddress.controls.City.setValue(res['data']['City']);
				this.subscriptionForm.controls.DeliveryAddress.controls.StateId.setValue(res['data']['StateId']);
				this.subscriptionForm.controls.DeliveryAddress.controls.Zip.setValue(res['data']['Zip']);
				this.subscriptionForm.controls.DeliveryAddress.controls.CountryId.setValue(this.countryId);


				document.getElementById("Add1").classList.add("is-focused");
				if (res['data']['Address2']) {
					document.getElementById("Add2").classList.add("is-focused");
				} else {
					document.getElementById("Add2").classList.remove("is-focused");
				}

				document.getElementById("City").classList.add("is-focused");
				document.getElementById("Code").classList.add("is-focused");
			} else {
				let param = {
					url: "frontend-site/member-subscription/fetch-address", params: { MemberId: this.memberId }
				}
				this._crud.getData(param).subscribe(res => {
					if (res['data']['Address1'] && this.addrsSet) {
						this.subscriptionForm.controls.DeliveryAddress.controls.AddressId.setValue(res['data']['AddressId']);
						this.subscriptionForm.controls.DeliveryAddress.controls.Address1.setValue(res['data']['Address1']);
						this.subscriptionForm.controls.DeliveryAddress.controls.Address2.setValue(res['data']['Address2']);
						this.subscriptionForm.controls.DeliveryAddress.controls.City.setValue(res['data']['City']);
						this.subscriptionForm.controls.DeliveryAddress.controls.StateId.setValue(res['data']['StateId']);
						this.subscriptionForm.controls.DeliveryAddress.controls.Zip.setValue(res['data']['Zip']);
						document.getElementById("Add1").classList.add("is-focused");
						if (res['data']['Address2']) {
							document.getElementById("Add2").classList.add("is-focused");
						} else {
							document.getElementById("Add2").classList.remove("is-focused");
						}

						document.getElementById("City").classList.add("is-focused");
						document.getElementById("Code").classList.add("is-focused");
					}
					if (res['data']['BillingAddressData']['isBillingAddressPresent'] == true) {
						// this.hideBillingAddress = false;
						// this.subscriptionForm.controls['IsBillingAddressIsDifferent'].setValue(true);
						this.subscriptionForm.controls.BillingAddress.controls.AddressId.setValue(res['data']['BillingAddressData']['BillingAddressId']);
						this.subscriptionForm.controls.BillingAddress.controls.Address1.setValue(res['data']['BillingAddressData']['BillingAddress1']);
						this.subscriptionForm.controls.BillingAddress.controls.Address2.setValue(res['data']['BillingAddressData']['BillingAddress2']);
						this.subscriptionForm.controls.BillingAddress.controls.City.setValue(res['data']['BillingAddressData']['BillingCity']);
						this.subscriptionForm.controls.BillingAddress.controls.StateId.setValue(res['data']['BillingAddressData']['BillingStateId']);
						this.subscriptionForm.controls.BillingAddress.controls.Zip.setValue(res['data']['BillingAddressData']['BillingZip']);
						this.subscriptionForm.controls.BillingAddress.controls.CountryId.setValue(this.countryId);
						document.getElementById("BAdd1").classList.add("is-focused");
						if (res['data']['BillingAddressData']['BillingAddress2']) {
							document.getElementById("BAdd2").classList.add("is-focused");
						} else {
							document.getElementById("BAdd2").classList.remove("is-focused");
						}

						document.getElementById("BCity").classList.add("is-focused");
						document.getElementById("BCode").classList.add("is-focused");

					}

				})

			}
			if (res['data']['BillingAddressData']['isBillingAddressPresent'] == true) {
				console.log("@@@@@@@@@@@@@@@", res['data'])
				// this.hideBillingAddress = false;
				// this.subscriptionForm.controls['IsBillingAddressIsDifferent'].setValue(true);
				this.subscriptionForm.controls.BillingAddress.controls.AddressId.setValue(res['data']['BillingAddressData']['BillingAddressId']);
				this.subscriptionForm.controls.BillingAddress.controls.Address1.setValue(res['data']['BillingAddressData']['BillingAddress1']);
				this.subscriptionForm.controls.BillingAddress.controls.Address2.setValue(res['data']['BillingAddressData']['BillingAddress2']);
				this.subscriptionForm.controls.BillingAddress.controls.City.setValue(res['data']['BillingAddressData']['BillingCity']);
				this.subscriptionForm.controls.BillingAddress.controls.StateId.setValue(res['data']['BillingAddressData']['BillingStateId']);
				this.subscriptionForm.controls.BillingAddress.controls.Zip.setValue(res['data']['BillingAddressData']['BillingZip']);
				this.subscriptionForm.controls.BillingAddress.controls.CountryId.setValue(this.countryId);
				document.getElementById("BAdd1").classList.add("is-focused");
				if (res['data']['BillingAddressData']['BillingAddress2']) {
					document.getElementById("BAdd2").classList.add("is-focused");
				} else {
					document.getElementById("BAdd2").classList.remove("is-focused");
				}

				document.getElementById("BCity").classList.add("is-focused");
				document.getElementById("BCode").classList.add("is-focused");

			} else if (res['data']['BillingAddressData']['isBillingAddressPresent'] == false) {
				// this.subscriptionForm.controls['IsBillingAddressIsDifferent'].setValue(false);
				// this.hideBillingAddress = true;
				this.subscriptionForm.controls.BillingAddress.controls.AddressId.setValue('');
				this.subscriptionForm.controls.BillingAddress.controls.Address1.setValue('');
				this.subscriptionForm.controls.BillingAddress.controls.Address2.setValue('');
				this.subscriptionForm.controls.BillingAddress.controls.City.setValue('');
				this.subscriptionForm.controls.BillingAddress.controls.StateId.setValue(null);
				this.subscriptionForm.controls.BillingAddress.controls.Zip.setValue('');
				// document.getElementById("BAdd1").classList.remove("is-focused");
				// document.getElementById("BAdd2").classList.remove("is-focused");
				// document.getElementById("BCity").classList.remove("is-focused");
				// document.getElementById("BCode").classList.remove("is-focused");

			}
			console.log("ask billing------------", res['isBillingAddressPresent'])


		});
		let countryCode = localStorage.getItem('countryCode')

		if (countryCode == 'US') {
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{5,5}$/)]));
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').updateValueAndValidity();
			this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code with five digits" }];
		} else if (countryCode == 'CA') {//} else if (this.countryId == 'gQFUOwHXczkw') { canada
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)[0-9A-Za-z\s]{6,7}$/)]));
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').updateValueAndValidity();
			this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code" }];
		} else if (countryCode == 'AU') {//  australia
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{4,4}$/)]));
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').updateValueAndValidity();
			this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code" }];
		} else if (countryCode == 'GB') {//uk
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)[0-9A-Za-z\s]{5,8}$/)]));
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').updateValueAndValidity();
			this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code" }];
		} else if (countryCode == 'IE') {//ireland
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)[0-9A-Za-z\s]{7,8}$/)]));
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').updateValueAndValidity();
			this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code" }];
		} else {
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').setValidators(Validators.pattern(/^(?!0+$)[0-9A-Za-z\s\-]{3,10}$/));
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').updateValueAndValidity();
			this.ValidationMessage.Zip = [{ type: "pattern", message: "Please enter a valid postal code" }];
		}
	}
	membersearch() {
		if (!this.subscriptionForm.controls.GiftTo.controls.FirstName.value
			&& !this.subscriptionForm.controls.GiftTo.controls.LastName.value && !this.subscriptionForm.controls.GiftTo.controls.Email.value &&
			!this.subscriptionForm.controls.GiftTo.controls.PrimaryPhone.value && !this.subscriptionForm.controls.GiftTo.controls.SecondaryPhone.value) {
			// console.log("no value")  
			this.giftMemberForm = true;
			this.newGiftMember = true;
		} else {
			// console.log("inside member has value else",this.subscriptionForm.controls.GiftTo.controls.SalutationId.value,this.subscriptionForm.controls.GiftTo.controls.FirstName.value)
			this.giftMemberForm = false;
			this.newGiftMember = true;
			this.subscriptionForm.controls.GiftTo.controls.GiftMember.setValue(null);
		}
	}


	getSubscriptionList() {
		this.Subscriptions = [];
		for (let i = 0; i < this.SubscriptionList.length; i++) {
			this.Subscriptions.push({ Id: this.SubscriptionList[i].SubscriptionId, Name: this.SubscriptionList[i].SubscriptionName })
		}
	}

	changeSubType(type) {
		console.log("inside chnage sub type------>", type);
		this.subType = type.Name;
		this.multiselect = true;
		this.flag = true;
		this.subscriptionForm.controls['IsGiftTo'].setValue(0);
		this.subscriptionForm.controls['Publication'].enable();
		this.subscriptionForm.controls['NoOfCopiesRequired'].setValidators(null);
		if (this.subType == "Gift Subscription") {
			this.subscriptionForm.controls['IsGiftTo'].setValue(1);
			console.log("is gift to value---chnge----", this.subscriptionForm.controls['IsGiftTo'].value)
			this.isGift = true;
			this.getPublicationList();
			//setting value null to delivery address field
			console.log("setting nul values to addrs")
			this.subscriptionForm.controls.DeliveryAddress.controls.AddressId.setValue('');
			this.subscriptionForm.controls.DeliveryAddress.controls.Address1.setValue('');
			this.subscriptionForm.controls.DeliveryAddress.controls.Address2.setValue('');
			this.subscriptionForm.controls.DeliveryAddress.controls.City.setValue('');
			this.subscriptionForm.controls.DeliveryAddress.controls.StateId.setValue('');
			this.subscriptionForm.controls.DeliveryAddress.controls.Zip.setValue('');
			this.newGiftMember = true;
			this.giftMemberForm = true;
			// this.setGiftCategoryValidator();
			let GiftTo = this.subscriptionForm.controls['GiftTo'];
			GiftTo.get('FirstName').setValidators([Validators.compose([Validators.required, Validators.maxLength(100)])]);
			GiftTo.get('LastName').setValidators([Validators.required, Validators.maxLength(100)]);
			// GiftTo.get('Email').setValidators([Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(150), Validators.pattern(/^[a-zA-Z0-9]+[a-zA-Z0-9-!#$%^&*{'~?]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)])]);
			GiftTo.get('Email').setValidators([Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])]);
			GiftTo.get('PrimaryPhone').setValidators([Validators.compose([Validators.required, Validators.pattern('(?:[0-9]●?){6,14}[0-9]')])]);
			GiftTo.get('SecondaryPhone').setValidators(Validators.pattern('(?:[0-9]●?){6,14}[0-9]'));
			GiftTo.get('SalutationId').updateValueAndValidity();
			GiftTo.get('FirstName').updateValueAndValidity();
			GiftTo.get('LastName').updateValueAndValidity();
			GiftTo.get('Email').updateValueAndValidity();
			GiftTo.get('PrimaryPhone').updateValueAndValidity();
			GiftTo.get('SecondaryPhone').updateValueAndValidity();
		} else if (this.subType == "Renew Subscription") {
			this.allDurations = [];
			this.totalPrice = 0;
			this.subscriptionForm.get('Publication').patchValue([]);
			this.subscriptionForm.get('SubscribeFor').patchValue([]);
			this.subscriptionForm.controls['Publication'].disable();
			this.addrsSet = true;
			this.getSelectedMemberAddress(this.selectedMemberId);
			//calling get renewl data call that o renew button
			this.getRenewalOptions(this.renewSubId);
		} else if (this.subType == "Bulk Subscription") {
			this.multiselect = false;
			this.allDurations = [];
			this.totalPrice = 0;
			this.addrsSet = true;
			this.getSelectedMemberAddress(this.selectedMemberId);
			this.subscriptionForm.get('Publication').patchValue([]);
			this.subscriptionForm.get('SubscribeFor').patchValue([]);
			this.subscriptionForm.controls['Subscription'].controls['Type'].setValue(3)
			this.subscriptionForm.get('NoOfCopiesRequired').setValidators(Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')]));
			this.subscriptionForm.get('NoOfCopiesRequired').updateValueAndValidity();
			this.Publications = [];
			let params = {
				url: "frontend-site/member-subscription/fetch-publications-bulk",
				params: {
					MemberCountryId: this.countryId
				}
			};
			this._crud.getData(params).subscribe(data => {

				if (data['status'] == "error") {
					//error case
				} else {
					this.publicationarr = data['data'];
					this.Publications = [];
					for (let i = 0; i < data['data'].length; i++) {
						this.Publications.push({ Id: this.publicationarr[i].Id, Name: this.publicationarr[i].PublicationName, PublicationId: this.publicationarr[i].PublicationId })
					}
					console.log("bulk publictions----->", this.Publications);
				}
			})
		} else if (this.subType == "New Subscription") {
			this.addrsSet = true;
			this.getSelectedMemberAddress(this.selectedMemberId);
			// console.log("inside new sub----")
			this.getPublicationList();
		}
	}
	getPublicationList() {
		this.allDurations = [];
		this.totalPrice = 0;
		this.Currency = null;
		this.selectedPublicationsArray = [];
		this.subscriptionForm.get('Publication').patchValue([]);
		this.subscriptionForm.get('SubscribeFor').patchValue([]);
		let params = {};
		params['url'] = "frontend-site/member-subscription/publication-list";
		params['params'] = {
			CountryId: this.countryId,
			SiteName: 'myshalom'
		}
		this._crud.getData(params).subscribe(data => {
			this.publicationarr = data['data']['PublicationList'];
			// console.log(data['data'], "get publition list");
			this.Publications = [];
			for (let i = 0; i < data['data']['PublicationList'].length; i++) {
				this.Publications.push({ Id: this.publicationarr[i].PublicationId, Name: this.publicationarr[i].PublicationName })
			}
			this.PublicationsList = data['data']['PublicationList'];
			// console.log("get publistion list ast---->",this.PublicationsList);
			if (this.queryParam.publicationId) {
				// this.publicationIdFromHome = this.queryParam.publicationId
				// this.subscriptionForm.controls['Publication'].disable();
				// this.subscriptionForm.controls['SubscriptionType'].disable()
				this.flag = false
				this.PublicationsfromHome = []
				let selectedOption = this.PublicationsList.filter(x => {
					if (x.PublicationId == this.queryParam.publicationId) {
						return x;
					}
				});
				// console.log(selectedOption,"oooooooooooo")
				// this.publicationIdFromHome = selectedOption[0]['PublicationId']
				this.PublicationsfromHome.push({ Id: selectedOption[0]['PublicationId'], Name: selectedOption[0]['PublicationName'] })
				this.selectPublication(this.PublicationsfromHome);
			}
			this.addPublication(true, this.PublicationsList[0].PublicationId);

		});
	}
	addPublication(checked, publicationId) {
		console.log('add publication subscriptionForm-------------', publicationId);
		if (this.subType == "Bulk Subscription") {
			// this.allDurations = [
			//     {
			//         Duration: 1,
			//         DurationType: 1,
			//         DisplayText: "1 Year"

			//     },
			//     {
			//         Duration: 2,
			//         DurationType: 1,
			//         DisplayText: "2 Year"
			//     },
			//     {
			//         Duration: 5,
			//         DurationType: 1,
			//         DisplayText: "5 Year"
			//     }
			// ]
			this.getStartIssue(publicationId)
			this.subscriptionForm.controls['StartIssueId'].enable();
			this.allDurations = [
				{
					Duration: 2,
					DurationType: 1,
					DisplayText: "2 Years"
				},
				{
					Duration: 1,
					DurationType: 1,
					DisplayText: "1 Year"

				},

				{
					Duration: 3,
					DurationType: 2,
					DisplayText: "6 Months"
				},
				{
					Duration: 1,
					DurationType: 2,
					DisplayText: "1 Issue"
				}
			]
			let params = {
				url: "frontend-site/publication/subscription/get-bulk-max-min-slab",
				params: {
					MemberCountryId: this.countryId,
					ComboId: publicationId.Id
				}
			};
			this._crud.getData(params).subscribe(data => {
				console.log("bluk slab data----", data);
				if (data) {
					this.bulkSubMinSlab = data['data'].MinimumSlab;
					this.bulkSubMaxSlab = data['data'].MaximumSlab;
				}
			});
		} else {
			let params = {
				// url: "frontend-site/member-subscription/subscription-options",
				url: "frontend-site/publication/subscription/tidings-subscription-options",

				params: {
					CountryId: this.countryId,
					Publications: this.selectedPublicationsArray
				}
			};
			for (let i = 0; i < this.selectedPublicationsArray.length; i++) {
				this.SelectedPublicationIds[i] = this.selectedPublicationsArray[i].Id
			}
			if (this.selectedPublicationsArray.length > 0) {
				this._crud.postData(params).subscribe((data) => {
					// console.log(data, "subscription-options ");
					if (data['data']) {
						this.errorShow = false;
						this.subscriptionOptions = data['data'].PriceOptions;
						this.allDurations = [];
						for (let i = 0; i < this.subscriptionOptions.length; i++) {
							if (data['data'].Type == 1) {
								if (this.SelectedPublicationIds[0] == this.subscriptionOptions[i].PublicationId) {
									this.allDurations = this.subscriptionOptions[i].PriceGrid;
									// console.log("all duartion----->",this.allDurations);
								}
							} else {
								// console.log("selected pub ids======",this.SelectedPublicationIds, this.subscriptionOptions[i].PublicationId)
								// need to find solution for is equal
								// console.log("this.SelectedPublicationIds.sort()-------",this.SelectedPublicationIds.sort());
								// console.log("this.subscriptionOptions[i].PublicationId.sort()-------",this.subscriptionOptions[i].PublicationId.sort());
								let a = this.SelectedPublicationIds.sort();
								let b = this.subscriptionOptions[i].PublicationId.sort();
								if (JSON.stringify(a) === JSON.stringify(b)) {
									this.allDurations = this.subscriptionOptions[i].PriceGrid;
									// console.log("all duaryion indide if---",this.subscriptionOptions[i].PriceGrid)
								}
								// if (this.SelectedPublicationIds.sort().equals(this.subscriptionOptions[i].PublicationId.sort()) ) {
								//     this.allDurations = this.subscriptionOptions[i].PriceGrid;
								// }
							}

							if (!this.allDurations.length || this.allDurations.some(x => { return x.value != this.subscriptionOptions[i].Duration })) {
								this.allDurations.push({
									text: this.subscriptionOptions[i].DisplayText,
									value: this.subscriptionOptions[i].Duration
								})
							}
						}
						this.subscriptionForm.controls['Subscription'].controls['Type'].setValue(data['data'].Type)
						this.subscriptionForm.controls['PaymentProcessParams'].controls['SubscriptionAmount'].setValue(this.totalPrice)
						this.subscriptionForm.controls['PaymentProcessParams'].controls['NetAmountToPay'].setValue(this.totalPrice);
					} else if (data['status'] == "error") {
						this.errorMsg = data['msg'];
						this.errorShow = true;
						//error case
					}

				});
			}
			if (this.subType == "New Subscription") {
				console.log(publicationId,'idididi')
				this.getStartIssue(publicationId)
				this.subscriptionForm.controls['StartIssueId'].disable()
			}
		}
	}
	getRenewalOptions(renewID = null) {
		this.subscriptionForm.get('Publication').patchValue([]);
		this.subscriptionForm.controls['Subscription'].get('RenewalSubscriptionId').markAsDirty();
		if (this.subscriptionForm.controls['Subscription'].get('RenewalSubscriptionId').invalid == false) {
			// backend code change pending
			let params = {
				url: "frontend-site/member-subscription/get-renewal-relevant-data",
				params: {
					PrimaryCountryId: this.countryId,
					// SubscriptionId: this.subscriptionForm.controls['Subscription'].get('RenewalSubscriptionId').value,
					SubscriptionId: renewID,
					// LastName: this.subscriptionForm.controls['Subscription'].get('LName').value,
					LastName: this.renewLName
				}
			};
			this._crud.getData(params).subscribe(data => {
				this.allDurations = [];
				this.totalPrice = 0;
				this.Currency = null;
				// console.log("renewl data----------->",data['data']);
				if (data['status'] == "error") {
					this.errorRenewal = true;
				} else {
					this.errorRenewal = false;
					this.selectpublication = data['data'].PublicationDetails.Publications;
					this.selectpublication.forEach(element => {
						this.subscriptionForm.controls['Publication'].setValue(element.Name)
					});
					this.selectedPublicationsArray = [];
					this.selectedPublicationsArray = data['data'].PublicationDetails.Publications;
					// console.log("selectedPublicationsArray item------>",this.selectedPublicationsArray);
					this.renewItems = [];
					this.renewItems.push({ Id: '1', Name: data['data'].PublicationDetails.PublicationName });
					// console.log("renew item------>",this.renewItems);
					this.renewalSubscription = '1';
					this.renewalOptions = data['data'];
					// console.log("subscrption detail renew-----------",this.renewalOptions)
					this.addPublication(true, this.renewalOptions.PublicationDetails.PublicationId);

				}

			});
		}
	}
	changeDuration(value) {
		// console.log("chnage duration------------",value);
		this.selectedSubscriptionOption = value;
		if (this.subType == "Bulk Subscription") {
			this.subscriptionForm.controls['Subscription'].controls['PublicationComboId'].setValue(this.selectedPublicationsArray['Id'])
			this.subscriptionForm.controls['NoOfCopiesRequired'].setValue('');
			this.totalPrice = 0;
			this.Currency = null;
			this.subscriptionForm.controls['Subscription'].controls['DurationType'].setValue(this.selectedSubscriptionOption.DurationType)
			this.subscriptionForm.controls['Subscription'].controls['Duration'].setValue(parseInt(this.selectedSubscriptionOption.Duration))
		} else {

			this.totalPrice = value.Price;
			if (this.totalPrice == '0.00') {
				console.log("zero maount case")
				this.hidePaymentMethod = true;
			} else {
				this.hidePaymentMethod = false;
			}
			this.Currency = value.CurrencyCode;
			this.subscriptionForm.controls['PaymentProcessParams'].controls['CurrencyISOCode'].setValue(this.Currency);
			this.subscriptionForm.controls['PaymentProcessParams'].controls['SubscriptionAmount'].setValue(this.totalPrice)
			this.subscriptionForm.controls['PaymentProcessParams'].controls['NetAmountToPay'].setValue(this.totalPrice);
			this.duration = value.Duration;
			this.googlePayClick()

			this.subscriptionForm.controls['Subscription'].controls['PublicationComboId'].setValue(this.selectedSubscriptionOption.PublicationComboId)
			if (this.selectedSubscriptionOption.ComboPriceId) {
				this.subscriptionForm.controls['Subscription'].controls['ComboPriceId'].setValue(this.selectedSubscriptionOption.ComboPriceId)
				this.subscriptionForm.controls['Subscription'].controls['PriceOptionId'].setValue(null)

			} else if (this.selectedSubscriptionOption.PriceOptionId) {
				this.subscriptionForm.controls['Subscription'].controls['ComboPriceId'].setValue(null)
				this.subscriptionForm.controls['Subscription'].controls['PriceOptionId'].setValue(this.selectedSubscriptionOption.PriceOptionId)

			}

			this.subscriptionForm.controls['Subscription'].controls['Format'].setValue(this.selectedSubscriptionOption.Format)
			this.subscriptionForm.controls['Subscription'].controls['DurationType'].setValue(this.selectedSubscriptionOption.DurationType)
			this.subscriptionForm.controls['Subscription'].controls['Duration'].setValue(parseInt(this.selectedSubscriptionOption.Duration))
			if ((this.countryId == 'BprLdASASGl0' && this.Currency == 'USD') || (this.countryId == 'gQFUOwHXczkw' && this.Currency == 'CAD')) {
				// element.text = "Bank Transfer (ACH)"
				console.log("inside not hide ach norml")
				this.PaymentMethods = this.paymentMethodList;
			} else {
				console.log("inside  hide ach norml",)
				let index = this.PaymentMethods.findIndex(element => element.PaymentMethod === 'ACH');
				// console.log("index----",index)
				if (index > 0) {
					this.PaymentMethods.splice(index, 1);
				}
				console.log("inside  hide ach norml", this.PaymentMethods, this.paymentMethodList)

			}
		}
		// console.log("payment method list------>",this.PaymentMethods)

	}
	selectPublication(data) {
		this.selectedPublicationsArray = []
		this.selectedPublicationsArray = data;
		if (this.subscription == 2) {
			this.validPub = false
		} else {
			this.selectedPublicationsArray.length > 0 ? this.validPub = false : this.validPub = true

		}
		this.subscriptionForm.controls['SubscribeFor'].setValue(null);
		this.totalPrice = 0;
		this.Currency = null;
		this.subscriptionForm.controls['NoOfCopiesRequired'].setValue('');
		this.allDurations = [];
		this.addPublication(true, this.selectedPublicationsArray);
	}
	amountCalculate() {
		// console.log("inside amount calcute no of copies",this.subscriptionForm.get('NoOfCopiesRequired').value);
		// console.log("selted pub array----------",this.selectedPublicationsArray);
		// if (this.subscriptionForm.controls['NoOfCopiesRequired'].value && this.subscriptionForm.controls['NoOfCopiesRequired'].value > 1 && this.subscriptionForm.controls['NoOfCopiesRequired'].value < 100001) {
		if (this.subscriptionForm.controls['NoOfCopiesRequired'].value && this.subscriptionForm.controls['NoOfCopiesRequired'].value >= this.bulkSubMinSlab && this.subscriptionForm.controls['NoOfCopiesRequired'].value <= this.bulkSubMaxSlab) {
			this.copiesValidation = false;
			let params = {}
			params["url"] = "frontend-site/member-subscription/get-actual-amount-for-bulk";
			params["params"] = {
				MemberCountryId: this.countryId,
				ComboId: this.selectedPublicationsArray['Id'],
				NoOfCopiesRequired: this.subscriptionForm.controls['NoOfCopiesRequired'].value,
				Duration: this.selectedSubscriptionOption.Duration,
				DurationType: this.selectedSubscriptionOption.DurationType
			};
			this._crud.postData(params).subscribe(data => {
				// console.log('get amount for bulk', data);
				this.totalPrice = (data['data'].ActualAmount).toFixed(2);
				console.log("total price--", this.totalPrice);
				this.Currency = data['data'].CurrencyCode;
				if ((this.countryId == 'BprLdASASGl0' && this.Currency == 'USD') || (this.countryId == 'gQFUOwHXczkw' && this.Currency == 'CAD')) {
					// element.text = "Bank Transfer (ACH)"
					console.log("inside not hide ach bulk", this.paymentMethodList);
					this.PaymentMethods = this.paymentMethodList;
				} else {
					console.log("inside  hide ach bulk")
					let index = this.PaymentMethods.findIndex(element => element.PaymentMethod === 'ACH');
					if (index > 0) {
						this.PaymentMethods.splice(index, 1);
					}
				}
				this.subscriptionForm.controls['PaymentProcessParams'].controls['SubscriptionAmount'].setValue(this.totalPrice)
				this.subscriptionForm.controls['PaymentProcessParams'].controls['NetAmountToPay'].setValue(this.totalPrice);
				this.subscriptionForm.controls['PaymentProcessParams'].controls['CurrencyISOCode'].setValue(this.Currency);

			});
		} else {
			this.copiesValidation = true;
			console.log("inside wrong slab condtion", this.bulkSubMaxSlab, this.bulkSubMinSlab, this.subscriptionForm.controls['NoOfCopiesRequired'].value)
			// this.copiesValidation = true;
			if (this.subscriptionForm.controls['NoOfCopiesRequired'].value < this.bulkSubMinSlab) {
				console.log("less tahn min slab")
				this.subscriptionForm.controls["NoOfCopiesRequired"].setErrors({ 'lessthanminslab': true });
				this.ValidationMessage.NoOfCopiesRequired = [
					{ type: "lessthanminslab", message: 'A minimum of ' + this.bulkSubMinSlab + ' copies is required' },
				]
				if (!this.subscriptionForm.controls['NoOfCopiesRequired'].value) {
					console.log("input empty value")
					this.subscriptionForm.controls["NoOfCopiesRequired"].setErrors({ 'required': true });
					this.ValidationMessage.NoOfCopiesRequired = [
						{ type: "required", message: 'Please enter number of copies required' },
					]
				}
			}
			if (this.subscriptionForm.controls['NoOfCopiesRequired'].value > this.bulkSubMaxSlab) {
				console.log("max tan maxslab")
				this.subscriptionForm.controls["NoOfCopiesRequired"].setErrors({ 'greaterthanmaxslab': true });
				const maxValue = parseInt(this.bulkSubMaxSlab) + 1;
				this.ValidationMessage.NoOfCopiesRequired = [
					{ type: "greaterthanmaxslab", message: 'Maximum number of copies exceeded. Please enter a number less than ' + maxValue },
				]
			}
		}
	}
	getAllPaymentMethods(countryId) {
		let params = {}
		params["params"] = {
			sourceType: 2,
			countryId: countryId,
			sourceId: null,
			type: null

		};
		params["url"] = "frontend-site/fetch-available-payment-methods";
		this._crud.getData(params).subscribe(data => {
			// console.log("inside get all paymnt methods--------",data['data']);
			this.PaymentMethods = data['data'];
			console.log("payment method data", data['data']);
			
			this.paymentMethodList = data['data'];
			this.countryPaymentId = this.paymentMethodList[0]['CountryPaymentSettingId']
			console.log("iiid", this.countryPaymentId);
			this.getThreedsecure();
			let braintree = data['data'].filter(x => { return x.UniqueName == "credit_or_debt" })
			this.brainTreeToken = braintree[0].TokenizationKey;
			// this.createBraintreeUI();
			
			if(this.Currency=='INR'){
                this.PaymentMethods.forEach((element, i) => {
                    if (element.PaymentMethod == 'Paypal') {
                this.PaymentMethods.splice(i, 1);
                    }               
                })  
            }
			this.PaymentMethods.map(element => {
				console.log("element",element);
				
				if (element.PaymentMethod == 'ACH') {
					console.log("1");
					element.text = "Bank Transfer (ACH)"
				} else if (element.PaymentMethod == 'Credit/Debit') {
					console.log("2");
					element.text = "Credit / Debit Card"
					// this.savedCreditCard();
				}
					else {
					element.text = element.PaymentMethod;
					if(element.BankAccountDetails!=null){
						this.text = (element.BankAccountDetails).replaceAll("|", "");
					}
					
				}
			});
			// if (this.InitialMethodId == null) {
			//   this.InitialMethodId = data['data'][0].PaymentMethodId;
			// }
			this.formInit();
			this.subscriptionForm.controls['PaymentProcessParams'].controls['SubscriptionAmount'].setValue(this.totalPrice);
			this.subscriptionForm.controls['PaymentProcessParams'].controls['NetAmountToPay'].setValue(this.totalPrice);

		});
	}
	// onPaymentMethodChange(paymentMethod) {
	//   //complte th api
	//   console.log("inside paymethod chnge-----",paymentMethod);

	// }

	savedCreditCard() { // member/saved-cards/{member_id}
		console.log("craeting clint token")
		this.loaderCreditsaved = true
		let params = {};
		let selectedId = this.PaymentMethods.filter(x => { return x.UniqueName == this.selectedPaymentMethod });
	

		params['url'] = 'frontend-site/member/saved-cards/' + this.selectedMemberId + '/' + selectedId[0].CountryPaymentSettingId;
		params['params'] = {};
		this._crud
			.getData(params)
			.subscribe(data => {
				console.log(data['data']['SavedCards'], "maped clint response")
				if (data['data']['SavedCards'] != null) {
					this.listSavedCreditCard = true;
					this.savedCreditCards = data['data']['SavedCards']
					console.log("cards", this.savedCreditCards);

					this.loaderCreditsaved = false
				} else {
					this.addNewCard = true
					this.listSavedCreditCard = false;
					this.getClientToken();
				}

				// return res.data.ClientToken;
			});
	}
	onPaymentMethodChange(paymentMethod) {
		console.log("method", paymentMethod);
		this.isLoading = false;
		this.paymentMethodError = false;
		this.addNewCard = false;
		this.subscriptionForm.controls.AddNewCard.setValue(false);
		// console.log(paymentMethod, "HAAAALOAAAA");
		let selectedId = this.PaymentMethods.filter(x => { return x.PaymentMethodId == paymentMethod });
		this.paymentSettingId = selectedId[0].CountryPaymentSettingId;
		console.log("pay", this.paymentSettingId);
		console.log("hello", selectedId[0].PaymentMethodId);
		this.paymentName = selectedId[0].UniqueName
		this.merchantId=   selectedId[0].UniqueId
        this.tokenizationkey=selectedId[0].TokenizationKey
        this.googlePayClick()
		this.InitialMethodId = selectedId[0].PaymentMethodId;
		console.log(this.InitialMethodId, "Initial");
		this.selectedPaymentname = selectedId[0].PaymentMethod;
		let details = this.PaymentMethods.filter(x => { return x.PaymentMethodId == paymentMethod });
		let PaymentFields = this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'];
		this.selectedPaymentMethod = selectedId[0].UniqueName;


		console.log("paymentmethod", this.selectedPaymentMethod);

		if (this.selectedPaymentMethod == "ach" || this.selectedPaymentMethod == "credit_or_debt" || this.selectedPaymentMethod == "gpay" || this.selectedPaymentMethod == "paypal") {
			if (this.selectedPaymentMethod == "credit_or_debt") {
				PaymentFields.get('ChooseCard').setValidators(Validators.required);

				// this.getClientToken()
			}
			else if(this.selectedPaymentMethod == "paypal") {
                this.getClientToken()
            }
			 else {
				this.loaderAchSaved = true

			}
			// this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(1);
			this.subscriptionForm.controls['PaymentProcessParams'].controls['ProceedWithoutPaymentProcess'].setValue(0);
		} else {
			if(details[0].BankAccountDetails!=null){
			this.text = (details[0].BankAccountDetails).replaceAll("|", "");
			}
			this.selectedPaymentMethod = "manual_payment"
			// this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(0);
			this.subscriptionForm.controls['PaymentProcessParams'].controls['ProceedWithoutPaymentProcess'].setValue(1);
		}
		if (this.selectedPaymentMethod == "ach") {
			PaymentFields.get('ChooseCard').setValidators(Validators.required);

			PaymentFields.get('AccountHolder').setValidators(Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z '&-]+$/)]));
			PaymentFields.get('AccountNumber').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{6,17}$/)]));
			// PaymentFields.get('BankName').setValidators(Validators.compose([Validators.required, Validators.maxLength(100)]));
			PaymentFields.get('AccountType').setValidators([Validators.required]);
			if (this.countryId == 'BprLdASASGl0') {
				PaymentFields.get('RoutingNumber').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{9,9}$/)]));
				PaymentFields.get('RoutingNumber').updateValueAndValidity();
				this.ValidationMessage.RoutingNumber = [
					{ type: "required", message: 'Please enter a valid routing number' },
					{ type: 'pattern', message: 'Please enter a valid routing number with nine digits' }
				]
			} else if (this.countryId == 'gQFUOwHXczkw') {
				PaymentFields.get('RoutingNumber').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{8,8}$/)]));
				PaymentFields.get('RoutingNumber').updateValueAndValidity();
				this.ValidationMessage.RoutingNumber = [
					{ type: "required", message: 'Please enter a valid routing number' },
					{ type: 'pattern', message: 'Please enter a valid routing number with eight digits' }
				]
			} else {
				PaymentFields.get('RoutingNumber').setValidators(Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')]));
				PaymentFields.get('RoutingNumber').updateValueAndValidity();
				this.ValidationMessage.RoutingNumber = [
					{ type: "required", message: 'Please enter your bank routing number' },
					{ type: 'pattern', message: 'Please enter a valid routing number using only numbers' }
				]
			}
		}
		else {
			PaymentFields.get('AccountHolder').setValidators('');
			PaymentFields.get('RoutingNumber').setValidators('');
			PaymentFields.get('AccountNumber').setValidators('');
			PaymentFields.get('BankName').setValidators('');
			PaymentFields.get('AccountType').setValidators('');
		}
		PaymentFields.get('AccountHolder').updateValueAndValidity();
		PaymentFields.get('RoutingNumber').updateValueAndValidity();
		PaymentFields.get('AccountNumber').updateValueAndValidity();
		PaymentFields.get('BankName').updateValueAndValidity();
		PaymentFields.get('AccountType').updateValueAndValidity();
		// console.log(selectedId);
		if (this.selectedPaymentMethod !== "credit_or_debt") {
			let params = {};
			params['url'] = 'frontend-site/member/client-token/' + selectedId[0].CountryPaymentSettingId + '/' + this.selectedMemberId;
			params['params'] = {};
			return this._crud.getData(params)
				.subscribe(data => {
					if (data['status'] == "success") {
						// console.log("saved cardlist-----",data['data']['SavedCards']);

					}
					if (data['data']['SavedCards'].length > 0) {
						// console.log("saved card present---------------")
						this.IsSavedAchPresent = true;
						this.savedAchCardButtonShow = true;
						this.savedCards = data['data']['SavedCards'];
						this.showNewCardOption = false;
						this.loaderAchSaved = false

					} else {
						this.savedAchCardButtonShow = false;
						this.showNewCardOption = true;
						this.IsSavedAchPresent = false;
						this.addNewCard = true
						this.loaderAchSaved = false

					}

				}, error => {
					// console.log("Some error tiggered" + error)
				});
		} else {
			// let params = {};
			//     params['url'] = 'frontend-site/member/credit-card-exist' + '/' + this.routeParam.memberId;
			//     params['params'] = {};
			//             return this._crud.getData(params).subscribe(response => {
			//                 console.log("saved credit card exit for use-----",response);
			//                 this.savedCreditCardButtonShow = response['data'];
			//             })
			this.savedCreditCard()
		}
	}
	onMemberChange(value) {
		console.log(value);
		this.selectedMemberId = value.Id;
		this.selectedMemberName = value.Name;
		this.getSelectedMemberAddress(this.selectedMemberId);
	}
	onGiftMemberChange(value) {
		console.log("gift eber cnhnge", value);
		if (!value) {
			console.log("gift eber cnhnge if nt", value);
			this.newGiftMember = true;
			this.selectedGiftMemberId = '';
			//setting value null to delivery address field
			this.subscriptionForm.controls.GiftTo.controls.GiftMember.setValue(null);
			this.subscriptionForm.controls.DeliveryAddress.controls.AddressId.setValue('');
			this.subscriptionForm.controls.DeliveryAddress.controls.Address1.setValue('');
			this.subscriptionForm.controls.DeliveryAddress.controls.Address2.setValue('');
			this.subscriptionForm.controls.DeliveryAddress.controls.City.setValue('');
			this.subscriptionForm.controls.DeliveryAddress.controls.StateId.setValue('');
			this.subscriptionForm.controls.DeliveryAddress.controls.Zip.setValue('');
		} else {
			this.selectedGiftMemberId = value.Id;
			this.addrsSet = true;
			this.getSelectedMemberAddress(this.selectedGiftMemberId);
			this.newGiftMember = false;
			let GiftTo = this.subscriptionForm.controls['GiftTo'];
			// GiftTo.get('SalutationId').setValidators(null);
			GiftTo.get('FirstName').setValidators(null);
			GiftTo.get('LastName').setValidators(null);
			GiftTo.get('Email').setValidators(null);
			GiftTo.get('PrimaryPhone').setValidators(null);
			GiftTo.get('SecondaryPhone').setValidators(null);
			GiftTo.get('FirstName').updateValueAndValidity();
			GiftTo.get('LastName').updateValueAndValidity();
			GiftTo.get('Email').updateValueAndValidity();
			GiftTo.get('PrimaryPhone').updateValueAndValidity();
			GiftTo.get('SecondaryPhone').updateValueAndValidity();
		}



	}
	tokenizeUserDetails(type = null) {
		this.cancelStatus = false;
		// this.disableMakePayment = true
		console.log('tokenizeUserDetails', this.subscriptionForm.value);
		console.log('method', this.selectedPaymentMethod);
		if (this.selectedPaymentMethod == 'credit_or_debt') {
			//   if(type == 'savedCreditcard'){
			//     this.submitForm(type)
			// }
			if (type == 'savedCreditcard') {
				if (this.threeDValue) {
					let lengthPri = this.threedCountryList.filter(x => { return x == this.primaryCountryId })
					let lengthCoun = this.threedCountryList.filter(x => { return x == this.countryId })
					if (lengthPri.length > 0 && lengthCoun.length > 0) {
						this.isLoading = true
						// let selectedId = this.PaymentMethods.filter(x => { return x.PaymentMethodId == this.selectedPaymentMethod });

						let Params = {}
						Params["params"] = {
							Token: this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value,
							Amount: this.subscriptionForm.controls['PaymentProcessParams'].controls['NetAmountToPay'].value
						}
						Params["url"] = "frontend-site/fetch-nonce-from-token";
						this._crud.getData(Params).subscribe((res) => {
							console.log('respo', res);
							if (res) {
								console.log('in res')
								this.getPaymentMethodNonceSavedcards(res);

							}
						})
					}
					else {
						this.submitForm(type)
					}

					// let param = {
					// 	url: "frontend-site/client-token/" + selectedId[0].CountryPaymentSettingId
					// }
					// this._crud.getData(param).subscribe(data => {
					// 	// console.log("clint token data-----------",data.ClientToken)
					// 	let token = data['data'].ClientToken;
					// 	// console.log("token----", token)





					// 	let params = {
					// 		url: "frontend-site/fetch-payment-method-nonce/" + this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value
					// 	}
					// 	this._crud.getData(params).subscribe(res => {
					// 		this.isLoading = true;
					// 		this.savedCardLoding = true;
					// 		console.log("cause list----", res['data']);
					// 		var threeDSecureParameters = {
					// 			amount: this.subscriptionForm.controls['PaymentProcessParams'].controls['NetAmountToPay'].value,
					// 			nonce: res['data']['PaymentMethodNonce'], // Example: hostedFieldsTokenizationPayload.nonce
					// 			bin: res['data']['Bin'], // Example: hostedFieldsTokenizationPayload.details.bin

					// 			onLookupComplete: function (data, next) {
					// 				// use `data` here, then call `next()`
					// 				next();
					// 			}
					// 		};
					// 		braintree.client.create({
					// 			authorization: token
					// 		}).then((clientInstance) => {
					// 			braintree.threeDSecure.create({
					// 				version: 2, // Will use 3DS2 whenever possible
					// 				client: clientInstance
					// 			}).then((threeDSecureInstance) => {

					// 				this.threeDSecure = threeDSecureInstance;

					// 				const self = this
					// 				this.threeDSecure.on('customer-canceled', function () {
					// 					// the customer canceled the 3D Secure challenge
					// 					self.cancelStatus = true
					// 				});
					// 				this.threeDSecure.verifyCard(threeDSecureParameters).then((response) => {
					// 					// submit authenticated response.nonce to the server from here
					// 					this.disableMakePayment = false
					// 					this.isLoading = false
					// 					console.log("3d secure response", response)
					// 					this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(response.nonce);

					// 					if (this.cancelStatus == true) {
					// 						this.disableMakePayment = false
					// 						this.isLoading = false
					// 					} else {
					// 						this.submitForm(type)
					// 					}

					// 				}).catch(function (error) {
					// 					// Handle error
					// 					self.disableMakePayment = false
					// 					self.isLoading = false
					// 					console.log("verify card error handling", error.details.originalError.details.originalError.error.message)
					// 					let popup = {}
					// 					popup['status'] = "error"
					// 					popup['msg'] = error.details.originalError.details.originalError.error.message
					// 					self.successPopup(popup);

					// 				});
					// 			});
					// 		});
					// 	});
					// })
				} else {
					this.submitForm(type)
				}
			}
			else {

				// this.hostedFieldsInstance.tokenize({ cardholderName: this.cardholdersName }).then((payload) => {
				// 	this.braintreeNonce = payload.nonce;
				// 	// console.log('payload', payload);
				// 	this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(payload.nonce);
				// 	// this.submitForm(type)
				// 	// submit payload.nonce to the server from here
				// 	// submit payload.nonce to the server from here
				// 	let lengthPri = this.threedCountryList.filter(x => { return x == this.primaryCountryId })
				// 	let lengthCoun = this.threedCountryList.filter(x => { return x == this.countryId })
				// 	if (lengthPri.length > 0 && lengthCoun.length > 0) {

				// 		//   if(this.primaryCountryId == 'BprLdASASGl0' && this.countryId == 'BprLdASASGl0'){
				// 		this.isLoading = true
				// 		var threeDSecureParameters = {
				// 			amount: this.subscriptionForm.controls['PaymentProcessParams'].controls['NetAmountToPay'].value,
				// 			nonce: payload.nonce, // Example: hostedFieldsTokenizationPayload.nonce
				// 			bin: payload.details.bin, // Example: hostedFieldsTokenizationPayload.details.bin

				// 			onLookupComplete: function (data, next) {
				// 				// use `data` here, then call `next()`
				// 				next();
				// 			}
				// 		};
				// 		const self = this
				// 		this.threeDSecure.on('customer-canceled', function () {
				// 			// the customer canceled the 3D Secure challenge
				// 			console.log('cancel eve in else')
				// 			self.cancelStatus = true
				// 		});
				// 		this.threeDSecure.verifyCard(threeDSecureParameters).then((response) => {
				// 			// submit authenticated response.nonce to the server from here
				// 			console.log("3d secure response", response)

				// 			this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(response.nonce);

				// 			if (this.cancelStatus == true) {
				// 				this.disableMakePayment = false
				// 				this.isLoading = false
				// 			} else {
				// 				this.submitForm(type)
				// 			}

				// 		}).catch(function (error) {
				// 			// Handle error
				// 			self.disableMakePayment = false
				// 			self.isLoading = false
				// 			console.log("verify card error handling", error.details.originalError.details.originalError.error.message)
				// 			let popup = {}
				// 			popup['status'] = "error"
				// 			popup['msg'] = error.details.originalError.details.originalError.error.message
				// 			self.successPopup(popup);

				// 		});
				// 	} else {
				// 		console.log('payload --> with out 3d security', payload);
				// 		this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(payload.nonce);
				// 		this.submitForm(type)

				// 	}
				if (this.hostedFieldsInstance == undefined) {
					this.disableMakePayment = false;

				} else {
					this.hostedFieldsInstance.tokenize({ cardholderName: this.cardholdersName }).then((payload) => {
						console.log('payload', payload);
						this.braintreeNonce = payload.nonce;
						// this.finalSubmitForm(value)
						// submit payload.nonce to the server from here

						if (this.threeDValue == true) {
							console.log('in three')
							// if(this.selectedFrequencyName == 'Onetime') {
							let lengthPri = this.threedCountryList.filter(x => { return x == this.primaryCountryId })
							let lengthCoun = this.threedCountryList.filter(x => { return x == this.countryId })
							if (lengthPri.length > 0 && lengthCoun.length > 0) {
								console.log('in length')
								//   if(this.primaryCountryId == 'BprLdASASGl0' && this.countryId == 'BprLdASASGl0'){
								this.isLoading = true
								var threeDSecureParameters = {
									amount: this.subscriptionForm.controls['PaymentProcessParams'].controls['NetAmountToPay'].value,
									nonce: payload.nonce, // Example: hostedFieldsTokenizationPayload.nonce
									bin: payload.details.bin, // Example: hostedFieldsTokenizationPayload.details.bin

									onLookupComplete: function (data, next) {
										// use `data` here, then call `next()`
										next();
									}
								};
								const self = this
								this.threeDSecure.on('customer-canceled', function () {
									// the customer canceled the 3D Secure challenge
									self.cancelStatus = true
								});
								this.threeDSecure.verifyCard(threeDSecureParameters).then((response) => {
									// submit authenticated response.nonce to the server from here
									this.braintreeNonce = response.nonce;
									console.log("3d secure response", response.nonce)
									this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(response.nonce);

									if (this.cancelStatus == true) {
										this.disableMakePayment = false
										this.isLoading = false
									} else {
										this.submitForm(type)
									}

								}).catch(function (error) {
									// Handle error
									self.disableMakePayment = false
									self.isLoading = false
									console.log("verify card error handling", error.details.originalError.details.originalError.error.message)
									let popup = {}
									popup['status'] = "error"
									popup['msg'] = error.details.originalError.details.originalError.error.message
									self.successPopup(popup);

								});
							}
							else {
								console.log('in else length')
								this.submitForm(type)
							}

							// }
							// else {
							//     console.log('in recu')
							//     this.getPaymentMethodNonce(payload.nonce);
							// }

						}
						else {
							console.log('in else')
							console.log('payload --> with out 3d security', payload);
							this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(payload.nonce);
							this.submitForm(type)
						}
					}).catch((error) => {
						// console.log('error_detail', error)
						this.brainTreeError = '';
						// perform custom validation here or log errors
						// if (this.donationForm.controls['Amount'].value == null && this.otheramoutValidation == false && this.maxOtherAmount == false) {
						//     this.donationForm.controls['Amount'].setValue(this.donationForm.controls['OtherAmount'].value)
						//     this.donationForm.controls['CausePaymentOptionValueId'].setValue(null);
						// }
						// let Step2Fields = [
						// this.subscriptionForm.controls['Amount'],
						// this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['BillingDate'],
						// this.subscriptionForm.controls['PersonalDetails'].controls['FirstName'],
						// this.subscriptionForm.controls['PersonalDetails'].controls['LastName'],
						// this.subscriptionForm.controls['PersonalDetails'].controls['PrimaryPhone'],
						// this.subscriptionForm.controls['PersonalDetails'].controls['SecondaryPhone'],
						// this.subscriptionForm.controls['PersonalDetails'].controls['Email'],
						//     this.subscriptionForm.controls['PersonalDetails'].controls['BillingAddress'].controls['Address1'],
						//     this.subscriptionForm.controls['PersonalDetails'].controls['BillingAddress'].controls['Address2'],
						//     this.subscriptionForm.controls['PersonalDetails'].controls['BillingAddress'].controls['City'],
						//     this.subscriptionForm.controls['PersonalDetails'].controls['BillingAddress'].controls['CountryId'],
						//     this.subscriptionForm.controls['PersonalDetails'].controls['BillingAddress'].controls['StateId'],
						//     this.subscriptionForm.controls['PersonalDetails'].controls['BillingAddress'].controls['Zip'],
						//     this.subscriptionForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['Address1'],
						//     this.subscriptionForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['Address2'],
						//     this.subscriptionForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['City'],
						//     this.subscriptionForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['CountryId'],
						//     this.subscriptionForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['StateId'],
						//     this.subscriptionForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['Zip'],
						//     this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountHolder'],
						//     this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['RoutingNumber'],
						//     this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountNumber'],
						//     this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountType'],
						//     this.subscriptionForm.controls['IsGiftTo'],
						//     this.subscriptionForm.controls['SubscribeFor'],
						// ]
						// // console.log(this.subscriptionForm, "TEST");
						// const invalid = []
						// const controls = Step2Fields;
						// let i: any;
						// for (i in controls) {
						//     controls[i].markAsDirty();
						//     if (controls[i].invalid) {
						//         invalid.push(i);
						//     }
						// }
						switch (error.code) {

							case 'HOSTED_FIELDS_FIELDS_EMPTY':
								// occurs when none of the fields are filled in
								console.error('All fields are empty! Please fill out the form.');
								// this.brainTreeError = 'All fields are empty! Please fill out the form.'
								this.noFieldsCardNumber = 1;
								this.noFieldsDate = 1;
								this.noFieldsCvv = 1;
								break;
							case 'HOSTED_FIELDS_FIELDS_INVALID':
								// occurs when certain fields do not pass client side validation
								console.error('Some fields are invalid:', error.details.invalidFieldKeys);
								// this.brainTreeError = 'Some fields are invalid'
								// you can also programtically access the field containers for the invalid fields
								// error.details.invalidFields.forEach(function(fieldContainer) {
								//     fieldContainer.className = 'invalid';
								// });
								error.details.invalidFieldKeys.forEach(element => {
									// console.log(element);
									if (element == 'number') {
										this.noFieldsCardNumber = 1;
									} if (element == 'cvv') {
										this.noFieldsCvv = 1;
									} if (element == 'expirationDate') {
										this.noFieldsDate = 1;
									}
								});
								break;
							case 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE':
								// occurs when:
								//   * the client token used for client authorization was generated
								//     with a customer ID and the fail on duplicate payment method
								//     option is set to true
								//   * the card being tokenized has previously been vaulted (with any customer)
								// See: https://developers.braintreepayments.com/reference/request/client-token/generate/#options.fail_on_duplicate_payment_method
								console.error('This payment method already exists in your vault.');
								this.brainTreeError = 'This payment method already exists in your vault.'
								break;
							case 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED':
								// occurs when:
								//   * the client token used for client authorization was generated
								//     with a customer ID and the verify card option is set to true
								//     and you have credit card verification turned on in the Braintree
								//     control panel
								//   * the cvv does not pass verfication (https://developers.braintreepayments.com/reference/general/testing/#avs-and-cvv/cid-responses)
								// See: https://developers.braintreepayments.com/reference/request/client-token/generate/#options.verify_card
								console.error('CVV did not pass verification');
								break;
							case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
								// occurs for any other tokenization error on the server
								console.error('Tokenization failed server side. Is the card valid?');
								this.brainTreeError = 'Tokenization failed server side. Is the card valid?'
								break;
							case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
								// occurs when the Braintree gateway cannot be contacted
								console.error('Network error occurred when tokenizing.');
								this.brainTreeError = 'Network error occurred when tokenizing.'
								break;
							default:
								console.error('Something bad happened!');
								this.brainTreeError = 'Something bad happened!  '
						}
					});
				}
			}
		}
		else {
			console.log('inside type')
			this.submitForm(type);
		}
	}

	getPaymentMethodNonceSavedcards(response: any) {
		let selectedId = this.PaymentMethods.filter(x => { return x.UniqueName == this.selectedPaymentMethod });
		console.log('in response')
		var threeDSecureParameters = {
			amount: response['data'].Amount,
			nonce: response['data'].PaymentMethodNonce, // Example: hostedFieldsTokenizationPayload.nonce
			bin: response['data'].Bin, // Example: hostedFieldsTokenizationPayload.details.bin

			onLookupComplete: function (data, next) {
				// use `data` here, then call `next()`
				// determine if you want to call next to start the challenge,
				// if not, call cancelVerifyCard
				next();
			},
		};
		let param = {
			url: "frontend-site/client-token/" + selectedId[0].CountryPaymentSettingId
		};
		this._crud.getData(param).subscribe((data) => {
			console.log('data23', data)
			let token = data['data'].ClientToken;
			braintree.client
				.create({
					authorization: token,
				})
				.then((clientInstance) => {
					braintree.threeDSecure
						.create({
							version: 2, // Will use 3DS2 whenever possible
							client: clientInstance,
						})
						.then((threeDSecureInstance) => {
							this.threeDSecure = threeDSecureInstance;

							const self = this;
							this.cancelStatus = false;
							this.threeDSecure.on("customer-canceled", function () {
								// the customer canceled the 3D Secure challenge
								self.cancelStatus = true;
							});
							this.threeDSecure
								.verifyCard(threeDSecureParameters)
								.then((res) => {
									// submit authenticated response.nonce to the server from here
									this.braintreeNonce = res.nonce;
									console.log(
										"2nd nonce after otp------",
										res,
										this.braintreeNonce,
										this.cancelStatus
									);
									if (self.cancelStatus == false) {
										console.log('inside4')
										this.submitForm();
									}
									else {
										console.log('in else cancel1')
										this.isLoading = false;
									}
								})
								.catch(function (error) {
									console.log(
										"verify card error handling",
										error.details.originalError.details.originalError.error
											.message
									);
									Helpers.sweetAlert({
										title: "Error",
										msg: error.details.originalError.details.originalError.error
											.message,
										type: "error",
									});
								});
						});
				});
		})
	}

	createPayapalUI(token?: any) {
        // Create a client.
        let mm = this
        console.log('mm.',mm.Currency)
        braintree.client.create({
            authorization: token,
        }, function (clientErr, clientInstance) {
            
            // Stop if there was a problem creating the client.
            // This could happen if there is a network error or if the authorization
            // is invalid.
            if (clientErr) {
                console.error('Error creating client:', clientErr);
                return;
            }

            // Create a PayPal Checkout component.
            braintree.paypalCheckout.create({
                client: clientInstance,
            }, function (paypalCheckoutErr, paypalCheckoutInstance) {
                paypalCheckoutInstance.loadPayPalSDK({
                    currency: mm.Currency,
                    vault: true,
                }, function () {
                    // if(mm.DonationForm.valid) {
                        
                    console.log('inside', clientInstance._configuration.gatewayConfiguration.paypal.clientId)
                    paypal.Buttons({
                        fundingSource: paypal.FUNDING.PAYPAL,

                        createBillingAgreement: function () {
                            
                                return paypalCheckoutInstance.createPayment({
                                    flow: 'vault', // Required
                                });

                        },
                        
                        style: {
                            label: 'pay',
                            layout: 'horizontal',
                            color: 'white',
                            shape: 'rect',
                            height: 40,

                        },


                        onApprove: function (data, actions) {
                                return paypalCheckoutInstance
                                    .tokenizePayment(data)
                                    .then(payload => {
                                        console.log(payload.nonce);
                                        mm.nonce = payload.nonce;
										mm.paypalLoad = true;
                                        mm.submitForm()
                                    });


                        },


                        onCancel: function (data) {
                            console.log('PayPal payment canceled', JSON.stringify(data));
                        },

                        onError: function (err) {
                            console.error('PayPal error', err);
                        }
                    }).render('#paypal-button').then(function () {
                        // The PayPal button will be rendered in an html element with the ID
                        // `paypal-button`. This function will be called when the PayPal button
                        // is set up and ready to be used
                    });
                    // }


                });

            });

        });
    }

	createBraintreeUI(token) {
		let fields = {}
        if(!this.isCvvhide) {
            fields = {
                number: {
                    selector: '#card-number',
                    placeholder: 'Card Number'
                },
                cvv: {
                    selector: '#cvv',
                    placeholder: 'CVV'
                },
                expirationDate: {
                    selector: '#expiration-date',
                    // placeholder: 'Expiration Date (MM/YY)'
                    placeholder: 'MM/YY'
                }
            }
        }
        else {
            fields = {
                number: {
                    selector: '#card-number',
                    placeholder: 'Card Number'
                },
                expirationDate: {
                    selector: '#expiration-date',
                    // placeholder: 'Expiration Date (MM/YY)'
                    placeholder: 'MM/YY'
                }
            }
        }
		// console.log("braintree");
		braintree.client.create({
			authorization: token
		}).then((clientInstance) => {
			this.loaderCreditsaved = false
			this.loaderCreditNew = false
			braintree.hostedFields.create({
				client: clientInstance,
				styles: {
					// Style all elements
					// 'input': {
					//   'font-size': '16px',
					//   'color': '#3A3A3A'
					// },
					'.invalid': {
						'color': '#D0041D'
					},

					// Styling element state
					// 'input': {
					//     // 'font-family': '"Merriweather", serif !important',
					//     'padding': "0 10px 0 20px !important",
					//     'letter-spacing': "0.025em !important",
					//     "font-weight": "300 !important",
					//     "font-size": "14px !important",
					// },
					'input': {
						'font-size': '16px!important',
						"font-weight": '500!important',
						'font-family': "'Poppins', sans-serif !important",
						'color': '#0D0D0D',
						':placeholder': {
							'font-weight': '500!important',
							'color': '#9F9F9F!important',
						}
					},

					// Media queries
					// Note that these apply to the iframe, not the root window.
					// '@media screen and (max-width: 700px)': {
					//     'input': {
					//         'font-size': '14px'
					//     }
					// }
				},

				// The hosted fields that we will be using
				// NOTE : cardholder's name field is not available in the field options
				// and a separate input field has to be used incase you need it
				fields: fields
			}).then((hostedFieldsInstance) => {
				this.hostedFieldsInstance = hostedFieldsInstance;
				hostedFieldsInstance.on('focus', (event) => {
					const field = event.fields[event.emittedBy];
					const label = this.findLabel(field);
					if (label['htmlFor'] == 'card-number') {
						this.noFieldsCardNumber = 0;
					}
					if (label['htmlFor'] == 'expiration-date') {
						this.noFieldsDate = 0;
					}
					if (label['htmlFor'] == 'cvv') {
						this.noFieldsCvv = 0;
					}
					// console.log(this.noFieldsCardNumber);
					// label.classList.remove('filled'); // added and removed css classes
					// can add custom code for custom validations here
					// this.cdRef.detectChanges();
				});
				hostedFieldsInstance.on('blur', (event) => {
					const field = event.fields[event.emittedBy];
					// const label = this.findLabel(field); // fetched label to apply custom validations
					// can add custom code for custom validations here
				});

				hostedFieldsInstance.on('empty', (event) => {
					const field = event.fields[event.emittedBy];
					// can add custom code for custom validations here
				});

				hostedFieldsInstance.on('validityChange', (event) => {
					const field = event.fields[event.emittedBy];
					const label = this.findLabel(field);
					if (field.isPotentiallyValid) { // applying custom css and validations
						// label.classList.remove('invalid');
					} else {
						// label.classList.add('invalid');
					}
					// can add custom code for custom validations here
				});
			});
			braintree.threeDSecure.create({
				version: 2, // Will use 3DS2 whenever possible
				client: clientInstance
			}).then((threeDSecureInstance) => {
				this.threeDSecure = threeDSecureInstance;
			});
		}).catch(error => {
			// console.log('error', error);
			if (error.name == 'BraintreeError') {
				// this.paymentGatewayErrorMsg = "Payment Gateway is not configured"
			}
		})
		// this.clearCardDetails();
	}
	// Fetches the label element for the corresponding field
	findLabel(field: braintree.HostedFieldsHostedFieldsFieldData) {
		return document.querySelector('.hosted-field--label[for="' + field.container.id + '"]');
	}
	AddNewAchAccount() {
		// console.log("on tab swich")
		this.showNewCardOption = true;
		this.isSavedCard = true
		this.selectedSavedCard = false;
		this.IsSavedAchPresent = false;
		//clearing new accunt card ach values on tab switch
		//if values are present in the new card form animate the placeholdr up,to avoid overlaping 
		if (this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder').value) {
			// console.log("accunt holder",document.getElementById("ach_person_name"))
			// document.getElementById("ach_person_name").classList.add("is-focused");
			this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder').setValue('');
			// this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder').setValidators(null);
		}
		if (this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber').value) {
			// console.log("accunt holder routing")
			// document.getElementById("ach_routing_number").classList.add("is-focused");
			this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber').setValue('');
			// this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber').setValidators(null);
		}
		if (this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber').value) {
			// console.log("accunt number",document.getElementById("ach_account_number")) 
			// document.getElementById("account_number").classList.add("is-focused");
			this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber').setValue('');
			// this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber').setValidators(null);

		}
		if (this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType').value) {
			this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType').setValue('');
		}
	}
	listsavedAchAccount() {
		this.IsSavedAchPresent = true;
		this.showNewCardOption = false;
	}
	onSelectedSavedcardChange(cardDetails) {
		console.log("inside saved card change------->", cardDetails);
		this.showNewCardOption = false;
		this.selectedSavedCard = true;
		this.addNewCard = false;
		this.subscriptionForm.controls['AddNewCard'].setValue(false)

	}
	AddNewCreditCard() {
		this.shownewCreditcardOption = true;
		this.showSavedCreditcardOption = false;
		let braintree = this.PaymentMethods.filter(x => { return x.PaymentMethodId == "credit_or_debt" })
		this.brainTreeToken = braintree[0].TokenizationKey;
		// this.createBraintreeUI();
	}
	listreditCard() {
		this.shownewCreditcardOption = false;
		this.showSavedCreditcardOption = true;
	}
	getClientToken() {
		console.log("client -token", this.selectedPaymentMethod);

		let selectedId = this.PaymentMethods.filter(x => { return x.UniqueName == this.selectedPaymentMethod });
		console.log("selectedId", selectedId);
		this.clientToken = selectedId[0].CountryPaymentSettingId
		let params = {};
		params['url'] = 'frontend-site/member/client-token/' + selectedId[0].CountryPaymentSettingId + '/' + this.selectedMemberId;
		params['params'] = {};
		//  this._crud
		//     .getBrainTreeToken(params)
		//     .pipe(map((res: any) => {
		this._crud.getData(params).subscribe(data => {
			// console.log(res.data.ClientToken,"maped clint response")
			// return res.data.ClientToken;
			let token = data['data'].ClientToken;
			console.log("token----", token)
			this.clearCardDetails();
			if(this.selectedPaymentMethod == "credit_or_debt") {
				this.createBraintreeUI(token);
			}
			else if(this.selectedPaymentMethod == "paypal") {
				this.createPayapalUI(token);
			}
		});
	}
	createPurchase(nonce: string, chargeAmount: number) {
		console.log("nonce",nonce);
		
		this.focusCall();
		// let currentUser = JSON.parse(localStorage.getItem("currentUser"));

		//     let headers = new Headers();
		//     headers.append("Content-Type", "application/json");
		//     headers.append("Authorization", "Bearer " + currentUser.access_token);
		//     headers.append("pc-id", currentUser.OptedPrimaryCountry);
		let param = {};
		let params = this.RequestPostParams(
			{ type: this.paymentName },
			nonce
		);

		this.step2Fields = [

			this.subscriptionForm.controls['DeliveryAddress'].controls['Address1'],
			this.subscriptionForm.controls['DeliveryAddress'].controls['Address2'],
			this.subscriptionForm.controls['DeliveryAddress'].controls['City'],
			this.subscriptionForm.controls['DeliveryAddress'].controls['CountryId'],
			this.subscriptionForm.controls['DeliveryAddress'].controls['StateId'],
			this.subscriptionForm.controls['DeliveryAddress'].controls['Zip'],

			this.subscriptionForm.controls['IsGiftTo'],
			this.subscriptionForm.controls['SubscribeFor'],
			this.subscriptionForm.controls['NoOfCopiesRequired'],
			this.subscriptionForm.controls['GiftTo'].controls['FirstName'],
			this.subscriptionForm.controls['GiftTo'].controls['LastName'],
			this.subscriptionForm.controls['GiftTo'].controls['PrimaryPhone'],
			this.subscriptionForm.controls['GiftTo'].controls['SecondaryPhone'],
			this.subscriptionForm.controls['GiftTo'].controls['Email'],


			this.subscriptionForm.controls['SubscriptionType'],
		]


		const invalid = [];
		const controls = this.step2Fields;
		let i: any;
		for (i in controls) {
			controls[i].markAsDirty();
			if (controls[i].invalid) {
				invalid.push(i);
			}
		}
		if (invalid[0] == undefined) {
			if (this.threeDValue == 'false') {
				if (this.subType == "Renew Subscription") {
					param['url'] = "frontend-site/member-subscription/renew-process";
				} else {
					console.log("hello hi");

					param['url'] = 'frontend-site/member-subscription/process';
				}
			}
			param['params'] = params;
			return this._crud
				.postData(param)
				.pipe(map((res: any) => {
					// console.log("responnse------from credit---",res)
					// if (res.status == "success") {
					//     // this.notifyPayment.emit(res.json());
					// } else {
					//     // this._crud.errorPaymentEvent.emit(res.json());
					//     this.brainTreeReInit = false;
					//     setTimeout(() => {
					//         this.brainTreeReInit = true;
					//     }, 0);
					// }
					this.formResult = res
					if (this.formResult['status'] == "success") {
						this.successPopup(this.formResult);
						// this.subscriptionForm.reset();
						// $('.focus-label.is-focused').removeClass('is-focused');
						// this.selectedPaymentMethod = "";
						// this.totalPrice = 0;
						// this.formInit();
						// this.getCountryList(this.UserLocation.PrimaryCountryId);
					} else {
						this.successPopup(this.formResult);

					}
					return res.data.nonce;
				}));
		}

	}
	successPopup(data) {
		if (data['status'] == 'success' && this.subType == 'Renew Subscription') {
			this.response = { title: 'Thank You', message: 'You have successfully renewed your subscription' }
		} else if (data['status'] == 'success' && (this.subType == 'New Subscription' || this.subType == 'Bulk Subscription')) {
			this.response = { title: 'Thank You', message: 'Your subscription has been added successfully' }
		}
		else {
			// this.response = {title: data['status'],message: data['msg']}
			this.response = { title: 'Error', message: data['msg'] }
		}
		//   this.modalService.show(SuccessPopupComponent, {initialState})
		const modalRef = this.modalService.open(SuccessPopupComponent, { size: "md" });
		modalRef.componentInstance.user = this.response;
		modalRef.result.then(
			result => {
				// console.log("result-----modal",result)
				if (result.result && data['status'] == 'success') {
					// /clearing all forms.
					if (this.flag == false) {
						this.router.navigate([`memberDashboard/home/` + this.memberId])
					}
					this.router.navigateByUrl("/memberDashboard/mysubscription/" + this.routeParam.memberId)
					this.subscriptionForm.reset();
					$('.focus-label.is-focused').removeClass('is-focused');
					this.selectedPaymentMethod = "";
					this.totalPrice = 0;
					this.Currency = null;
					this.selectedGiftMemberId = null;
					this.formInit();
					this.ngOnInit();
				} else {
					// this.selectedPaymentMethod = "";
					// this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentMethodId'].setValue(0);
					this.TabActiveIndex = 2;
				}
			});
	}
	onPaymentStatus(response): void {
		// console.log(response);
	}
	public isSavedCard: boolean = false;
	payButtonStatus(response): void {
		if ($(".braintree-method.braintree-methods-initial").length == 0) {
			this.isSavedCard = response;
		}
	}
	private savedCardLoding: boolean = false;
	submitForm(type = null) {
		if(this.selectedPaymentMethod=="gpay" || this.selectedPaymentMethod=="paypal" ){
			this.subscriptionForm.controls['IsThreeDSecure'].setValue(false);
		}else{
		this.subscriptionForm.controls['IsThreeDSecure'].setValue(this.threeDValue);
		}
		console.log("inside submit form---")
		if (type == 'savedcard') {
			this.savedCardLoding = true;
			console.log("choose card-------")
			this.step2Fields = [
				this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard']
			]
		}
		else if (type == 'savedCreditcard') {
			this.savedCardLoding = true;
			console.log("hhhhh", this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'])
			this.step2Fields = [
				this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard']
			]
		} else {
			this.savedCardLoding = false;
			console.log("new card-------")
			this.step2Fields = [

				// this.subscriptionForm.controls['DeliveryAddress'].controls['Address1'],
				// this.subscriptionForm.controls['DeliveryAddress'].controls['Address2'],
				// this.subscriptionForm.controls['DeliveryAddress'].controls['City'],
				// this.subscriptionForm.controls['DeliveryAddress'].controls['CountryId'],
				// this.subscriptionForm.controls['DeliveryAddress'].controls['StateId'],
				// this.subscriptionForm.controls['DeliveryAddress'].controls['Zip'],

				// this.subscriptionForm.controls['IsGiftTo'],
				// this.subscriptionForm.controls['SubscribeFor'],
				// this.subscriptionForm.controls['NoOfCopiesRequired'],
				// this.subscriptionForm.controls['GiftTo'].controls['FirstName'],
				// this.subscriptionForm.controls['GiftTo'].controls['LastName'],
				// this.subscriptionForm.controls['GiftTo'].controls['PrimaryPhone'],
				// this.subscriptionForm.controls['GiftTo'].controls['SecondaryPhone'],
				// this.subscriptionForm.controls['GiftTo'].controls['Email'],

				this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountHolder'],
				this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['RoutingNumber'],
				this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountNumber'],
				this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountType'],
				// this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'],
				// this.subscriptionForm.controls['SubscriptionType'],
			]
		}


		// else{
		//   this.step2Fields = [

		//     this.subscriptionForm.controls['DeliveryAddress'].controls['Address1'],
		//     this.subscriptionForm.controls['DeliveryAddress'].controls['Address2'],
		//     this.subscriptionForm.controls['DeliveryAddress'].controls['City'],
		//     this.subscriptionForm.controls['DeliveryAddress'].controls['CountryId'],
		//     this.subscriptionForm.controls['DeliveryAddress'].controls['StateId'],
		//     this.subscriptionForm.controls['DeliveryAddress'].controls['Zip'],

		//     this.subscriptionForm.controls['IsGiftTo'],
		//     this.subscriptionForm.controls['SubscribeFor'],
		//     this.subscriptionForm.controls['NoOfCopiesRequired'],
		//     this.subscriptionForm.controls['GiftTo'].controls['FirstName'],
		//     this.subscriptionForm.controls['GiftTo'].controls['LastName'],
		//     this.subscriptionForm.controls['GiftTo'].controls['PrimaryPhone'],
		//     this.subscriptionForm.controls['GiftTo'].controls['SecondaryPhone'],
		//     this.subscriptionForm.controls['GiftTo'].controls['Email'],

		//     this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'],

		//     this.subscriptionForm.controls['SubscriptionType'],
		//   ]
		// }

		const invalid = [];
		const controls = this.step2Fields;
		let i: any;
		for (i in controls) {
			controls[i].markAsDirty();
			if (controls[i].invalid) {
				invalid.push(i);
			}
		}

		console.log("validdtion before,", invalid[0], this.subscriptionForm.controls['GiftTo']);
		if (invalid[0] == undefined) {

this.loadingGpay=true
			this.isLoading = true;
			this.disableMakePayment = true;
			console.log("validddd");
			if (this.subType == "Renew Subscription") {
				let param = {};
				param['params'] = {};
				param['url'] = 'frontend-site/member-subscription/renew-process';
				if (this.threeDValue) {
					param["params"] = this.RequestPostParams(
						{ type: this.paymentName },
						this.braintreeNonce, 'submit'
					);
				}
				else {
					param["params"] = this.RequestPostParams({
						type: this.paymentName,
					}, this.braintreeNonce);
				}
				this._crud.postData(param).subscribe(data => {
					this.isLoading = false;
					this.loadingGpay=false;
					this.paypalLoad = false;
					//  console.log("remew subdata----------",data); 
					this.formResult = data
					if (this.formResult['status'] == "success") {
						this.successPopup(this.formResult);
						//  this.subscriptionForm.reset();
						//  $('.focus-label.is-focused').removeClass('is-focused');
						//  this.selectedPaymentMethod = "";
						// this.getCountryList(this.UserLocation.PrimaryCountryId);
					} else {
						this.successPopup(this.formResult);
						this.disableMakePayment = false;

					}
				});

			} else {
				//all other subscription type
				if (this.subType == "Bulk Subscription" ? this.copiesValidation != true : true) {
					if (this.subscriptionForm.controls['NoOfCopiesRequired'].value && this.subscriptionForm.controls['NoOfCopiesRequired'].value > 1 && this.subscriptionForm.controls['NoOfCopiesRequired'].value < 100001) {
						this.copiesValidation = false;
					} else {
						this.copiesValidation = true;
					}
					let param = {};
					param['params'] = {};
					param['url'] = 'frontend-site/member-subscription/process';
					if (this.threeDValue) {
						param["params"] = this.RequestPostParams(
							{ type: this.paymentName },
							this.braintreeNonce, 'submit'
						);
					}
					else {
						param["params"] = this.RequestPostParams({
							type: this.paymentName,
						}, this.braintreeNonce);
					}
					// param['params'] = this.RequestPostParams({ type: this.paymentName },'submit');
					this._crud.postData(param).subscribe(data => {
						this.isLoading = false;
						this.loadingGpay=false;
						this.paypalLoad = false;
						this.disableMakePayment = true;
						// console.log("other subdata----------",data); 
						this.formResult = data
						if (this.formResult['status'] == "success") {
							this.successPopup(this.formResult);
							// this.subscriptionForm.reset();
							// $('.focus-label.is-focused').removeClass('is-focused');
							// this.selectedPaymentMethod = "";
							// this.getCountryList(this.UserLocation.PrimaryCountryId);
						} else {
							this.successPopup(this.formResult);
							this.disableMakePayment = false;
						}
					});

				}
			}
		} else {
			// this.focusCall();

		}


	}
	RequestPostParams(paymentFields = null, nonce = null, requestType?: any) {
		console.log("is gift to value-------", this.subscriptionForm.controls['IsGiftTo'].value, paymentFields)
		this.postDataJsonPrepare = {};
		this.postDataJsonPrepare['PaymentProcessParams'] = {};
		this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields'] = {};
		this.postDataJsonPrepare['MemberId'] = this.selectedMemberId;
		this.postDataJsonPrepare['IsGiftTo'] = '0';
		this.postDataJsonPrepare['DeliveryAddress'] = {};
		this.postDataJsonPrepare['IsThreeDSecure'] = this.threeDValue;
		this.postDataJsonPrepare['DeliveryAddress']['AddressId'] = this.subscriptionForm.controls['DeliveryAddress'].controls['AddressId'].value;
		this.postDataJsonPrepare['DeliveryAddress']['NewAddress'] = {};
		this.postDataJsonPrepare['DeliveryAddress']['NewAddress']['Address1'] = this.subscriptionForm.controls['DeliveryAddress'].controls['Address1'].value;
		this.postDataJsonPrepare['DeliveryAddress']['NewAddress']['Address2'] = this.subscriptionForm.controls['DeliveryAddress'].controls['Address2'].value;
		this.postDataJsonPrepare['DeliveryAddress']['NewAddress']['City'] = this.subscriptionForm.controls['DeliveryAddress'].controls['City'].value;
		this.postDataJsonPrepare['DeliveryAddress']['NewAddress']['StateId'] = this.subscriptionForm.controls['DeliveryAddress'].controls['StateId'].value;
		this.postDataJsonPrepare['DeliveryAddress']['NewAddress']['Zip'] = this.subscriptionForm.controls['DeliveryAddress'].controls['Zip'].value;
		this.postDataJsonPrepare['IsBillingAddressIsDifferent'] = this.subscriptionForm.controls['IsBillingAddressIsDifferent'].value;
		if (this.subscriptionForm.controls['IsBillingAddressIsDifferent'].value) {
			this.postDataJsonPrepare['BillingAddress'] = {};
			this.postDataJsonPrepare['BillingAddress']['Address1'] = this.subscriptionForm.controls['BillingAddress'].controls['Address1'].value;
			this.postDataJsonPrepare['BillingAddress']['Address2'] = this.subscriptionForm.controls['BillingAddress'].controls['Address2'].value;
			this.postDataJsonPrepare['BillingAddress']['City'] = this.subscriptionForm.controls['BillingAddress'].controls['City'].value;
			this.postDataJsonPrepare['BillingAddress']['StateId'] = this.subscriptionForm.controls['BillingAddress'].controls['StateId'].value;
			this.postDataJsonPrepare['BillingAddress']['Zip'] = this.subscriptionForm.controls['BillingAddress'].controls['Zip'].value;
		}

		// renew subscription
		if (this.subType == 'Renew Subscription') {
			if (this.renewalOptions.Type == 1) {
				this.postDataJsonPrepare['Subscription'] = {
					"RenewalSubscriptionId": this.subscriptionForm.controls['Subscription'].controls['RenewalSubscriptionId'].value,
					"PublicationComboId": this.subscriptionForm.controls['Subscription'].controls['PublicationComboId'].value,
					"PriceOptionId": this.subscriptionForm.controls['Subscription'].controls['PriceOptionId'].value,
					"Type": 1
				}
			} else if (this.renewalOptions.Type == 2) {
				//after testing replace with form values
				this.postDataJsonPrepare['Subscription'] = {
					"RenewalSubscriptionId": this.subscriptionForm.controls['Subscription'].controls['RenewalSubscriptionId'].value,
					"PublicationComboId": this.subscriptionForm.controls['Subscription'].controls['PublicationComboId'].value,
					"ComboPriceId": this.subscriptionForm.controls['Subscription'].controls['ComboPriceId'].value,
					"Format": this.subscriptionForm.controls['Subscription'].controls['Format'].value,
					"Type": 2
				}
			}

		}
		else if (this.subType == 'Bulk Subscription') {
			this.postDataJsonPrepare["NoOfCopiesRequired"] = this.subscriptionForm.controls['NoOfCopiesRequired'].value;
			// this.postDataJsonPrepare["SponsorshipFor"] = (this.subscriptionForm.controls['SponsorshipFor'].value?this.subscriptionForm.controls['SponsorshipFor'].value:this.selectedMemberName);
			this.postDataJsonPrepare["SponsorshipFor"] = this.subscriptionForm.controls['SponsorshipFor'].value;
			this.postDataJsonPrepare['Subscription'] = {
				"PublicationComboId": this.subscriptionForm.controls['Subscription'].controls['PublicationComboId'].value,
				"ComboPriceId": this.subscriptionForm.controls['Subscription'].controls['ComboPriceId'].value,
				"Format": this.subscriptionForm.controls['Subscription'].controls['Format'].value,
				"Duration": this.subscriptionForm.controls['Subscription'].controls['Duration'].value,
				"DurationType": this.subscriptionForm.controls['Subscription'].controls['DurationType'].value
			};

		} else if (this.subType == 'Gift Subscription') {
			this.postDataJsonPrepare['GiftTo'] = {};
			//need discuss for gift memberid
			this.postDataJsonPrepare['GiftTo']['MemberId'] = this.selectedGiftMemberId;
			this.postDataJsonPrepare['GiftTo']['NewMemberDetails'] = {};
			this.postDataJsonPrepare['IsGiftTo'] = '1';
			//need country listing
			this.postDataJsonPrepare['GiftTo']['NewMemberDetails']['CountryId'] = '';
			this.postDataJsonPrepare['GiftTo']['NewMemberDetails']['Email'] = this.subscriptionForm.controls['GiftTo'].controls['Email'].value;
			this.postDataJsonPrepare['GiftTo']['NewMemberDetails']['FirstName'] = this.subscriptionForm.controls['GiftTo'].controls['FirstName'].value;
			this.postDataJsonPrepare['GiftTo']['NewMemberDetails']['Gender'] = '';
			this.postDataJsonPrepare['GiftTo']['NewMemberDetails']['LastName'] = this.subscriptionForm.controls['GiftTo'].controls['LastName'].value;
			this.postDataJsonPrepare['GiftTo']['NewMemberDetails']['MobileNumber'] = this.subscriptionForm.controls['GiftTo'].controls['PrimaryPhone'].value;
			this.postDataJsonPrepare['GiftTo']['NewMemberDetails']['SalutationId'] = this.subscriptionForm.controls['GiftTo'].controls['SalutationId'].value;
			this.postDataJsonPrepare['GiftTo']['NewMemberDetails']['SecondaryNumber'] = this.subscriptionForm.controls['GiftTo'].controls['SecondaryPhone'].value;
			this.postDataJsonPrepare['Subscription'] = {
				"PublicationComboId": this.subscriptionForm.controls['Subscription'].controls['PublicationComboId'].value,
				"PriceOptionId": this.subscriptionForm.controls['Subscription'].controls['PriceOptionId'].value,
				"ComboPriceId": this.subscriptionForm.controls['Subscription'].controls['ComboPriceId'].value,
				"Format": this.subscriptionForm.controls['Subscription'].controls['Format'].value,
			};

		}
		else {
			//other subscriptions

			this.postDataJsonPrepare['Subscription'] = {
				"PublicationComboId": this.subscriptionForm.controls['Subscription'].controls['PublicationComboId'].value,
				"ComboPriceId": this.subscriptionForm.controls['Subscription'].controls['ComboPriceId'].value,
				"Format": this.subscriptionForm.controls['Subscription'].controls['Format'].value,
				"PriceOptionId": this.subscriptionForm.controls['Subscription'].controls['PriceOptionId'].value

			};
		}



		// common subscription param

		this.postDataJsonPrepare['PaymentProcessParams']['SubscriptionAmount'] = this.subscriptionForm.controls['PaymentProcessParams'].controls['SubscriptionAmount'].value;
		this.postDataJsonPrepare['PaymentProcessParams']['InstallmentAmount'] = "";
		this.postDataJsonPrepare['PaymentProcessParams']['CouponCode'] = "";
		this.postDataJsonPrepare['PaymentProcessParams']['PayAmount'] = this.subscriptionForm.controls['PaymentProcessParams'].controls['NetAmountToPay'].value;
		this.postDataJsonPrepare['PaymentProcessParams']['InvoiceId'] = "";
		this.postDataJsonPrepare['PaymentProcessParams']['CurrencyISOCode'] = this.subscriptionForm.controls['PaymentProcessParams'].controls['CurrencyISOCode'].value;
		this.postDataJsonPrepare['PaymentProcessParams']['IsComplementary'] = '0';
		this.postDataJsonPrepare['PaymentProcessParams']['NetAmountToPay'] = this.subscriptionForm.controls['PaymentProcessParams'].controls['NetAmountToPay'].value;
		this.postDataJsonPrepare['PaymentProcessParams']['DiscountAmount'] = "";


		//paymentFields
		if (paymentFields.type != null) {
			console.log("not null payment filelds",paymentFields)
			this.postDataJsonPrepare["PaymentProcessParams"]["SaveCardForFuture"] = "0";
			this.postDataJsonPrepare["PaymentProcessParams"]["ProceedWithoutPaymentProcess"] = "0";
			this.postDataJsonPrepare["PaymentProcessParams"]["PaymentMethodId"] = this.InitialMethodId;
			if (paymentFields.type == "check" || paymentFields.type == "bank_transfer" || paymentFields.type == "cash") {
                this.postDataJsonPrepare["PaymentProcessParams"]["ProceedWithoutPaymentProcess"] = "1";
                // this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["PayNow"] = "0"
            } else {
				console.log("not null payment filelds1",paymentFields)
                this.postDataJsonPrepare["PaymentProcessParams"][
                    "ProceedWithoutPaymentProcess"
                ] = "0";
            }
			if (paymentFields.type == "ach") {
				if (this.savedCardLoding == false) {
					// if (this.selectedSavedCard == false) {
					this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
						"AccountHolder"
					] = this.subscriptionForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountHolder.value;
					this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
						"AccountNumber"
					] = this.subscriptionForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountNumber.value;
					this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
						"RoutingNumber"
					] = this.subscriptionForm.controls.PaymentProcessParams.controls.PaymentFields.controls.RoutingNumber.value;
					this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
						"AccountType"
					] = this.subscriptionForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountType.value;
					// this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
					//     "BankName"
					// ] = this.subscriptionForm.controls.PaymentProcessParams.controls.PaymentFields.controls.BankName.value;
					if (this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['SaveCardForFuture'].value) {
						// console.log("inside save card tick")
						this.postDataJsonPrepare["PaymentProcessParams"][
							"SaveCardForFuture"
						] = "1";
					} else {
						this.postDataJsonPrepare["PaymentProcessParams"][
							"SaveCardForFuture"
						] = "0";
					}
				}
				else {
					this.postDataJsonPrepare["PaymentProcessParams"][
						"PaymentGatewayTokenId"
					] = this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value;
				}
			} else if (paymentFields.type == "credit_or_debt") {
				if (this.savedCardLoding == false) {
					console.log('in if save', nonce)
					this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
						"Nonce"
					] = nonce;
				} else {
					console.log('inside else')
					if (requestType == 'submit') {
						console.log('inifco')
						this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["Nonce"] = nonce;
					}
					else {
						console.log('inifelse')
						this.postDataJsonPrepare["PaymentProcessParams"][
							"PaymentGatewayTokenId"
						] = this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value;
					}


				}
				if (this.subscriptionForm.controls.PaymentProcessParams.controls.PaymentFields.controls.SaveCardForFuture.value) {
					this.postDataJsonPrepare["PaymentProcessParams"][
						"SaveCardForFuture"
					] = "1";
				} else {
					this.postDataJsonPrepare["PaymentProcessParams"][
						"SaveCardForFuture"
					] = "0";
				}
				// if (this.selectedSavedCard == false) {
				// if (this.savedCardLoding == false) {

				// 	this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
				// 		"Nonce"
				// 	] = this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].value

				// 	if (this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['SaveCardForFuture'].value) {
				// 		this.postDataJsonPrepare["PaymentProcessParams"][
				// 			"SaveCardForFuture"
				// 		] = "1";
				// 	} else {
				// 		this.postDataJsonPrepare["PaymentProcessParams"][
				// 			"SaveCardForFuture"
				// 		] = "0";
				// 	}
				// } else {
				// 	//   this.postDataJsonPrepare["PaymentProcessParams"][
				// 	//     "PaymentGatewayTokenId"
				// 	// ] = this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value;
				// 	if (nonce == null) {
				// 		this.postDataJsonPrepare["PaymentProcessParams"][
				// 			"PaymentGatewayTokenId"
				// 		] = this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value;
				// 	} else {
				// 		this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields']['Nonce'] = nonce;
				// 	}
				// }
			} 
			else if (paymentFields.type == "gpay" || paymentFields.type == "paypal") {
				if (this.savedCardLoding == false) {
					console.log('in if save', nonce)
					this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
						"Nonce"
					] = this.nonce;
				} else {
					console.log('inside else')
					if (requestType == 'submit') {
						console.log('inifco')
						this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["Nonce"] = this.nonce;
					}
					else {
						console.log('inifelse')
						this.postDataJsonPrepare["PaymentProcessParams"][
							"PaymentGatewayTokenId"
						] = this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value;
					}


				}
				}
			// else if (paymentFields.type == "check") {
			// 	this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
			// 		"PaymentReceivedDate"
			// 	] = this.subscriptionForm.controls.paymentReceivedDate.value;
			// 	this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
			// 		"CheckDate"
			// 	] = this.subscriptionForm.controls.CheckDate.value;
			// 	this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
			// 		"CheckNumber"
			// 	] = this.subscriptionForm.controls.CheckNumber.value;
			// 	// this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
			// 	//     "CheckImage"
			// 	// ] = this.CheckImage;

			// 	this.postDataJsonPrepare["PaymentProcessParams"][
			// 		"PayNowWithManualPaymentMethod"
			// 	] = this.subscriptionForm.get('manualPaymentMethod').value;

			// } else if (paymentFields.type == "bank_transfer") {
			// 	console.log('this.subscriptionForm.controls',this.subscriptionForm.controls)
			// 	this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
			// 		"ReceivedDate"
			// 	] = this.subscriptionForm.controls.BankReceivedDate.value;
			// 	this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
			// 		"ReferenceNumber"
			// 	] = this.subscriptionForm.controls.ReferenceNumber.value;
			// 	this.postDataJsonPrepare["PaymentProcessParams"][
			// 		"PayNowWithManualPaymentMethod"
			// 	] = this.subscriptionForm.get('manualPaymentMethod').value;
			// } 
			// else if (paymentFields.type == "cash") {
			// 	this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
			// 		"ReceivedDate"
			// 	] = this.subscriptionForm.controls.cashReceivedDate.value;
			// 	this.postDataJsonPrepare["PaymentProcessParams"][
			// 		"PayNowWithManualPaymentMethod"
			// 	] = this.subscriptionForm.get('manualPaymentMethod').value;
			// }
		} else {
			//zro amount case
			console.log("not null payment filelds2",paymentFields)
			this.postDataJsonPrepare["PaymentProcessParams"]["ProceedWithoutPaymentProcess"] = "1";
		}

		return this.postDataJsonPrepare;
	}
	billingZipValidation(value) {
		let countryCode = localStorage.getItem('countryCode')
		if (countryCode == 'CA') {
			var arr = value
			if (arr.length == 6 && !arr.match(" ")) {
				console.log("true 1")
			} else if (arr.length == 7 && arr[3] == " " && arr.split(" ").length == 2) {
				console.log("true 2")
			} else {
				this.subscriptionForm.controls['BillingAddress'].controls['Zip'].setErrors({ 'pattern': true })
				console.log("false ")

			}
		} else if (countryCode == 'GB') {//uk
			var arr = value
			if (arr.length == 5 && !arr.match(" ")) {
				console.log("true 1")
			} else if (arr.length > 5 && arr.length < 9) {
				if (arr.match(" ")) {
					if ((arr[2] == " " || arr[3] == " " || arr[4] == " ") && arr.split(" ").length == 2) {
						console.log("true 2", arr.split(" ").length)

					} else {
						this.subscriptionForm.controls['BillingAddress'].controls['Zip'].setErrors({ 'pattern': true })
						console.log("false ")
					}
				}
			} else {
				this.subscriptionForm.controls['BillingAddress'].controls['Zip'].setErrors({ 'pattern': true })
				console.log("false ")

			}
		} else if (countryCode == 'IE') {//ireland
			var arr = value
			if (arr.length == 7 && !arr.match(" ")) {
				console.log("true 1")
			} else if (arr.length == 8 && arr[3] == " " && arr.split(" ").length == 2) {
				console.log("true 2")
			} else {
				this.subscriptionForm.controls['BillingAddress'].controls['Zip'].setErrors({ 'pattern': true })
				console.log("false ")

			}
		}
	}
	shippingZipValidation(value) {
		let countryCode = localStorage.getItem('countryCode')
		if (countryCode == 'CA') { // canada
			var arr = value
			if (arr.length == 6 && !arr.match(" ")) {
				console.log("true 1")
			} else if (arr.length == 7 && arr[3] == " " && arr.split(" ").length == 2) {
				console.log("true 2")
			} else {
				this.subscriptionForm.controls['DeliveryAddress'].controls['Zip'].setErrors({ 'pattern': true })
				console.log("false ")

			}
		} else if (countryCode == 'GB') {//uk
			var arr = value
			if (arr.length == 5 && !arr.match(" ")) {
				console.log("true 1")
			} else if (arr.length > 5 && arr.length < 9) {
				if (arr.match(" ")) {
					if ((arr[2] == " " || arr[3] == " " || arr[4] == " ") && arr.split(" ").length == 2) {
						console.log("true 2", arr.split(" ").length)

					} else {
						this.subscriptionForm.controls['DeliveryAddress'].controls['Zip'].setErrors({ 'pattern': true })
						console.log("false ")
					}
				}
			} else {
				this.subscriptionForm.controls['DeliveryAddress'].controls['Zip'].setErrors({ 'pattern': true })
				console.log("false ")

			}
		} else if (countryCode == 'IE') {//ireland
			var arr = value
			if (arr.length == 7 && !arr.match(" ")) {
				console.log("true 1")
			} else if (arr.length == 8 && arr[3] == " " && arr.split(" ").length == 2) {
				console.log("true 2")
			} else {
				this.subscriptionForm.controls['DeliveryAddress'].controls['Zip'].setErrors({ 'pattern': true })
				console.log("false ")

			}
		}
	}


	onLoadPaymentData = (
        event: google.payments.api.PaymentData
      ): void => {
      }
      onClickPreventDefault = (event: Event): void => {
		
       
      };
      onPaymentDataAutherized:google.payments.api.PaymentAuthorizedHandler=(PaymentData)=>{
        console.log("payment autherized", PaymentData.paymentMethodData.tokenizationData.token );
        var array = PaymentData.paymentMethodData.tokenizationData.token.split(" ");
        
        var datas =array[0].split('{')
        var arrays=datas[2].split(':')
        var value =arrays[2].split('" "');
      
        var values = value[0].split('","')
        var final= values[0].split('"\"')
        console.log(final[0].split('"'));
        var finalvalue=final[0].split('"')
        this.nonce=finalvalue[1]
        console.log("nonce",this.nonce);
      this.submitForm()
    //     this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(this.nonce);
    //    this.formSumbit()
        // } else {
        //     this.buttonStatus = false;
        //     console.log("enale", this.buttonStatus);
        //     if (this.captcharesponse == "yes") {
        //         this.captcha = false;
        //     } else {
        //         this.captcharesponse = ''
        //         this.captcha = true;
        //     }
        // }
        this.googlePayClick()
        return{
            transactionState:"SUCCESS"
        }
      

      }
	googlePayClick(){
        console.log("mid","rkr",this.merchantId,this.tokenizationkey);
      
        this. paymentRequest ={
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
              {
                type: "CARD",
                parameters: {
                    billingAddressRequired: true,
                    billingAddressParameters: {
                      format: "FULL",
                      phoneNumberRequired: true
                    },
        
                  allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                  allowedCardNetworks: ["AMEX", "VISA", "MASTERCARD"]
                },
                tokenizationSpecification: {
                  type: "PAYMENT_GATEWAY",
                
                  parameters: {
                    "gateway": "braintree",
                    "braintree:apiVersion": "v1",
                    "braintree:sdkVersion": braintree.client.VERSION,
                    "braintree:merchantId": this.merchantId,
                    "braintree:clientKey": this.tokenizationkey
                  }
                }
              }
            ],
            merchantInfo: {
              merchantId:this.merchantId,
              merchantName: "braintree"
            },
            transactionInfo: {
              totalPriceStatus: "FINAL",
              totalPriceLabel: "Total",
              totalPrice:  this.subscriptionForm.controls['PaymentProcessParams'].controls['NetAmountToPay'].value,
              currencyCode:this.Currency,
              countryCode: "US"
            },   
              
            callbackIntents:['PAYMENT_AUTHORIZATION']
          
              
          };
        
    }
	zipValidation() {
		let countryCode = localStorage.getItem('countryCode')

		if (countryCode == 'US') {     // console.log("zip validation--------us")
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{5,5}$/)]));
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').updateValueAndValidity();
			this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code with five digits" }];
		} else if (countryCode == 'CA') {//} else if (this.countryId == 'gQFUOwHXczkw') { canada
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)[0-9A-Za-z\s]{6,7}$/)]));
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').updateValueAndValidity();
			this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code" }];
		} else if (countryCode == 'AU') {//  australia
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{4,4}$/)]));
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').updateValueAndValidity();
			this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code" }];
		} else if (countryCode == 'GB') {//uk
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)[0-9A-Za-z\s]{5,8}$/)]));
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').updateValueAndValidity();
			this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code" }];
		} else if (countryCode == 'IE') {//ireland
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)[0-9A-Za-z\s]{7,8}$/)]));
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').updateValueAndValidity();
			this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code" }];
		} else {
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').setValidators(Validators.pattern(/^(?!0+$)[0-9A-Za-z\s\-]{3,10}$/));
			this.subscriptionForm.controls['DeliveryAddress'].get('Zip').updateValueAndValidity();
			this.ValidationMessage.Zip = [{ type: "pattern", message: "Please enter a valid postal code" }];
		}
		if (this.subscriptionForm.controls['IsBillingAddressIsDifferent'].value) {
			if (countryCode == 'US') {
				// console.log("zip validation--------us")
				this.subscriptionForm.controls['BillingAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{5,5}$/)]));
				this.subscriptionForm.controls['BillingAddress'].get('Zip').updateValueAndValidity();
				this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code with five digits" }];
			} else if (countryCode == 'CA') {//} else if (this.countryId == 'gQFUOwHXczkw') { canada
				this.subscriptionForm.controls['BillingAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)[0-9A-Za-z\s]{6,7}$/)]));
				this.subscriptionForm.controls['BillingAddress'].get('Zip').updateValueAndValidity();
				this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code" }];
			} else if (countryCode == 'AU') {//  australia
				this.subscriptionForm.controls['BillingAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{4,4}$/)]));
				this.subscriptionForm.controls['BillingAddress'].get('Zip').updateValueAndValidity();
				this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code" }];
			} else if (countryCode == 'GB') {//uk
				this.subscriptionForm.controls['BillingAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)[0-9A-Za-z\s]{5,8}$/)]));
				this.subscriptionForm.controls['BillingAddress'].get('Zip').updateValueAndValidity();
				this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code" }];
			} else if (countryCode == 'IE') {//ireland
				this.subscriptionForm.controls['BillingAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)[0-9A-Za-z\s]{7,8}$/)]));
				this.subscriptionForm.controls['BillingAddress'].get('Zip').updateValueAndValidity();
				this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code" }];
			} else {
				this.subscriptionForm.controls['BillingAddress'].get('Zip').setValidators(Validators.pattern(/^(?!0+$)[0-9A-Za-z\s\-]{3,10}$/));
				this.subscriptionForm.controls['BillingAddress'].get('Zip').updateValueAndValidity();
				this.ValidationMessage.Zip = [{ type: "pattern", message: "Please enter a valid postal code" }];
			}
		}
	}
	focusCall() {
		console.log(this.subscriptionForm.controls['PaymentProcessParams'], "####4444444");
		// console.log(this.subscriptionForm.value)
		if (this.subscriptionForm.controls['Publication'].value == null || (this.subscriptionForm.controls['Publication'].value).length == 0) {
			document.getElementById("Publications_enabled").classList.add("ng-select-focused");
			this.validPub = true;
		}
		else if (this.subscriptionForm.controls['SubscriptionType'].invalid) {
			// console.log("SubscriptionType");
			document.getElementById("Subscriptiontype").classList.add("ng-select-focused");
		} else if (this.subscriptionForm.controls['SubscribeFor'].invalid) {
			console.log("SubscribeFo");
			document.getElementById("Subscribefor").classList.add("ng-select-focused");
		} else if (this.subscriptionForm.controls['NoOfCopiesRequired'].invalid) {
			let invalidControl = this.el.nativeElement.querySelector('#noOfCopiesRequired');
			invalidControl.focus();
		} else if (this.subscriptionForm.controls['GiftTo'].invalid) {
			let invalidControl = null;
			for (const j of Object.keys(this.subscriptionForm.controls['GiftTo'].controls)) {
				if (this.subscriptionForm.controls['GiftTo'].controls[j].invalid) {
					invalidControl = this.el.nativeElement.querySelector('#GiftTo-' + j);
					invalidControl.focus();
					break;
				}
			}
		} else if (this.subscriptionForm.controls['DeliveryAddress'].invalid) {
			let invalidControl = null;
			// if (this.DonationForm.controls['SubscriberInfo'].controls) {
			for (const i of Object.keys(this.subscriptionForm.controls['DeliveryAddress'].controls)) {
				if (this.subscriptionForm.controls['DeliveryAddress'].controls[i].invalid) {
					invalidControl = this.el.nativeElement.querySelector('#BillingAddress-' + i);
					// if (this.DonationForm.controls['DeliveryAddress'].controls[i].controls) {
					//     for (const j of Object.keys(this.DonationForm.controls['DeliveryAddress'].controls[i].controls)) {
					//         if (this.DonationForm.controls['SubscriberInfo'].controls['BillingAddress'].controls[j] != undefined && this.DonationForm.controls['SubscriberInfo'].controls['BillingAddress'].controls[j].invalid) {
					//             invalidControl = this.el.nativeElement.querySelector('#BillingAddress-' + j);
					//             console.log("dfdfdsf", invalidControl, j);
					//             if (j == 'StateId') {
					//                 document.getElementById("BillingAddress-StateId").classList.add("ng-select-focused");
					//                 break;
					//             } else {
					//                 invalidControl.focus();
					//                 break;
					//             }

					//         } else if (this.DonationForm.controls['SubscriberInfo'].controls['BillingAddress'].valid && this.DonationForm.controls['SubscriberInfo'].controls['ShippingAddress'].controls[j] != undefined && this.DonationForm.controls['SubscriberInfo'].controls['ShippingAddress'].controls[j].invalid) {
					//             invalidControl = this.el.nativeElement.querySelector('#ShippingAddress-' + j);
					//             if (j == 'StateId') {
					//                 document.getElementById("ShippingAddress-StateId").classList.add("ng-select-focused");
					//                 break;
					//             } else {
					//                 invalidControl.focus();
					//                 break;
					//             }

					//         }
					//         else if (this.DonationForm.controls['SubscriberInfo'].controls['GiftTo'].controls) {
					//             for (const k of Object.keys(this.DonationForm.controls['SubscriberInfo'].controls['GiftTo'].controls)) {
					//                 if (this.DonationForm.controls['SubscriberInfo'].controls['GiftTo'].controls[k].invalid) {

					//                     if (this.DonationForm.controls['SubscriberInfo'].controls['GiftTo'].controls['ShippingAddress'].controls) {
					//                         for (const l of Object.keys(this.DonationForm.controls['SubscriberInfo'].controls['GiftTo'].controls['ShippingAddress'].controls)) {
					//                             if (this.DonationForm.controls['SubscriberInfo'].controls['GiftTo'].controls['ShippingAddress'].controls[l].invalid) {
					//                                 invalidControl = this.el.nativeElement.querySelector('#GiftTo-' + l);
					//                                 if (l == 'StateId') {
					//                                     document.getElementById("GiftTo-StateId").classList.add("ng-select-focused");
					//                                     break;
					//                                 } else {
					//                                     invalidControl.focus();
					//                                     break;
					//                                 }

					//                             }
					//                         }
					//                     }
					//                     invalidControl = this.el.nativeElement.querySelector('#GiftTo-' + k);
					//                     invalidControl.focus();
					//                     break;
					//                 }
					//             }
					//         }

					//     }
					// }

					if (i == 'StateId') {
						document.getElementById("BillingAddress-StateId").classList.add("ng-select-focused");
						break;
					} else {
						invalidControl.focus();
						break;
					}
				}
			}
			// }
		} else if (this.subscriptionForm.controls['PaymentProcessParams'].invalid && this.showNewCardOption) {
			// if(this.zeroAmount == 0){
			for (const i of Object.keys(this.subscriptionForm.controls['PaymentProcessParams'].controls)) {
				if (this.subscriptionForm.controls['PaymentProcessParams'].controls[i].invalid) {
					for (const k of Object.keys(this.subscriptionForm.controls['PaymentProcessParams'].controls[i].controls)) {
						if (this.subscriptionForm.controls['PaymentProcessParams'].controls[i].controls[k].invalid) {
							const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + k + '"]');


							invalidControl.focus();
							break;
						}
					}
				}
			}
		}
		// }
	}
	firstNameFetch(fname) {
		var regex1 = /^[a-zA-Z-.,' ]{1,100}$/
		var isValid1 = regex1.test(fname);
		if (!isValid1) {
			console.log("not valid fnmae---");
			var regex2 = /^(?=.*[0-9]).*$/
			var isValid2 = regex2.test(fname);
			//   var regex3 = /^(?=.*[!@#%()<>{}]).*$/
			// var regex3 = /^(?=.*[!@#%()<>\[\]:;"?\/\|{}]).*$/
			var regex3 = /^(?=.*[!~^&*_=+@#%()<>\[\]:;"?\/\|{}]).*$/
			var isValid3 = regex3.test(fname);

			if (isValid2 && isValid3) {
				console.log("contain nubers and spcl");
				this.subscriptionForm.controls['GiftTo'].controls['FirstName'].setErrors({ 'hasnumSpcl': true });
			} else if (isValid2) {
				console.log("contain nubers");
				this.subscriptionForm.controls['GiftTo'].controls['FirstName'].setErrors({ 'hasnuber': true });
			}
			else if (isValid3) {
				console.log("contain spcl");
				this.subscriptionForm.controls['GiftTo'].controls['FirstName'].setErrors({ 'hasspecl': true })
			} else if (fname.length > 100) {
				console.log("limit reached");
				this.subscriptionForm.controls['GiftTo'].controls['FirstName'].setErrors({ 'maxlength': true })
			}
		} else {
			console.log("valid name");
		}

	}
	lastNameFetch(lname) {
		var regex1 = /^[a-zA-Z-.,' ]{1,100}$/
		var isValid1 = regex1.test(lname);
		if (!isValid1) {
			console.log("not valid fnmae---");
			var regex2 = /^(?=.*[0-9]).*$/
			var isValid2 = regex2.test(lname);
			//   var regex3 = /^(?=.*[!@#%()<>\[\]:;"?\/\|{}]).*$/
			var regex3 = /^(?=.*[!~^&*_=+@#%()<>\[\]:;"?\/\|{}]).*$/
			var isValid3 = regex3.test(lname);
			if (isValid2 && isValid3) {
				console.log("contain nubers and spcl");
				this.subscriptionForm.controls['GiftTo'].controls['LastName'].setErrors({ 'hasnumSpcl': true });
			} else if (isValid2) {
				console.log("contain nubers");
				this.subscriptionForm.controls['GiftTo'].controls['LastName'].setErrors({ 'hasnuber': true });
			}
			else if (isValid3) {
				console.log("contain spcl");
				this.subscriptionForm.controls['GiftTo'].controls['LastName'].setErrors({ 'hasspecl': true })
			} else if (lname.length > 100) {
				console.log("limit reached");
				this.subscriptionForm.controls['GiftTo'].controls['LastName'].setErrors({ 'maxlength': true })
			}
		} else {
			console.log("valid name");
		}
	}
	sponcerNameFetch(lname) {
		var regex1 = /^[a-zA-Z-.,' ]{1,100}$/
		var isValid1 = regex1.test(lname);
		if (!isValid1) {
			console.log("not valid fnmae---");
			var regex2 = /^(?=.*[0-9]).*$/
			var isValid2 = regex2.test(lname);
			//   var regex3 = /^(?=.*[!@#%()<>\[\]:;"?\/\|{}]).*$/
			var regex3 = /^(?=.*[!~^&*_=+@#%()<>\[\]:;"?\/\|{}]).*$/
			var isValid3 = regex3.test(lname);
			if (isValid2 && isValid3) {
				console.log("contain nubers and spcl");
				this.subscriptionForm.controls['SponsorshipFor'].setErrors({ 'hasnumSpcl': true });
			} else if (isValid2) {
				console.log("contain nubers");
				this.subscriptionForm.controls['SponsorshipFor'].setErrors({ 'hasnuber': true });
			}
			else if (isValid3) {
				console.log("contain spcl");
				this.subscriptionForm.controls['SponsorshipFor'].setErrors({ 'hasspecl': true })
			} else if (lname.length > 100) {
				console.log("limit reached");
				this.subscriptionForm.controls['SponsorshipFor'].setErrors({ 'maxlength': true })
			}
		} else {
			console.log("valid name");
		}
	}
	validateFileds() {
		console.log("hello", this.TabActiveIndex);

		if (this.TabActiveIndex == 0) {
			console.log("hello11", this.TabActiveIndex);
			//first page
			this.step2Fields = [

				this.subscriptionForm.controls['DeliveryAddress'].controls['Address1'],
				this.subscriptionForm.controls['DeliveryAddress'].controls['Address2'],
				this.subscriptionForm.controls['DeliveryAddress'].controls['City'],
				// this.subscriptionForm.controls['DeliveryAddress'].controls['CountryId'],
				this.subscriptionForm.controls['DeliveryAddress'].controls['StateId'],
				this.subscriptionForm.controls['DeliveryAddress'].controls['Zip'],

				this.subscriptionForm.controls['IsGiftTo'],
				this.subscriptionForm.controls['SubscribeFor'],
				this.subscriptionForm.controls['NoOfCopiesRequired'],
				this.subscriptionForm.controls['GiftTo'].controls['FirstName'],
				this.subscriptionForm.controls['GiftTo'].controls['LastName'],
				this.subscriptionForm.controls['GiftTo'].controls['PrimaryPhone'],
				this.subscriptionForm.controls['GiftTo'].controls['SecondaryPhone'],
				this.subscriptionForm.controls['GiftTo'].controls['Email'],
				// this.subscriptionForm.controls['SubscriptionType'],
				this.subscriptionForm.controls['Publication'],
			]
		}
		if (this.TabActiveIndex == 1) {
			console.log("total price------", this.totalPrice)
			if (this.totalPrice !== '0.00' && this.subscriptionForm.controls['IsBillingAddressIsDifferent'].value == true) {
				this.step2Fields = [
					this.subscriptionForm.controls['BillingAddress'].controls['Address1'],
					this.subscriptionForm.controls['BillingAddress'].controls['Address2'],
					this.subscriptionForm.controls['BillingAddress'].controls['City'],
					this.subscriptionForm.controls['BillingAddress'].controls['CountryId'],
					this.subscriptionForm.controls['BillingAddress'].controls['StateId'],
					this.subscriptionForm.controls['BillingAddress'].controls['Zip'],
					this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentMethodId']
				]
			} else if (this.totalPrice !== '0.00' && this.subscriptionForm.controls['IsBillingAddressIsDifferent'].value == false) {
				this.step2Fields = [
					this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentMethodId']
				]
			}
			else {
				this.step2Fields = [
					this.subscriptionForm.controls['BillingAddress'].controls['Address1'],
					this.subscriptionForm.controls['BillingAddress'].controls['Address2'],
					this.subscriptionForm.controls['BillingAddress'].controls['City'],
					this.subscriptionForm.controls['BillingAddress'].controls['CountryId'],
					this.subscriptionForm.controls['BillingAddress'].controls['StateId'],
					this.subscriptionForm.controls['BillingAddress'].controls['Zip'],
					this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentMethodId']
				]
			}

		}
		const invalid = [];
		const controls = this.step2Fields;
		let i: any;
		console.log("controljjj", controls);

		for (i in controls) {
			controls[i].markAsDirty();
			if (controls[i].invalid) {
				// if(this.TabActiveIndex == 1){
				//   invalid.push("paymentMethod");
				// }else{
				invalid.push(i);
				// }

			}
		}
		return invalid;



	}

	clearCardDetails() {
		if (this.hostedFieldsInstance) {
			this.hostedFieldsInstance.clear('number');
			this.hostedFieldsInstance.clear('cvv');
			this.hostedFieldsInstance.clear('expirationDate');
		}

	}
	sponsorNameFetch(lname) {
		var regex1 = /^[a-zA-Z-.,'& ]{1,100}$/
		var isValid1 = regex1.test(lname);
		if (!isValid1) {
			console.log("not valid fnmae---");
			var regex2 = /^(?=.*[0-9]).*$/
			var isValid2 = regex2.test(lname);
			var regex3 = /^(?=.*[!~^&`$\\*_=+@#%()<>\[\]:;"?\/\|{}]).*$/
			var isValid3 = regex3.test(lname);
			if (isValid2 && isValid3) {
				console.log("contain nubers and spcl");
				this.subscriptionForm.controls["SponsorshipFor"].setErrors({ 'hasnumSpcl': true });
			} else if (isValid2) {
				console.log("contain nubers");
				this.subscriptionForm.controls["SponsorshipFor"].setErrors({ 'hasnuber': true });
			}
			else if (isValid3) {
				console.log("contain spcl");
				this.subscriptionForm.controls["SponsorshipFor"].setErrors({ 'hasspecl': true })
			} else if (lname.length > 100) {
				console.log("limit reached");
				this.subscriptionForm.controls["SponsorshipFor"].setErrors({ 'maxlength': true })
			}
		} else {
			console.log("valid name");
		}
	}
	isAddNewCard(value) {
		console.log(value);
		this.loaderCreditNew = true
		this.subscriptionForm.controls.PaymentProcessParams.controls.PaymentFields.controls['ChooseCard'].reset(); //setValue('')
		value == true ? this.addNewCard = true : this.addNewCard = false;
		if (this.selectedPaymentMethod == 'credit_or_debt' && value == true) {
			this.getClientToken()
		}

	}
	keyPressNumbers(event) {
		var charCode = (event.which) ? event.which : event.keyCode;
		// Only Numbers 0-9
		if ((charCode < 48 || charCode > 57)) {
			event.preventDefault();
			return false;
		} else {
			return true;
		}
	}
	changeBilling(value) {
		console.log("value-----", this.subscriptionForm.controls['IsBillingAddressIsDifferent'].value, value)
		if (this.subscriptionForm.controls['IsBillingAddressIsDifferent'].value) {
			// this.subscriptionForm.controls['IsBillingAddressIsDifferent'].setValue(true);
			this.hideBillingAddress = false;
			this.zipValidation();
		} else {
			// this.subscriptionForm.controls['IsBillingAddressIsDifferent'].setValue(false);
			this.hideBillingAddress = true;

		}
	}
	getStartIssue(publicationId) {
		let params = {};
		params['url'] = "frontend-site/publication/subscription/get-issue-list";
		params['params'] = {
			PublicationId: publicationId
		}
		this._crud.getData(params).subscribe(data => {

			if (data['data'].length > 0) {
				this.issueList = data['data']
				this.selectedIssueId = this.issueList[0]['PublicationIssueId']
				this.subscriptionForm.controls['StartIssueId'].setValue(this.selectedIssueId)
			}


		})
	}
	getThreedsecureCountries() {
		let params = {}
		params["url"] = "frontend-site/3d-secure-countries";
		this._crud.getData(params).subscribe(data => {
			this.threedCountryList = data['data'];
			console.log('list', this.threedCountryList);

		})
	}
	getThreedsecure() {
		let params = {}
		// let selectedId = this.PaymentMethods.filter(x => { return x.UniqueName == this.selectedPaymentMethod });
		// console.log('iiid',selectedId);
		console.log('paymentid', this.countryPaymentId);

		this.memberId = localStorage.getItem('memberId');
		console.log('memberid', this.memberId);
		params["url"] = "frontend-site/check-3d-secure/" + this.countryPaymentId + '/' + this.memberId;
		this._crud.getData(params).subscribe(data => {
			this.threeDValue = data['data'];
			console.log('list', this.threeDValue);

		})
	}

}
