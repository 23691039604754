import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonCrudService } from 'src/app/_services/common-crud.service';
import { FormBuilder, Validators, FormArray, FormGroup, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import * as braintree from 'braintree-web';
import { Helpers } from 'src/app/helpers';
declare let $: any;
declare let paypal: any;

@Component({
	selector: 'app-makesubpayment',
	templateUrl: './makesubpayment.component.html',
	styleUrls: ['./makesubpayment.component.scss']
})
export class MakesubpaymentComponent implements OnInit {
	eventDetails: any = [];
	routeParam: any;
	subId;
	isLoading: boolean = false;
	TabActiveIndex: number = 0;
	makePaymentForm;
	Currency;
	PaymentMethods;
	countryId;
	selectedPaymentMethod;
	text;
	brainTreeToken;
	paymentMethodError: boolean = false;
	eventId;
	PendingAmount = 0;
	Step2Fields = [];
	paymentSettingId;
	InitialMethodId;
	selectedPaymentname;
	primaryCountryId;
	hostedClientToken;
	selectedMemberId;
	IsSavedAchPresent: boolean = false;
	savedCards = [];
	brainTreeError: string;
	cardholdersName: string;
	noFieldsCardNumber: number;
	noFieldsDate: number;
	noFieldsCvv: number;
	hostedFieldsInstance: braintree.HostedFields;
	selectedSavedCard: boolean = false;
	response;
	listSavedCreditCard: boolean = false;
	isSpfMember: boolean;
	singleDonationExist: boolean = false;
	buttonColor = "black";
    buttonType = "donate";
    isCustomSize = false;
    buttonWidth = 90;
    buttonHeight = 40;
    tokenizationkey:any;
    merchantId:any;
    nonce:any
    paymentRequest:google.payments.api.PaymentDataRequest
    loadingGpay: boolean = false;
	ValidationMessage: any = {
		AccountNumber: [
			{ type: 'required', message: 'Please enter your bank account number' },
			{ type: 'pattern', message: 'Please enter a valid account number' },
		],
		RoutingNumber: [],
		AccountHolder: [
			{
				type: 'required',
				message: 'Please enter the name on your bank account',
			},
			{ type: 'pattern', message: 'Please enter a valid name on account' },
		],
		BankName: [
			{ type: 'required', message: 'Please enter the name of your bank' },
			{
				type: 'maxlength',
				message: 'Bank name can only have a maximum of 100 characters',
			},
		],
		Fname: [
			{ type: 'required', message: 'Please enter your first name' },
			{ type: 'hasnuber', message: 'Numbers not allowed' },
			{ type: 'hasspecl', message: 'Special charecters not allowed' },
			{
				type: 'hasnumSpcl',
				message: 'Numbers & special charecters not alowed',
			},
			{
				type: 'maxlength',
				message: 'First name can only have a maximum of 100 characters',
			},
		],
		Lname: [
			{ type: 'required', message: 'Please enter your last name' },
			{ type: 'hasnuber', message: 'Numbers not allowed' },
			{ type: 'hasspecl', message: 'Special charecters not allowed' },
			{
				type: 'hasnumSpcl',
				message: 'Numbers & special charecters not alowed',
			},
			{
				type: 'maxlength',
				message: 'Last name can only have a maximum of 100 characters',
			},
		],
		Amount: [
			{ type: 'required', message: 'Please enter the amount' },
			{
				type: 'max',
				message: 'Amount to Pay cannot be greater than Balance Amount',
			},
			{
				type: 'min',
				message: 'Amount to Pay ',
			},
		],
	};
	Step1Fields;
	formResult;
	postDataJsonPrepare;
	accounttypeList;
	savedCreditCards;
	addNewCard: boolean = false;
	loaderAchSaved: boolean;
	loaderCreditsaved: boolean;
	loaderCreditNew: boolean;
	threeDSecure: braintree.threeDSecure;
	cancelStatus: boolean = false;
	threedCountryList: any;
	clientToken: any;
	countryPaymentId: any;
	threeDValue: any;
	braintreeNonce: any;
	isCvvhide: any;
	clientTokenPaypal: any;
	paypalbutton: any;
	paypalLoad: boolean;
	brainTreepaypalToken: any;
	constructor(
		public _crud: CommonCrudService,
		public activeRoute: ActivatedRoute,
		public router: Router,
		public modalService: NgbModal,
		public formBuilder: FormBuilder
	) {
	}

	ngOnInit(): void {

		this.getThreedsecureCountries();
		this.routeParam = this.activeRoute.snapshot.params;
		this.selectedMemberId = this.routeParam.memberId;
		this.subId = this.routeParam.subId;
		this.countryId = localStorage.getItem('countryId');
		this.getCvvhide(this.countryId);
		this.primaryCountryId = localStorage.getItem('primaryCountryId');


		this.accounttypeList = [
			{
				value: 'checking',
				name: 'Checking',
			},
			{
				value: 'savings',
				name: 'Savings',
			},
		];
		this.isSpfMember = (localStorage.getItem('IsSpfMember') == 'true' ? true : false);
		let currentUser = JSON.parse(localStorage.getItem("currentUser"));
		this.singleDonationExist = currentUser.singleDonationExist;
		this.getEventDetails();

	}

	getCvvhide(countryId) {
        let params = {};
        params["url"] = `frontend-site/member/check-cvv-required/${countryId}`;
        this._crud.getData(params).subscribe(data => {
            this.isCvvhide = data['data'];
        });
    }
	
	getEventDetails() {
		let params = {
			url:
				'frontend-site/member-subscription/get-subscription-data/' + this.subId,

		};
		this._crud.getData(params).subscribe((res) => {
			this.eventDetails = res['data'];
			this.formInit();
			this.Currency = this.eventDetails.CurrencyISOCode;
			
			this.eventId = this.eventDetails.SubscriptionId;
			this.PendingAmount = this.eventDetails.BalanceAmount;
			this.googlePayClick()
			document.getElementById('balAmount').classList.add('is-focused');
			this.getAllPaymentMethods();
		});
	}
	formInit() {

		this.makePaymentForm = this.formBuilder.group({
			Amount: ['', Validators.compose([Validators.required, Validators.max(this.eventDetails.BalanceAmount),])],
			PaymentProcessParams: this.getPaymentParams(),
			AddNewCard: [false],
			IsThreeDSecure: [null]

		});
	}

	getPaymentParams() {
		return this.formBuilder.group({
			RegistrationAmount: [''],
			InvoiceId: [''],
			DiscountAmount: [''],
			CurrencyISOCode: this.Currency,
			NetAmountToPay: [''],
			// IsAccomadationRequired: [''],
			PaymentMethodId: ['', Validators.required],
			ProceedWithoutPaymentProcess: [null],
			PaymentFields: this.getPaymentFields(),
		});
	}
	getPaymentFields(): FormGroup {
		let fbGroup = this.formBuilder.group({
			AccountNumber: [null],
			RoutingNumber: [null],
			AccountHolder: [null],
			BankName: [null],
			AccountType: [null],
			Nonce: [null],
			ChooseCard: [''],
			SaveCardForFuture: ["1"],
		});

		return fbGroup;
	}
	animateLabel(event) {
		event.parentNode.classList.add('is-focused');
	}

	animateLabelRemove(e) {
		// console.log(e.value, "AHGKND");
		if (e.value == '') {
			e.parentNode.classList.remove('is-focused');
		}
	}
	getAllPaymentMethods() {
		let params = {};
		params['params'] = {
			sourceType: 2,
			countryId: this.countryId,
			sourceId: null,
			type: 'make_payment'
		};
		params['url'] = 'frontend-site/fetch-available-payment-methods';
		this._crud.getData(params).subscribe((data) => {
			this.selectedPaymentMethod = '';
			this.PaymentMethods = data['data'];
			console.log(this.PaymentMethods['0'].CountryPaymentSettingId,'PaymentMethods')
			this.countryPaymentId = this.PaymentMethods['0'].CountryPaymentSettingId;
			this.getThreedsecure()
			if(this.Currency=='INR'){
                this.PaymentMethods.forEach((element, i) => {
                    if (element.PaymentMethod == 'Paypal') {
                this.PaymentMethods.splice(i, 1);
                    }               
                })  
            }
			this.PaymentMethods.forEach((element, i) => {
				if (element.PaymentMethod == 'ACH') {
					if (
						(this.countryId == 'BprLdASASGl0' && this.Currency == 'USD') ||
						(this.countryId == 'gQFUOwHXczkw' && this.Currency == 'CAD')
					) {
						// element.text = "Bank Transfer (ACH)"
					} else {
						console.log('ach hide------', this.countryId, this.Currency);
						this.PaymentMethods.splice(i, 1);
					}
				}
				// if (element.electronicPayment == 0) {
				// 	// make payment section only need electronic payments
				// 	this.PaymentMethods.splice(i, 1);
				// }
			});

			for( let i=0; i < this.PaymentMethods.length; i++) {
				console.log(this.PaymentMethods[i].UniqueName,'value')
				if(this.PaymentMethods[i].UniqueName == 'check' || this.PaymentMethods[i].UniqueName == 'cash' || this.PaymentMethods[i].UniqueName == 'bank_transfer') {
					console.log('inside',i)
					this.PaymentMethods.splice(i--, 1);
				}
			}
			this.PaymentMethods.forEach((element) => {
				if (element.PaymentMethod == 'ACH') {
					element.text = 'Bank Transfer (ACH)';
				} else if (element.PaymentMethod == 'Credit/Debit') {
					element.text = 'Credit / Debit Card';
				} else {
					element.text = element.PaymentMethod;
					if(element.BankAccountDetails!=null){
						this.text = (element.BankAccountDetails).replaceAll("|", "");
					}
				}
			});
			let braintree = this.PaymentMethods.filter((x) => {
				return x.UniqueName == 'credit_or_debt';
			});
			if(braintree.length != 0) {
				console.log('iside creditdeb')
				this.brainTreeToken = braintree[0].TokenizationKey;
				this.clientToken = braintree[0].CountryPaymentSettingId;
			}
			
			let braintreePaypal = this.PaymentMethods.filter((x) => {
				return x.UniqueName == 'paypal';
			});
			if(braintreePaypal.length != 0) {
				this.brainTreepaypalToken = braintreePaypal[0].TokenizationKey;
				this.clientTokenPaypal = braintreePaypal[0].CountryPaymentSettingId;
	
			}
		});
		// let braintree = this.PaymentMethods.filter((x) => {
		// 	return x.UniqueName == 'credit_or_debt';
		// });
		// this.brainTreeToken = braintree[0].TokenizationKey;
		// // this.createBraintreeUI();
		// this.clientToken = braintree[0].CountryPaymentSettingId;
		// console.log(' braintree[0].CountryPaymentSettingId', braintree[0].CountryPaymentSettingId);
		
	}
	onLoadPaymentData = (
        event: google.payments.api.PaymentData
      ): void => {
      }
      onClickPreventDefault = (event: Event): void => {
		
       
      };
      onPaymentDataAutherized:google.payments.api.PaymentAuthorizedHandler=(PaymentData)=>{
        console.log("payment autherized", PaymentData.paymentMethodData.tokenizationData.token );
        var array = PaymentData.paymentMethodData.tokenizationData.token.split(" ");
        
        var datas =array[0].split('{')
        var arrays=datas[2].split(':')
        var value =arrays[2].split('" "');
      
        var values = value[0].split('","')
        var final= values[0].split('"\"')
        console.log(final[0].split('"'));
        var finalvalue=final[0].split('"')
        this.nonce=finalvalue[1]
        console.log("nonce",this.nonce);
      this.submitForm()
    //     this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(this.nonce);
    //    this.formSumbit()
        // } else {
        //     this.buttonStatus = false;
        //     console.log("enale", this.buttonStatus);
        //     if (this.captcharesponse == "yes") {
        //         this.captcha = false;
        //     } else {
        //         this.captcharesponse = ''
        //         this.captcha = true;
        //     }
        // }
        this.googlePayClick()
        return{
            transactionState:"SUCCESS"
        }
      

      }
	googlePayClick(){
        console.log("mid","rkr",this.merchantId,this.tokenizationkey);
 
        this. paymentRequest ={
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
              {
                type: "CARD",
                parameters: {
                    billingAddressRequired: true,
                    billingAddressParameters: {
                      format: "FULL",
                      phoneNumberRequired: true
                    },
        
                  allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                  allowedCardNetworks: ["AMEX", "VISA", "MASTERCARD"]
                },
                tokenizationSpecification: {
                  type: "PAYMENT_GATEWAY",
                
                  parameters: {
                    "gateway": "braintree",
                    "braintree:apiVersion": "v1",
                    "braintree:sdkVersion": braintree.client.VERSION,
                    "braintree:merchantId": this.merchantId,
                    "braintree:clientKey": this.tokenizationkey
                  }
                }
              }
            ],
            merchantInfo: {
              merchantId:this.merchantId,
              merchantName: "braintree"
            },
            transactionInfo: {
              totalPriceStatus: "FINAL",
              totalPriceLabel: "Total",
              totalPrice: this.PendingAmount.toString(),
              currencyCode:this.Currency,
              countryCode: "US"
            },   
              
            callbackIntents:['PAYMENT_AUTHORIZATION']
          
              
          };
        
    }
	nextStep() {
		let validity = this.validateFileds();
		console.log(validity[0], this.TabActiveIndex);
		if (this.TabActiveIndex == 0) {
			this.googlePayClick()
			if (validity[0] == 'paymentMethod') {
				this.paymentMethodError = true;
				
			} else {
				this.paymentMethodError = false;
			}
			if (validity[0] == undefined) {
				this.TabActiveIndex++;
			}
		}
	}
	validateFileds() {
		if (this.TabActiveIndex == 0) {
			this.Step2Fields = [
				this.makePaymentForm.controls['PaymentProcessParams'].controls[
				'PaymentMethodId'
				],
				this.makePaymentForm.controls['Amount'],
			];
		}
		const invalid = [];
		const controls = this.Step2Fields;
		let i: any;
		for (i in controls) {
			controls[i].markAsDirty();
			if (controls[i].invalid) {
				if (this.TabActiveIndex == 0) {
					invalid.push('paymentMethod');
				} else {
					invalid.push(i);
				}
			}
		}
		console.log('invalid array-----', invalid);
		return invalid;
	}
	onPaymentMethodChange(paymentMethod) {
		this.isLoading = false;
		this.paymentMethodError = false;
		this.addNewCard = false;
		this.makePaymentForm.controls.AddNewCard.setValue(false);
		let PaymentFields = this.makePaymentForm.controls['PaymentProcessParams']
			.controls['PaymentFields'];
		let selectedId = this.PaymentMethods.filter((x) => {
			return x.PaymentMethod == paymentMethod;
		});
		this.paymentSettingId = selectedId[0].CountryPaymentSettingId;
		this.InitialMethodId = selectedId[0].PaymentMethodId;
		this.selectedPaymentMethod = selectedId[0].UniqueName;
		this.merchantId=   selectedId[0].UniqueId
        this.tokenizationkey=selectedId[0].TokenizationKey
		this.selectedPaymentname = selectedId[0].PaymentMethod;
		if (
			this.selectedPaymentMethod == 'ach' ||
			this.selectedPaymentMethod == 'credit_or_debt'||
			this.selectedPaymentMethod == 'gpay' || this.selectedPaymentMethod == "paypal"
		) {
			// console.log("11111");
			if (this.selectedPaymentMethod == 'credit_or_debt') {
				PaymentFields.get('ChooseCard').setValidators(Validators.required);

				// this.getClientToken();
			} 
			else if(this.selectedPaymentMethod == "paypal") {
                this.getClientToken()
            }
			else {
				this.loaderAchSaved = true

			}
			// this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(1);
			this.makePaymentForm.controls['PaymentProcessParams'].controls[
				'ProceedWithoutPaymentProcess'
			].setValue(0);
		} else {
			// console.log("2222");
			this.selectedPaymentMethod = 'manual_payment';
			// this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(0);
			this.makePaymentForm.controls['PaymentProcessParams'].controls[
				'ProceedWithoutPaymentProcess'
			].setValue(1);
		}
		if (this.selectedPaymentMethod == 'ach') {
			PaymentFields.get('ChooseCard').setValidators(Validators.required);

			PaymentFields.get('AccountHolder').setValidators(
				Validators.compose([
					Validators.required,
					Validators.pattern(/^[a-zA-Z '&-]+$/),
				])
			);
			PaymentFields.get('AccountNumber').setValidators(
				Validators.compose([
					Validators.required,
					Validators.pattern(/^(?!0+$)\d{6,17}$/),
				])
			);
			// PaymentFields.get('BankName').setValidators(Validators.compose([Validators.required, Validators.maxLength(100)]));
			PaymentFields.get('AccountType').setValidators([Validators.required]);
			if (this.primaryCountryId == 'BprLdASASGl0') {
				// PaymentFields.get('RoutingNumber').setValidators(Validators.compose([Validators.required, Validators.pattern('(?:[0-9]●?){8}[0-9]')]));
				PaymentFields.get('RoutingNumber').setValidators(
					Validators.compose([
						Validators.required,
						Validators.pattern(/^(?!0+$)\d{9,9}$/),
					])
				);
				PaymentFields.get('RoutingNumber').updateValueAndValidity();
				this.ValidationMessage.RoutingNumber = [
					{ type: 'required', message: 'Please enter a valid routing number' },
					{
						type: 'pattern',
						message: 'Please enter a valid routing number with nine digits',
					},
				];
			} else if (this.primaryCountryId == 'gQFUOwHXczkw') {
				PaymentFields.get('RoutingNumber').setValidators(
					Validators.compose([
						Validators.required,
						Validators.pattern(/^(?!0+$)\d{8,8}$/),
					])
				);
				PaymentFields.get('RoutingNumber').updateValueAndValidity();
				this.ValidationMessage.RoutingNumber = [
					{ type: 'required', message: 'Please enter a valid routing number' },
					{
						type: 'pattern',
						message: 'Please enter a valid routing number with eight digits',
					},
				];
			} else {
				PaymentFields.get('RoutingNumber').setValidators(
					Validators.compose([
						Validators.required,
						Validators.pattern('^[0-9]*$'),
					])
				);
				PaymentFields.get('RoutingNumber').updateValueAndValidity();
				this.ValidationMessage.RoutingNumber = [
					{
						type: 'required',
						message: 'Please enter your bank routing number',
					},
					{
						type: 'pattern',
						message: 'Please enter a valid routing number using only numbers',
					},
				];
			}
		} else {
			PaymentFields.get('AccountHolder').setValidators(null);
			PaymentFields.get('RoutingNumber').setValidators(null);
			PaymentFields.get('AccountNumber').setValidators(null);
			PaymentFields.get('BankName').setValidators(null);
			PaymentFields.get('AccountType').setValidators(null);
		}
		PaymentFields.get('AccountHolder').updateValueAndValidity();
		PaymentFields.get('RoutingNumber').updateValueAndValidity();
		PaymentFields.get('AccountNumber').updateValueAndValidity();
		PaymentFields.get('BankName').updateValueAndValidity();
		PaymentFields.get('AccountType').updateValueAndValidity();
		if (this.selectedPaymentMethod !== 'credit_or_debt') {
			let params = {};
			params['url'] =
				'frontend-site/member/client-token/' +
				selectedId[0].CountryPaymentSettingId +
				'/' +
				this.selectedMemberId;
			params['params'] = {};
			return this._crud.getData(params).subscribe(
				(data) => {
					if (data['status'] == 'success') {
						console.log("saved cardlist-----", data['data']['SavedCards']);
					}
					if (data['data']['SavedCards'].length > 0) {
						// console.log("saved card present---------------")
						this.IsSavedAchPresent = true;
						// this.savedAchCardButtonShow = true;
						this.savedCards = data['data']['SavedCards'];
						// this.showNewCardOption = false;
						this.loaderAchSaved = false

					} else {
						//  / this.savedAchCardButtonShow = false;
						// this.showNewCardOption = true;
						this.IsSavedAchPresent = false;
						this.addNewCard = true
						this.loaderAchSaved = false

					}
				},
				(error) => {
					// console.log("Some error tiggered" + error)
				}
			);
		} else {
			this.savedCreditCard();
			// let params = {};
			// params['url'] =
			//   'frontend-site/member/credit-card-exist' +
			//   '/' +
			//   this.routeParam.memberId;
			// params['params'] = {};
			// return this._crud.getData(params).subscribe((response) => {
			//   console.log('saved credit card exit for use-----', response);
			//   // this.savedCreditCardButtonShow = response['data'];
			// });
		}
	}
	getClientToken() {
		console.log('craeting clint token');
		let selectedId = this.PaymentMethods.filter((x) => {
			return x.UniqueName == this.selectedPaymentMethod;
		});
		let params = {};
		params['url'] =
			'frontend-site/member/client-token/' +
			selectedId[0].CountryPaymentSettingId +
			'/' +
			this.selectedMemberId;
		params['params'] = {};
		this._crud.getData(params).subscribe((data) => {
			console.log(data['data'].ClientToken, 'maped clint response');
			// return res.data.ClientToken;
			this.hostedClientToken = data['data'].ClientToken;
			if(this.selectedPaymentMethod == "credit_or_debt") {
                this.createBraintreeUI();
            }
            else if(this.selectedPaymentMethod == "paypal") {
                this.createPayapalUI(this.hostedClientToken);
            }
		});
		// this.savedCreditCard();
	}
	savedCreditCard() {
		// member/saved-cards/{member_id}
		this.loaderCreditsaved = true
		console.log('craeting clint token');
		let params = {};
		params['url'] = 'frontend-site/member/saved-cards/' + this.selectedMemberId + '/' + this.paymentSettingId;
		params['params'] = {};
		this._crud.getData(params).subscribe((data) => {
			console.log(data['data'], 'maped clint response');
			if (data['data']['SavedCards'] != null) {
				this.listSavedCreditCard = true;
				this.savedCreditCards = data['data']['SavedCards'];
				this.loaderCreditsaved = false

			} else {
				// this.shownewCreditcardOption = false
				this.addNewCard = true
				this.getClientToken()

			}

			// return res.data.ClientToken;
		});
	}
	prevStep() {
		if (this.TabActiveIndex == 1) {
			this.TabActiveIndex--;
		}
	}
	tokenizeUserDetails(type = null) {
		this.cancelStatus = false;
		console.log(this.threeDValue,'value')
		this.makePaymentForm.controls['IsThreeDSecure'].setValue(this.threeDValue);
		if (this.selectedPaymentMethod == 'credit_or_debt') {
			if (type == 'savedCreditcard') {
				// this.submitForm(type)
				let lengthPri = this.threedCountryList.filter(x => { return x == this.primaryCountryId })
				let lengthCoun = this.threedCountryList.filter(x => { return x == this.countryId })
				if (lengthPri.length > 0 && lengthCoun.length > 0) {
					this.isLoading = true
					let Params = {}
                        Params["params"] = {
                            Token: this.makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value,
                            Amount: this.makePaymentForm.get('Amount').value
                        }
                        Params["url"] = "frontend-site/fetch-nonce-from-token";
                        this._crud.getData(Params).subscribe((res) => {
                            console.log('respo', res);
                            if (res) {
                                console.log('in res')
                                this.getPaymentMethodNonceSavedcards(res);

                            }
                        })
					// let selectedId = this.PaymentMethods.filter(x => { return x.UniqueName == this.selectedPaymentMethod });
					// let param = {
					// 	url: "frontend-site/client-token/" + selectedId[0].CountryPaymentSettingId
					// }
					// this._crud.getData(param).subscribe(data => {
					// 	// console.log("clint token data-----------",data.ClientToken)
					// 	let token = data['data'].ClientToken;
					// 	console.log("token----", token)





					// 	let params = {
					// 		url: "frontend-site/fetch-payment-method-nonce/" + this.makePaymentForm.controls.PaymentProcessParams.controls.PaymentFields.controls['ChooseCard'].value
					// 	}
					// 	this._crud.getData(params).subscribe(res => {
					// 		this.isLoading = true;
					// 		this.savedCardLoding = true;
					// 		console.log("cause list----", res['data']);
					// 		var threeDSecureParameters = {
					// 			amount: this.makePaymentForm.get(
					// 				'Amount'
					// 			).value,
					// 			nonce: res['data']['PaymentMethodNonce'], // Example: hostedFieldsTokenizationPayload.nonce
					// 			bin: res['data']['Bin'], // Example: hostedFieldsTokenizationPayload.details.bin

					// 			onLookupComplete: function (data, next) {
					// 				// use `data` here, then call `next()`
					// 				next();
					// 			}
					// 		};
					// 		braintree.client.create({
					// 			authorization: token
					// 		}).then((clientInstance) => {
					// 			braintree.threeDSecure.create({
					// 				version: 2, // Will use 3DS2 whenever possible
					// 				client: clientInstance
					// 			}).then((threeDSecureInstance) => {
					// 				this.threeDSecure = threeDSecureInstance;

					// 				const self = this
					// 				this.threeDSecure.on('customer-canceled', function () {
					// 					// the customer canceled the 3D Secure challenge
					// 					self.cancelStatus = true
					// 				});
					// 				this.threeDSecure.verifyCard(threeDSecureParameters).then((response) => {
					// 					// submit authenticated response.nonce to the server from here
					// 					console.log("3d secure response", response)
					// 					this.makePaymentForm.controls['PaymentProcessParams'].controls[
					// 						'PaymentFields'
					// 					].controls['Nonce'].setValue(response.nonce);
					// 					if (this.cancelStatus == true) {
					// 						// this.buttonStatus = false
					// 						this.isLoading = false
					// 					} else {
					// 						this.submitForm(type)
					// 					}

					// 				}).catch(function (error) {
					// 					// Handle error
					// 					// self.buttonStatus = false
					// 					self.isLoading = false
					// 					console.log("verify card error handling", error.details.originalError.details.originalError.error.message)
					// 					let popup = {}
					// 					popup['status'] = "error"
					// 					popup['msg'] = error.details.originalError.details.originalError.error.message
					// 					self.successPopup(popup);

					// 				});
					// 			});
					// 		});
					// 	});
					// })
				} else {
					this.submitForm(type)
				}
			} else {
				// this.hostedFieldsInstance
				// 	.tokenize({ cardholderName: this.cardholdersName })
				// 	.then((payload) => {
				// 		console.log('payload', payload);
				// 		// this.makePaymentForm.controls['PaymentProcessParams'].controls[
				// 		//   'PaymentFields'
				// 		// ].controls['Nonce'].setValue(payload.nonce);
				// 		// this.submitForm(type);
				// 		// submit payload.nonce to the server from here
				// 		let lengthPri = this.threedCountryList.filter(x => { return x == this.primaryCountryId })
				// 		let lengthCoun = this.threedCountryList.filter(x => { return x == this.countryId })
				// 		if (lengthPri.length > 0 && lengthCoun.length > 0) {

				// 			//   if(this.primaryCountryId == 'BprLdASASGl0' && this.countryId == 'BprLdASASGl0'){
				// 			this.isLoading = true
				// 			var threeDSecureParameters = {
				// 				amount: this.makePaymentForm.get(
				// 					'Amount'
				// 				).value,
				// 				nonce: payload.nonce, // Example: hostedFieldsTokenizationPayload.nonce
				// 				bin: payload.details.bin, // Example: hostedFieldsTokenizationPayload.details.bin

				// 				onLookupComplete: function (data, next) {
				// 					// use `data` here, then call `next()`
				// 					next();
				// 				}
				// 			};
				// 			const self = this
				// 			this.threeDSecure.on('customer-canceled', function () {
				// 				// the customer canceled the 3D Secure challenge
				// 				self.cancelStatus = true
				// 			});
				// 			this.threeDSecure.verifyCard(threeDSecureParameters).then((response) => {
				// 				// submit authenticated response.nonce to the server from here
				// 				console.log("3d secure response", response)
				// 				this.makePaymentForm.controls['PaymentProcessParams'].controls[
				// 					'PaymentFields'
				// 				].controls['Nonce'].setValue(response.nonce);

				// 				if (this.cancelStatus == true) {
				// 					// this.buttonStatus = false
				// 					this.isLoading = false
				// 				} else {
				// 					this.submitForm(type);
				// 				}

				// 			}).catch(function (error) {
				// 				// Handle error
				// 				// self.buttonStatus = false
				// 				self.isLoading = false
				// 				console.log("verify card error handling", error.details.originalError.details.originalError.error.message)
				// 				let popup = {}
				// 				popup['status'] = "error"
				// 				popup['msg'] = error.details.originalError.details.originalError.error.message
				// 				self.successPopup(popup);

				// 			});
				// 		} else {
				// 			console.log('payload --> with out 3d security', payload);
				// 			this.makePaymentForm.controls['PaymentProcessParams'].controls[
				// 				'PaymentFields'
				// 			].controls['Nonce'].setValue(payload.nonce);
				// 			this.submitForm(type);
				// 		}
				if (this.hostedFieldsInstance == undefined) {
                    // this.buttonStatus = false;

                } else {
                    this.hostedFieldsInstance.tokenize({ cardholderName: this.cardholdersName }).then((payload) => {
                        console.log('payload', payload);
                        this.braintreeNonce = payload.nonce;
                        // this.finalSubmitForm(value)
                        // submit payload.nonce to the server from here

                        if(this.threeDValue == true) {
							console.log('in three')
                            // if(this.selectedFrequencyName == 'Onetime') {
                                let lengthPri = this.threedCountryList.filter(x => { return x == this.primaryCountryId })
                                let lengthCoun = this.threedCountryList.filter(x => { return x == this.countryId })
                                if (lengthPri.length > 0 && lengthCoun.length > 0) {
									console.log('in length')
                                    //   if(this.primaryCountryId == 'BprLdASASGl0' && this.countryId == 'BprLdASASGl0'){
                                    this.isLoading = true
                                    var threeDSecureParameters = {
                                        amount: this.makePaymentForm.get('Amount').value,
										nonce: payload.nonce, // Example: hostedFieldsTokenizationPayload.nonce
										bin: payload.details.bin, // Example: hostedFieldsTokenizationPayload.details.bin
        
                                        onLookupComplete: function (data, next) {
                                            // use `data` here, then call `next()`
                                            next();
                                        }
                                    };
                                    const self = this
                                    this.threeDSecure.on('customer-canceled', function () {
                                        // the customer canceled the 3D Secure challenge
                                        self.cancelStatus = true
                                    });
                                    this.threeDSecure.verifyCard(threeDSecureParameters).then((response) => {
                                        // submit authenticated response.nonce to the server from here
										this.braintreeNonce = response.nonce;
                                        console.log("3d secure response", response.nonce)
                                        this.makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(response.nonce);
        
                                        if (this.cancelStatus == true) {
                                            // this.disableMakePayment = false
                                            self.isLoading = false
                                        } else {
                                            this.submitForm(type)
                                        }
        
                                    }).catch(function (error) {
                                        // Handle error
                                        // self.disableMakePayment = false
                                        self.isLoading = false
                                        console.log("verify card error handling", error.details.originalError.details.originalError.error.message)
                                        let popup = {}
                                        popup['status'] = "error"
                                        popup['msg'] = error.details.originalError.details.originalError.error.message
                                        self.successPopup(popup);
        
                                    });
                                } 
								else {
									console.log('in else length')
									this.submitForm(type)
								}
                               
                            // }
                            // else {
                            //     console.log('in recu')
                            //     this.getPaymentMethodNonce(payload.nonce);
                            // }
                            
                        }
                        else {
							console.log('in else')
                            console.log('payload --> with out 3d security', payload);
                            this.makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(payload.nonce);
							this.submitForm(type);
                        }
					})
					.catch((error) => {
						//   console.log('error_detail', error)
						this.brainTreeError = '';
						// perform custom validation here or log errors
						// if (this.donationForm.controls['Amount'].value == null && this.otheramoutValidation == false && this.maxOtherAmount == false) {
						//     this.donationForm.controls['Amount'].setValue(this.donationForm.controls['OtherAmount'].value)
						//     this.donationForm.controls['CausePaymentOptionValueId'].setValue(null);
						// }
						let Step2Fields = [
						];
						//   console.log(this.eventForm, "TEST");
						const invalid = [];
						const controls = Step2Fields;
						let i: any;
						for (i in controls) {
							controls[i].markAsDirty();
							if (controls[i].invalid) {
								invalid.push(i);
							}
						}
						switch (error.code) {
							case 'HOSTED_FIELDS_FIELDS_EMPTY':
								// occurs when none of the fields are filled in
								console.error('All fields are empty! Please fill out the form.');
								// this.brainTreeError =
								//   'All fields are empty! Please fill out the form.';
								this.noFieldsCardNumber = 1;
								this.noFieldsDate = 1;
								this.noFieldsCvv = 1;
								break;
							case 'HOSTED_FIELDS_FIELDS_INVALID':
								// occurs when certain fields do not pass client side validation
								console.error(
									'Some fields are invalid:',
									error.details.invalidFieldKeys
								);
								// this.brainTreeError = 'Some fields are invalid'
								// you can also programtically access the field containers for the invalid fields
								// error.details.invalidFields.forEach(function(fieldContainer) {
								//     fieldContainer.className = 'invalid';
								// });
								error.details.invalidFieldKeys.forEach((element) => {
									console.log(element);
									if (element == 'number') {
										this.noFieldsCardNumber = 1;
									}
									if (element == 'cvv') {
										this.noFieldsCvv = 1;
									}
									if (element == 'expirationDate') {
										this.noFieldsDate = 1;
									}
								});
								break;
							case 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE':
								// occurs when:
								//   * the client token used for client authorization was generated
								//     with a customer ID and the fail on duplicate payment method
								//     option is set to true
								//   * the card being tokenized has previously been vaulted (with any customer)
								// See: https://developers.braintreepayments.com/reference/request/client-token/generate/#options.fail_on_duplicate_payment_method
								console.error(
									'This payment method already exists in your vault.'
								);
								this.brainTreeError =
									'This payment method already exists in your vault.';
								break;
							case 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED':
								// occurs when:
								//   * the client token used for client authorization was generated
								//     with a customer ID and the verify card option is set to true
								//     and you have credit card verification turned on in the Braintree
								//     control panel
								//   * the cvv does not pass verfication (https://developers.braintreepayments.com/reference/general/testing/#avs-and-cvv/cid-responses)
								// See: https://developers.braintreepayments.com/reference/request/client-token/generate/#options.verify_card
								console.error('CVV did not pass verification');
								break;
							case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
								// occurs for any other tokenization error on the server
								console.error(
									'Tokenization failed server side. Is the card valid?'
								);
								this.brainTreeError =
									'Tokenization failed server side. Is the card valid?';
								break;
							case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
								// occurs when the Braintree gateway cannot be contacted
								console.error('Network error occurred when tokenizing.');
								this.brainTreeError = 'Network error occurred when tokenizing.';
								break;
							default:
								console.error('Something bad happened!');
								this.brainTreeError = 'Something bad happened!  ';
						}
					});
				}
			}
		} else {
			this.submitForm(type);
		}
	}

	getPaymentMethodNonceSavedcards(response: any) {
		let selectedId = this.PaymentMethods.filter(x => { return x.UniqueName == this.selectedPaymentMethod });
		console.log('in response',selectedId)
		var threeDSecureParameters = {
			amount: response['data'].Amount,
			nonce: response['data'].PaymentMethodNonce, // Example: hostedFieldsTokenizationPayload.nonce
			bin: response['data'].Bin, // Example: hostedFieldsTokenizationPayload.details.bin

			onLookupComplete: function (data, next) {
				// use `data` here, then call `next()`
				// determine if you want to call next to start the challenge,
				// if not, call cancelVerifyCard
				next();
			},
		};
		let param = {
			url: "frontend-site/client-token/" + selectedId[0].CountryPaymentSettingId
		};
		this._crud.getData(param).subscribe((data) => {
            console.log('data23',data)
			let token = data['data'].ClientToken;
			braintree.client
				.create({
					authorization: token,
				})
				.then((clientInstance) => {
					braintree.threeDSecure
						.create({
							version: 2, // Will use 3DS2 whenever possible
							client: clientInstance,
						})
						.then((threeDSecureInstance) => {
							this.threeDSecure = threeDSecureInstance;

							const self = this;
							this.cancelStatus = false;
							this.threeDSecure.on("customer-canceled", function () {
								// the customer canceled the 3D Secure challenge
								self.cancelStatus = true;
							});
							this.threeDSecure
								.verifyCard(threeDSecureParameters)
								.then((res) => {
									// submit authenticated response.nonce to the server from here
									this.braintreeNonce = res.nonce;
									console.log(
										"2nd nonce after otp------",
										res,
										this.braintreeNonce,
										this.cancelStatus
									);
									if (self.cancelStatus == false) {
										console.log('inside4')
										this.submitForm();
									}
									else {
										console.log('in else cancel1')
										this.isLoading = false;
									}
								})
								.catch(function (error) {
									console.log(
										"verify card error handling",
										error.details.originalError.details.originalError.error
											.message
									);
									Helpers.sweetAlert({
										title: "Error",
										msg: error.details.originalError.details.originalError.error
											.message,
										type: "error",
									});
								});
						});
				});
		})
	}

	
	public savedCardLoding: boolean = false;
	
	submitForm(type = null) {
		if(this.selectedPaymentMethod=='gpay' || this.selectedPaymentMethod=='paypal'){
            this.makePaymentForm.controls['IsThreeDSecure'].setValue(false);
        }else{
        this.makePaymentForm.controls['IsThreeDSecure'].setValue(this.threeDValue);
        }
		console.log('inside submit----', this.makePaymentForm);
		this.Step1Fields = [];
		console.log(this.Step1Fields.length);
		// new code for step2 fields
		if (type == 'savedcard' || type == 'savedCreditcard') {
			console.log('choose card-------');
			this.savedCardLoding = true;
			this.Step2Fields = [
				this.makePaymentForm.controls['PaymentProcessParams'].controls[
					'PaymentFields'
				].controls['ChooseCard'],
			];
		} else {
			console.log('new card-------');
			this.savedCardLoding = false;
			this.Step2Fields = [
				this.makePaymentForm.controls['PaymentProcessParams'].controls[
					'PaymentFields'
				].controls['AccountHolder'],
				this.makePaymentForm.controls['PaymentProcessParams'].controls[
					'PaymentFields'
				].controls['RoutingNumber'],
				this.makePaymentForm.controls['PaymentProcessParams'].controls[
					'PaymentFields'
				].controls['AccountNumber'],
				this.makePaymentForm.controls['PaymentProcessParams'].controls[
					'PaymentFields'
				].controls['AccountType'],
			];
		}

		// console.log(this.eventForm, "TEST");
		const invalid = [];
		const controls = this.Step2Fields;
		let i: any;
		for (i in controls) {
			controls[i].markAsDirty();
			if (controls[i].invalid) {
				invalid.push(i);
				// invalid.focus();
			}
		}
		console.log('invalid-----', invalid);
		if (invalid[0] == undefined && this.Step1Fields.length == 0) {
			console.log('invalid is undefined');
			this.isLoading = true;
			this.loadingGpay=true
			// console.log("Api call position");

			// let params = {};
			// (params['url'] =
			// 	'frontend-site/member-subscription/make-payment/' + this.subId),
			// 	(params['params'] = this.RequestPostParams({
			// 		type: this.selectedPaymentMethod,
			// 	}));
				let params = {}
                    params["url"] = `frontend-site/member-subscription/make-payment/${this.subId}`;
                    if(this.threeDValue) {
                        params["params"] = this.RequestPostParams(
                            { type: this.selectedPaymentMethod },
                            this.braintreeNonce, 'submit'
                        );
                    }
                    else {
                        params["params"] = this.RequestPostParams({
                            type: this.selectedPaymentMethod
                        }, this.braintreeNonce);
                    }
			console.log(params)
			this._crud.postData(params).subscribe((data) => {
				this.isLoading = false;
				this.loadingGpay=false;
				this.paypalLoad = false;
				// console.log('func5');
				// console.log('form submission response', data);
				this.formResult = data;
				if (this.formResult['status'] == 'success') {
					this.successPopup(this.formResult);
					this.makePaymentForm.reset();
					$('.focus-label.is-focused').removeClass('is-focused');
					this.selectedPaymentMethod = '';
					// this.getCountryList(this.UserLocation.PrimaryCountryId);
				} else {
					this.successPopup(this.formResult);
				}
			});
		} else {
			// this.focusCall();
		}
	}
	RequestPostParams(withpayment = null, nonce = null, requestType?: any) {
		this.postDataJsonPrepare = {};
		if (withpayment.type == 'gpay' || withpayment.type == 'paypal') {
			this.postDataJsonPrepare['IsThreeDSecure'] = false;
		}else{
			this.postDataJsonPrepare['IsThreeDSecure'] = this.threeDValue;
		}
		
		this.postDataJsonPrepare['MemberId'] = this.selectedMemberId;
		this.postDataJsonPrepare['TransactionAmount'] = this.makePaymentForm.get(
			'Amount'
		).value;
		this.postDataJsonPrepare[
			'CurrencyISOCode'
		] = this.eventDetails.CurrencyISOCode;
		this.postDataJsonPrepare['PaymentProcessParams'] = {};
		this.postDataJsonPrepare['PaymentProcessParams'][
			'ProceedWithoutPaymentProcess'
		] = this.makePaymentForm.controls['PaymentProcessParams'].controls[
			'ProceedWithoutPaymentProcess'
		].value;

		// this.postDataJsonPrepare['PaymentProcessParams'][
		//   'RegistrationAmount'
		// ] = this.makePaymentForm.controls.PaymentProcessParams.controls.NetAmountToPay.value;
		// this.postDataJsonPrepare['PaymentProcessParams']['InvoiceId'] = '';
		// this.postDataJsonPrepare['PaymentProcessParams'][
		//   'DiscountAmount'
		// ] = this.makePaymentForm.controls.PaymentProcessParams.controls.DiscountAmount.value;
		// /this.postDataJsonPrepare['PaymentProcessParams']['CurrencyISOCode'] = '';
		// this.postDataJsonPrepare['PaymentProcessParams'][
		//   'NetAmountToPay'
		// ] = this.makePaymentForm.get('Amount').value;
		// this.postDataJsonPrepare['PaymentProcessParams']['IsAccomadationRequired'] = (this.eventForm.controls.PaymentProcessParams.controls.IsAccomadationRequired.value) ? 1 : 0;

		// this.postDataJsonPrepare['PaymentProcessParams']['UpdatePaymentAsCompleted'] = (this.createForm.get('UpdatePayment').value) ? 1 : 0;
		this.postDataJsonPrepare['PaymentProcessParams'][
			'UpdatePaymentAsCompleted'
		] = 1;
		this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields'] = {};
		this.postDataJsonPrepare['PaymentProcessParams'][
			'PaymentMethodId'
		] = this.InitialMethodId;
		if (withpayment.type == 'ach') {
			if (this.savedCardLoding == false) {
				this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields'][
					'AccountHolder'
				] = this.makePaymentForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountHolder.value;
				this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields'][
					'AccountNumber'
				] = this.makePaymentForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountNumber.value;
				this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields'][
					'RoutingNumber'
				] = this.makePaymentForm.controls.PaymentProcessParams.controls.PaymentFields.controls.RoutingNumber.value;
				this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields'][
					'AccountType'
				] = this.makePaymentForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountType.value;

				if (
					this.makePaymentForm.controls.PaymentProcessParams.controls
						.PaymentFields.controls.SaveCardForFuture.value
				) {
					this.postDataJsonPrepare['PaymentProcessParams'][
						'SaveCardForFuture'
					] = '1';
				} else {
					this.postDataJsonPrepare['PaymentProcessParams'][
						'SaveCardForFuture'
					] = '0';
				}
			} else {
				this.postDataJsonPrepare['PaymentProcessParams'][
					'PaymentGatewayTokenId'
				] = this.makePaymentForm.controls['PaymentProcessParams'].controls[
					'PaymentFields'
				].controls['ChooseCard'].value;
			}
		} else if (withpayment.type == 'credit_or_debt') {
			if (this.savedCardLoding == false) {
				console.log('in if save', nonce)
				this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
					"Nonce"
				] = nonce;
			} else {
				console.log('inside else')
				if(requestType == 'submit') {
					console.log('inifco')
					this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["Nonce"] = nonce;
				}
				else {
					console.log('inifelse')
						this.postDataJsonPrepare["PaymentProcessParams"][
							"PaymentGatewayTokenId"
						] = this.makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value;
				}
				

			}
			if (this.makePaymentForm.controls.PaymentProcessParams.controls.PaymentFields.controls.SaveCardForFuture.value) {
				this.postDataJsonPrepare["PaymentProcessParams"][
					"SaveCardForFuture"
				] = "1";
			} else {
				this.postDataJsonPrepare["PaymentProcessParams"][
					"SaveCardForFuture"
				] = "0";
			}
			// if (this.savedCardLoding == false) {
			// 	this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields'][
			// 		'Nonce'
			// 	] = this.makePaymentForm.controls['PaymentProcessParams'].controls[
			// 		'PaymentFields'
			// 	].controls['Nonce'].value;

			// 	if (
			// 		this.makePaymentForm.controls['PaymentProcessParams'].controls[
			// 			'PaymentFields'
			// 		].controls['SaveCardForFuture'].value
			// 	) {
			// 		this.postDataJsonPrepare['PaymentProcessParams']['SaveCardForFuture'] =
			// 			'1';
			// 	} else {
			// 		this.postDataJsonPrepare['PaymentProcessParams']['SaveCardForFuture'] =
			// 			'0';
			// 	}
			// } else {
			// 	//   this.postDataJsonPrepare["PaymentProcessParams"][
			// 	//     "PaymentGatewayTokenId"
			// 	// ] = this.makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value;
			// 	if (nonce == null) {
			// 		this.postDataJsonPrepare["PaymentProcessParams"][
			// 			"PaymentGatewayTokenId"
			// 		] = this.makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value;
			// 	} else {
			// 		this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields']['Nonce'] = nonce;
			// 	}
			// }
		}
		else if (withpayment.type == 'gpay' || withpayment.type == 'paypal') {
			if (this.savedCardLoding == false) {
				console.log('in if save', nonce)
				this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
					"Nonce"
				] =this.nonce;
			} else {
				console.log('inside else')
				if(requestType == 'submit') {
					console.log('inifco')
					this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["Nonce"] = this.nonce;
				}
				else {
					console.log('inifelse')
						this.postDataJsonPrepare["PaymentProcessParams"][
							"PaymentGatewayTokenId"
						] = this.makePaymentForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value;
				}
				

			}
			}
		return this.postDataJsonPrepare;
	}
	onSelectedSavedcardChange(cardDetails) {
		console.log("inside saved card change------->", this.makePaymentForm.controls['AddNewCard']);
		// this.showNewCardOption = false;
		this.selectedSavedCard = true;
		this.addNewCard = false;
		this.makePaymentForm.controls['AddNewCard'].setValue(false)
	}
	successPopup(data) {
		if (data['status'] == 'success') {
			this.response = { title: 'Thank You', message: 'Your payment has been processed successfully' }
		}
		else {
			this.response = { title: data['status'], message: data['msg'] }
		}
		const modalRef = this.modalService.open(SuccessPopupComponent, {
			size: 'md',
		});
		modalRef.componentInstance.user = this.response;
		modalRef.result.then((result) => {
			//   console.log("result-----modal",result)
			if (result.result && data['status'] == 'success') {
				//redirecting to eventlisting screen on sucees event registration
				this.router.navigateByUrl(
					'/memberDashboard/mysubscription/' + this.selectedMemberId
				);
				// /clearing all forms.
				this.makePaymentForm.reset();
				$('.focus-label.is-focused').removeClass('is-focused');
				this.Currency = null;
				this.formInit();
				this.ngOnInit();
			} else {
				this.selectedPaymentMethod = '';
				this.makePaymentForm.controls['PaymentProcessParams'].controls[
					'PaymentMethodId'
				].setValue(0);
			}
		});
	}

	createPayapalUI(token?: any) {
        // Create a client.
        let mm = this
        console.log('mm.',mm.Currency)
        braintree.client.create({
            authorization: token,
        }, function (clientErr, clientInstance) {
            
            // Stop if there was a problem creating the client.
            // This could happen if there is a network error or if the authorization
            // is invalid.
            if (clientErr) {
                console.error('Error creating client:', clientErr);
                return;
            }

            // Create a PayPal Checkout component.
            braintree.paypalCheckout.create({
                client: clientInstance,
            }, function (paypalCheckoutErr, paypalCheckoutInstance) {
                paypalCheckoutInstance.loadPayPalSDK({
                    currency: mm.Currency,
                    vault: true,
                }, function () {
                    // if(mm.DonationForm.valid) {
                        
                    console.log('inside', clientInstance._configuration.gatewayConfiguration.paypal.clientId)
                    paypal.Buttons({
                        fundingSource: paypal.FUNDING.PAYPAL,

                        createBillingAgreement: function () {
                                return paypalCheckoutInstance.createPayment({
                                    flow: 'vault', // Required
                                });

                        },
                        
                        style: {
                            label: 'pay',
                            layout: 'horizontal',
                            color: 'white',
                            shape: 'rect',
                            height: 40,

                        },


                        onApprove: function (data, actions) {
                                return paypalCheckoutInstance
                                    .tokenizePayment(data)
                                    .then(payload => {
                                        console.log(payload.nonce);
                                        mm.nonce = payload.nonce;
										mm.paypalLoad = true;
                                        mm.submitForm()
                                    });


                        },


                        onCancel: function (data) {
                            console.log('PayPal payment canceled', JSON.stringify(data));
                        },

                        onError: function (err) {
                            console.error('PayPal error', err);
                        }
                    }).render('#paypal-button').then(function () {
                        // The PayPal button will be rendered in an html element with the ID
                        // `paypal-button`. This function will be called when the PayPal button
                        // is set up and ready to be used
                    });
                    // }


                });

            });

        });
    }
	
	createBraintreeUI() {
		let fields = {}
        if(!this.isCvvhide) {
            fields = {
                number: {
                    selector: '#card-number',
                    placeholder: 'Card Number'
                },
                cvv: {
                    selector: '#cvv',
                    placeholder: 'CVV'
                },
                expirationDate: {
                    selector: '#expiration-date',
                    // placeholder: 'Expiration Date (MM/YY)'
                    placeholder: 'MM/YY'
                }
            }
        }
        else {
            fields = {
                number: {
                    selector: '#card-number',
                    placeholder: 'Card Number'
                },
                expirationDate: {
                    selector: '#expiration-date',
                    // placeholder: 'Expiration Date (MM/YY)'
                    placeholder: 'MM/YY'
                }
            }
        }
		// console.log("braintree");
		braintree.client
			.create({
				authorization: this.hostedClientToken,
			})
			.then((clientInstance) => {
				this.loaderCreditsaved = false
				this.loaderCreditNew = false
				braintree.hostedFields
					.create({
						client: clientInstance,
						styles: {
							// Style all elements
							// 'input': {
							//   'font-size': '16px',
							//   'color': '#3A3A3A'
							// },
							'.invalid': {
								color: '#D0041D',
							},

							// Styling element state
							// 'input': {
							//     // 'font-family': '"Merriweather", serif !important',
							//     'padding': "0 10px 0 20px !important",
							//     'letter-spacing': "0.025em !important",
							//     "font-weight": "300 !important",
							//     "font-size": "14px !important",
							// },
							input: {
								'font-size': '16px!important',
								'font-weight': '500!important',
								'font-family': "'Poppins', sans-serif !important",
								color: '#0D0D0D',
								':placeholder': {
									'font-weight': '500!important',
									color: '#9F9F9F!important',
								},
							},

							// Media queries
							// Note that these apply to the iframe, not the root window.
							// '@media screen and (max-width: 700px)': {
							//     'input': {
							//         'font-size': '14px'
							//     }
							// }
						},

						// The hosted fields that we will be using
						// NOTE : cardholder's name field is not available in the field options
						// and a separate input field has to be used incase you need it
						fields: fields
					})
					.then((hostedFieldsInstance) => {
						this.hostedFieldsInstance = hostedFieldsInstance;
						hostedFieldsInstance.on('focus', (event) => {
							const field = event.fields[event.emittedBy];
							const label = this.findLabel(field);
							if (label['htmlFor'] == 'card-number') {
								this.noFieldsCardNumber = 0;
							}
							if (label['htmlFor'] == 'expiration-date') {
								this.noFieldsDate = 0;
							}
							if (label['htmlFor'] == 'cvv') {
								this.noFieldsCvv = 0;
							}
							// console.log(this.noFieldsCardNumber);
							// label.classList.remove('filled'); // added and removed css classes
							// can add custom code for custom validations here
							// this.cdRef.detectChanges();
						});
						hostedFieldsInstance.on('blur', (event) => {
							const field = event.fields[event.emittedBy];
							// const label = this.findLabel(field); // fetched label to apply custom validations
							// can add custom code for custom validations here
						});

						hostedFieldsInstance.on('empty', (event) => {
							const field = event.fields[event.emittedBy];
							// can add custom code for custom validations here
						});

						hostedFieldsInstance.on('validityChange', (event) => {
							const field = event.fields[event.emittedBy];
							const label = this.findLabel(field);
							if (field.isPotentiallyValid) {
								// applying custom css and validations
								// label.classList.remove('invalid');
							} else {
								// label.classList.add('invalid');
							}
							// can add custom code for custom validations here
						});
					});
				braintree.threeDSecure.create({
					version: 2, // Will use 3DS2 whenever possible
					client: clientInstance
				}).then((threeDSecureInstance) => {
					this.threeDSecure = threeDSecureInstance;
				});
			})
			.catch((error) => {
				// console.log('error', error);
				if (error.name == 'BraintreeError') {
					// this.paymentGatewayErrorMsg = "Payment Gateway is not configured"
				}
			});
		// this.clearCardDetails();
	}
	// Fetches the label element for the corresponding field
	findLabel(field: braintree.HostedFieldsHostedFieldsFieldData) {
		return document.querySelector(
			'.hosted-field--label[for="' + field.container.id + '"]'
		);
	}
	isAddNewCard(value) {
		console.log(value);
		this.loaderCreditNew = true
		this.makePaymentForm.controls.PaymentProcessParams.controls.PaymentFields.controls['ChooseCard'].reset(); //setValue('')
		value == true ? this.addNewCard = true : this.addNewCard = false;
		if (this.selectedPaymentMethod == 'credit_or_debt' && value == true) {
			this.getClientToken()
		}

	}
	getThreedsecureCountries() {
		let params = {}
		params["url"] = "frontend-site/3d-secure-countries";
		this._crud.getData(params).subscribe(data => {
			this.threedCountryList = data['data'];
			console.log('list', this.threedCountryList);

		})
	}

	getThreedsecure() {
		let params = {}
		// let selectedId = this.PaymentMethods.filter(x => { return x.UniqueName == this.selectedPaymentMethod });
		// console.log('iiid',selectedId);
		console.log('paymentid', this.countryPaymentId);

		// this.memberId = localStorage.getItem('memberId'); 
		console.log('memberid', this.selectedMemberId);
		params["url"] = "frontend-site/check-3d-secure/" + this.countryPaymentId + '/' + this.selectedMemberId;
		this._crud.getData(params).subscribe(data => {
			this.threeDValue = data['data'];
			console.log('list-------------value', this.threeDValue);

		})
	}
}


