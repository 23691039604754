<section class="member-wrp">
    <div class="bg-body">
        <div class="login-wrp ">
            <div class="login-form">
                <form [formGroup]="resetPasswordForm">
                    <div class="row form-wrp">
                        <div class="col-md-7 form-wrp-left">
                            <figure>
                                <img src="./assets/member/images/img-login-left-brown.svg" alt="Shalom">
                            </figure>
                        </div>
                        <div class="col-md-5 form-wrp-right">
                            
                            
                            <div  *ngIf="tokenErrorMsg != ''" class="m-alert m-alert--outline alert alert-danger alert-dismissible" role="alert">
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close"></button>
                                <span>{{tokenErrorMsg}}</span>
                            </div>
                            <div class="form-inner"  *ngIf="tokenErrorMsg == ''">
                                <div class="login-head-title">
                                    <h1>Hi, Welcome Back!</h1>
                                    <p>Reset Your Password</p>
                                </div>
                                <!-- <div class="form-group focus-label mb-1" id="passWord">
                                    <label class="form-label-of" for="Password">New Password</label>
                                    <input autocomplete="off" formControlName="newPassword" id="Password" type="password" class="pswrd-input" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.newPassword">
                                        <span class="error-message"
                                            *ngIf="resetPasswordForm.get('newPassword').hasError(validation.type) && (resetPasswordForm.get('newPassword').dirty || resetPasswordForm.get('newPassword')?.touched)">
                                            {{validation.message}}
                                        </span>
                                    </span>
                                </div> -->
                                <!-- <div class="col-md-6"> -->
                                    <div class="form-group focus-label">
                                        <label class="form-label-of" for="">New Password</label>
                                        <input class="form-control dropdown-toggle" id="" name="cpwd" formControlName="newPassword" [type]="newpwd" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="passwordFetch($event.target.value)">
                                        <a (click)="newpasswordType()" class="pswrd-img"> <img *ngIf="newshow" src="./assets/member/images/icon-password.svg" alt="Shalom">
                                            <img *ngIf="!newshow" src="./assets/member/images/icon-passwordshow.svg" alt="Shalom">
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end">
                                            <ul>
                                                <li [ngClass]="{'pwd_class': totalChar}"><span>&#10003;</span> 8 or more characters</li>
                                                <li [ngClass]="{'pwd_class': atleaatAlpha}"><span> &#10003;</span> Upper & Lowercase letters</li>
                                                <li [ngClass]="{'pwd_class': atleastDigit}"> <span>&#10003;</span> Atleast one number</li>
                                            </ul>
                                        </div>
                                        <span class="m-form__help" *ngFor="let validation of ValidationMessage.newPassword">
                                        <span class="error-message"
                                            *ngIf="resetPasswordForm.get('newPassword').hasError(validation.type) && (resetPasswordForm.get('newPassword').dirty || resetPasswordForm.get('newPassword')?.touched)">
                                            {{validation.message}}
                                        </span>
                                        </span>
                                    </div>
                                <!-- </div> -->
                                <!-- <div class="form-group focus-label mb-1">
                                    <label class="form-label-of" for="cPassword">Confirm Password</label>
                                    <input autocomplete="off" formControlName="confirmPassword" id="" type="text" class="pswrd-input" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.confirmPassword">
                                        <span class="error-message"
                                            *ngIf="resetPasswordForm.get('confirmPassword').hasError(validation.type) && (resetPasswordForm.get('confirmPassword').dirty || resetPasswordForm.get('confirmPassword')?.touched)">
                                            {{validation.message}}
                                        </span>
                                    </span>
                                </div> -->
                                <div class="form-group focus-label">
                                    <label class="form-label-of" for="">Confirm Password</label>
                                    <input class="form-control" id="" formControlName="confirmPassword" [type]="confirmpwd" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="checkPassword($event.target.value)">
                                    <a (click)="confirmpasswordType()" class="pswrd-img"> <img *ngIf="confirmshow" src="./assets/member/images/icon-password.svg" alt="Shalom">
                                        <img *ngIf="!confirmshow" src="./assets/member/images/icon-passwordshow.svg" alt="Shalom">
                                    </a>
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.confirmPassword">
                                    <span class="error-message"
                                        *ngIf="resetPasswordForm.get('confirmPassword').hasError(validation.type) && (resetPasswordForm.get('confirmPassword').dirty || resetPasswordForm.get('confirmPassword')?.touched) || resetPasswordForm.get('confirmPassword').hasError('passwordMismatch')">
                                        {{validation.message}}
                                    </span>
                                    </span>
                                </div>
                                <!-- <span class="error-message mb-3">{{tokenErrorMsg}}</span> -->
                                
                                <button type="submit" class="btn-common mb-3" (click)="Submit()">Reset Password<i *ngIf="isloading" class="spinner-border"></i></button>
            
                                <button type="submit" class="btn-border" routerLink="/member-login">Cancel</button>
                            </div>
                        </div>
                        
                        <!-- <app-alert-popup data="tokenErrorMsg"></app-alert-popup> -->
                    </div>
                    
                </form>

            </div>
        </div>
        <ul class="nav-menu-btm">
            <li><a href="javascript:void(0)">Privacy Notice</a></li>
            <!-- <li><a href="javascript:void(0)">Help</a></li> -->
            <li><a>Help</a></li>
            <li><a href="javascript:void(0)">Conditions of Use</a></li>
        </ul>
    </div>
</section>