import { Component, OnInit } from '@angular/core';
import { CommonCrudService } from 'src/app/_services/common-crud.service';
import { SharedService } from 'src/app/_services/shared.service';
import { AuthService } from '../../auth/services/auth.service';
declare let $: any;

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  memberId;
  FirstName: any;
  PhotoUrl: any;
  profileData: any = [];
  activeTab;
  tab;
  isSpfMember:boolean;
  singleDonationExist:boolean = false;
  isLogged: boolean;
  constructor(public _auth:AuthService,public sharedService:SharedService,public _crud: CommonCrudService) { }

  ngOnInit(): void {
    if(localStorage.getItem('currentUser') == null){
      this.isLogged = false

    }else{
      this.isLogged = true
    }
    this.sharedService.pathInfo.subscribe(path => {
      console.log("path top bar",path);
      this.activeTab = path;
    });
    this.sharedService.tabInfo.subscribe(path =>{
      // console.log("tab top bar",path);
      this.tab = path;
  });
    this.memberId = localStorage.getItem('memberId'); 
    this.sharedService.currentFname.subscribe(fname =>{
      this.FirstName = fname
    })
    this.sharedService.currentPhoto.subscribe(photo =>{
      this.PhotoUrl = photo
    })
    let params = {
      url: "frontend-site/view-member", params: { MemberId: this.memberId }
  }
    this._crud.getData(params).subscribe(res => {
      if(res['status'] != 'error') {
        this.profileData = res['data']['MemberProfile'];
        this.PhotoUrl =  this.profileData['PhotoUrl']
        this.FirstName = this.profileData['FirstName']
      }
    });
    //Mobile Menu
$('.mob-btn').click(function() {
  if (!$('html').hasClass('show-menu')) {
      $('html').addClass('show-menu');
  } else {
      $('html').removeClass('show-menu');
  }
});


$('.tab-wrp li a').on('click',function(){
  if ($(this).hasClass('active')) {
    $('html').removeClass('show-menu');
} 
});
this.isSpfMember = (localStorage.getItem('IsSpfMember')=='true'?true:false);
let currentUser = JSON.parse(localStorage.getItem("currentUser"));
this.singleDonationExist = currentUser?.singleDonationExist;
  }
  logOut(){
    this._auth.SignOut();
  }
  scrollTop() {
    window.scroll(0, 0);
  }
}
