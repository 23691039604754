import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { NgbModalModule, NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard } from './auth/guards/auth.guard';
import { FootBarComponent } from '././main/foot-bar/foot-bar.component';
import { HeadBarComponent } from './main/head-bar/head-bar.component';
import { SideBarComponent } from './main/side-bar/side-bar.component';
import { TopBarComponent } from './main/top-bar/top-bar.component';
import { DashboardThemeComponent } from '../dashboard-theme/dashboard-theme.component';
import { MyEventRegistrationModule } from './main/my-event-registration/my-event-registration.module';
import { HomeModule } from './main/home/home.module';
import { MyDonationModule } from './main/my-donation/my-donation.module';
import { MySubscriptionModule } from './main/my-subscription/my-subscription.module';
import { MySettingsModule } from './main/my-settings/my-settings.module';
import { DonationModule } from './main/donation/donation.module';
import { SubscriptionModule } from './main/subscription/subscription.module';
import { EventregistrationModule } from './main/eventregistration/eventregistration.module';
import { LoginComponent } from './auth/login/login.component';
import { LoginModule } from './auth/login/login.module';
import { CancelEventRegistrationModule } from './main/cancel-event-registration/cancel-event-registration.module'
import { RegisterComponent } from './auth/register/register.component';
import { RegisterModule } from './auth/register/register.module';
import { CancelSubscriptionModule } from './main/cancel-subscription/cancel-subscription.module'
import { ContactUsPopupComponent } from './main/contact-us-popup/contact-us-popup.component';
import { ContactUsPopupModule } from './main/contact-us-popup/contact-us-popup.module'
import { AlertPopupComponent } from './main/alert-popup/alert-popup.component';
import { VideoPopupComponent } from './main/video-popup/video-popup.component';
import { VideoPopupModule } from './main/video-popup/video-popup.module';
import { EventListModule } from './main/event-list/event-list.module';
import { SuccessPopupComponent } from './main/success-popup/success-popup.component';
import { AddMemberComponent } from './main/add-member/add-member.component';
import { EditContactInfoComponent } from './main/edit-contact-info/edit-contact-info.component';
import { ChangePasswordModule } from './main/change-password/change-password.module';
import { EditContactInfoModule } from './main/edit-contact-info/edit-contact-info.module';
// import { TestimonyComponent } from './main/testimony/testimony.component';
// import { PrayerRequestComponent } from './main/prayer-request/prayer-request.component';
import { EditShippingAddressComponent } from './main/edit-shipping-address/edit-shipping-address.component';
import { EditBillingAddressComponent } from './main/edit-billing-address/edit-billing-address.component';
import { AddMemberModule } from "./main/add-member/add-member.module"
import { EditBillingAddressModule } from './main/edit-billing-address/edit-billing-address.module';
import { EditShippingAddressModule } from './main/edit-shipping-address/edit-shipping-address.module';

// import { PrayerRequestModule } from './main/prayer-request/prayer-request.module';
import { UpdateReccuringDonationModule } from './main/update-reccuring-donation/update-reccuring-donation.module';
// import { TestimonyModule } from './main/testimony/testimony.module';
import { AddAddressComponent } from './main/add-address/add-address.component';
import { AddAddressModule } from './main/add-address/add-address.module';
import { SavedAccountsModule } from './main/saved-accounts/saved-accounts.module';
import { SavedAccountsComponent } from './main/saved-accounts/saved-accounts.component';
import { AlertPopupModule } from './main/alert-popup/alert-popup.module';
import { SuccessPopupModule } from './main/success-popup/success-popup.module';
import { SiderBarMemberComponent } from './main/sider-bar-member/sider-bar-member.component';
import { HomeMemberModule } from './main/home-member/home-member.module';
import { FamilyMemberModule } from './main/family-member/family-member.module';
import { TopBarMemberComponent } from './main/top-bar-member/top-bar-member.component';
import { ForgotpasswordModule } from './auth/forgotpassword/forgotpassword.module';
import { ForgotpasswordComponent } from './auth/forgotpassword/forgotpassword.component';
import { ResetpasswordModule } from './auth/resetpassword/resetpassword.module';
import { ResetpasswordComponent } from './auth/resetpassword/resetpassword.component';
import { AlleventlistModule } from './main/alleventlist/alleventlist.module';
import { DonationcauselistModule } from './main/donationcauselist/donationcauselist.module';
import { AllvideolistModule } from './main/allvideolist/allvideolist.module';
import { MakeeventregpaymentModule } from './main/makeeventregpayment/makeeventregpayment.module';
import { RecentnewslistModule } from './main/recentnewslist/recentnewslist.module';
import { AttachmentdetailsComponent } from './main/attachmentdetails/attachmentdetails.component';
import { AttachmentdetailsModule } from './main/attachmentdetails/attachmentdetails.module';
import { DonationPaymentModule } from './main/donation-payment/donation-payment.module';
import { EventregistrationPaymentModule } from './main/eventregistration-payment/eventregistration-payment.module';
import { SubscriptionPaymentModule} from './main/subscription-payment/subscription-payment.module';
import { EventParticipantlistModule } from './main/event-participantlist/event-participantlist.module';
import { EventParticipantlistComponent } from './main/event-participantlist/event-participantlist.component';
import { EventParticipantdetailModule } from './main/event-participantdetail/event-participantdetail.module';
import { EventParticipantdetailComponent } from './main/event-participantdetail/event-participantdetail.component'; 
import { MemberLoginComponent } from './auth/member-login/member-login.component';
import { MemberRegisterComponent } from './auth/member-register/member-register.component';
import { MemberLoginModule } from './auth/member-login/member-login.module';
import { MemberRegisterModule } from './auth/member-register/member-register.module';
import { MemberForgotpasswordModule } from './auth/member-forgotpassword/member-forgotpassword.module';
import { MemberForgotpasswordComponent } from './auth/member-forgotpassword/member-forgotpassword.component';
import { MemberResetpasswordComponent } from './auth/member-resetpassword/member-resetpassword.component';
import { MemberResetpasswordModule }  from './auth/member-resetpassword/member-resetpassword.module';
import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';
import { PrivacyPolicyModule } from './main/privacy-policy/privacy-policy.module';
import { TermsConditionModule } from "./main/terms-condition/terms-condition.module";
import { PrivacyPolicyComponent } from "./main/privacy-policy/privacy-policy.component";
import { TermsConditionComponent } from "./main/terms-condition/terms-condition.component";
import { HelpComponent } from './main/help/help.component';
import { HelpModule } from './main/help/help.module'
import { MakesubpaymentModule } from './main/makesubpayment/makesubpayment.module';
const routes: Routes = [
  {
    "path": "memberDashboard",
    "component": DashboardThemeComponent,
    "canActivate": [AuthGuard],
    "children": [
      {
        "path": "myeventregistration\/:memberId",
        "loadChildren": "./main/my-event-registration/my-event-registration.module#MyEventRegistrationModule",
      },
      {
        "path": "myeventregistration\/:memberId\/cancel-registration\/:regId\/:paymentName",
        "loadChildren": ".\/main\/cancel-event-registration\/cancel-event-registration.module#CancelEventRegistrationModule",
      },
      {
        "path": "myeventregistration\/:memberId\/cancel-registration\/:regId",
        "loadChildren": ".\/main\/cancel-event-registration\/cancel-event-registration.module#CancelEventRegistrationModule",
      },
      {
        "path": "home\/:memberId",
        // "loadChildren": ".\/main\/home\/home.module#HomeModule",
        "loadChildren": ".\/main\/home-member\/home-member.module#HomeMemberModule",
      },
      {
        "path": "mydonation\/:memberId",
        "loadChildren": ".\/main\/my-donation\/my-donation.module#MyDonationModule",
      },
      {
        "path": "mysubscription\/:memberId",
        "loadChildren": ".\/main\/my-subscription\/my-subscription.module#MySubscriptionModule",
      },
      {
        "path": "settings\/:memberId",
        "loadChildren": ".\/main\/my-settings\/my-settings.module#MySettingsModule",
      },
      {
        "path": "mydonation\/:memberId\/donation",
        // "loadChildren": ".\/main\/donation\/donation.module#DonationModule",
        "loadChildren": ".\/main\/donation-payment\/donation-payment.module#DonationPaymentModule"
      },
      {
        "path": "mysubscription\/:memberId\/subscription",
        // "loadChildren": ".\/main\/subscription\/subscription.module#SubscriptionModule",
        "loadChildren":".\/main\/subscription-payment\/subscription-payment.module#SubscriptionPaymentModule"
      },
      {
        "path": "mysubscription\/:memberId\/cancel-subscription\/:subId\/:paymentName",
        "loadChildren": ".\/main\/cancel-subscription\/cancel-subscription.module#CancelSubscriptionModule",
      },
      {
        "path": "mysubscription\/:memberId\/cancel-subscription\/:subId",
        "loadChildren": ".\/main\/cancel-subscription\/cancel-subscription.module#CancelSubscriptionModule",
      },
      {
        "path": "myeventregistration\/:memberId\/eventregistration",
        // "loadChildren": ".\/main\/eventregistration\/eventregistration.module#EventregistrationModule",
        "loadChildren":".\/main\/eventregistration-payment\/eventregistration-payment.module#EventregistrationPaymentModule"
      },
      {

        "path": "settings\/:memberId\/update-recurring-donation\/:donationId",
        "loadChildren": ".\/main\/update-reccuring-donation\/update-reccuring-donation.module#UpdateReccuringDonationModule",

      },
      {
        "path": ":memberId\/familyMemberList",
        "loadChildren": ".\/main\/family-member\/family-member.module#FamilyMemberModule"
      },
      {
        "path": ":memberId\/eventList",
        "loadChildren": ".\/main\/alleventlist\/alleventlist.module#AlleventlistModule"
      },
      {
        "path": ":memberId\/donationCauseList",
        "loadChildren": ".\/main\/donationcauselist\/donationcauselist.module#DonationcauselistModule"
      },

      {
        "path": ":memberId\/videoList",
        "loadChildren": ".\/main\/allvideolist\/allvideolist.module#AllvideolistModule"
      },
      {
        "path": "myeventregistration\/:memberId\/make-eventpayment\/:regId",
        "loadChildren": ".\/main\/makeeventregpayment\/makeeventregpayment.module#MakeeventregpaymentModule",
      },
      {
        "path": "mysubscription\/:memberId\/make-subpayment\/:subId",
        "loadChildren": ".\/main\/makesubpayment\/makesubpayment.module#MakesubpaymentModule",
      },
      {
        "path": ":memberId\/recentNewsList",
        "loadChildren": ".\/main\/recentnewslist\/recentnewslist.module#RecentnewslistModule"
      },
     

    ]
  },
  // {
  //   "path": "memberlogin",
  //   "component": LoginComponent
  // },
  {
    "path": "verify-email\/:token",
    "component": VerifyEmailComponent
  },
  {
    "path": "memberregister",
    "component": RegisterComponent
  },
  {
    "path": "memberforgotpassword",
    "component": ForgotpasswordComponent
  },
  {
    "path": "member/reset-password",
    // "component": ResetpasswordComponent
    "component": MemberResetpasswordComponent
  },
  {
    "path": "member-login",
    "component": MemberLoginComponent
  },
  {
    "path": "member-register",
    "component": MemberRegisterComponent
  },
  {
    "path": "member-forgotpassword",
    "component": MemberForgotpasswordComponent
  },
  {
    "path":"privacy-policy",
    "component": PrivacyPolicyComponent
  },
  {
    "path":"terms-and-conditions",
    "component":TermsConditionComponent
  },
  {
    "path":"help",
    "component":HelpComponent
  },
  {
    "path": "**",
    "redirectTo": "member-login",
    "pathMatch": "full"
  }

];
const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'top',
  // scrollPositionRestoration: 'disabled',
  // In order to get anchor / fragment scrolling to work at all, we need to
  // enable it on the router.
  anchorScrolling: 'enabled',
  onSameUrlNavigation: "reload",
  enableTracing: true,
};

@NgModule({
  declarations: [
    FootBarComponent,
    HeadBarComponent,
    SideBarComponent,
    TopBarComponent,
    SiderBarMemberComponent,
    TopBarMemberComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, routerOptions),
    BrowserModule,
    // NgbModule.forRoot(),
    MyEventRegistrationModule,
    HomeModule,
    MyDonationModule,
    MySubscriptionModule,
    MySettingsModule,
    DonationModule,
    SubscriptionModule,
    EventregistrationModule,
    ContactUsPopupModule,
    CancelEventRegistrationModule,
    CancelSubscriptionModule,
    VideoPopupModule,
    EventListModule,
    ChangePasswordModule,
    AddMemberModule,
    // RegisterModule
    EditContactInfoModule,
    EditBillingAddressModule,
    EditShippingAddressModule,
    // PrayerRequestModule,
    UpdateReccuringDonationModule,
    // TestimonyModule
    AddAddressModule,
    SavedAccountsModule,
    AlertPopupModule,
    SuccessPopupModule,
    LoginModule,
    RegisterModule,
    HomeMemberModule,
    FamilyMemberModule,
    ForgotpasswordModule,
    ResetpasswordModule,
    AlleventlistModule,
    DonationcauselistModule,
    AllvideolistModule,
    MakeeventregpaymentModule,
    MakesubpaymentModule,
    RecentnewslistModule,
    AttachmentdetailsModule,
    DonationPaymentModule,
    EventregistrationPaymentModule,
    SubscriptionPaymentModule,
    EventParticipantlistModule,
    EventParticipantdetailModule,
    MemberLoginModule,
    MemberRegisterModule,
    MemberForgotpasswordModule,
    MemberResetpasswordModule,
    PrivacyPolicyModule,
    TermsConditionModule,
    HelpModule
  ],
  exports: [
    FootBarComponent,
    HeadBarComponent,
    SideBarComponent,
    TopBarComponent,
    SiderBarMemberComponent,
    TopBarMemberComponent
  ],
  entryComponents: [
    ContactUsPopupComponent,
    AlertPopupComponent,
    VideoPopupComponent,
    SuccessPopupComponent,
    AddMemberComponent,
    EditBillingAddressComponent,
    EditContactInfoComponent,
    // TestimonyComponent,
    // PrayerRequestComponent,
    EditShippingAddressComponent,
    AddAddressComponent,
    SavedAccountsComponent,
    AttachmentdetailsComponent,
    EventParticipantlistComponent,
    EventParticipantdetailComponent

  ]
})
export class DashboardThemeRoutingModule { }
