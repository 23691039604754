import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonCrudService } from 'src/app/_services/common-crud.service';
import { SharedService } from 'src/app/_services/shared.service';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { data } from 'jquery';


@Component({
	selector: 'app-cancel-event-registration',
	templateUrl: './cancel-event-registration.component.html',
	styleUrls: ['../../../../../assets/member/css/style.css', './cancel-event-registration.component.scss']
})
export class CancelEventRegistrationComponent implements OnInit {
	routeParam: any;
	eventData: any;
	eventId: any;
	postDataJsonPrepare: {};
	arrayPar: {};
	paymentMethod
	cancelSubscriptionForm: any;
	selectRefundType: boolean = true;
	showRefundOption: boolean = true;
	netPaidAmount: any;
	cancelReg = 0;
	cancelParticipentId: any;
	ParticipantDetails: any;
	selectAllPart: number = 2;
	response: { title: any; message: any; };
	memberId: string;
	responseCancel: { location: string; message: string; };
	isLoading: boolean = false;
	errorForSelectPar: string;
	eventStartDate: any;
	startDay: any;
	startMonth: any;
	startYear: any;
	eventEndDate: any;
	endDay: any;
	endMonth: any;
	endYear: any;
	result: any = [];
	price: any = "0.00";
	participantId: any;
	selectData: any;
	

	constructor(public sharedService: SharedService, public activeRoute: ActivatedRoute, public _crud: CommonCrudService, public router: Router,
		public modalService: NgbModal, private cdRef: ChangeDetectorRef, public formBuilder: FormBuilder) {
		this.formInit();
	}

	ngOnInit(): void {

		this.sharedService.fetchPathData('cancel', 'cancel-event');
		this.routeParam = this.activeRoute.snapshot.params;
		console.log("params-----", this.routeParam);
		this.eventId = this.routeParam.regId;
		this.memberId = this.routeParam.memberId;
		this.paymentMethod = this.routeParam.paymentName;
		console.log("payment", this.paymentMethod);
		// this.sharedService.fetchPathData('cancel','cancel-subscription');
		let params = {
			url: "frontend-site/member-event/get-cancel-relevant-data/" + this.eventId,
		}
		this._crud.getData(params).subscribe(res => {
			this.eventData = res['data'];
			this.eventStartDate = this.eventData.EventStartAt.split("-")
			this.startDay = this.eventStartDate[1];
			this.startMonth = this.eventStartDate[0];
			this.startYear = this.eventStartDate[2];
			this.eventEndDate = this.eventData.EventCloseAt.split("-")
			this.endDay = this.eventEndDate[1];
			this.endMonth = this.eventEndDate[0];
			this.endYear = this.eventEndDate[2];
			// console.log("this.eventData",this.eventData);
			// this.cancelParticipentId = this.activeRoute.snapshot.queryParamMap.get("id");
			this.setParticipantDetails();
			
			if (this.eventData.RefundedAmount == 0) {
				this.showRefundOption = false;
				// this.cancelSubscriptionForm.controls.RefundType.setValue(0);
				this.netPaidAmount = '0.00';
			}
		});
		this.formInit();
	}
	setParticipantDetails() {
		let ParticipantDetails = <FormArray>this.cancelSubscriptionForm.get('ParticipantDetails');
		for (let option of this.eventData.ParticipantDetails) {
			let identifier = false
			console.log(option,this.eventData,'option======>>')
			ParticipantDetails.push(this.formBuilder.group({
				Name: option.Name,
				registration_fee: option.registration_fee,
				status: option.status,
				id: option.id,
				identifier: identifier,
				stream: option.EventStreamId,
			}))
		}
	}
	formInit() {
		this.cancelSubscriptionForm = this.formBuilder.group({
			CancelRegistration: [this.cancelReg],
            NetAmountToPay: ['', Validators.compose([Validators.required])],
            NetPaidAmount: ['', Validators.compose([Validators.required])],
            RefundedAmount: [0, Validators.compose([Validators.required])],
            DonatedAmount: [0, Validators.compose([Validators.required])],
            RefundToAccount: [1],
            RefundAccount: [0],
			RefundType: ["2", Validators.compose([Validators.required])],
			PaymentMethods: [''],
			ParticipantDetails: this.formBuilder.array([]),

		});
	}
	RequestPostParams() {
		this.postDataJsonPrepare = {};
		this.postDataJsonPrepare['PaymentMethodId'] = this.cancelSubscriptionForm.get('PaymentMethods').value;
		this.postDataJsonPrepare['TypeOfCancellation'] = this.cancelSubscriptionForm.get('CancelRegistration').value == 0 ? 1 : 2;
		// this.postDataJsonPrepare['RefundType'] = parseInt(this.cancelSubscriptionForm.get('RefundType').value);
		this.postDataJsonPrepare['RefundType'] = this.cancelSubscriptionForm.get('RefundAccount').value;
		//proces refund 
		if (this.cancelSubscriptionForm.get('RefundType').value == 1) {
			this.postDataJsonPrepare['RefundProcessType'] = (this.eventData?.IsSingleElectronicTransaction != 0) ? 1 : 2;
		}
		this.postDataJsonPrepare['TypeOfCancellation'] = this.selectAllPart;

		this.postDataJsonPrepare['CancelledParticipantData'] = [];
		for (let option of this.cancelSubscriptionForm.value.ParticipantDetails) {
			if (option.identifier) {
				this.postDataJsonPrepare['CancelledParticipantData'].push({
					Id: option.id
				})
			}
		}

		this.postDataJsonPrepare['RefundAmount'] = this.netPaidAmount
		this.postDataJsonPrepare['CancelledAmount'] = this.netPaidAmount

		// if (this.cancelSubscriptionForm.get('RefundType').value == 2) {
		// 	this.postDataJsonPrepare['RefundAmount'] = this.netPaidAmount
		// }
		if (this.cancelSubscriptionForm.get('RefundAccount').value == 2) {
            this.postDataJsonPrepare['RefundAmount'] = this.cancelSubscriptionForm.get('DonatedAmount').value;
        }

        if (this.cancelSubscriptionForm.get('RefundAccount').value == 1) {
            this.postDataJsonPrepare['RefundProcessType'] = this.cancelSubscriptionForm.get('RefundToAccount').value == 0 ? 1 : 2;
            this.postDataJsonPrepare['PaymentProcessParams'] = {};
            this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields'] = {};
        }

		if (this.cancelSubscriptionForm.get('RefundType').value == 1) {
			// this.postDataJsonPrepare['RefundProcessType'] = 1;
			this.postDataJsonPrepare['PaymentProcessParams'] = {};
			this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields'] = {};
		}
		return this.postDataJsonPrepare;
	}

	cancel() {
		this.arrayPar = {}
		this.arrayPar['CancelledParticipantData'] = []


		// console.log(this.RequestPostParams());
		for (let option of this.cancelSubscriptionForm.value.ParticipantDetails) {
			if (option.identifier) {
				this.arrayPar['CancelledParticipantData'].push({
					Id: option.id
				})
			}
		}
		if ((this.selectAllPart == 1 || this.arrayPar['CancelledParticipantData'].length > 0) && this.cancelSubscriptionForm.controls['RefundType'].value != null) {
			console.log("api", this.cancelSubscriptionForm.controls['RefundType'].value);
			this.errorForSelectPar = ""

			this.isLoading = true;
			this.responseCancel = { location: "Event-cancel", message: "Are you sure you want to cancel?" }
			const modalRef = this.modalService.open(AlertPopupComponent, {
				size: "md",
				backdrop: 'static', // disable modal from closing on click outside
				keyboard: false, // disable modal closing by keyboard esc
			}
			);
			modalRef.componentInstance.data = this.responseCancel;
			modalRef.result.then(
				result => {
					// console.log("result-----modal",result)
					if (result.data) {
						//calling cancel api
						let params = {};
						params['url'] = 'frontend-site/member-event/cancel/' + this.eventId
						params['params'] = this.RequestPostParams();
						this._crud.postData(params).subscribe(

							data => {
								this.isLoading = false;
								// console.log(data);
								this.successPopup(data);

							});
					} else {
						this.isLoading = false;
					}
				});
		} else {
			// console.log("error message")
			if (this.selectAllPart == 1 || this.arrayPar['CancelledParticipantData'].length > 0) {
				this.errorForSelectPar = ""

			} else {
				this.errorForSelectPar = "Select atleast one participant"

			}
			if (this.cancelSubscriptionForm.controls['RefundType'].value == null) {
				this.selectRefundType = false
			} else {
				this.selectRefundType = true

			}
		}

	}
	changeRefundType() {
		this.selectRefundType = true;
	}
	selectPart(ParticipantDetails: any) {
		console.log('in select', ParticipantDetails,this.result)
		this.errorForSelectPar = ""
		// console.log("event:",data,amt,this.cancelSubscriptionForm.value.ParticipantDetails);
		let amount = 0;
		this.result = [];
		for (let option of ParticipantDetails.value) {
			if(option.identifier) {
				this.result.push(
					{
						Id: option.id,
						StreamId: option.stream,
					}
				)
			}
			this.postDataCancel(this.result);
			
		}
		
		// this.netPaidAmount = amount;
	}

	ChangeAmount(id) {
        console.log('ghfghfghfhjfhjghhhhhhhhhhhhhhhhhh', this.cancelSubscriptionForm.value, id ,this.selectData);

		// this.selectData.checked = false;

        this.participantId = id

            < FormArray > this.cancelSubscriptionForm.controls.ParticipantDetails.controls;


        let array = this.cancelSubscriptionForm.controls.ParticipantDetails.value;
		let i =0;
        if (id.value.identifier) {
			
            this.result.push(
                {
                    Id: id.value.id,
                    StreamId: id.value.stream,
                }
            )
        } else {
			console.log(i,'i')
			this.RemoveElementFromObjectArray(id.value.id);
            // this.result.pop(
            //     {
            //         Id: id.value.id,
            //         StreamId: id.value.stream,
            //     }
            // )
        }

        if (this.result.length != 0) {
            console.log("test", this.result);
			if(this.selectData) {
				console.log("inside");
				this.selectData.checked = false;
			}
			
			this.postDataCancel(this.result);
            
        } else {
			console.log("test1", this.result);
			// this.result = [];
            this.netPaidAmount = "0.00"
        }
        // } 

    }

	RemoveElementFromObjectArray(key: number) {
		this.result.forEach((value,index)=>{
			if(value.Id==key) this.result.splice(index,1);
		});
	} 
	
	postDataCancel(result) {
		let data = {
			url: "frontend-site/member-event/calculate-event-participant-cancel-amount",
			params: {
				"EventRegistrationId": this.eventId,
				"ParticipantData": result

			}
		}
		console.log('result========',result);

		this._crud.postData(data).subscribe(res => {
			this.price = res['data'].TotalPrice;
			this.netPaidAmount = res['data'].TotalPrice;
			this.cancelSubscriptionForm.get('RefundedAmount').setValue(res['data'].TotalPrice);
			this.cancelSubscriptionForm.get('DonatedAmount').setValue(res['data'].TotalPrice);
			console.log("price", res);

			// if(!this.checked.identifier){
			//     this.NetPaidAmount="0.00"  
			// }

		})
	}
	selectAll(data) {
		this.selectData = data;
		this.errorForSelectPar = ""
		// console.log("data from sletal----", this.cancelSubscriptionForm.value.ParticipantDetails);
		let ParticipantDetails = <FormArray>this.cancelSubscriptionForm.get('ParticipantDetails');
		console.log("options------",data.checked)
		if (data.checked == true) {
			if(this.result.length != 0) {
				this.result = [];
			}
			this.selectAllPart = 1;

			console.log("option2------",this.cancelSubscriptionForm.value.ParticipantDetails)
			for (let option of this.eventData.ParticipantDetails) {
				console.log("option-----1-",option)
				ParticipantDetails.removeAt(option.Id);
				ParticipantDetails.push(this.formBuilder.group({
					Name: option.Name,
					registration_fee: option.registration_fee,
					status: option.status,
					id: option.id,
					identifier: option.status == 1 ? true : false,
					stream: option.EventStreamId,
				}));
			}
			
			this.selectPart(ParticipantDetails);

		}
		else{
			if(this.result.length != 0) {
				this.result = [];
			}
			console.log("option22------",this.cancelSubscriptionForm.value.ParticipantDetails, this.result)
			this.selectAllPart = 2;
			this.netPaidAmount = "0.00"
			for (let option of this.eventData.ParticipantDetails) {
				console.log("option------2",option)
				ParticipantDetails.removeAt(option.Id);
				ParticipantDetails.push(this.formBuilder.group({
					Name: option.Name,
					registration_fee: option.registration_fee,
					status: option.status,
					id: option.id,
					identifier: false,
					stream: option.EventStreamId,
				}))
			}
		}
	}
	successPopup(data) {
		this.isLoading = false;
		this.response = { title: data['status'], message: data['msg'] }

		//   this.modalService.show(SuccessPopupComponent, {initialState})
		const modalRef = this.modalService.open(SuccessPopupComponent, {
			size: "md",
		});
		modalRef.componentInstance.user = this.response;
		modalRef.result.then(
			result => {
				// console.log("result-----modal",result)
				if (result.result) {
					if (data['status'] == 'success') {
						// console.log("result-----modal",result)
						this.router.navigateByUrl('/memberDashboard/myeventregistration/' + this.memberId)

					}
					// /clearing all forms.
					// this.activeModal.close({ 'result': true });
				}
			});
	}
}
