<section class="member-wrp">
    <div class="bg-body">
        <figure class="logo-top">
            <img src="./assets/member/images/page-logo.svg" alt="Shalom">
        </figure>
        <div class="login-wrp ">
            <div class="login-form">
                <form [formGroup]="forgotPasswordForm">
                    <div class="form-wrp">
                        <h1>Forgotten Password ?</h1>
                        <p class="text-center">Enter your email to reset your password:</p>
                        <div class="form-inner">
                            <div class="form-group focus-label" id="">
                                <label class="form-label-of" for="Email">Email</label>
                                <input autocomplete="off" formControlName="Email" id="Email" type="email" class="" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Email">
                                    <span class="error-message"
                                        *ngIf="forgotPasswordForm.get('Email').hasError(validation.type) && (forgotPasswordForm.get('Email').dirty || forgotPasswordForm.get('Email')?.touched)">
                                        {{validation.message}}
                                    </span>
                                </span>
                            </div>

                            <span class="error mb-3">{{displayMsg}}</span>
                            <div class="d-flex remember-me-wrp pb-3">

                                <button type="submit" class="btn-blue-login mr-3" (click)="Submit()">Request <i *ngIf="isLoading" class="spinner-border text-primary"></i></button>
                                <button type="submit" class="btn-blue-cancel" routerLink="/member-login">Cancel</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <ul class="nav-menu-btm">
            <li><a href="javascript:void(0)">Privacy Notice</a></li>
            <li><a href="javascript:void(0)">Conditions of Use</a></li>
            <!-- <li><a href="javascript:void(0)">Help</a></li> -->
            <li><a>Help</a></li>
            <li><a href="javascript:void(0)">Learn more about when to create an account</a></li>
        </ul>
    </div>
</section>