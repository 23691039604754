<section class="member-wrp">
    <div class="bg-body">
        <div class="login-wrp ">
            <div class="login-form">
                <form [formGroup]="loginForm">
                    <div class="row form-wrp">
                        <div class="col-md-7 form-wrp-left">
                            <figure>
                                <img src="./assets/member/images/img-login-left-brown.svg" alt="Shalom">
                            </figure>
                        </div>
                        <div class="col-md-5 form-wrp-right">
                            <div class="login-head-title">
                                <h1>Hi, Welcome Back!</h1>
                                <p>Please login to your account</p>
                            </div>

                            <div class="form-inner">
                                <div class="form-group focus-label" id="userName">
                                    <label class="form-label-of" for="Email">Email</label>
                                    <input autocomplete="off" formControlName="Email" id="Email" type="email" class="" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.Email">
                                        <span class="error-message"
                                            *ngIf="loginForm.get('Email').hasError(validation.type) && (loginForm.get('Email').dirty || loginForm.get('Email')?.touched)">
                                            {{validation.message}}
                                        </span>
                                    </span>
                                </div>
                                <div class="form-group focus-label mb-1" id="passWord">
                                    <label class="form-label-of" for="Password">Password</label>
                                    <input autocomplete="off" formControlName="Password" class="pswrd-input" id="Password" [type]="password" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                    <a (click)="passwordType()" class="pswrd-img"> <img *ngIf="show" src="./assets/member/images/icon-password.svg" alt="Shalom">
                                        <img *ngIf="!show" src="./assets/member/images/icon-passwordshow.svg" alt="Shalom">
                                    </a>
                                    <!-- <input autocomplete="off" formControlName="Password" id="Password" [type]="password" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                   <a (click)="passwordType()">
                                       <i  class="" *ngIf="!show">eye</i>
                                       <i  class="pswrd-input" *ngIf="show"></i>

                                </a> -->
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.Password">
                                        <span class="error-message"
                                            *ngIf="loginForm.get('Password').hasError(validation.type) && (loginForm.get('Password').dirty || loginForm.get('Password')?.touched)">
                                            {{validation.message}}
                                        </span>
                                    </span>
                                </div>
                                <div *ngIf="errorShow" class="error-message">
                                    <span>{{errorMsg}}</span>
                                </div>

                                <div class="text-right mb-3 frgt-pswrd-link">
                                    <a class=""  (click)="forgotPassword()">Forgot Password?</a>
                                </div>
                                <div class="captcha-holder mb-3">
                                    <re-captcha formControlName="captcha" (resolved)="resolved($event)" (error)="onError($event)" errorMode="handled" required siteKey="6LeVUbkcAAAAAJhTC5Z-1Gi6purkrQ9OF09ro6YM"></re-captcha>
                                    <span class="m-form__help" *ngIf="notcaptcha">
                                    <span class="error-message">Captcha is required</span>
                                    </span>
                                </div>
                                <button type="submit" class="btn-common mb-3" (click)="loginSubmit()">Sign In <i *ngIf="isloading" class="spinner-border"></i> </button>
                                <div class="text-center mb-3 create-link">
                                    <p class="">Not registered yet? <a href="/member-register">Create an account</a></p>
                                </div>
                                <!-- <button type="submit" class="btn-border" routerLink="/member-register">Create an account</button> -->
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
        <ul class="nav-menu-btm">
            <li><a href="/privacy-policy">Privacy Notice</a></li>
            <!-- <li><a href="/help">Help</a></li> -->
            <li><a>Help</a></li>
            <li><a href="/terms-and-conditions">Conditions of Use</a></li>
        </ul>
    </div>
</section>