<section class="member-wrp">
    <div class="bg-body">
        <figure class="logo-top">
            <img src="./assets/member/images/page-logo.svg" alt="Shalom">
        </figure>
        <div class="login-wrp register-wrp">
            <div class="login-form ">
                <form [formGroup]="registerForm">

                    <div class="form-wrp">
                        <h1>Create Your MyShalom Account</h1>
                        <div class="row form-inner">
                            <div class="col-md-6">
                                <div class="form-group focus-label">
                                    <label class="form-label-of" for="fname">First Name</label>
                                    <input autocomplete="off" formControlName="Fname" id="fname" type="text" class="" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="firstNameFetch($event.target.value)">
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.Fname">
                                        <span class="error-message"
                                            *ngIf="registerForm.get('Fname').hasError(validation.type) && (registerForm.get('Fname').dirty || registerForm.get('Fname')?.touched)">
                                            {{validation.message}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group focus-label">
                                    <label class="form-label-of" for="lname">Last Name</label>
                                    <input autocomplete="off" formControlName="Lname" id="lname" type="text" class="" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="lastNameFetch($event.target.value)">
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.Lname">
                                        <span class="error-message"
                                            *ngIf="registerForm.get('Lname').hasError(validation.type) && (registerForm.get('Lname').dirty || registerForm.get('Lname')?.touched)">
                                            {{validation.message}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group focus-label">
                                    <!-- <label class="form-label-of" for="country">Country</label>
                                    <input autocomplete="off" formControlName="Country" id="country" type="text" class="" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                    <ng-select formControlName="CountryId" id="country" [(ngModel)]="countryId" [clearable]="false" placeholder="Country" required (change)="onCountryChange($event.Id)" bindValue="Id" bindLabel="Name" [items]="CountryList" autocomplete="autocomplete_off_hack_xfr4!k"></ng-select>
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.CountryId">
                                        <span class="error-message"
                                            *ngIf="registerForm.get('CountryId').hasError(validation.type) && (registerForm.get('CountryId').dirty || registerForm.get('CountryId')?.touched)">
                                            {{validation.message}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group focus-label">
                                    <label class="form-label-of" for="mobnum">Mobile Number</label>
                                    <input autocomplete="off" formControlName="Mobile" id="mobnum" type="tel" class="" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.Mobile">
                                        <span class="error-message"
                                            *ngIf="registerForm.get('Mobile').hasError(validation.type) && (registerForm.get('Mobile').dirty || registerForm.get('Mobile')?.touched)">
                                            {{validation.message}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group focus-label">
                                    <label class="form-label-of" for="email">Email</label>
                                    <input autocomplete="off" formControlName="Email" id="email" type="email" class="" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="fetchEmail($event.target.value)" maxlength="150">
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.Email">
                                        <span class="error-message"
                                            *ngIf="registerForm.get('Email').hasError(validation.type) && (registerForm.get('Email').dirty || registerForm.get('Email')?.touched)">
                                            {{validation.message}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group focus-label">
                                    <label class="form-label-of" for="cemail">Confirm Email</label>
                                    <input autocomplete="off" formControlName="ConfirmEmail" id="cemail" name="cemail" type="email" class="drop" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="fetchConfirmEmail($event.target.value)" maxlength="150">
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.ConfirmEmail">
                                        <span class="error-message"
                                            *ngIf="registerForm.get('ConfirmEmail').hasError(validation.type) && (registerForm.get('ConfirmEmail').dirty || registerForm.get('ConfirmEmail')?.touched) || registerForm.get('ConfirmEmail').hasError('passwordMismatch')">
                                            {{validation.message}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group focus-label dropdown">
                                    <label class="form-label-of" for="password">Password</label>
                                    <input autocomplete="off" formControlName="Password" id="password" type="password" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="passwordFetch($event.target.value)">
                                    <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end">
                                        <ul>
                                            <li [ngClass]="{'pwd_class': totalChar}"><span>&#10003;</span> 8 or more characters</li>
                                            <li [ngClass]="{'pwd_class': atleaatAlpha}"><span> &#10003;</span> Upper & Lowercase letters</li>
                                            <li [ngClass]="{'pwd_class': atleastDigit}"> <span>&#10003;</span> Atleast one number</li>
                                        </ul>
                                    </div>
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.Password">
                                        <span class="error-message"
                                            *ngIf="registerForm.get('Password').hasError(validation.type) && (registerForm.get('Password').dirty || registerForm.get('Password')?.touched)">
                                            {{validation.message}}
                                        </span>
                                    </span> 
                                </div>

                            </div>
                            <div class="col-md-6">
                                <div class="form-group focus-label">
                                    <label class="form-label-of" for="cpassword">Confirm Password</label>
                                    <input autocomplete="off" formControlName="ConfirmPassword" id="password" type="password" class="" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="checkPassword($event.target.value)">
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.ConfirmPassword">
                                        <span class="error-message"
                                            *ngIf="registerForm.get('ConfirmPassword').hasError(validation.type) && (registerForm.get('ConfirmPassword').dirty || registerForm.get('ConfirmPassword')?.touched) || registerForm.get('ConfirmPassword').hasError('passwordMismatch')">
                                            {{validation.message}}
                                        </span>
                                    <!-- <span class="error-message"
                                        *ngIf="registerForm.get('ConfirmPassword').hasError('passwordMismatch') && registerForm.get('ConfirmPassword').touched">
                                            {{validation.message}}
                                        </span> -->
                                    <!-- <p class="help-block" *ngIf="registerForm.get('ConfirmPassword').hasError('passwordMismatch') && registerForm.get('ConfirmPassword').touched">password not match</p> -->
                                    </span>
                                </div>
                            </div>
                            <div *ngIf="errorShow">
                                <!-- <span class="error-message">{{errorMsg}}</span> -->
                            </div>
                            <div class="col-12 mt-3 mb-3 captcha-wrp">
                                <div class="row ">
                                    <div class="col-md-4 col-each captcha-col">
                                        <div class="captcha-code">
                                            <re-captcha (resolved)="resolved($event)" (error)="onError($event)" errorMode="handled" siteKey="6LeVUbkcAAAAAJhTC5Z-1Gi6purkrQ9OF09ro6YM"></re-captcha>
                                            <span class="m-form__help" *ngIf="captcha">
                                            <span class="error-message">
                                                please check
                                            </span></span>
                                        </div>
                                    </div>

                                    <div class="col-sm-5 col-each text-center mt-3 link-center">
                                        <p>Already a member? <a routerLink="/member-login" class="underline-txt">Login</a></p>
                                    </div>
                                    <div class="col-sm-3 col-each text-right">
                                        <button type="submit" class="btn-blue-login" (click)="submit()">SIGN UP <i *ngIf="isloading" class="spinner-border text-primary"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <p class="note-text">*Once you’ve registered you will receive an email confirmation. Please click on the link provided in the email to activate your account.</p>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
        <ul class="nav-menu-btm">
            <li><a href="javascript:void(0)">Privacy Notice</a></li>
            <li><a href="javascript:void(0)">Conditions of Use</a></li>
            <!-- <li><a href="javascript:void(0)">Help</a></li> -->
            <li><a>Help</a></li>
            <li><a href="javascript:void(0)">Learn more about when to create an account</a></li>
        </ul>
    </div>
</section>