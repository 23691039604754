import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonCrudService } from 'src/app/_services/common-crud.service';
import { SharedService } from '../../../../_services/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsPopupComponent } from '../contact-us-popup/contact-us-popup.component';

@Component({
  selector: 'app-alleventlist',
  templateUrl: './alleventlist.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css', './alleventlist.component.scss']
})
export class AlleventlistComponent implements OnInit {
  routeParam: any;
  memberId: any;
  listOfEvents: any;
  isSpfMember:boolean;
  singleDonationExist:boolean = false;

  constructor(public activeRoute: ActivatedRoute, public _crud: CommonCrudService, private router: Router, public sharedService: SharedService, public modalService: NgbModal) { }

  ngOnInit(): void {
    this.routeParam = this.activeRoute.snapshot;
    this.memberId = this.routeParam.params['memberId'];
    this.sharedService.fetchPathData('dashboard', 'eventlist');
    this.eventList();
    this.isSpfMember = (localStorage.getItem('IsSpfMember')=='true'?true:false);
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
	  this.singleDonationExist = currentUser.singleDonationExist;
  }
  eventList(data = null) {
    let params = {
      url: "frontend-site/event/list", params: { MemberId: this.memberId, EventTypeId: data == null ? '' : data }
    }
    this._crud.getData(params).subscribe(res => {

      // console.log("event list----", res);
      this.listOfEvents = res['data'];
      console.log(this.listOfEvents,'events')
      this.listOfEvents.forEach(element => {
        let eventStartDate = this._crud.spaceSplit(new Date(element.EventStartDate).toDateString());
        element.startDay = eventStartDate[2];
        element.startMonth = eventStartDate[1];
        element.startYear = eventStartDate[3];
        let eventEndDate = this._crud.spaceSplit(new Date(element.EventEndDate).toDateString());
        element.endDay = eventEndDate[2];
        element.endMonth = eventEndDate[1];
        element.endYear = eventEndDate[3];
      });
    });
  }
  registerEvent(eventDate, eventType, eventName) {
    this.router.navigate(["/memberDashboard/myeventregistration/" + this.memberId + "/eventregistration"], { queryParams: { eventDate: eventDate, eventType: eventType, eventName: eventName } });
  }
  modalOpen(type) {

    // console.log("inside modl open")
    const modalRef = this.modalService.open(ContactUsPopupComponent, {
      size: "lg"
    });
    modalRef.componentInstance.memberId = this.memberId;
    modalRef.componentInstance.type = type;
  }
  downloadFlyer(link) {
    if (link != null) {
      var win = window.open(link);
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        // alert('Please allow popups for this website');
      }
    }

  }
}
