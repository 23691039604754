import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SharedService } from '../../../../_services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventListComponent } from '../event-list/event-list.component';
import * as $ from 'jquery';
import { AttachmentdetailsComponent } from '../attachmentdetails/attachmentdetails.component';
import { DatePipe } from '@angular/common';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';

@Component({
	selector: 'app-my-event-registration',
	templateUrl: './my-event-registration.component.html',
	styleUrls: ['../../../../../assets/member/css/style.css', './my-event-registration.component.scss'],
	providers: [DatePipe]
})
export class MyEventRegistrationComponent implements OnInit, AfterViewInit {
	displayedColumns: string[] = ['PrimaryCountryTime', 'EventType', 'EventLocation', 'ParticipantNames', 'RegistrationFee', 'PaidAmount', 'PendingAmount',
		'PaymentMethodName', 'RegistrationStatusText'];
	dataSource = new MatTableDataSource();
	eventRegistrationdata;
	routeParam;
	paymentMethod: any
	memberId;
	newDate: any;
	eventList = [];
	@ViewChild(MatPaginator) paginator: MatPaginator;
	addRegdisabled: boolean = false;
	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
	}

	constructor(public _crud: CommonCrudService, public sharedService: SharedService, public activeRoute: ActivatedRoute, private router: Router,
		private modalService: NgbModal,
		private datePipe: DatePipe) { }

	ngOnInit(): void {
		this.routeParam = this.activeRoute.snapshot.params;
		this.newDate = new Date();
		this.newDate = this.datePipe.transform(this.newDate, 'yyyy/MM/dd');
		console.log("params-----", this.newDate, this.routeParam);
		this.memberId = this.routeParam.memberId;
		this.sharedService.fetchPathData('dashboard', 'myeventregistration');
		this.sharedService.changeSidebarView(true);
		let params = {
			url: "frontend-site/event-register/list", params: { MemberId: this.memberId }
		}
		this._crud.getData(params).subscribe(res => {
			this.eventRegistrationdata = res['data'];
			// console.log("eventRegistrationdata data----",this.eventRegistrationdata);
			// const ELEMENT_DATA: PeriodicElement[] = this.eventRegistrationdata
			this.dataSource = new MatTableDataSource(this.eventRegistrationdata);
			this.dataSource.paginator = this.paginator;
		});
		$('.status-icon').on('click', function () {
			alert();
		});
	}
	selectEvents() {
		this.addRegdisabled = true
		let params = {
			url: "frontend-site/event/list", params: { MemberId: this.memberId }
		}
		this._crud.getData(params).subscribe(res => {

			console.log("event list----", res['data']);
			this.eventList = [];
			res['data'].forEach((element, i) => {
				if (element.IsEventClosed == 0 && element.IsMaxEventRegisterExceed == 0) {
					this.eventList.push(element)
				}
			});
			const modalRef = this.modalService.open(EventListComponent, {
				size: "lg",
				backdrop: 'static', // disable modal from closing on click outside
				keyboard: false, // disable modal closing by keyboard esc
			});
			modalRef.componentInstance.title = "Select Event to Register";
			modalRef.componentInstance.dataList = this.eventList;
			modalRef.componentInstance.memberId = this.memberId;
			modalRef.result.then(
				result => {
					console.log("result-----modal", result.result)
					if (result.result) {
						this.addRegdisabled = false

					}
				});

		});

	}
	downloadFlyer(link) {
		console.log("sadasdasd");
		if (link != null) {
			var win = window.open(link);
			if (win) {
				//Browser has allowed it to be opened
				win.focus();
			} else {
				//Browser has blocked it
				// alert('Please allow popups for this website');
			}
		}

	}
	attachmentDetailsPopup(data) {
		const modalRef = this.modalService.open(AttachmentdetailsComponent, { size: "lg" });
		// modalRef.componentInstance.title = "Select Event to Register";
		modalRef.componentInstance.dataOfAttachment = data;
		// modalRef.componentInstance.memberId = this.memberId;


	}
	payment(data) {
		console.log("datda", this.datePipe.transform(new Date(data.RegistrationEndDate), 'yyyy/MM/dd'));
		this.paymentMethod = data.PaymentMethodName;
		// let date = '2023/07/13'
		if (this.datePipe.transform(new Date(data.RegistrationEndDate), 'yyyy/MM/dd') >= this.newDate) {
			console.log('in if')
			this.router.navigateByUrl(`/memberDashboard/myeventregistration/${this.memberId}/cancel-registration/${data.RecordID}/${data.PaymentMethodUniqueName}`);
		}
		else {
			console.log('in else')
			this.successPopup();
		}


	}

	successPopup() {
		console.log('in popup')
		let response = { title: '', message: 'Registration is closed for this event. Please contact Shalom office for further assistance.' }


		const modalRef = this.modalService.open(SuccessPopupComponent, { size: "md" });
		modalRef.componentInstance.user = response;
		modalRef.result.then(
			result => {
				//   console.log("result-----modal",result)
				if (result.result == "success") {
					//redirecting to eventlisting screen on sucees event registration
					this.router.navigateByUrl(`/memberDashboard/myeventregistration/${this.memberId}`);
				}

			});
	}
}
