<div class="contact-alert" [ngClass]="{'themePurpleSpf':isSpfMember == true, 'themeGreenNonSpf': (singleDonationExist == true && isSpfMember == false),'themeBrownSpf' : (singleDonationExist == false && isSpfMember == false)}">
    <!-- <div class="alert-top-bar">
        <div class="row align-item-center">
            <div class="col-7 col-md-7" *ngIf="type == 'contact'">
                <h5>Contact Us</h5>
            </div>
            <div class="col-7 col-md-7" *ngIf="type == 'prayer'">
                <h5>Prayer Request</h5>
            </div>
            <div class="col-7 col-md-7" *ngIf="type == 'testimony'">
                <h5>Testimony</h5>
            </div>
            <div class="col-5 col-md-5 text-right">
                <figure class="mb-0">
                    <img src="./assets/member/images/dashboard-logo.svg " alt="Shalom ">
                </figure>
                <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close">
                    <span aria-hidden="true">
                        &times;
                    </span>
                </button>
            </div>
        </div>
    </div> -->
    <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close">
        <span aria-hidden="true">
            &times;
        </span>
    </button>
    <div class="popup-inner">
        <div class="text-center pt-5 popup-title">
            <h2>Connect with Us</h2>
            <p>Together we can change the world</p>
        </div>
        <ul class="nav nav-tabs mt-5">
            <li rel="prayer" [class.active]="type == 'prayer'" (click)="tabSwitch('prayer')">Prayer Request</li>
            <li rel="testimony" [class.active]="type == 'testimony'" (click)="tabSwitch('testimony')">Testimony</li>
            <li rel="contact" [class.active]="type == 'contact'" (click)="tabSwitch('contact')">Contact Us</li>
        </ul>
        <div class="tab-content" [ngSwitch]="type">
            <div id="prayer" class="" *ngSwitchCase="'prayer'">
                <form [formGroup]="prayerRequestForm">
                    <div class="contact-form">
                        <div class="text-center  popup-title1">
                            <!-- <h2>Connect with us</h2> -->
                            <p>Worried? Stressed? Feeling helpless? No matter what you are going through, be assured that our intercessory team is here to pray for you round the clock. Your intentions will be remembered during Holy Mass, Eucharistic Adoration, Rosary, and Divine Mercy LIVE broadcasts</p>
                            <p>“Do not fear: I am with you; do not be anxious: I am your God. I will strengthen you, I will help you, I will uphold you with my victorious right hand.” (Isaiah 41:10)</p>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group focus-label">
                                    <ng-select [formControl]="prayerRequestForm.controls.member" [items]="memberList" placeholder="Member Name" bindValue="Id" bindLabel="Name" [(ngModel)]="selectedMemberId" (change)="selectedMember($event)">
                                    </ng-select>
                                </div>
                            </div>
                            <!-- <div class="col-md-6">
                            <div class="form-group focus-label">
                                <label class="form-label-of" for="">Last Name</label>
                                <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group focus-label">
                                <label class="form-label-of" for="">Email</label>
                                <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group focus-label">
                                <label class="form-label-of" for="">Select Country</label>
                                <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group focus-label">
                                <label class="form-label-of" for="">Address</label>
                                <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group focus-label">
                                <label class="form-label-of" for="">City</label>
                                <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group focus-label">
                                <label class="form-label-of" for="">Select State/Province</label>
                                <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group focus-label">
                                <label class="form-label-of" for="">Postal Code</label>
                                <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            </div>
                        </div> -->
                            <div class="col-md-12">
                                <div class="form-group focus-label">
                                    <label class="form-label-of" for="">Prayer Request</label>
                                    <textarea class="form-control" id="" type="text" formControlName="preyerRequest" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"></textarea>
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.preyerRequest">
                                    <span class="error-message"
                                        *ngIf="prayerRequestForm.get('preyerRequest').hasError(validation.type) && (prayerRequestForm.get('preyerRequest').dirty || prayerRequestForm.get('preyerRequest')?.touched)">
                                        {{validation.message}}
                                    </span>
                                    </span>
                                </div>
                            </div>
                            <!-- <div class="col-md-12">
                            <div class="form-group upload-btn-wrapper">
                                <label class="form-label-of upload-input" for="upload" *ngIf="!fileName">Upload Photo</label>
                                <label class="form-label-of upload-input" for="upload" *ngIf="fileName">{{fileName}}</label>
                                <input class="form-control" id="upload" type="file" accept="image/*" (change)="fileChange(fileInput)" formControlName="photo" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" #fileInput>
                                <input type="file" id="upload" (change)="fileChange(fileInput)" name="photo" formControlName="photo" accept="image/*" class="form-control m-input" value="" #fileInput>
                            </div>
                        </div> -->
                            <!-- <div class="col-md-12">
                            <div class="form-group  upload-btn-wrapper">
                                <label class="form-label-of upload-input" for="upload">Upload Photo</label>
                                <input class="form-control" id="upload" type="file" accept="image/*" (change)="fileChange(fileInput)" formControlName="photo" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" #fileInput>
                            </div>
                        </div> -->
                            <div class="col-md-12">
                                <div class="checkbox-wrp d-flex mr-2">
                                    <input formControlName="publishPermission" id="publish-permission" value="" type="checkbox" class="form-check-input ml-3">
                                    <label for="publish-permission" class="form-check-label">I give consent to display my request on SW PRAYER that worldwide viewers may join in prayer for my petition</label>
                                </div>
                            </div>
                            <div class="col-md-12 captcha-code my-3"></div>
                            <div class="col-md-12 mt-2 mb-3 d-flex text-center">
                                <!-- <button type="submit" class="btn-border btn-modal mr-2">Cancel</button> -->
                                <button type="submit" class="btn-submit btn-border" (click)="save('prayer')" [disabled]="buttonPrayerSubitDiable"><span class="span-loader-padding">Submit</span> <i *ngIf="isloadingprayer" class="spinner-border"></i></button>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
            <div id="testimony" class="" *ngSwitchCase="'testimony'">
                <form [formGroup]="testimonyForm">
                    <div class="contact-form">
                        <div class="text-center  popup-title1">
                            <!-- <h2>Connect with us</h2> -->
                            <p>"I will praise you to all my brothers; I will stand up before the congregation and testify of the wonderful things you have done.” (Psalm 22:22)
                            </p>
                            <p>Share testimonies of answered prayer, SW PRAYER’s impact on your life or that of a loved one. Your personal stories testify to the power of God in your life and bless all those who read them.</p>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group focus-label">
                                    <ng-select [formControl]="testimonyForm.controls.member" [items]="memberList" placeholder="Member Name" bindValue="Id" bindLabel="Name" [(ngModel)]="selectedMemberId" (change)="selectedMember($event)">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group upload-btn-wrapper">
                                    <label class="form-label-of upload-input upload-label" for="upload" *ngIf="!fileName">Photo</label>
                                    <label class="form-label-of upload-input upload-file-path" for="upload" *ngIf="fileName">{{fileName}}</label>
                                    <input type="file" id="upload" (change)="fileChange(fileInputt)" name="photo" formControlName="photo" accept="image/*" class="form-control m-input" value="" #fileInputt>
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.photo">
                                    <span class="error-message"
                                        *ngIf="testimonyForm.get('photo').hasError(validation.type) && (testimonyForm.get('photo').dirty || testimonyForm.get('photo')?.touched)">
                                        {{validation.message}}
                                    </span>
                                    </span>
                                </div>
                             
                            </div>
                            <!-- <div class="col-md-6">
                            <div class="form-group focus-label">
                                <label class="form-label-of" for="">Last Name</label>
                                <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group focus-label">
                                <label class="form-label-of" for="">Email</label>
                                <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group focus-label">
                                <label class="form-label-of" for="">Select Country</label>
                                <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group focus-label">
                                <label class="form-label-of" for="">Address</label>
                                <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group focus-label">
                                <label class="form-label-of" for="">City</label>
                                <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group focus-label">
                                <label class="form-label-of" for="">Select State/Province</label>
                                <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group focus-label">
                                <label class="form-label-of" for="">Postal Code</label>
                                <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            </div>
                        </div> -->
                            <div class="col-md-12">
                                <div class="form-group focus-label">
                                    <label class="form-label-of" for="">Testimony</label>
                                    <textarea class="form-control" id="" formControlName="testimony" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"></textarea>
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.testimony">
                                    <span class="error-message"
                                        *ngIf="testimonyForm.get('testimony').hasError(validation.type) && (testimonyForm.get('testimony').dirty || testimonyForm.get('testimony')?.touched)">
                                        {{validation.message}}
                                    </span>
                                    </span>
                                </div>
                            </div>

                            <!-- <div class="col-md-12">
                            <div class="form-group  upload-btn-wrapper">
                                <label class="form-label-of upload-input" for="upload">Upload Photo</label>
                                <input class="form-control" id="upload" type="file" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
        
                            </div>
                        </div> -->
                            <div class="col-md-12">
                                <div class="checkbox-wrp d-flex mr-2">
                                    <input formControlName="publishPermission" id="publishpermission" value="" type="checkbox" class="form-check-input ml-3">
                                    <label for="publishpermission" class="form-check-label">I give permission to publish the photo and testimony in the website</label>
                                </div>
                            </div>
                            <div class="col-md-12 captcha-code my-3"></div>
                            <div class="col-md-12 mt-2 mb-3 d-flex">
                                <!-- <button type="submit" class="btn-border btn-modal mr-2">Cancel</button> -->
                                <button type="submit" class="btn-submit btn-border" (click)="save('testimony')"  [disabled]="buttonTestimonySubitDiable"><span class="span-loader-padding">Submit</span>  <i *ngIf="isloadingtestimony" class="spinner-border"></i></button>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
            <div id="contact" class="" *ngSwitchCase="'contact'">
                <form [formGroup]="createForm">
                    <div class="contact-form">
                        <div class="text-center  popup-title1">
                            <!-- <h2>Connect with us</h2> -->
                            <p>Have a question? We are happy to provide you with more information or answer any questions you have. Please fill out the form below to send us a message and we’ll get back to you soon.</p>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group focus-label">
                                    <ng-select [formControl]="createForm.controls.Member" [items]="memberList" placeholder="Member Name" bindValue="Id" bindLabel="Name" [(ngModel)]="selectedMemberId" (change)="selectedMember($event)">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group focus-label">
                                    <ng-select id="contactReason" formControlName="contactReason" placeholder="What are you contacting us about" [items]="contactList" [(ngModel)]="selectedContactId" bindValue="Id" bindLabel="Name" (change)="selectedReason($event)">
                                        <ng-template ng-option-tmp >
                                            <span class="full-name">{{ item.Name }}</span>
                                        </ng-template>
                                    </ng-select>
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.contactReason">
                                        <span class="error-message"
                                            *ngIf="createForm.get('contactReason').hasError(validation.type) && (createForm.get('contactReason').dirty || createForm.get('contactReason')?.touched)">
                                            {{validation.message}}
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group focus-label">
                                    <label class="form-label-of" for="Message">Write your message in the box</label>
                                    <textarea class="form-control" formControlName="Message" id="message" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                    </textarea>
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.Msg">
                                        <span class="error-message"
                                            *ngIf="createForm.get('Message').hasError(validation.type) && (createForm.get('Message').dirty || createForm.get('Message')?.touched)">
                                            {{validation.message}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12 captcha">

                            </div>
                            <div class="col-md-12 mt-4 mb-3">
                                <button type="submit" class="btn-submit btn-border" (click)="save(contactus)" [disabled]="buttonContactSubitDiable"><span class="span-loader-padding">Submit</span>   <i *ngIf="isloading" class="spinner-border"></i></button>
                            </div>
                            <div class="col-md-12">
                                <!-- <p>Shalom Media USA is a 501(c)3 non-profit organization. All donations are tax-deductible. Ein # 30-0585506</p> -->
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>