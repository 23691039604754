<div class="d-flex dashboard-content">
    <div class="home-left">
        <div class="purple-thanks-support mb-3">
            <div class="row purple-thanks-support-inner">
                <div class="col-md-7 thanks-content-left">
                    <h2>Thanks for your support</h2>
                    <p>You make a lasting impact!</p>
                    <div class="d-flex">
                        <div class="form-group mr-2 mb-0">
                            <input [formControl]="donationForm.controls.OtherAmount" type="text"
                                placeholder="Enter amount" class="form-control"
                                (input)="donationAmount($event.target.value)" (focusout)="roundOtherAmount()">
                            <span class="error-message danger alert amount-error" *ngIf="otheramoutValidation">
                                Please enter an amount of at least {{minAmount == null ? 1 : minAmount}}
                                {{Currency}}
                            </span>
                            <span class="error-message danger alert amount-error" *ngIf="decimalVal">Please enter an
                                amount less than one hundred million</span>
                            <span class="error-message danger alert amount-error" *ngIf="decimalError">Please enter a
                                valid amount</span>
                        </div>
                        <button type="submit" class="btn-common" (click)="donateFRomFamily(null,true,false)">Donate
                            Now</button>
                    </div>
                </div>
                <div class="col-md-5 thanks-content-right text-right"
                    *ngIf="totalDonatedAmount && totalDonatedAmount > 0">
                    <strong>{{currencySymbol}}{{totalDonatedAmount}}</strong>
                    <span>Donation in {{currentYear}}</span>
                </div>
            </div>
        </div>

        <div class="slide-banner mb-3" [hidden]="iframeLoad" *ngIf="bannerList.length > 0">
            <!-- swiper 1 -->
            <div class="swiper-container swiper1">
                <div class="swiper-wrapper">
                    <div class="swiper-slide dashboard-slide" *ngFor="let banner of bannerList">
                        <a class="watch_video" href="javascript:void(0)"
                            *ngIf="banner.BannerType == 1 && banner.BannerLink != ''"
                            (click)="watchVideo(banner.BannerLink)"></a>
                        <img src="{{banner.MemberImageUrl}}" alt="Shalom">
                        <!-- <ng-image-slider [images]="imageObjects" #nav [infinite]="true" [autoSlide]="1"
                            [imageSize]="{width: '140%', height: 275}" [slideImage]="1" [showArrow]="false" [showVideoControls]="false" [animationSpeed]="5"></ng-image-slider> -->

                        <div class="slide-caption">
                            <h3>{{banner.Title}}</h3>
                            <p>{{banner.Description}}</p>
                            <div class="d-flex banner-slide-btn">
                                <button type="button" class="btn-common"
                                    *ngIf="!isSpfMember && banner.BannerType == 2 && banner.CauseId != ''"
                                    (click)="donateFRomFamily(null,true,true)">Become a
                                    Member</button>
                                <button type="button" class="btn-common"
                                    *ngIf="banner.BannerType == 1 && banner.CauseId != ''"
                                    (click)="donate(banner.CauseId,banner.CauseName)">Donate Now</button>
                                <a href="javascript:void(0)" *ngIf="banner.BannerType == 1 && banner.BannerLink != ''"
                                    (click)="watchVideo(banner.BannerLink)">Watch Video</a>
                                <button type="button" class="btn-common btn-white"
                                    *ngIf="banner.BannerType == 2 && banner.BannerLink != ''"
                                    (click)="gotoUrl(banner.BannerLink)">View Details</button>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="swiper-pagination swp1"></div>
                <!-- <div class="swiper-button-next swn1" hidden></div>
                <div class="swiper-button-prev swnp1" hidden></div> -->
            </div>

            <!-- <div class="dashboard-slide">
                <img src="./assets/member/images/img-home-slide1.png" alt="Shalom">
                <div class="slide-caption">
                    <h3>Mission Africa2</h3>
                    <p>Every five second one child dies in Africa due to malnutrition and related diseases, and you can help !</p>
                    <div class="d-flex banner-slide-btn">
                        <button type="button" class="btn-common">Donate Now</button>
                        <a href="javascript:void(0)">Watch Video</a>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="slide-banner" *ngIf="iframeLoad">
            <!-- <iframe [src]='safeURL' frameborder="0" allowfullscreen allow='autoplay'></iframe> -->
            <!-- <iframe width="420" height="345" src="http://www.youtube.com/embed/oHg5SJYRHA0?autoplay=1" frameborder="0" allowfullscreen></iframe> -->
            <!-- <iframe class="modest-vid-embed__item"
            src='https://player.vimeo.com/video/455934767?autoplay=1&loop=1&autopause=0&muted=1'
                allow="autoplay" width="640" height="360" frameborder="0"
                webkitallowfullscreen mozallowfullscreen allowfullscreen>
            </iframe> -->
            <iframe [src]='safeURL' frameborder="0" allow="autoplay" allowfullscreen webkitallowfullscreen
                mozallowfullscreen></iframe>
            <a class="video-modal-close" (click)="iframeLoad = false">
                <img src="./assets/member/images/modal-close.svg" alt="Shalom">
            </a>

        </div>
        <form [formGroup]="donationForm">
            <div class="bx-white   thanks-support-wrp">
                <div class="row">
                    <div class="col-md-4 thanks-support-left">
                        <figure class="mb-0" *ngIf="singleDonationExist == true && isSpfMember == false">
                            <img src="./assets/member/images/img-thanks-support-green.svg" alt="Shalom">
                        </figure>
                        <figure class="mb-0" *ngIf="singleDonationExist == false && isSpfMember == false">
                            <img src="./assets/member/images/img-thanks-support-brown.svg" alt="Shalom">
                        </figure>
                    </div>
                    <div class="col-md-8 thanks-support-right d-flex">
                        <div class="thanks-support-inner">
                            <h3>Thanks for the support</h3>
                            <!-- <p>{{CurrencyCodeDefaultCause}} {{defaultCauseAmount }} Donation in {{defaultCauseYear}}</p> -->
                            <!-- <p>{{donationTotal}}</p> -->
                            <p *ngIf="totalDonatedAmount && totalDonatedAmount > 0">
                                {{currencySymbol}}{{totalDonatedAmount}}</p>
                            <div class="d-flex align-items-center radio-amountbx-wrp">
                                <div class="mob-mb amnt-box-radio form-group pr-2"
                                    *ngFor="let amount of frequencyOptions; let i=index;">
                                    <div class="form-check amnt-radio-wrp pl-0">
                                        <input formControlName="Amount" [checked]="i == 0" class="form-check-input"
                                            id="{{amount?.PaymentOptionValueId}}" name="amountSelect" type="radio"
                                            value="{{amount?.Amount}}" (click)="getAmount(amount?.Amount)">
                                        <label class="form-check-label" for="{{amount?.PaymentOptionValueId}}"
                                            *ngIf="i < 3">
                                            {{amount?.PaymentOptionText}}
                                        </label>
                                    </div>
                                </div>
                                <!-- <div class="mob-mb amnt-box-radio form-group pr-2">
                                <div class="form-check amnt-radio-wrp pl-0">
                                    <input checked="" class="form-check-input" id="amount2" name="amountSelect" type="radio" value="amount2">
                                    <label class="form-check-label" for="amount2">
                                    $200
                                </label>
                                </div>
                            </div>
                            <div class="mob-mb amnt-box-radio form-group pr-2">
                                <div class="form-check amnt-radio-wrp pl-0">
                                    <input checked="" class="form-check-input" id="amount3" name="amountSelect" type="radio" value="amount3">
                                    <label class="form-check-label" for="amount3">
                                    $500
                                </label>
                                </div>
                            </div> -->
                                <!-- <div class="or-txt pr-2">OR</div> -->
                                <!-- <div class="mob-mb amnt-box-radio form-group">
                                <div class="form-check amnt-radio-wrp pl-0">
                                    <input checked="" class="form-check-input" id="amount4" name="amountSelect" type="radio" value="amount4">
                                    <label class="form-check-label" for="amount4">
                                    $other
                                </label>
                                </div>
                            </div> -->
                                <div class="mob-mb amnt-box-radio form-group d-flex amnt-currency-wrp">
                                    <input [formControl]="donationForm.controls.OtherAmount" type="text"
                                        id="other_amount_home" autocomplete="off" placeholder="Other Amount"
                                        (focus)="animateLabel($event.target)"
                                        (focusout)="animateLabelRemove($event.target)"
                                        (input)="upfunction($event.target.value)" (focusout)="roundOtherAmount()">
                                    <!-- <span class="error-message danger alert" *ngIf="otheramoutValidation">
                                    Please enter an amount of at least {{minAmount == null ? 1 : minAmount}} {{Currency}}
                            </span>
                        <span class="error-message danger alert" *ngIf="maxOtherAmount">
                                Please enter an amount of maximum 12 characters
                        </span> -->

                                    <select [formControl]="donationForm.controls.CurrencyISOCode" id="currency"
                                        [(ngModel)]="Currency" (change)="getCurrencyOptions($event.target.value)">
                                        <!-- <option>USD</option> -->
                                        <option *ngFor="let Currency of currencyList" value="{{Currency.CurrencyCode}}">
                                            {{ Currency.CurrencyCode }}</option>

                                    </select>
                                </div>
                            </div>
                            <span class="error-message danger alert amount-error" *ngIf="otheramoutValidation">
                                Please enter an amount of at least {{minAmount == null ? 1 : minAmount}}
                                {{Currency}}
                            </span>
                            <span class="error-message danger alert amount-error" *ngIf="decimalVal">Please enter an
                                amount less than one hundred million</span>
                            <span class="error-message danger alert amount-error" *ngIf="decimalError">Please enter a
                                valid amount</span>
                            <div class="d-flex select-btn-wrp mt-3">
                                <div class="select-drpdwn mr-3">
                                    <select [formControl]="donationForm.controls.Frequency" class=""
                                        [(ngModel)]="initialFrequency"
                                        (change)="getFrequencyOptions({id:$event.target.value,checked:false})">
                                        <option *ngFor="let frequency of frequencyList; let i=index;"
                                            value="{{frequency?.Id}}">{{frequency?.Name}}</option>
                                    </select>
                                </div>
                                <button type="submit" class="btn-common" (click)="donateNow()">Donate Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <!-- <ng-image-slider #nav
    [images]="imageObject"
    [imagePopup]="true"
    [infinite]="false"
    [autoSlide]="1"
    [imageSize]="{width: '50%', height: 200}"
    slideImage="1"
   ></ng-image-slider> -->
  
        <div *ngIf="listOfVideos?.length > 0 && isSpfMember">
            <div class="bx-white mt-3">
                <div class="top-strip d-flex btm-border type2 mb-4">
                    <h5 class="mb-0">Exclusive Videos</h5>
                    <a href="javascript:void(0)" (click)="allVideoList()">View all</a>
                </div>
                <div class="row exclusive-video-list pb-4">
                    <!-- <carousel>
                    <div class="col-6 col-md-4 " *ngFor="let videos of listOfVideos">
                        <div class="video-thumb-img">
                            <figure>
                                <img src="{{videos.VideoImageUrl}}" alt="Shalom ">
                            </figure>
                            <button type="button" class="btn-play-video" (click)="videoModal(videos.VideoUrl)"></button>
                        </div>
                        <h5>{{videos.videoDescription}}</h5>
                        <span>12:00 AM - 04:30 PM, 24 Jan 2021</span>
                    </div>
                </carousel> -->
                    <!-- swipwer 2 -->
            
                    <div class="swiper-container swiper2">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide exclusive-video-each set-width-video" *ngFor="let videos of listOfVideos">
                                <div class="video-thumb-img">
                                    <figure>
                                        <img src="{{videos.VideoImageUrl}}" alt="Shalom ">
                                    </figure>
                                    <button type="button" class="btn-play-video"
                                        (click)="videoModal(videos.VideoUrl)"></button>
                                </div>
                                <h5>{{videos.videoDescription}}</h5>
                                <span>{{videos.VideoDuration}}, {{videos.PublishedDate}}</span>
                            </div>
                        </div>
                        <div class="swiper-button-next swn2"></div>
                        <div class="swiper-button-prev swnp2"></div>
                    </div>
                    <!-- <div class="col-6 col-md-4 ">
                    <div class="video-thumb-img">
                        <figure>
                            <img src="./assets/member/images/img-ex-video1.png " alt="Shalom ">
                        </figure>
                        <button type="button" class="btn-play-video"></button>
                    </div>
                    <h5>Excepteur sint occaecat cupidatat non</h5>
                    <span>12:00 AM - 04:30 PM, 24 Jan 2021</span>
                </div>
                <div class="col-6 col-md-4 ">
                    <div class="video-thumb-img">
                        <figure>
                            <img src="./assets/member/images/img-ex-video2.png " alt="Shalom ">
                        </figure>
                        <button type="button" class="btn-play-video"></button>
                    </div>
                    <h5>Excepteur sint occaecat cupidatat non</h5>
                    <span>12:00 AM - 04:30 PM, 24 Jan 2021</span>
                </div>
                <div class="col-6 col-md-4 ">
                    <div class="video-thumb-img">
                        <figure>
                            <img src="./assets/member/images/img-ex-video3.png " alt="Shalom ">
                        </figure>
                        <button type="button" class="btn-play-video"></button>
                    </div>
                    <h5>Excepteur sint occaecat cupidatat non</h5>
                    <span>12:00 AM - 04:30 PM, 24 Jan 2021</span>
                </div> -->
                </div>
            </div>
        </div>
        <script src="js/swiper/swiper.min.js"></script>
        <div class="bx-white mt-3">
            <div class="top-strip d-flex btm-border type2 mb-4 family-top-strip">
                <h5 class="mb-0 width-1">Family Members <span>. {{familyMemberCount}} Members</span></h5>
                <div class="d-flex f-top-strip-btn">
                    <button type="submit" class="btn-add-member btn-border" (click)="addMember()">+ Add a
                        member</button>
                    <a href="javascript:void(0)" (click)="viewAllFamilymember()">View all</a>
                </div>
            </div>
            <div class="row family-member-list pb-4">
                <!-- swiper 3 -->
                <div class="swiper-container swiper3">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide family-list-each text-center" *ngFor="let member of listOfFamilyMem">
                            <div class="family-list-inner">
                                <figure class="text-center ">
                                    <img src="{{ member.PhotoUrl}}" alt="Shalom ">
                                </figure>
                                <div class="list-desc">
                                    <div class="desc-content-sec"
                                        [ngClass]="{'causeClass':member.MemberName.length > 21}">
                                        <h5 class="heading-restrict">{{ member.MemberName}}</h5>
                                        <div class="hover-bx">
                                            <h5>{{ member.MemberName}}</h5>
                                        </div>
                                    </div>
                                    <h6>{{member.Relationship?member.Relationship:"Primary"}}</h6>
                                    <span *ngIf="member.SpfId != null">SPF {{member.SpfId}}</span>
                                    <span class="txt-underline" *ngIf="member.SpfId == null"
                                        (click)="donateFRomFamily(member.MemberId,true,true)">Join SPF</span>
                                    <a href="javascript:void(0)" class="blue-link d-block mb-2"
                                        (click)="member.Relationship?addMember(member):editContactInfo()">Edit
                                        Profile</a>
                                    <div class="d-flex btn-family justify-content-center">
                                        <button type="submit" class="btn-border mr-1"
                                            (click)="donateFRomFamily(member.MemberId,true,false)">Donate</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="swiper-button-next swn3"></div>
                    <div class="swiper-button-prev swnp3"></div>
                </div>

                <!-- <div class="col-md-4 family-list-each text-center">
                    <div class="family-list-inner">
                        <figure class="text-center ">
                            <img src="./assets/member/images/img-family-member1.png " alt="Shalom ">
                        </figure>
                        <div class="list-desc">
                            <h5>Mr. John Mccarthy</h5>
                            <h6>Primary</h6>
                            <span>SPFID4567</span>
                            <a href="javascript:void(0)" class="blue-link d-block mb-2">View Profile</a>
                            <button type="submit" class="btn-border">Donate</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 family-list-each text-center">
                    <div class="family-list-inner">
                        <figure class="text-center">
                            <img src="./assets/member/images/img-family-member2.png " alt="Shalom ">
                        </figure>
                        <div class="list-desc">
                            <h5>Hema John</h5>
                            <h6>Relationship</h6>
                            <span class="txt-underline">Join SPF</span>
                            <a href="javascript:void(0)" class="blue-link d-block mb-2">View Profile</a>
                            <button type="submit" class="btn-border">Donate</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 family-list-each text-center">
                    <div class="family-list-inner">
                        <figure class="text-center">
                            <img src="./assets/member/images/img-family-member3.png " alt="Shalom ">
                        </figure>
                        <div class="list-desc">
                            <h5>Neha John</h5>
                            <h6>Relationship</h6>
                            <span class="txt-underline">Join SPF</span>
                            <a href="javascript:void(0)" class="blue-link d-block mb-2">View Profile</a>
                            <button type="submit" class="btn-border">Donate</button>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="bx-white mt-3" *ngIf="listOfEvents.length > 0">
            <div class="top-strip d-flex btm-border mb-4 type2">
                <h5 class="mb-0 width-1">Events</h5>
                <a href="javascript:void(0)" class="view-all-link" (click)="viewAllEvent()">View all</a>
            </div>
            <div class="event-tab-btn-wrp d-flex mb-4">
                <div class="event-tab-btn-each d-flex">
                    <a href="javascript:void(0)" [ngClass]="{ 'active':eventTypeIndex === -1}"
                        (click)="eventList(null,-1)">All Events ({{totalEventCount}})</a>
                </div>
                <div class="event-tab-btn-each d-flex" *ngFor="let eventType of eventTypes; let index = index;">
                    <a href="javascript:void(0)" [ngClass]="{ 'active':index === eventTypeIndex}"
                        (click)="eventList(eventType.EventTypeId,index)">{{eventType.Count}}</a>
                    <!-- <a href="javascript:void(0)" (click)="eventList()">Mission Fire (07)</a> -->
                </div>
                <!-- <a href="javascript:void(0)" class="view-all-link" (click)="viewAllEvent()">View all</a> -->
            </div>
            <div class="row events-list pb-4">
                <!-- swiper 4 -->
                <div class="swiper-container swiper4">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide events-each" *ngFor="let event of listOfEvents">
                            <figure class="text-center mb-0">
                                <img src="{{event.logo_url}}" alt="Shalom ">
                            </figure>
                            <div class="events-desc ">
                                <div class="desc-content-sec"
                                    [ngClass]="{'causeClass':event.EventTypeName.length > 23 }">
                                    <h5 class="heading-restrict">{{event.EventTypeName}}</h5>
                                    <h6 class="d-block location-txt heading-restrict">&nbsp;{{event.Title}}</h6>
                                    <div class="hover-bx">
                                        <h5>{{event.EventTypeName}}</h5>
                                        <h6 class="d-block location-txt">{{event.Title}}</h6>
                                    </div>
                                </div>
                                <p>{{event.EventFromToDate}}</p>
                                <div class="event-btn-wrp d-flex mt-2">
                                    <button type="submit" class="btn btn-common reg mr-3"
                                        (click)="registerEvent(event.EventStartDate,event.EventTypeName,event.Title)"
                                        [disabled]="event.IsEventClosed == 1 || event.IsMaxEventRegisterExceed == 1">Register</button>
                                    <button *ngIf="event.AttachmentDetails != null" type="submit"
                                        class="btn btn-border btn-flyer"
                                        (click)="downloadFlyer(event.AttachmentDetails?event.AttachmentDetails.FileName:null)">Flyer</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="swiper-button-next swn4"></div>
                    <div class="swiper-button-prev swnp4"></div>
                </div>

                <!-- <div class="col-md-4 events-each">
                    <figure class="text-center mb-0">
                        <img src="./assets/member/images/img-dashbrd-event1.png " alt="Shalom ">
                    </figure>
                    <div class="events-desc ">
                        <h6 class="d-block location-txt">Canada</h6>
                        <h5>Shalom Retreat</h5>
                        <p>Mar 19 - 21, 2021</p>
                        <div class="d-flex">
                            <button type="submit" class="btn-common">Register</button>
                            <button type="submit" class="btn-border">Flayer</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 release-each">
                    <figure class="text-center mb-0">
                        <img src="./assets/member/images/img-dashbrd-event2.png " alt="Shalom ">
                    </figure>
                    <div class="events-desc ">
                        <h6 class="d-block location-txt">Canada</h6>
                        <h5>Shalom Retreat</h5>
                        <p>Mar 19 - 21, 2021</p>
                        <div class="d-flex">
                            <button type="submit" class="btn-common">Register</button>
                            <button type="submit" class="btn-border">Flayer</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 release-each">
                    <figure class="text-center mb-0">
                        <img src="./assets/member/images/img-dashbrd-event3.png " alt="Shalom ">
                    </figure>
                    <div class="events-desc ">
                        <h6 class="d-block location-txt">Canada</h6>
                        <h5>Shalom Retreat</h5>
                        <p>Mar 19 - 21, 2021</p>
                        <div class="d-flex">
                            <button type="submit" class="btn-common">Register</button>
                            <button type="submit" class="btn-border">Flayer</button>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="bx-white mt-3" id="donation-cause">
            <div class="top-strip d-flex btm-border type2">
                <h5 class="mb-0">Donation Causes</h5>
                <a href="javascript:void(0)" (click)="viewAllDonation()">View all</a>
            </div>
            <ul class="donation-cause-list v-scroll">
                <li class="d-flex" *ngFor="let cause of listOfCauses">
                    <figure>
                        <img src="{{cause.ImageUrl}}" alt="Shalom">
                    </figure>
                    <div class="donation-desc">
                        <div class="desc-content-sec"
                            [ngClass]="{'causeClass':cause.CauseDescription.length > 180 || cause.CauseName.length > 60}">
                            <h5 class="heading-restrict">{{ cause.CauseName}}</h5>
                            <p class="para-restrict">{{ cause.CauseDescription}}</p>
                            <div class="hover-bx">
                                <h5>{{ cause.CauseName}}</h5>
                                <p class="mb-0">{{ cause.CauseDescription}}</p>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="btn-border"
                        (click)="donate(cause.CauseId, cause.CauseName)">Donate</button>
                </li>
                <!-- <li class="d-flex">
                    <figure>
                        <img src="./assets/member/images/img-donation2.png" alt="Shalom">
                    </figure>
                    <div class="donation-desc">
                        <h5>Excepteur sint occaecat cupidatat non</h5>
                        <p>exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.<a href="" class="more-link">More</a></p>
                    </div>
                    <button type="submit" class="btn-border">Donate</button>
                </li>
                <li class="d-flex">
                    <figure>
                        <img src="./assets/member/images/img-donation2.png" alt="Shalom">
                    </figure>
                    <div class="donation-desc">
                        <h5>Excepteur sint occaecat cupidatat non</h5>
                        <p>exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.<a href="" class="more-link">More</a></p>
                    </div>
                    <button type="submit" class="btn-border">Donate</button>
                </li>
                <li class="d-flex">
                    <figure>
                        <img src="./assets/member/images/img-donation2.png" alt="Shalom">
                    </figure>
                    <div class="donation-desc">
                        <h5>Excepteur sint occaecat cupidatat non</h5>
                        <p>exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.<a href="" class="more-link">More</a></p>
                    </div>
                    <button type="submit" class="btn-border">Donate</button>
                </li>
                <li class="d-flex">
                    <figure>
                        <img src="./assets/member/images/img-donation2.png" alt="Shalom">
                    </figure>
                    <div class="donation-desc">
                        <h5>Excepteur sint occaecat cupidatat non</h5>
                        <p>exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.<a href="" class="more-link">More</a></p>
                    </div>
                    <button type="submit" class="btn-border">Donate</button>
                </li> -->
            </ul>
        </div>
        <div class="bx-white mt-3">
            <div class="top-strip d-flex btm-border mb-4">
                <h5 class="mb-0">More Ways to Give</h5>
            </div>
            <div class="row more-way-list">
                <div class="col-6 col-md-4 " *ngFor="let pgm of listOprogram ">
                    <figure>
                        <img src="{{pgm.ProgramImageUrl}}" alt="Shalom ">
                    </figure>
                    <a href="{{pgm.SiteUrl}}" target="_blank">
                        <h5>{{pgm.ProgramTitle}}</h5>
                    </a>
                    <p>{{pgm.ProgramDescription}}</p>
                </div>
                <!-- <div class="col-6 col-md-4 ">
                    <figure>
                        <img src="./assets/member/images/img-more-way2.png " alt="Shalom ">
                    </figure>
                    <h5>Excepteur sint occaecat cupidatat non</h5>
                    <p>itation ullamco laboris nisi ut aliquip ex ea of a comm odo consequat.</p>
                </div>
                <div class="col-6 col-md-4 ">
                    <figure>
                        <img src="./assets/member/images/img-more-way3.png " alt="Shalom ">
                    </figure>
                    <h5>Excepteur sint occaecat cupidatat non</h5>
                    <p>itation ullamco laboris nisi ut aliquip ex ea of a comm odo consequat.</p>
                </div>
                <div class="col-6 col-md-4 ">
                    <figure>
                        <img src="./assets/member/images/img-more-way4.png " alt="Shalom ">
                    </figure>
                    <h5>Excepteur sint occaecat cupidatat non</h5>
                    <p>itation ullamco laboris nisi ut aliquip ex ea of a comm odo consequat.</p>
                </div>
                <div class="col-6 col-md-4 ">
                    <figure>
                        <img src="./assets/member/images/img-more-way5.png " alt="Shalom ">
                    </figure>
                    <h5>Excepteur sint occaecat cupidatat non</h5>
                    <p>itation ullamco laboris nisi ut aliquip ex ea of a comm odo consequat.</p>
                </div> -->
                <!-- <div class="col-6 col-md-4 ">
                    <figure>
                        <img src="./assets/member/images/img-more-way6.png " alt="Shalom ">
                    </figure>
                    <h5>Excepteur sint occaecat cupidatat non</h5>
                    <p>itation ullamco laboris nisi ut aliquip ex ea of a comm odo consequat.</p>
                </div> -->
            </div>
        </div>
        <div class="bx-white mt-3">
            <div class="top-strip d-flex btm-border type2 mb-3">
                <h5 class="mb-0 width-1">Recent News</h5>
                <a href="javascript:void(0)" (click)="viewAllRecentNews()">View all</a>
                <!-- (click)="gotoUrl('https://www.shalomworld.org/news/')" -->
            </div>
            <div class="recent-news-wrp v-scroll">
                <div class="row recent-news-list ">
                    <div class=" col-md-6 recent-news-each " *ngFor="let news of recentNews">
                        <div class="recent-news-inner d-flex">
                            <div class="recent-news-desc ">
                                <h5 (click)="gotoUrl('https://www.shalomworld.org/news/'+news.blogUrl)">
                                    {{news.blogTitle}}</h5>
                                <p><span
                                        class="color-yellow">{{news.category[0].category}}</span>&nbsp;.&nbsp;{{news.modifieddate
                                    | date: 'dd MMM yyyy'}}&nbsp;.&nbsp;{{news.views}} Views</p>
                            </div>
                            <figure class="text-center mb-0">
                                <img src="{{news.blogImageThumb}}" alt="Shalom ">
                            </figure>
                        </div>
                    </div>
                    <!-- <div class=" col-md-6 recent-news-each d-flex">
                        <div class="recent-news-inner d-flex">
                            <div class="recent-news-desc">
                                <h5>exercitation ullamco ullamco labo ris nisi ut aliquip…</h5>
                                <p><span class="color-yellow">Vatican</span> . Jun 18/2021 . 345 Views</p>
                            </div>
                            <figure class="text-center mb-0">
                                <img src="./assets/member/images/img-more-way6.png " alt="Shalom ">
                            </figure>
                        </div>
                    </div>
                    <div class="col-md-6 recent-news-each d-flex">
                        <div class="recent-news-inner d-flex">
                            <div class="recent-news-desc">
                                <h5>exercitation ullamco ullamco labo ris nisi ut aliquip…</h5>
                                <p><span class="color-yellow">Vatican</span> . Jun 18/2021 . 345 Views</p>
                            </div>
                            <figure class="text-center mb-0">
                                <img src="./assets/member/images/img-more-way6.png " alt="Shalom ">
                            </figure>
                        </div>
                    </div> -->
                    <!-- <div class="col-md-6 recent-news-each d-flex">
                        <div class="recent-news-inner d-flex">
                            <div class="recent-news-desc">
                                <h5>exercitation ullamco ullamco labo ris nisi ut aliquip…</h5>
                                <p><span class="color-yellow">Vatican</span> . Jun 18/2021 . 345 Views</p>
                            </div>
                            <figure class="text-center mb-0">
                                <img src="./assets/member/images/img-more-way6.png " alt="Shalom ">
                            </figure>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="bx-white mt-3">
            <div class="top-strip d-flex btm-border type2 mb-3">
                <h5 class="mb-0 width-1">Coming Up</h5>
                <!-- <a href="javascript:void(0)">View all</a> -->
            </div>
            <div class="row coming-news-list">
                <div class=" col-md-6 coming-news-each d-flex">
                    <figure class="text-center mb-0">
                        <img src="./assets/member/images/img-coming-news1.png " alt="Shalom ">
                    </figure>
                    <div class="coming-news-desc ml-3">
                        <h5 class="mb-0">{{comingProgramPrayer}}</h5>
                        <p>SW Prayer . <a class="schedule" style="cursor: pointer;"
                                (click)="gotoUrl('https://www.swprayer.org/watch-live/:fullschedule')">View Schedule</a>
                        </p>
                    </div>
                </div>
                <div class="col-md-6 coming-news-each d-flex">
                    <figure class="text-center mb-0">
                        <img src="./assets/member/images/img-coming-news2.png " alt="Shalom ">
                    </figure>
                    <div class="coming-news-desc ml-3">
                        <h5 class="mb-0">{{comingProgramworld}}</h5>
                        <p>Shalom World . <a class="schedule" style="cursor: pointer;"
                                (click)="gotoUrl('https://www.shalomworld.org/watchlive/fullschedule')">View
                                Schedule</a></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="bx-white mt-3">
            <div class="top-strip d-flex btm-border type2 mb-3">
                <h5 class="mb-0 width-1">Holy Mass Schedule</h5>
                <a href="javascript:void(0)" (click)="gotoUrl('https://www.swprayer.org/watch-live/:fullschedule')">View
                    all</a>
            </div>
            <div class="row holy-mass-list">
                <ul>
                    <li class="d-flex" *ngFor="let holymass of holyMass;let i=index;">
                        <figure class="mb-0" *ngIf="isSpfMember">
                            <img src="./assets/member/images/icon-holymass-purple.svg" alt="Shalom ">
                        </figure>
                        <!-- green -->
                        <figure class="mb-0" *ngIf="singleDonationExist == true && isSpfMember == false">
                            <img src="./assets/member/images/icon-holymass-green.svg" alt="Shalom ">
                        </figure>
                        <figure class="mb-0" *ngIf="singleDonationExist == false && isSpfMember == false">
                            <img src="./assets/member/images/icon-holymass-brown.svg" alt="Shalom ">
                        </figure>
                        <div class="holy-mass-desc ml-3">
                            <h5 class="mb-0">{{holymass.name}}</h5>
                            <p>{{holymass.start}}</p>
                        </div>
                        <!-- <button type="button" class="btn-base btn-watch" *ngIf =" i==0" (click)="gotoUrl('https://www.swprayer.org/watch-live')">Watch Live</button>  -->
                        <button type="button" class="btn-base btn-remind btn-border" (click)="remindMe(holymass)">Remind
                            me</button>

                    </li>
                    <!-- <li class="d-flex">
                        <figure class="mb-0">
                            <img src="./assets/member/images/icon-holy-mass.svg" alt="Shalom ">
                        </figure>
                        <div class="holy-mass-desc ml-3">
                            <h5 class="mb-0">Episod 123 - Many desktop ullamco labo ris nisi ut aliquip.</h5>
                            <p>English . 9:00 AM - 12:00 PM .</p>
                        </div>
                        <button type="button" class="btn-base btn-watch">Watch Live</button>
                    </li>
                    <li class="d-flex">
                        <figure class="mb-0">
                            <img src="./assets/member/images/icon-holy-mass.svg " alt="Shalom ">
                        </figure>
                        <div class="holy-mass-desc ml-3">
                            <h5 class="mb-0">Episod 123 - Many desktop ullamco labo ris nisi ut aliquip.</h5>
                            <p>English . 9:00 AM - 12:00 PM .</p>
                        </div>
                        <button type="button" class="btn-base btn-remind">Remind me</button>
                    </li>
                    <li class="d-flex">
                        <figure class="mb-0">
                            <img src="./assets/member/images/icon-holy-mass.svg " alt="Shalom ">
                        </figure>
                        <div class="holy-mass-desc ml-3">
                            <h5 class="mb-0">Episod 123 - Many desktop ullamco labo ris nisi ut aliquip.</h5>
                            <p>English . 9:00 AM - 12:00 PM .</p>
                        </div>
                        <button type="button" class="btn-base btn-remind">Remind me</button>
                    </li> -->
                </ul>
            </div>
        </div>
        <!-- <div class="row publication-list mt-3" id="publications">
            <div class="col-6 col-md-4 publication-list-each">
                <figure class="mb-0">
                    <img src="./assets/member/images/img-publication1.png " alt="Shalom ">
                </figure>
                <div class="fig-caption">
                    <h5>Shalom Tidings</h5>
                    <span>Many desktop publishing</span>
                    <button type="button" class="btn-common">Subscribe</button>
                </div>
            </div>
            <div class="col-6 col-md-4 publication-list-each">
                <figure class="mb-0">
                    <img src="./assets/member/images/img-publication2.png " alt="Shalom ">
                </figure>
                <div class="fig-caption">
                    <h5>Shalom Tidings</h5>
                    <span>Many desktop publishing</span>
                    <button type="button" class="btn-common">Subscribe</button>
                </div>
            </div>
            <div class="col-6 col-md-4 publication-list-each">
                <figure class="mb-0">
                    <img src="./assets/member/images/img-publication3.png " alt="Shalom ">
                </figure>
                <div class="fig-caption">
                    <h5>Shalom Tidings</h5>
                    <span>Many desktop publishing</span>
                    <button type="button" class="btn-common">Download</button>
                </div>
            </div>
        </div> -->
        <div class="row publication-list mt-3" id="publications">
            <!-- swiper 5 -->
            <div class="swiper-container swiper5">
                <div class="swiper-wrapper">
                    <div class="swiper-slide  publication-list-each text-center"
                        *ngFor="let publication of publicationList">
                        <figure class="mb-0">
                            <img src="{{publication.PublicationLogo}} " alt="Shalom ">

                        </figure>
                        <div class="fig-caption">
                            <h5>{{publication.PublicationCategoryName}}</h5>
                            <span>{{publication.Description}}</span>
                            <button type="button" class="btn-common"
                                (click)="subscriptionfrmPublication(0,publication.PublicationId)">Subscribe</button>
                        </div>
                    </div>
                    <div class="swiper-slide  publication-list-each text-center">
                        <figure class="mb-0">
                            <img src="./assets/member/images/img-publication3.png " alt="Shalom ">

                        </figure>
                        <div class="fig-caption">
                            <h5>Shalom Tidings</h5>
                            <span>Many desktop publishing</span>
                            <button type="button" class="btn-common" (click)="downloadPrayerBook()">Download</button>
                        </div>
                    </div>
                </div>
                <div class="swiper-button-next swn5"></div>
                <div class="swiper-button-prev swnp5"></div>
            </div>
        </div>
        <!-- <div class="bx-white mt-3">
            <div class="top-strip d-flex btm-border type2 mb-3">
                <h5 class="mb-0 width-1">New Releases<span>.SW Movies</span></h5>
                <a href="javascript:void(0)">View all</a>
            </div>
            <div class="row release-list pb-4">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide release-each">
                            <figure class="text-center mb-0">
                                <img src="./assets/member/images/img-release1.png " alt="Shalom ">
                            </figure>
                            <div class="release-desc ">
                                <span class="text-center d-block">Heaven is for Real </span>
                            </div>
                        </div>
                        <div class="swiper-slide release-each">
                            <figure class="text-center mb-0">
                                <img src="./assets/member/images/img-release2.png " alt="Shalom ">
                            </figure>
                            <div class="release-desc ">
                                <span class="text-center d-block">Silencio </span>
                            </div>
                        </div>
                        <div class="swiper-slide release-each">
                            <figure class="text-center mb-0">
                                <img src="./assets/member/images/img-release3.png " alt="Shalom ">
                            </figure>
                            <div class="release-desc ">
                                <span class="text-center d-block">Paul Apostle of 
                                    Christ </span>
                            </div>
                        </div>
                        <div class="swiper-slide release-each">
                            <figure class="text-center mb-0">
                                <img src="./assets/member/images/img-release4.png " alt="Shalom ">
                            </figure>
                            <div class="release-desc ">
                                <span class="text-center d-block">Noah</span>
                            </div>
                        </div>
                    </div>
                    Add Pagination -->
        <!-- <div class="swiper-pagination"></div> -->
        <!-- Add Arrows
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                </div>
            </div>
        </div> -->
    </div>
    <div class="home-right pl-0 ">
        <div class="bx-white connect-bx">
            <h4 class="text-center mb-3 mt-3">Connect with Us</h4>
            <div class="connect-btn-wrp">
                <figure class="text-center mb-2" *ngIf="isSpfMember">
                    <img src="./assets/member/images/img-connect-purple.svg " alt="Shalom ">
                </figure>
                <figure class="text-center mb-2" *ngIf="singleDonationExist == true && isSpfMember == false">
                    <img src="./assets/member/images/img-connect-green.svg " alt="Shalom ">
                </figure>
                <figure class="text-center mb-2" *ngIf="singleDonationExist == false && isSpfMember == false">
                    <img src="./assets/member/images/img-connect-brown.svg " alt="Shalom ">
                </figure>

                <ul class="button-list">
                    <li class="d-flex">
                        <a (click)="modalOpen('prayer')">Prayer Request</a>
                    </li>
                    <li class="d-flex">
                        <a (click)="modalOpen('testimony')">Testimony</a>
                    </li>
                    <li class="d-flex">
                        <a (click)="modalOpen('contact')">Contact Us</a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- <div class="bx-white live-desc-bx">
            <button type="button" class="btn-live mb-2">Live Now</button>
            <div class="live-desc ">
                <h4>Excepteur sint occaecat cupidatat non</h4>
                <p>itation ullamco laboris nisi ut aliquip ex ea of a comm odo consequat.</p>
            </div>
        </div> -->
    </div>
</div>
<!-- <script href="../../../../../assets/member/js/slick.min.js" type="text/javascript"></script> -->
<!-- <script src="../../../../../../node_modules/swiper/swiper-bundle.min.js"></script> -->