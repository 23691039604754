<!-- 
<style>
    .datepicker {
      z-index: 1600 !important; /* has to be larger than 1050 */
    }
</style> -->

<div class="contact-alert" [ngClass]="{'themePurpleSpf':isSpfMember == true, 'themeGreenNonSpf': (singleDonationExist == true && isSpfMember == false),'themeBrownSpf' : (singleDonationExist == false && isSpfMember == false)}">
    <!-- <div class="alert-top-bar">
        <div class="row align-item-center">
            <div class="col-7 col-md-7" [hidden] = "editFAmilymem">
                <h5>Add Family Member</h5>
            </div>
            <div class="col-7 col-md-7" [hidden] = "!editFAmilymem">
                <h5>Edit Family Member</h5>
            </div>
            <div class="col-5 col-md-5 text-right">
                <figure class="mb-0">
                    <img src="./assets/member/images/dashboard-logo.svg " alt="Shalom ">
                </figure>
                <button type="button" class="close" (click)="close()">
                    <span aria-hidden="true">
                        &times;
                    </span>
                </button>
            </div>
        </div>
    </div> -->
    <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close">
        <span aria-hidden="true">
            &times;
        </span>
    </button>
    <div class="popup-inner">
        <div class="text-center pt-5 popup-title" [hidden]="editFAmilymem">
            <h2>Add Family Member</h2>
            <p>Together we can change the world</p>
        </div>
        <div class="text-center pt-5 popup-title" [hidden]="!editFAmilymem">
            <h2>Edit Family Member</h2>
            <p>Together we can change the world</p>
        </div>
        <form>
            <div class="contact-form">
                <div class="row">
                    <!-- <div class="col-md-12 prof-add-member">
                        <div class="img-upload-bx">
                            <figure class="text-center">
                                <a *ngIf="!notValidImage" class="close-button" (click)="removePhoto()">
                                    <i class="la la-remove">x</i>
                                </a>
                                <img [src]="url" alt="Shalom ">

                            </figure>
                            <label for="fileInput" style="display: block;"><a style="cursor:pointer;"
                                    class="add-photo-link mb-5"><img src="./assets/member/images/icon-camera.svg"
                                        alt="Shalom ">{{notValidImage ? 'Upload' : 'change'}}</a></label>
                            <input hidden id="fileInput" type='file' (change)="fileChange($event)">
                        </div>
                    </div> -->
                            <!-- <div class="form-group upload-btn-wrapper">
                        <label class="form-label-of upload-input" for="upload" *ngIf="!fileName">Upload Photo</label>
                        <label class="form-label-of upload-input" for="upload" *ngIf="fileName">{{fileName}}</label>
                        <input type="file" id="upload" (change)="fileChange(fileInput)" name="photo" formControlName="photo" accept="image/*" class="form-control m-input" value="" #fileInput>
                    </div> -->

                            <!-- <label class="hoverable" for="fileInput">
                        <img src="./assets/member/images/icon-camera.svg " alt="Shalom ">
                        <div class="hover-text">Choose file</div>
                        <div class="background"></div>
                        </label>
                        <br/>
                        <input hidden id="fileInput" type='file' (change)="fileChange($event)">   -->
                        
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <div class="form-group focus-label">
                                <!-- <label class="form-label-of" for="">Select Relationship</label>
                            <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <ng-select *ngIf="selectedRelationshipId == null"
                                    [formControl]="createForm.controls.RelationshipId" [items]="relationshipList"
                                    placeholder="Relationship" bindValue="Id" bindLabel="Name"
                                    [(ngModel)]="selectedRelationshipId">
                                </ng-select>
                                <ng-select *ngIf="selectedRelationshipId "
                                    [formControl]="createForm.controls.RelationshipId" [items]="relationshipList"
                                    placeholder="Relationship" bindValue="Id" bindLabel="Name"
                                    [(ngModel)]="selectedRelationshipId">
                                </ng-select>
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.RelationshipId">
                                    <span class="error-message"
                                        *ngIf="createForm.get('RelationshipId').hasError(validation.type) && (createForm.get('RelationshipId').dirty || createForm.get('RelationshipId')?.touched)">
                                        {{validation.message}}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <div class="form-group focus-label">
                                <!-- <label class="form-label-of" for="">Select Salutation</label>
                            <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <ng-select *ngIf="selectedSalutationId == null"
                                    [formControl]="createForm.controls.SalutationId" [items]="SalutationList"
                                    placeholder="Title" bindValue="Id" bindLabel="Name"
                                    [(ngModel)]="selectedSalutationId">
                                </ng-select>
                                <ng-select *ngIf="selectedSalutationId" [formControl]="createForm.controls.SalutationId"
                                    [items]="SalutationList" placeholder="Title" bindValue="Id" bindLabel="Name"
                                    [(ngModel)]="selectedSalutationId">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <div class="form-group focus-label" id="fname">
                                <label class="form-label-of" for="">First Name</label>
                                <input [formControl]="createForm.controls.FirstName" class="form-control" id=""
                                    type="text" (focus)="animateLabel($event.target)"
                                    (focusout)="animateLabelRemove($event.target)"
                                    (input)="firstNameFetch($event.target.value)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.FirstName">
                                    <span class="error-message"
                                        *ngIf="createForm.get('FirstName').hasError(validation.type) && (createForm.get('FirstName').dirty || createForm.get('FirstName')?.touched)">
                                        {{validation.message}}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <div class="form-group focus-label" id="lname">
                                <label class="form-label-of" for="">Last Name</label>
                                <input [formControl]="createForm.controls.LastName" class="form-control" id=""
                                    type="text" (focus)="animateLabel($event.target)"
                                    (focusout)="animateLabelRemove($event.target)"
                                    (input)="lastNameFetch($event.target.value)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.LastName">
                                    <span class="error-message"
                                        *ngIf="createForm.get('LastName').hasError(validation.type) && (createForm.get('LastName').dirty || createForm.get('LastName')?.touched)">
                                        {{validation.message}}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <div class="form-group focus-label" id="pnone1">
                                <label class="form-label-of" for="">Mobile Number</label>
                                <input [formControl]="createForm.controls.MobileNumber" class="form-control" id=""
                                    type="text" (focus)="animateLabel($event.target)"
                                    (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.MobileNumber">
                                    <span class="error-message"
                                        *ngIf="createForm.get('MobileNumber').hasError(validation.type) && (createForm.get('MobileNumber').dirty || createForm.get('MobileNumber')?.touched)">
                                        {{validation.message}}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <div class="form-group focus-label" id="phone2">
                                <label class="form-label-of" for="">Secondary Number</label>
                                <input [formControl]="createForm.controls.SecondaryNumber" class="form-control" id=""
                                    type="text" (focus)="animateLabel($event.target)"
                                    (focusout)="animateLabelRemove($event.target)">

                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.SecondaryNumber">
                                    <span class="error-message"
                                        *ngIf="createForm.get('SecondaryNumber').hasError(validation.type) && (createForm.get('SecondaryNumber').dirty || createForm.get('SecondaryNumber')?.touched)">
                                        {{validation.message}}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <div class="form-group focus-label" id="email">
                                <label class="form-label-of" for="">Email</label>
                                <input [formControl]="createForm.controls.Email" class="form-control" id="" type="text"
                                    (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"
                                    maxlength="150">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Email">
                                    <span class="error-message"
                                        *ngIf="createForm.get('Email').hasError(validation.type) && (createForm.get('Email').dirty || createForm.get('Email')?.touched)">
                                        {{validation.message}}
                                    </span>
                                </span>
                                <span class="m-form__help error-message">{{emailOrMob}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <div class="form-group focus-label" id="dob">
                                <!-- <label class="form-label-of" for="">Date of Birth</label>
                            <input [formControl]="createForm.controls.Dob" class="form-control dob-input" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            <input type="text" class="form-control m-input" [formControl]="createForm.controls.Dob" name="Dob" placeholder="" autocomplete="off" id="dob" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <!-- <div class="content">
                                <div class="row">
                                  <div class="col-xs-12 col-12 col-md-4 form-group"> -->
                                <label class="form-label-of" for="">Date of Birth</label>
                                <!-- <input type="text" [(ngModel)]="selectedStartDate" class="form-control dob-input"
                                    (ngModelChange)="onDateChange($event)" (focus)="animateLabel($event.target)"
                                    (focusout)="animateLabelRemove($event.target)"
                                    [formControl]="createForm.controls.Dob" #dp="bsDatepicker" [bsConfig]="bsConfig"
                                    bsDatepicker [(bsValue)]="myDateValue" id="txtdate"> -->

                                    <input class="form-control dob-input" type="text"  (focus)="animateLabel($event.target)"
                                    (focusout)="animateLabelRemove($event.target)" [formControl]="createForm.controls.Dob"
          angular-mydatepicker name="mydate" (click)="dp.toggleCalendar()" [(ngModel)]="model" (dateChanged)="changeDobEvent($event)" id="txtdate" readonly
           [options]="myDpOptions" 
          #dp="angular-mydatepicker"/>
                                <!-- </div>
                                </div>
                              </div> -->
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <div class="form-group focus-label">
                                <!-- <label class="form-label-of" for="">Preferred Languages</label> -->
                                <!-- <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <ng-select [items]="languages" bindValue="Id"
                                    [formControl]="createForm.controls.language" class="textClass" bindLabel="Language"
                                    placeholder="Preferred Languages" [searchable]="false" [clearable]="false"
                                    (change)="selectLanguage($event)" [multiple]="true" notFoundText="no language found"
                                    [(ngModel)]="selectedLanguages"></ng-select>
                                <!-- <span class="m-form__help" *ngFor="let validation of ValidationMessage.language">
                                <span class="error-message"
                                    *ngIf="editContactForm.get('language').hasError(validation.type) && (editContactForm.get('language').dirty || editContactForm.get('language')?.touched)">
                                    {{validation.message}}
                                </span>
                                </span> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <div class="form-group focus-label">
                                <!-- <label class="form-label-of" for="">Preferred Communication Method</label>
                            <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <ng-select [items]="communicationMethods" bindValue="Id"
                                    [formControl]="createForm.controls.communicationMethod" class="textClass"
                                    bindLabel="CommunicationMethod" placeholder="Preferred Communication Method"
                                    [searchable]="false" [clearable]="false" (change)="selectedContactMode($event)"
                                    [multiple]="true" notFoundText="no conatct mode found"
                                    [(ngModel)]="selectedComModes"></ng-select>
                                <!-- <span class="m-form__help" *ngFor="let validation of ValidationMessage.communicationMethod">
                                <span class="error-message"
                                    *ngIf="editContactForm.get('communicationMethod').hasError(validation.type) && (editContactForm.get('communicationMethod').dirty || editContactForm.get('communicationMethod')?.touched)">
                                    {{validation.message}}
                                </span>
                                </span> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <div class="form-group focus-label">
                                <!-- <label class="form-label-of" for="">Select Gender</label>
                            <input [formControl]="createForm.controls.Gender" class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <ng-select *ngIf="selectedGenderId == null" [formControl]="createForm.controls.Gender"
                                    [items]="genderList" placeholder="Gender" bindValue="Id" bindLabel="Name"
                                    [(ngModel)]="selectedGenderId">
                                </ng-select>
                                <ng-select *ngIf="selectedGenderId" [formControl]="createForm.controls.Gender"
                                    [items]="genderList" placeholder="Gender" bindValue="Id" bindLabel="Name"
                                    [(ngModel)]="selectedGenderId">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <div class="form-group focus-label">
                                <!-- <label class="form-label-of" for="">Preferred Communication Method</label>
                            <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                    <ng-select  [formControl]="createForm.controls.TaxOptoutFlag"
                                    [items]="taxStatements" placeholder="Tax statement" bindValue="Id" bindLabel="CommunicationMethod"
                                    [(ngModel)]="selectedtax">
                                </ng-select>
                               
                                <!-- <span class="m-form__help" *ngFor="let validation of ValidationMessage.communicationMethod">
                                <span class="error-message"
                                    *ngIf="editContactForm.get('communicationMethod').hasError(validation.type) && (editContactForm.get('communicationMethod').dirty || editContactForm.get('communicationMethod')?.touched)">
                                    {{validation.message}}
                                </span>
                                </span> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <div class="form-group focus-label">
                                <!-- <label class="form-label-of" for="">Preferred Communication Method</label>
                            <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                               
                                    <ng-select  [formControl]="createForm.controls.EmailOptutFlag"
                                    [items]="emailCommunications" placeholder="Email communications" bindValue="mailId" bindLabel="CommunicationMethod"
                                    [(ngModel)]="selectedemail">
                                </ng-select>
                                <!-- <span class="m-form__help" *ngFor="let validation of ValidationMessage.communicationMethod">
                                <span class="error-message"
                                    *ngIf="editContactForm.get('communicationMethod').hasError(validation.type) && (editContactForm.get('communicationMethod').dirty || editContactForm.get('communicationMethod')?.touched)">
                                    {{validation.message}}
                                </span>
                                </span> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <div class="form-group focus-label">
                                <!-- <label class="form-label-of" for="">Preferred Communication Method</label>
                            <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                              
                                    <ng-select  [formControl]="createForm.controls.MailOptoutFlag"
                                    [items]="emailCommunications" placeholder="Mail communications" bindValue="mailId" bindLabel="CommunicationMethod"
                                    [(ngModel)]="selectedmail">
                                </ng-select>
                                <!-- <span class="m-form__help" *ngFor="let validation of ValidationMessage.communicationMethod">
                                <span class="error-message"
                                    *ngIf="editContactForm.get('communicationMethod').hasError(validation.type) && (editContactForm.get('communicationMethod').dirty || editContactForm.get('communicationMethod')?.touched)">
                                    {{validation.message}}
                                </span>
                                </span> -->
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-6">
                        <div class="form-group upload-btn-wrapper">
                            <label id="label-up" class="form-label-of upload-input upload-label" for="upload" ><span>Photo</span></label>
                            <span  class="upload-file-path">{{photoName}}</span>

                            <input type="file" id="upload" (change)="fileChange(fileInputt)" name="photo" [formControl]="createForm.controls.Photo" accept="image/*" class="form-control m-input" value="" #fileInputt>
                        </div>
                        
                    </div>
                </div>
                <div class="row" style="margin-top: 5px;">
                    <div class="col-md-6" >
                        <button type="submit" class="btn-border btn-modal" style="float: right;" (click)="close()">Cancel</button>
                    </div>
                    <div class="col-md-6">
                        <button type="submit" class="btn-filled btn-modal" (click)="addMember()" ><span class="span-loader-padding">Submit</span> <i
                            *ngIf="isLoading" class="spinner-border"></i></button>
                    </div>
                </div>
                   
                   
                  
                    <!-- <div class="col-md-12 mt-2 mb-3 d-flex justify-content-center">
                        
                        
                    </div> -->

                
            </div>
        </form>
    </div>
</div>