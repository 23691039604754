<head>
    <link href="//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css" rel="stylesheet">
</head>
<div class="sidebar-wrp">
    <div class="bx-white prof-sidebar">
        <div class="prof-img-desc">
            <div class="prof-icon">
                <figure class="mt-4">
                    <img src="{{PhotoUrl}}" alt="Shalom">
                </figure>
                <i class="icon-love" *ngIf="isSpfmember == 'true'">
                    <img src="./assets/member/images/prof-love-purple.svg" alt="Shalom">
                </i>
            </div>
            <div>
                <h6 class="mb-0">{{Salutation}} {{FirstName }} {{LastName}}</h6>
                <a  class="mail-link">{{memberProfile.Email}}</a>
            </div>
        </div>
        <ul class="list-each mt-3">
            <li class="d-flex " [ngClass]="{ 'active':activeTab==='settings'}">
                <a routerLink="settings/{{this.memberId}}">
                    <!-- <img src="./assets/member/images/list-icon1.svg" alt="Shalom"> -->
                    <span>My Account</span></a>
            </li>
            <li class="d-flex " [ngClass]="{ 'active':activeTab==='familylist'}">
                <a routerLink="./{{this.memberId}}/familyMemberList">
                    <!-- <img src="./assets/member/images/list-icon2.svg" alt="Shalom"> -->
                    <span>Family Members</span></a>
            </li>
            <li class="d-flex " [ngClass]="{ 'active':activeTab==='eventlist'}">
                <a routerLink="./{{this.memberId}}/eventList">
                    <!-- <img src="./assets/member/images/list-icon3.svg" alt="Shalom"> -->
                    <span>Events</span></a>
            </li>
            <!-- added for scrolling issue -->
            <li class="d-flex" [ngClass]="{ 'active':activeTab==='publications'}">
                <a [routerLink]="['/memberDashboard/home/',memberId]" fragment="publications">
                    <!-- <img src="./assets/member/images/list-icon4.svg" alt="Shalom"> -->
                    <span>Publications</span></a>
            </li>
            <li class="d-flex" [ngClass]="{ 'active':activeTab==='causelist'}">
                <a routerLink="./{{this.memberId}}/donationCauseList">
                    <!-- <img src="./assets/member/images/list-icon5.svg" alt="Shalom"> -->
                    <span>Donation Causes</span></a>

            </li>
        </ul>
    </div>
    <div class="sidebar-address mt-4">
        <div class="d-flex location-wrp">
            <!-- <i class="fa fa-map-marker fa-sm" style="color:#c1ccd4;font-size: 20px;"></i> -->
            <img src="./assets/member/images/icon-location-grey.svg" alt="Shalom">
                <!-- <address>
                    Shalom Media USA Inc. 211 E Wisconsin Edinburg, TX 78539 
                </address> -->
                <address>
                    {{ShalomOfficeAddress.ORGANIZATION_NAME}}<br>
                    {{ShalomOfficeAddress.ADDRESS_LINE_ONE}}<br>
                    {{ShalomOfficeAddress.ADDRESS_LINE_TWO}}
                </address>
        </div>
        <!-- <address>
            {{ShalomOfficeAddress.ADDRESS_LINE_ONE}}
        </address>
        <address>
         {{ShalomOfficeAddress.ADDRESS_LINE_TWO}}
        </address> -->
        <ul class="list-each bd-top-border pt-3">
            <li class="d-flex">
                <img src="./assets/member/images/icon-mail.svg" alt="Shalom">
                <!-- <a href="mailto:info@shalommedia.org">info@shalommedia.org</a> -->
                <a href="mailto:info@shalommedia.org">{{ShalomOfficeAddress.EMAIL}}</a>

            </li>
            <li class="d-flex">
                <img src="./assets/member/images/icon-tel.svg" alt="Shalom">
                <!-- <a href="tel:(215) 366-3031">(215) 366-3031</a> -->
                <a href="tel:(215) 366-3031">{{ShalomOfficeAddress.TELEPHONE}}</a>
            </li>
        </ul>
    </div>

</div>