<div class="payment-wrp">
    <form [formGroup]="subscriptionForm">
    <div class="bx-white" [hidden]="TabActiveIndex == 1 || TabActiveIndex == 2">
        <div class="row">
            <div class="payment-left-img col-md-6 text-center">
                <figure>
                    <img src="./assets/member/images/img-subscription-left.svg" alt="">
                </figure>
                <h3>From misery to hope</h3>
                <p>A gift you can open again and again</p>
            </div>
            <div class="payment-right-form col-md-6">
                <div class="payment-first-section">
                    <div class="subscriber-details mb-3">
                        <h3 *ngIf="subType =='New Subscription'">New  Subscription</h3>
                        <h3 *ngIf="subType =='Bulk Subscription'">Subscription Bulk Order</h3>
                        <h3 *ngIf="subType =='Renew Subscription'">Subscription Renewal</h3>
                        <div class="row">
                            <!-- subscription -->
                            <!-- <div class="form-group focus-label col-md-6 pr-1">
                                <ng-select [items]="Subscriptions" formControlName="SubscriptionType" bindValue="Id" bindLabel="Name" id="Subscriptiontype" placeholder="Subscription" [searchable]="false" [clearable]="false" (change)="changeSubType($event)" [(ngModel)]="subscription">
                                </ng-select>
                                <span class="error-message alert danger" *ngIf="(subscriptionForm.get('SubscriptionType')?.dirty || subscriptionForm.get('SubscriptionType')?.touched) && subscriptionForm.get('SubscriptionType')?.invalid">
                                    Please select subscription
                                </span>
                            </div> -->
                                  <!-- publication list -->
                            <div class="col-md-12">
                                <div class="form-group focus-label" *ngIf="! flag">
                                    <ng-select [items]="Publications" bindValue="Id" formControlName="Publication" class="textClass" bindLabel="Name" placeholder="Publications" [(ngModel)]="publicationIdFromHome" (change)="selectPublication($event)" [multiple]="multiselect"  [searchable]="false" [clearable]="false" notFoundText="There are no publications available for subscription in your country"></ng-select>
                                </div>
                                <div class="form-group focus-label  " *ngIf="subType !='Renew Subscription' && flag">
                                    <ng-select [items]="Publications" bindValue="Id" id="Publications_enabled" formControlName="Publication" class="textClass" bindLabel="Name" placeholder="Publications" [searchable]="false" [clearable]="false" (change)="selectPublication($event)" [multiple]="multiselect" notFoundText="There are no publications available for subscription in your country"></ng-select>
                                    <span class="error-message alert danger" *ngIf="(subscriptionForm.get('Publication')?.dirty || subscriptionForm.get('Publication')?.touched) && subscriptionForm.get('Publication')?.invalid">
                                        Please select publication
                                    </span>
                                    <!-- doubt case -->
                                    <!-- <span class="col-sm-12 m-form__help">
                                        <span class="error-message alert danger" *ngIf="validPub">
                                            Please select publication 
                                        </span>
                                    </span> -->
                                </div>
                                <div class="form-group focus-label  " *ngIf="subType =='Renew Subscription'">
                                    <ng-select [items]="renewItems" bindValue="Id" formControlName="Publication" class="textClass" bindLabel="Name" placeholder="Publications" [(ngModel)]="renewalSubscription"></ng-select>
                                </div>
                            </div>
                          <div class="col-md-6 pr-1">
                            <!-- subscibe for -->
                            <div class="form-group focus-label"  *ngIf="subType !=='Bulk Subscription'">
                                <ng-select placeholder="Subscribe for" [items]="allDurations" bindValue="Duration" formControlName="SubscribeFor" id="Subscribefor" bindLabel="DisplayText" (change)="changeDuration($event)" [(ngModel)]="subscribefor" [searchable]="false" [clearable]="false">
                                </ng-select>
                                <span class="error-message alert danger" [hidden]="errorShow" *ngIf="(subscriptionForm.get('SubscribeFor')?.dirty || subscriptionForm.get('SubscribeFor')?.touched) && subscriptionForm.get('SubscribeFor')?.invalid">
                                    Please select subscription period
                                </span>
                                <span class="error-message alert danger" *ngIf="errorShow">{{errorMsg}}</span>
                            </div>
                            <div class="" *ngIf="subType=='Bulk Subscription'">
                                <!-- subscribe for -->
                                <div class="form-group focus-label">
                                    <ng-select placeholder="Subscribe for" [items]="allDurations" bindValue="Duration" formControlName="SubscribeFor" id="Subscribefor" bindLabel="DisplayText" (change)="changeDuration($event)" [(ngModel)]="subscribefor" [searchable]="false" [clearable]="false">
                                    </ng-select>
                                    <span class="error-message alert danger" [hidden]="errorShow" *ngIf="(subscriptionForm.get('SubscribeFor')?.dirty || subscriptionForm.get('SubscribeFor')?.touched) && subscriptionForm.get('SubscribeFor')?.invalid">
                                        Please select subscription period
                                    </span>
                                    <span class="error-message alert danger" *ngIf="errorShow">{{errorMsg}}</span>
                                </div>
                          </div>
                        </div>
                       
                          <!-- no,of copies -->
                          <div class="col-md-6  pl-1" *ngIf="subType=='Bulk Subscription'">
                            <div class="form-group focus-label" >
                                <label class="form-label-of" for="copy">Number of copies</label>
                                <input type="text" id="noOfCopiesRequired" formControlName="NoOfCopiesRequired" name="NoOfCopiesRequired" required value="" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="amountCalculate()"  (keypress)="keyPressNumbers($event)">
                                <!-- <span class="error-message danger alert" *ngIf="copiesValidation">
                                    Number of copies should be in between 2 and 1 lakh
                                </span> -->
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.NoOfCopiesRequired">
                                    <span class="error-message danger alert" *ngIf="(subscriptionForm.get('NoOfCopiesRequired')?.hasError(validation.type) && (subscriptionForm.get('NoOfCopiesRequired')?.dirty || subscriptionForm.get('NoOfCopiesRequired')?.touched)) && subscriptionForm.get('NoOfCopiesRequired')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                          </div>
                          <div class="col-md-6  pr-1" *ngIf="subType=='Bulk Subscription'"> 
                            <!-- member name -->
                            <div class="form-group focus-label">
                                <!-- <input> -->
                                <ng-select [formControl]="subscriptionForm.controls.Member" [items]="memberList" placeholder="Member Name" bindValue="Id" bindLabel="Name" [(ngModel)]="selectedMemberId" (change)="onMemberChange($event)">
                                </ng-select>
                            </div>
                        </div>
                          <div class="col-md-6  pl-1"  *ngIf="subType !='Bulk Subscription'"> 
                            <!-- member name -->
                            <div class="form-group focus-label">
                                <!-- <input> -->
                                <ng-select [formControl]="subscriptionForm.controls.Member" [items]="memberList" placeholder="Member Name" bindValue="Id" bindLabel="Name" [(ngModel)]="selectedMemberId" (change)="onMemberChange($event)">
                                </ng-select>
                            </div>
                        </div>
                         <div class="col-md-6 pr-1"  *ngIf="subType=='New Subscription'">
                            <div class="form-group focus-label">
                                <ng-select style="background-color:#e6e5eb!important ;" [formControl]="subscriptionForm.controls.StartIssueId" [items]="issueList" placeholder="Start Issue" bindValue="PublicationIssueId" id="" bindLabel="IssueName"
                                [(ngModel)]="selectedIssueId"
                               [searchable]="false" [clearable]="false"  ></ng-select>

                            </div>
                        </div>
                        <!-- sponsership -->
                        <div class="col-md-6  pl-1">
                            <div class="form-group focus-label"  *ngIf="subType=='Bulk Subscription'">
                                <label class="form-label-of" for="Sponsorship">Sponsorship for</label>
                                <input formControlName="SponsorshipFor" type="text" id="Sponsorship" (input)="sponsorNameFetch($event.target.value)" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.SponsorshipFor">
                                    <span class="error-message danger alert" *ngIf="(subscriptionForm.get('SponsorshipFor')?.hasError(validation.type) && (subscriptionForm.get('SponsorshipFor')?.dirty || subscriptionForm.get('SponsorshipFor')?.touched)) && subscriptionForm.get('SponsorshipFor')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6"  *ngIf="subType=='Bulk Subscription'">
                            <div class="form-group focus-label">
                                <ng-select [formControl]="subscriptionForm.controls.StartIssueId" [items]="issueList" placeholder="Start Issue" bindValue="PublicationIssueId" id="" bindLabel="IssueName"
                                [(ngModel)]="selectedIssueId"
                               [searchable]="false" [clearable]="false"></ng-select>

                            </div>
                        </div>
                        </div>
                        <!-- <div class="form-group focus-label" *ngIf="subType=='Bulk Subscription'">
                            <p>If you would like to order bulk copies for a different duration or multiple magazines, please contact our office.</p>
                        </div> -->
                    </div>
                    <!-- gift recipint details -->
                    <div class="delivery-address" *ngIf="subType == 'Gift Subscription'" formGroupName="GiftTo">
                        <h3>Gift Recipient Details</h3>
                        <div class="form-group focus-label" *ngIf="this.giftMemberForm == true">
                            <ng-select class="custom" [formControl]="subscriptionForm.controls.GiftTo.controls.GiftMember" [items]="memberList" placeholder="Select Member Name" bindValue="Id" bindLabel="Name" [clearable]="true" [(ngModel)]="selectedGiftMemberId" (change)="onGiftMemberChange($event)" >
                            </ng-select>
                        </div>
                        <div class="two-col-wrp d-flex" *ngIf="this.newGiftMember == true">
                            <div class="form-group focus-label mr-1" >
                                <ng-select formControlName="SalutationId" [items]="SalutationList" placeholder="Title" bindValue="SalutationId" bindLabel="SalutationName" [clearable]="false" (change)="membersearch()">
                                </ng-select>
                            </div>
                            <div class="form-group focus-label ml-1" id="fname">
                                <label class="form-label-of" for="firstName">First Name</label>
                                <input class="form-control" formControlName="FirstName" type="text" id="GiftTo-FirstName" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (change)="membersearch()" (input)="firstNameFetch($event.target.value)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.FirstName">
                                    <span class="error-message danger alert" *ngIf="(subscriptionForm.controls['GiftTo'].get('FirstName')?.hasError(validation.type) && (subscriptionForm.controls['GiftTo'].get('FirstName')?.dirty || subscriptionForm.controls['GiftTo'].get('FirstName')?.touched)) && subscriptionForm.controls['GiftTo'].get('FirstName')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                            <div class="form-group focus-label ml-1" id="lanme">
                                <label class="form-label-of" for="lastName">Last Name</label>
                                <input class="form-control" formControlName="LastName" type="text" id="GiftTo-LastName" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (change)="membersearch()" (input)="lastNameFetch($event.target.value)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.LastName">
                                    <span class="error-message danger alert" *ngIf="(subscriptionForm.controls['GiftTo'].get('LastName')?.hasError(validation.type) && (subscriptionForm.controls['GiftTo'].get('LastName')?.dirty || subscriptionForm.controls['GiftTo'].get('LastName')?.touched)) && subscriptionForm.controls['GiftTo'].get('LastName')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="two-col-wrp d-flex" *ngIf="this.newGiftMember == true">
                            <div class="form-group focus-label mr-1" id="gmail">
                                <label class="form-label-of" for="Email">Email</label>
                                <input class="form-control" formControlName="Email" type="email" id="GiftTo-Email" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" maxlength="150" (change)="membersearch()">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Email">
                                    <span class="error-message danger alert" *ngIf="(subscriptionForm.controls['GiftTo'].get('Email')?.hasError(validation.type) && (subscriptionForm.controls['GiftTo'].get('Email')?.dirty || subscriptionForm.controls['GiftTo'].get('Email')?.touched)) && subscriptionForm.controls['GiftTo'].get('Email')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                            <div class="form-group focus-label ml-1" id="mphone">
                                <label class="form-label-of" for="mob">Mobile Phone</label>
                                <input class="form-control" formControlName="PrimaryPhone" type="tel" id="GiftTo-PrimaryPhone" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (change)="membersearch()">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.PrimaryPhone">
                                    <span class="error-message danger alert" *ngIf="(subscriptionForm.controls['GiftTo'].get('PrimaryPhone')?.hasError(validation.type) && (subscriptionForm.controls['GiftTo'].get('PrimaryPhone')?.dirty || subscriptionForm.controls['GiftTo'].get('PrimaryPhone')?.touched)) && subscriptionForm.controls['GiftTo'].get('PrimaryPhone')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="two-col-wrp d-flex" *ngIf="this.newGiftMember == true">
                            <div class="form-group focus-label mr-1" id="addPhone">
                                <label class="form-label-of" for="add-ph">Additional Phone (Optional)</label>
                                <input class="form-control" formControlName="SecondaryPhone" type="tel" id="add-ph" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (change)="membersearch()">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.SecondaryPhone">
                                    <span class="error-message danger alert" *ngIf="(subscriptionForm.controls['GiftTo'].get('SecondaryPhone')?.hasError(validation.type) && (subscriptionForm.controls['GiftTo'].get('SecondaryPhone')?.dirty || subscriptionForm.controls['GiftTo'].get('SecondaryPhone')?.touched)) && subscriptionForm.controls['GiftTo'].get('SecondaryPhone')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- delivery address -->
                    <div class="delivery-address" formGroupName="DeliveryAddress">
                        <h3>Mailing Address</h3>
                        <div class="form-group focus-label" id="Add1">
                            <label for="add-msg" class="form-label-of">Street Address</label>
                            <!-- <textarea class="form-control" id="add-msg" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"></textarea>
                            <label class="form-label-of" for="adress">Street Address</label> -->
                            <input class="form-control" type="text" id="BillingAddress-Address1" formControlName="Address1" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            <span class="m-form__help" *ngFor="let validation of ValidationMessage.Address1"> 
                                <span class="error-message danger alert"  *ngIf="(subscriptionForm.controls['DeliveryAddress'].get('Address1')?.hasError(validation.type) && (subscriptionForm.controls['DeliveryAddress'].get('Address1')?.dirty || subscriptionForm.controls['DeliveryAddress'].get('Address1')?.touched)) && subscriptionForm.controls['DeliveryAddress'].get('Address1')?.invalid">
                                    {{ validation.message }}
                                </span>
                            </span>
                        </div>
                        <div class="two-col-wrp d-flex">
                            <div class="form-group focus-label mr-1" id="Add2">
                                <label for="add-code" class="form-label-of">Apt, Suite (Optional)</label>
                                <!-- <input class="form-control" id="add-code" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <input class="form-control" type="text" id="opt-address" formControlName="Address2" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Address2"> 
                                    <span class="error-message danger alert"  *ngIf="(subscriptionForm.controls['DeliveryAddress'].get('Address2')?.hasError(validation.type) && (subscriptionForm.controls['DeliveryAddress'].get('Address2')?.dirty || subscriptionForm.controls['DeliveryAddress'].get('Address2')?.touched)) && subscriptionForm.controls['DeliveryAddress'].get('Address2')?.invalid">
                                       {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                            <div class="form-group focus-label ml-1" id="City">
                                <label for="city" class="form-label-of">City</label>
                                <!-- <input class="form-control" id="city" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <input class="form-control" type="text" id="BillingAddress-City" formControlName="City" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.City"> 
                                    <span class="error-message danger alert"  *ngIf="(subscriptionForm.controls['DeliveryAddress'].get('City')?.hasError(validation.type) && (subscriptionForm.controls['DeliveryAddress'].get('City')?.dirty || subscriptionForm.controls['DeliveryAddress'].get('City')?.touched)) && subscriptionForm.controls['DeliveryAddress'].get('City')?.invalid">
                                       {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="two-col-wrp d-flex">
                            <div class="form-group focus-label mr-1" id="State">
                                <ng-select formControlName="StateId" [items]="StatesList" id="BillingAddress-StateId" placeholder="State" bindValue="StateId" bindLabel="StateName" [(ngModel)]="state" [clearable]="false">
                                </ng-select>
                                <span class="error-message alert danger" *ngIf="(subscriptionForm.controls['DeliveryAddress'].get('StateId')?.dirty || subscriptionForm.controls['DeliveryAddress'].get('StateId')?.touched) && subscriptionForm.controls['DeliveryAddress'].get('StateId')?.invalid">
                                    Please select your state
                                </span>
                            </div>
                            <div class="form-group focus-label ml-1"  id="Code">
                                <label for="postal-code" class="form-label-of">Postal code</label>
                                <!-- <input class="form-control" id="postal-code" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <input class="form-control" type="text" id="BillingAddress-Zip" formControlName="Zip" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="shippingZipValidation($event.target.value)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Zip"> 
                                    <span class="error-message danger alert"  *ngIf="(subscriptionForm.controls['DeliveryAddress'].get('Zip')?.hasError(validation.type) && (subscriptionForm.controls['DeliveryAddress'].get('Zip')?.dirty || subscriptionForm.controls['DeliveryAddress'].get('Zip')?.touched)) && subscriptionForm.controls['DeliveryAddress'].get('Zip')?.invalid">
                                       {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                       
                    </div>
                    <!-- *ngIf="askBilling" -->
                    <div class="delivery-address" *ngIf="totalPrice > 0">
                        <div class="checkbox-wrp d-flex">
                            <input formControlName="IsBillingAddressIsDifferent" class="form-check-input" id="exampleCheck2" type="checkbox" (change)="changeBilling($event.target.value)">
                            <label class="form-check-label" for="exampleCheck2">My billing address is different</label>
                        </div>
                    </div>
                    <div class="delivery-address">
                        <h5 class="mb-0 total-pos">Total: <span class="amount-theme">{{totalPrice}} {{Currency}}</span></h5>
                    </div>
                    <button type="submit" class="btn-common mt-2" (click)="nextStep()">Continue</button>
                </div>
            </div>
        </div>
    </div>
    <div class="bx-white" [hidden]="TabActiveIndex == 0 || TabActiveIndex == 2">
        <div class="row">
            <div class="payment-left-img col-md-6 text-center">
                <figure>
                    <img src="./assets/member/images/img-subscription-left.svg" alt="">
                </figure>
                <h3>From misery to hope</h3>
                <p>A gift you can open again and again</p>
            </div>
            <div class="payment-right-form col-md-6">
                <div class="payment-second-section">
                    <div class="thank-msg-area text-center">
                        <h3>You are almost there...</h3>
                        <div class="donated-amnt-bx">
                            <span *ngIf="totalPrice > 0">{{totalPrice}} {{Currency}}</span>
                            <span *ngIf="totalPrice == 0">Free</span>
                            <h6>Subscription Amount</h6>
                        </div>
                    </div>
                    <!-- billing addres section -->
                    <div class="delivery-addres mb-3" *ngIf="!hideBillingAddress && totalPrice > 0" formGroupName="BillingAddress" >
                        <h4>Billing Address</h4>
                        <div class="form-group focus-label" id="BAdd1" >
                            <!-- <div class="form-group focus-label" id="BAdd1"> -->
                            <!-- <label for="add-msg" class="form-label-of">Street Address</label>
                            <textarea class="form-control" id="add-msg" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"></textarea> -->
                            <label for="add-msg" class="form-label-of">Street Address</label>
                            <!-- <textarea class="form-control" id="add-msg" [formControl]="subscriptionForm.controls.BillingAddress.controls.Address1" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"></textarea> -->
                            <input class="form-control" type="text" id="BillingAddress-Address1" [formControl]="subscriptionForm.controls.BillingAddress.controls.Address1" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">

                            <span class="m-form__help" *ngFor="let validation of ValidationMessage.Address1">
                                <span class="error-message danger alert"
                                    *ngIf="(subscriptionForm.controls['BillingAddress'].get('Address1')?.hasError(validation.type) && (subscriptionForm.controls['BillingAddress'].get('Address1')?.dirty || subscriptionForm.controls['BillingAddress'].get('Address1')?.touched)) && subscriptionForm.controls['BillingAddress'].get('Address1')?.invalid">
                                    {{ validation.message }}
                                </span>
                            </span>
                        </div>
                        <div class="two-col-wrp d-flex">
                            <!-- <div class="form-group focus-label mr-1" id="BAdd2" [ngClass]="subscriptionForm.controls.BillingAddress.controls.Address2 ? 'is-focused' : ''"> -->
                                <div class="form-group focus-label mr-1" id="BAdd2">
                                <!-- <label for="add-code" class="form-label-of">Apt, Suite, Bldg</label>
                                <input class="form-control" id="add-code" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <label for="add-code" class="form-label-of">Apt, Suite, Bldg</label>
                                <input class="form-control" id="add-code" [formControl]="subscriptionForm.controls.BillingAddress.controls.Address2" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Address2">
                                    <span class="error-message danger alert"
                                        *ngIf="(subscriptionForm.controls['BillingAddress'].get('Address2')?.hasError(validation.type) && (subscriptionForm.controls['BillingAddress'].get('Address2')?.dirty || subscriptionForm.controls['BillingAddress'].get('Address2')?.touched)) && subscriptionForm.controls['BillingAddress'].get('Address2')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                            <!-- <div class="form-group focus-label ml-1" id="BCity" [ngClass]="subscriptionForm.controls.BillingAddress.controls.City ? 'is-focused' : ''"> -->
                                <div class="form-group focus-label ml-1" id="BCity">
                                <!-- <label for="city" class="form-label-of">City</label>
                                <input class="form-control" id="city" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <label for="city" class="form-label-of">City</label>
                                <input class="form-control" id="city" [formControl]="subscriptionForm.controls.BillingAddress.controls.City" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.City">
                                    <span class="error-message danger alert"
                                        *ngIf="(subscriptionForm.controls['BillingAddress'].get('City')?.hasError(validation.type) && (subscriptionForm.controls['BillingAddress'].get('City')?.dirty || subscriptionForm.controls['BillingAddress'].get('City')?.touched)) && subscriptionForm.controls['BillingAddress'].get('City')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="two-col-wrp d-flex">
                            <div class="form-group focus-label mr-1">
                                <!-- <input> -->
                                <ng-select [formControl]="subscriptionForm.controls.BillingAddress.controls.StateId" [items]="StatesList" id="BillingAddress-StateId" placeholder="State" bindValue="StateId" bindLabel="StateName" [(ngModel)]="state" [clearable]="false">
                                </ng-select>
                                <span class="error-message alert danger" *ngIf="(subscriptionForm.controls['BillingAddress'].get('StateId')?.dirty || subscriptionForm.controls['BillingAddress'].get('StateId')?.touched) && subscriptionForm.controls['BillingAddress'].get('StateId')?.invalid">
                                    Please select your state
                                </span>
                            </div>
                            <!-- <div class="form-group focus-label ml-1" id="BCode" [ngClass]="subscriptionForm.controls.BillingAddress.controls.Zip ? 'is-focused' : ''"> -->
                                <div class="form-group focus-label ml-1" id="BCode" >
                                <!-- <label for="postal-code" class="form-label-of">Postal code</label>
                                <input class="form-control" id="postal-code" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <label for="postal-code" class="form-label-of">Postal code</label>
                                <input class="form-control" id="postal-code" [formControl]="subscriptionForm.controls.BillingAddress.controls.Zip" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="billingZipValidation($event.target.value)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Zip">
                                    <span class="error-message danger alert"
                                        *ngIf="(subscriptionForm.controls['BillingAddress'].get('Zip')?.hasError(validation.type) && (subscriptionForm.controls['BillingAddress'].get('Zip')?.dirty || subscriptionForm.controls['BillingAddress'].get('Zip')?.touched)) && subscriptionForm.controls['BillingAddress'].get('Zip')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    
                    </div>
                    <div class="payment-method-list"  formGroupName="PaymentProcessParams" *ngIf="!hidePaymentMethod">
                        <h4>Choose payment method:</h4>
                        <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing provident.</p> -->
                        <ul class="payment-radio-choose">
                            <li class="d-flex active" *ngFor="let payment of PaymentMethods; let i= index;">
                                <!-- <input type="radio" id="test1" name="radio-group" checked>
                                <label for="test1">Credit/Debit</label> -->
                                <input type="radio" class="form-check-input" id="{{payment.PaymentMethodId}}" formControlName="PaymentMethodId" value="{{payment.PaymentMethodId}}" (change)="onPaymentMethodChange($event.target.id)" required [checked]="false">
                                <label class="form-check-label" for="{{payment.PaymentMethodId}}">
                                    <img *ngIf="isSpfMember" src="../../assets/images/icon-{{payment.UniqueName}}-purple.svg" alt="" style="width:50px; height: 20px;">
                                    <img  *ngIf="singleDonationExist == true && isSpfMember == false" src="../../assets/images/icon-{{payment.UniqueName}}-green.svg" alt="" style="width:50px; height: 20px;">
                                    <img *ngIf="singleDonationExist == false && isSpfMember == false" src="../../assets/images/icon-{{payment.UniqueName}}-brown.svg" alt="" style="width:50px; height: 20px;">
                                    {{payment.text}}
                                </label>
                            </li>
                            <!-- <li class="d-flex">
                                <input type="radio" id="test2" name="radio-group">
                                <label for="test2">Bank Transfer</label>
                            </li>
                            <li class="d-flex">
                                <input type="radio" id="test3" name="radio-group">
                                <label for="test3">UPI Payment</label>
                            </li> -->
                        </ul>
                        <span class="error-message alert danger" *ngIf="paymentMethodError">
                            Please choose payment method
                        </span>
                    </div>
                    <div class="btn-wrp d-flex">
                        <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                        <button type="submit" *ngIf="totalPrice !== '0.00'" class="btn-common mt-2 ml-3" (click)="nextStep()">Continue</button>
                        <button type="submit" *ngIf="totalPrice == '0.00'" class="btn-common mt-2 ml-3" (click)="tokenizeUserDetails()"><span class="span-loader-padding">Subscribe</span>  <i *ngIf="isLoading" class="spinner-border"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bx-white" [hidden]="TabActiveIndex == 0 || TabActiveIndex == 1">
        <div class="row">
            <div class="payment-left-img col-md-6 text-center">
                <figure>
                    <img src="./assets/member/images/img-subscription-left.svg" alt="">
                </figure>
                <h3>From misery to hope</h3>
                <p>A gift you can open again and again</p>
            </div>
            <div class="payment-right-form col-md-6" formGroupName="PaymentProcessParams" >
                <div class="payment-third-section" formGroupName="PaymentFields">
                    <!-- credit card  need backend work-->
                    <div class="row payment-method-field" *ngIf="selectedPaymentMethod == 'credit_or_debt'">
                        <div class="col-7 payment-method-head-left">
                            <h3>Payment Method</h3>
                            <span>Credit/ Debit card</span>
                        </div>
                        <div class="col-5 text-right payment-method-head-right">
                            <h3>Amount</h3>
                            <span>{{totalPrice}} {{Currency}}</span>
                        </div>
                    </div>
                    <i *ngIf="loaderCreditsaved" class="spinner-border common-loader"></i>

                    <div class="" *ngIf="selectedPaymentMethod == 'credit_or_debt' && listSavedCreditCard">
                        <h6 class="text-center"  style="text-align: center;">Saved Cards </h6>
                        <span class="col-sm-12 m-form__help">
                            <span class="error-message alert danger" *ngIf="( this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.dirty || this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.touched) && this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.invalid">
                                Please select your account
                            </span>
                        </span>
                        <ul class="payment-radio-choose">
                                <li class="d-flex active" *ngFor="let option of savedCreditCards; let i=index">
                                    <input type="radio"  name="ChooseCard" checked="" formControlName="ChooseCard" value="{{option.PaymentGatewayToken}}" id="{{option.PaymentGatewayToken}}" (change)="onSelectedSavedcardChange($event.target.id)">
                                    <label for="{{option.PaymentGatewayToken}}" class="d-flex" style="padding-left: 10px;"> 
                                        <img src="{{option.CreditCardImageUrl}}" alt="" style="width: 50px;height: 20px;">
                                        <span>xxx {{option.Last4}}</span>{{option.CardHolderName}}
                                    </label>
                                </li>
                           
                        </ul>
                        <div class="mob-mb  single-radio form-group mb-3" *ngIf="selectedPaymentMethod == 'credit_or_debt' && listSavedCreditCard">
                            <div class="form-check  pl-0">
                                <input class="form-check-input" [formControl]="subscriptionForm.controls.AddNewCard"  type="checkbox" value="" id="acc" (change)="isAddNewCard($event.target.checked)">
                                <label class="form-check-label" for="acc"> Add New Card </label>
                            </div>
                        </div>
                        <i *ngIf="loaderCreditNew" class="spinner-border common-loader"></i>
                        <div class="btn-wrp d-flex"  *ngIf="!addNewCard">
                            <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                            <button type="submit" class="btn-common mt-2 ml-3" (click)="tokenizeUserDetails('savedCreditcard')" [disabled]="disableMakePayment && savedCardLoding" ><span class="span-loader-padding">Make Payment</span> <i *ngIf="isLoading" class="spinner-border"></i></button>
                        </div>
                    </div>
                    <div class="bx-add-card" *ngIf="selectedPaymentMethod == 'credit_or_debt'  && addNewCard">
                        <h6 class="text-center">Add a new card</h6>
                        <div *ngIf="selectedPaymentMethod == 'credit_or_debt'" class="p-0 credit_or_debt clearfix col-xs-12">
                            <!-- hosted fields -->
                            <div class="row" style="margin-top: -15px;">
                                <div class="field-container col-md-12 col-lg-12">
                                    <!-- Begin hosted fields section -->
                                    <label class="hosted-field--label" for="card-number">
                                    </label>
                                    <div class="hosted-field" id="card-number"></div>
                                    <span class="m-form__help" *ngIf="noFieldsCardNumber == 1">
                                        <span class="error-message danger alert">
                                        Please enter a valid card number
                                        </span>
                                    </span>
                                    <!-- End hosted fields section -->
                                </div>
                                <!-- <div class="textfield--float-label">
                                    <input type="text" class="account_holder" placeholder="Account Holder">
                                </div> -->
                                </div>
                                <div class="row" class="row" style="margin-top: -10px;">
                                <div class="field-container col-md-6 col-lg-6" style="padding-right: 5px;">
                                    <!-- Begin hosted fields section -->
                                    <label class="hosted-field--label" for="expiration-date">
                                    <!-- Expiration Date -->
                                    </label>
                                    <div class="hosted-field" id="expiration-date"></div>
                                    <span class="m-form__help" *ngIf="noFieldsDate == 1">
                                        <span class="error-message danger alert">
                                        Please enter a valid  card expiration date
                                        </span>
                                    </span>
                                    <!-- <div id="expiration-date" class="hosted-field"></div> -->
                                    <!-- End hosted fields section -->
                                </div>
                                <div class="field-container col-md-6 col-lg-6" style="padding-left: 5px;" *ngIf="!isCvvhide">
                                    <!-- Begin hosted fields section -->
                                    <label class="hosted-field--label" for="cvv">
                                        <!-- CVV -->
                                    </label>
                                    <!-- End hosted fields section -->
                                    <div class="hosted-field" id="cvv"></div>
                                    <span class="m-form__help" *ngIf="noFieldsCvv == 1">
                                        <span class="error-message danger alert">
                                        Please enter a valid card security code (CVV)
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <!-- <div class="textfield--float-label"> -->
                            <!-- Begin hosted fields section -->
                            <!-- <label id="postal-code" class="hosted-field--label" for="postal-code"> -->
                            <!-- Postal Code -->
                            <!-- </label> -->
                            <!-- End hosted fields section -->
                            <!-- </div> -->
                            <div style="color:#ff5a5a; padding: 0px;" *ngIf="brainTreeError != ''" class="alert danger">
                                <p>{{brainTreeError}}</p>
                            </div>
                            <div style="color:#ff5a5a; padding: 0px;" *ngIf="paymentGatewayErrorMsg !=null" class="alert danger">
                                <p>{{paymentGatewayErrorMsg}}</p>
                            </div>
                            <!-- <button class="pay-button">Pay</button> -->
                        </div>
                    </div>
                    <div class="btn-wrp d-flex" *ngIf="selectedPaymentMethod == 'credit_or_debt' && addNewCard">
                        <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                        <button type="submit" class="btn-common mt-2 ml-3"  (click)="tokenizeUserDetails()"><span class="span-loader-padding">Make Payment</span>  <i *ngIf="isLoading" class="spinner-border"></i></button>
                    </div>
                    <!-- <div class="" *ngIf="selectedPaymentMethod == 'credit_or_debt' && !shownewCreditcardOption">
                        <h6 class="text-center">saved card </h6>
                    </div> -->
                    
                    <!-- ACH -->
                    <div class="row payment-method-field" *ngIf="selectedPaymentMethod == 'ach'">
                        <div class="col-7 payment-method-head-left">
                            <h3>Payment Method</h3>
                            <span>ACH</span>
                        </div>
                        <div class="col-5 text-right payment-method-head-right">
                            <h3>Amount</h3>
                            <span>{{totalPrice}} {{Currency}}</span>
                        </div>
                    </div>
                    <!-- ach saved card list -->
                    <i *ngIf="loaderAchSaved" class="spinner-border common-loader"></i>

                    <div class="saved-card-wrp mt-2"  *ngIf="selectedPaymentMethod == 'ach' && IsSavedAchPresent">
                        <h6 class="text-center"  style="text-align: center;">Saved Accounts</h6>
                        <span class="col-sm-12 m-form__help">
                            <span class="error-message alert danger" *ngIf="( this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.dirty || this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.touched) && this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.invalid">
                                Please select your account
                            </span>
                        </span>
                        <ul class="payment-radio-choose">
                            <li class="d-flex active" *ngFor="let option of savedCards; let i=index">
                                <input type="radio"  name="ChooseCard" checked="" formControlName="ChooseCard" value="{{option.PayMethodToken}}" id="{{option.PayMethodToken}}" (change)="onSelectedSavedcardChange($event.target.id)">
                                <label for="{{option.PayMethodToken}}" class="d-flex"> 
                                    <img src="./assets/member/images/ach.png" alt="" style="width:50px; height: 20px;">
                                    <span>{{option.MaskedAccountNumber}}</span>{{option.AccountHolder}}
                                </label>
                            </li>
                        </ul>
                        <div class="mob-mb  single-radio form-group mb-3" *ngIf="selectedPaymentMethod == 'ach' && IsSavedAchPresent">
                            <div class="form-check  pl-0">
                                <input class="form-check-input" [formControl]="subscriptionForm.controls.AddNewCard"  type="checkbox" value="" id="acc" (change)="isAddNewCard($event.target.checked)">
                                <label class="form-check-label" for="acc">Add new account </label>
                            </div>
                        </div>
                        <div class="btn-wrp d-flex"  *ngIf="!addNewCard">
                            <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                            <button type="submit" class="btn-common mt-2 ml-3" (click)="tokenizeUserDetails('savedcard')" [disabled]="disableMakePayment && savedCardLoding"><span class="span-loader-padding">Make Payment</span>  <i *ngIf="isLoading" class="spinner-border"></i></button>
                        </div>
                    </div>
                    <!-- ach new card -->
                    <div class="bx-add-card mt-4" *ngIf="selectedPaymentMethod == 'ach'  && addNewCard">
                        <h6 class="text-center">Add a new account</h6>
                        <div class="radio-choose-type mt-3 mb-2">
                            <div class=" d-flex align-item-center">
                                <h5 class="mb-0 mr-3 mt-1">Account Type</h5>
                                <ul class="d-flex">
                                    <li  *ngFor="let accounttype of accounttypeList;let i = index;">
                                        <input type="radio" class="form-check-input" id="{{accounttype.name}}" formControlName="AccountType" value="{{accounttype.value}}">
                                        <label class="form-check-label" for="{{accounttype.name}}">
                                        {{accounttype.name}}
                                        </label>
                                    </li>
                                    <!-- <div class="form-check radio-btn-wrp" *ngFor="let accounttype of accounttypeList;let i = index;">
                                        <input type="radio" class="form-check-input" id="{{accounttype.value}}" formControlName="AccountType" value="{{accounttype.value}}">
                                        <label class="form-check-label" for="{{accounttype.value}}">
                                        {{accounttype.name}}
                                        </label>
                                    </div> -->
                                    <!-- <li>
                                        <input type="radio" id="type2" name="radio-group">
                                        <label for="type2">Savings</label>
                                    </li> -->
                                </ul>
                            </div>
                            <span class="m-form__help">
                                <span class="error-message alert danger" *ngIf="( this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.dirty || this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.touched) && this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.invalid">
                                    Please select your account type
                                </span>
                            </span>
                        </div>
                        <div class="two-col-row d-flex">
                            <div class="form-group focus-label mr-1" id="account_number">
                                <label for="ac-num" class="form-label-of" >Account Number</label>
                                <input id="ac-num" class="form-control" formControlName="AccountNumber" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" data-rule-number="true">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.AccountNumber">
                                    <span class="error-message danger alert"  *ngIf="(subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.hasError(validation.type) && (subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.dirty || subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.touched)) && subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                            <div class=" form-group focus-label ml-1" id="ach_routing_number">
                                <label for="route-num" class="form-label-of" >Routing Number</label>
                                <input id="route-num" class="form-control" formControlName="RoutingNumber" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" data-rule-number="true">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.RoutingNumber">
                                    <span class="error-message danger alert"  *ngIf="(subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.hasError(validation.type) && (subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.dirty || subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.touched)) && subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                            <div class="form-group focus-label mr-1">
                                <label for="acnt-name" class="form-label-of">Name on Account</label>
                                <input id="acnt-name"  formControlName="AccountHolder" class="form-control" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" maxlength="100">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.AccountHolder">
                                    <span class="error-message danger alert"  *ngIf="(subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.hasError(validation.type) && (subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.dirty || subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.touched)) && subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.invalid">
                                        {{ validation.message }}
                                    </span>
                            </span>
                            </div>
                            <!-- <div class="form-group checkbx-handle ml-1">
                                <input type="checkbox" id="html" formControlName="SaveCardForFuture">
                                <label for="html">Save Account for Future</label>
                            </div> -->
                        </div>
                    </div>




<!--                    
                    <div class="bx-add-card" *ngIf="selectedPaymentMethod == 'ach'">
                        <span class="radio-label">Account Type</span>
                        <div class="form-check radio-btn-wrp" *ngFor="let accounttype of accounttypeList;let i = index;">
                            <input type="radio" class="form-check-input" id="{{accounttype.value}}" formControlName="AccountType" value="{{accounttype.value}}">
                            <label class="form-check-label" for="{{accounttype.value}}">
                            {{accounttype.name}}
                            </label>
                        </div>
                        <span class="col-sm-12 m-form__help">
                            <span class="error-message alert danger" *ngIf="( this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.dirty || this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.touched) && this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.invalid">
                                Please select your account type
                            </span>
                        </span>
                        <div class="form-group focus-label" id="account_number">
                        <label class="form-label-of" for="ach_account_number">Account Number</label>
                            <input formControlName="AccountNumber" type="text" name="ach_account_number" id="ach_account_number" class=" required" data-rule-number="true" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" />
                            <span class="m-form__help" *ngFor="let validation of ValidationMessage.AccountNumber">
                                <span class="error-message danger alert"  *ngIf="(subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.hasError(validation.type) && (subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.dirty || subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.touched)) && subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.invalid">
                                    {{ validation.message }}
                                </span>
                            </span>
                        </div>
                        <div class="two-col-wrp d-flex">
                            <div class="form-group focus-label mr-1" id="ach_routing_number">
                                <label class="form-label-of" for="ach_routing_number">Routing Number</label>
                                    <input formControlName="RoutingNumber" type="text" class="required" autocomplete="off" name="ach_routing_number" id="ach_routing_number" data-rule-number="true" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.RoutingNumber">
                                        <span class="error-message danger alert"  *ngIf="(subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.hasError(validation.type) && (subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.dirty || subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.touched)) && subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.invalid">
                                            {{ validation.message }}
                                        </span>
                                    </span>
                            </div>
                            <div class="form-group focus-label mr-1" id="ach_person_name">
                                <label class="form-label-of" for="accName">Name on Account</label>
                                <input formControlName="AccountHolder" type="text" autocomplete="off" name="ach_person_name" id="ach_person_name" class="required" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" maxlength="100">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.AccountHolder">
                                        <span class="error-message danger alert"  *ngIf="(subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.hasError(validation.type) && (subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.dirty || subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.touched)) && subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.invalid">
                                            {{ validation.message }}
                                        </span>
                                </span>
                            </div>
                        </div>
                        <div class="checkbox-wrp ml-3">
                            <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" id="save-id">
                            <label for="save-id">Save Account for Future</label>
                        </div>
                    </div> -->
                    
                    <!-- ach saved card list need to do design css-->
                    <!-- <div class="col-7 payment-method-head-left" *ngIf="selectedPaymentMethod == 'ach' && IsSavedAchPresent" >
                        <h6 class="">saved card </h6>
                    </div>
                    <div class="row" *ngIf="selectedPaymentMethod == 'ach' && IsSavedAchPresent">
                        <div class="col-lg-6 form-check radio-btn-wrp mob-padding" *ngFor="let option of savedCards; let i=index">
                            <div class="bx-radio-btn-each">
                                <input formControlName="ChooseCard" type="radio" class="form-check-input" value="{{option.PayMethodToken}}" id="{{option.PayMethodToken}}" (change)="onSelectedSavedcardChange($event.target.id)">
                                <label for="{{option.PayMethodToken}}">
                                <span class="d-block">
                                    {{option.AccountHolder}}
                                    </span>
                                <span>
                                    {{option.MaskedAccountNumber}}
                                </span>
                            </label>
                            </div>
                        </div>
                    </div> -->
                    <div class="row payment-method-field" *ngIf="selectedPaymentMethod == 'gpay'">
                        <div class="col-7 payment-method-head-left">
                            <h3>Payment Method</h3>
                            <span>Google Pay</span>
                        </div>
                        <div class="col-5 text-right payment-method-head-right">
                            <h3>Amount</h3>
                            <span>{{totalPrice}} {{Currency}}</span>
                        </div>
                    </div>
                    <div class="row payment-method-field" *ngIf="selectedPaymentMethod == 'paypal'">
                        <div class="col-7 payment-method-head-left">
                            <h3>Payment Method</h3>
                            <span>PayPal</span>
                        </div>
                        <div class="col-5 text-right payment-method-head-right">
                            <h3>Amount</h3>
                            <span>{{totalPrice}} {{Currency}}</span>
                        </div>
                    </div>
                    <!-- manual payment -->
                    <div class="row payment-method-field" *ngIf="selectedPaymentMethod == 'manual_payment'">
                        <div class="col-7 payment-method-head-left">
                            <h3>Payment Method</h3>
                            <span>{{selectedPaymentname}}</span>
                        </div>
                        <div class="col-5 text-right payment-method-head-right">
                            <h3>Amount</h3>
                            <span>{{totalPrice}} {{Currency}}</span>
                        </div>
                    </div>
                    <div class="bx-add-card" *ngIf="selectedPaymentMethod == 'manual_payment'">
                        <div class="col-md-12 col-lg-12">
                            <span class="total-amnt-bold" >BankAccount Details : </span>
                            <br>
                            <span  style="white-space: pre-line;">{{text}}</span>
                        </div>
                    </div>
                    
                    <div class="btn-wrp d-flex"  *ngIf="addNewCard || selectedPaymentMethod == 'manual_payment'">
                        <button type="submit"  *ngIf="selectedPaymentMethod == 'manual_payment'" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                        <button *ngIf="selectedPaymentMethod ==  'hidePaymentMethod' || selectedPaymentMethod == 'manual_payment'"  type="submit" class="btn-common mt-2 ml-3" [disabled]="disableMakePayment && !savedCardLoding"  (click)="tokenizeUserDetails()"><span class="span-loader-padding">Make Payment</span>   <i *ngIf="isLoading && !savedCardLoding" class="spinner-border"></i></button>
                    </div>
                    <div class="btn-wrp d-flex mt-2"  *ngIf="((selectedPaymentMethod == 'gpay') || (selectedPaymentMethod == 'paypal'))">
                        <button type="submit" class="btn-border mr-3" (click)="prevStep()">Back</button>
                        <div class="gpay-wrap  ml-3" *ngIf="selectedPaymentMethod == 'gpay'">
                            <google-pay-button 
                            class="span-loader-padding"
                                environment="TEST"
                            
                                [buttonColor]="buttonColor"
                                [buttonType]="buttonType"
                                [buttonSizeMode]="isCustomSize ? 'fill' : 'static'"
                                [paymentRequest]="paymentRequest"
                                [style.width.px]="buttonWidth"
                                [style.height.px]="buttonHeight"
                                [clickCallback]="onClickPreventDefault"
                                (loadpaymentdata)="onLoadPaymentData($event)"
                                [paymentAuthorizedCallback]="onPaymentDataAutherized"
                                
                                >
                                
                            </google-pay-button>
                            <div class="loader" *ngIf="loadingGpay && selectedPaymentMethod == 'gpay'" >
                                <div class="loading">
                                </div>
                            </div>
                        </div>
                        <div *ngIf="selectedPaymentMethod == 'paypal'">
                            <div id="paypal-button"></div>
                            <span class="spinner-border common-loader" *ngIf="paypalLoad" style="margin-top: 25px;
                            margin-left: 10px;"></span>
                        </div>
                        </div>
                       
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</form>
</div>