import { DatePipe } from '@angular/common';
import { ThrowStmt } from '@angular/compiler';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { CustomCheckBox } from 'src/app/_directives/custom-fields/custom-checkbox';
import { CustomField } from 'src/app/_directives/custom-fields/custom-fields.base';
import { CustomControlService } from 'src/app/_directives/custom-fields/custom-fields.service';
import { CustomTextarea } from 'src/app/_directives/custom-fields/custom-textarea';
import { CustomTextbox } from 'src/app/_directives/custom-fields/custom-textbox';
import { CustomTextboxDatepicker } from 'src/app/_directives/custom-fields/custom-textbox-datepicker';
import { CommonCrudService } from 'src/app/_services/common-crud.service';
import { SharedService } from 'src/app/_services/shared.service';
import { EventParticipantdetailComponent } from '../event-participantdetail/event-participantdetail.component';
import { EventParticipantlistComponent } from '../event-participantlist/event-participantlist.component';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';
import * as braintree from 'braintree-web';
import { Helpers } from 'src/app/helpers';
declare let $: any;
declare let paypal: any;

@Component({
    selector: 'app-eventregistration-payment',
    templateUrl: './eventregistration-payment.component.html',
    styleUrls: ['./eventregistration-payment.component.scss']
})
export class EventregistrationPaymentComponent implements OnInit {
    TabActiveIndex: number = 0;
    eventForm;
    isAgeRequired: boolean;
    GenderList;
    paymentId:any
    selectedMemberId;
    PrimaryCountryList;
    Genders;
    countryId;
    eventName;
    eventType;
    eventDate;
    eventId = '1612767375ZF2';
    eventTypeId = '160456906579g';
    Currency = '';
    salutationId;
    SalutationList;
    secondAddressChecked: boolean;
    text;
    userLocation;
    primaryCountryId;
    countryName;
    SelectedPaymentOptionId;
    PaymentOptionValueId;
    paymentGatewayErrorMsg;
    selectedFrequencyName;
    InitialMethodId;
    selectedPrimaryCountry;
    selectedPrimaryCountryId;
    CountryList;
    customCountry;
    isAccomodationRequired: boolean = true;
    EventStatus;
    EventDetails;
    eventStartDate;
    startDay;
    startMonth;
    startYear;
    eventEndDate;
    endDay;
    endMonth;
    endYear;
    Streams;
    customFieldsCheckbox: CustomField<any>[] = [];
    public dynamicForm: FormGroup;
    customFields: CustomField<any>[] = [];
    streamList;
    public specialRequestForm: FormGroup;
    emptyParticipant;
    errorMessage;
    errorFound: boolean;
    SelectedRequestId;
    zeroAmount;
    selectedFrequencyId
    accounttypeList: { value: string; name: string; }[];
    brainTreeToken: string;
    paymentName:any;
    selectedPaymentMethod: string;
    brainTreeError: string;
    cardholdersName: string;
    noFieldsCardNumber: number;
    noFieldsDate: number;
    noFieldsCvv: number;
    IsSavedAchPresent: boolean = false;
    savedCards;
    showNewCardOption: boolean = false;
    postDataJsonPrepare;
    brainTreeReInit: boolean = true;
    selectedSavedCard: boolean = false;
    public EventsList: any = [];
    public chooseEventIndex: number;
    routeParam;
    memberId;
    public response
    queryParam;
    additionalFieldRequiredMsg: boolean = false;
    savedAchCardButtonShow: boolean;
    paymentSettingId;
    shownewCreditcardOption: boolean = false;
    showSavedCreditcardOption: boolean = true;
    savedCreditCardButtonShow: boolean;
    hostedFieldsInstance: braintree.HostedFields;
    selectedPaymentname;
    hostedClientToken;
    isSpfMember: boolean;
    singleDonationExist: boolean = false;
    	buttonColor = "black";
    buttonType = "pay";
    isCustomSize = false;
    buttonWidth = 90;
    buttonHeight = 40;
    tokenizationkey:any;
    merchantId:any;
    nonce:any
    paymentRequest:google.payments.api.PaymentDataRequest
    loadingGpay: boolean = false;
    ValidationMessage: any = {
        // 'Salutation': [
        //     { type: 'required', message: 'Salutation is required' }
        // ],
        'FirstName': [
            { type: 'required', message: 'Please enter your first name' },
            { type: 'maxlength', message: 'First name can only have a maximum of 100 characters' }
        ],
        'LastName': [
            { type: 'required', message: 'Please enter your last name' },
            { type: 'maxlength', message: 'Last name can only have a maximum of 100 characters' }
        ],
        'Email': [
            { type: 'required', message: 'Please enter your email address' },
            { type: 'pattern', message: 'Please enter a valid email address' }
        ],
        "PrimaryPhone": [
            { type: 'required', message: 'Please enter your Phone number' },
            // { type: 'minlength', message: 'Phone Number should be atleast 7 characters' },
            // { type: 'maxlength', message: 'Phone Number can only have a maximum of 15 characters' },
            { type: 'pattern', message: 'Please enter a valid phone number using only numbers' }
        ],
        'SecondaryPhone': [
            { type: 'pattern', message: 'Please enter a valid phone number using only numbers' }
        ],
        'Address1': [
            { type: 'required', message: 'Please enter your street address' },
            { type: 'maxlength', message: 'Street address can only have a maximum of 100 characters' },
        ],
        'Address2': [
            { type: 'maxlength', message: 'Apt, Suite, Bldg can only have a maximum of 100 characters' },
        ],
        'City': [
            { type: 'required', message: 'Please enter your city' },
            { type: 'pattern', message: 'Please enter your city without special characters' },
            { type: 'maxlength', message: 'City can only have a maximum of 100 characters' },
        ],
        'State': [
            { type: 'required', message: 'Please select your state' }
        ],
        "Zip": [],
        'AccountNumber': [
            { type: "required", message: 'Please enter your bank account number' },
            { type: 'pattern', message: 'Please enter a valid account number' }
        ],
        'RoutingNumber': [],
        'AccountHolder': [
            { type: "required", message: 'Please enter the name on your bank account' },
            { type: 'pattern', message: 'Please enter a valid name on account' }
        ],
        'BankName': [
            { type: "required", message: 'Please enter the name of your bank' },
            { type: 'maxlength', message: 'Bank name can only have a maximum of 100 characters' }
        ],
        'Fname': [
            { type: "required", message: "Please enter your first name" }, { type: "hasnuber", message: "Numbers not allowed" },
            { type: "hasspecl", message: "Special charecters not allowed" },
            { type: "hasnumSpcl", message: "Numbers & special charecters not alowed" },
            { type: 'maxlength', message: 'First name can only have a maximum of 100 characters' }
        ],
        'Lname': [
            { type: "required", message: "Please enter your last name" }, { type: "hasnuber", message: "Numbers not allowed" },
            { type: "hasspecl", message: "Special charecters not allowed" },
            { type: "hasnumSpcl", message: "Numbers & special charecters not alowed" },
            { type: 'maxlength', message: 'Last name can only have a maximum of 100 characters' }
        ]
    }
    isManualPaymentMethod: boolean;
    manualPaymentMethod: any;
    Step2Fields: any[];
    Step1Fields;

    formResult: any;
    isLoading: boolean = false;
    PaymentMethods: any = [];
    datePickerId: any;
    count: any = 0;
    paymentMethodError: boolean = false;
    additionInfoMsg;
    selectedCustomDate;
    isParticipantsEmpty: boolean = false;
    totalParticipants: any = 0;
    addPartButton: boolean = false;
    savedCreditCards: any;
    selectedSavedCreditCard: boolean = false;
    addNewCard: boolean = false;
    loaderCreditNew: boolean;
    loaderCreditsaved: boolean;
    loaderAchSaved: boolean;
    cancelStatus: boolean = false;
    threeDSecure: braintree.threeDSecure;
    threedCountryList: any;
    clientToken: any;
    state: any;
    StatesList: any;
    hideBillingAddress: boolean = false;
    countryPaymentId: any;
    threeDValue: any;
    braintreeNonce: any;
    isCvvhide: any;
    paypalbutton: any;
    brainTreepaypalToken: any;
    clientTokenPaypal: any;
    paypalLoad: boolean;
    constructor(public sharedService: SharedService, public formBuilder: FormBuilder, public _crud: CommonCrudService, private ctrl: CustomControlService,
        public activeRoute: ActivatedRoute, public modalService: NgbModal, public router: Router, private el: ElementRef) {

    }
    ngOnInit(): void {
        this.GenderList = [
            {
                GenderId: 1,
                GenderName: "Male"
            },
            {
                GenderId: 2,
                GenderName: "Female"
            }
        ];
        this.accounttypeList = [
            {
                value: 'checking',
                name: 'Checking'
            },
            {
                value: 'savings',
                name: 'Savings'
            }
        ];
        
        this.getThreedsecureCountries();
        this.countryId = localStorage.getItem('countryId')
        this.primaryCountryId = localStorage.getItem('primaryCountryId')
        this.routeParam = this.activeRoute.snapshot.params;
        console.log("params-----", this.routeParam);
        this.queryParam = this.activeRoute.snapshot.queryParams;
        console.log("queryParam-----", this.queryParam);
        this.selectedMemberId = this.routeParam.memberId;
        this.getAllPrimaryCountry();
        this.formInit();
        this.getGenderList();
        this.getSalutation();
        this.getAllStates(this.countryId);
        this.getCvvhide(this.countryId);
        this.getSelectedMemberAddress(this.selectedMemberId);
        // this.getUserLocation();
        this.dynamicForm = this.ctrl.toFormGroup(this.customFields);
        this.specialRequestForm = this.ctrl.toFormGroup(this.customFieldsCheckbox);
        this.sharedService.fetchPathData('payment', 'event');
        this.eventName = this.queryParam.eventName;
        this.eventType = this.queryParam.eventType;
        this.eventDate = this.queryParam.eventDate;
        localStorage.removeItem("participant");
        this.isSpfMember = (localStorage.getItem('IsSpfMember') == 'true' ? true : false);
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        this.singleDonationExist = currentUser.singleDonationExist;

    }

    getCvvhide(countryId) {
        let params = {};
        params["url"] = `frontend-site/member/check-cvv-required/${countryId}`;
        this._crud.getData(params).subscribe(data => {
            this.isCvvhide = data['data'];
        });
    }
    animateLabel(event) {
        event.parentNode.classList.add('is-focused');
    }

    animateLabelRemove(e) {
        // console.log(e.value, "AHGKND");
        if (e.value == '') {
            e.parentNode.classList.remove('is-focused');
        }
    }
    addParticipant() {
        console.log(this.selectedMemberId, this.streamList)
        if (this.streamList != undefined) {
            const modalRef = this.modalService.open(EventParticipantdetailComponent, {
                size: "lg"
            });
            modalRef.componentInstance.eventId = this.eventForm.controls.Event.value.EventId;
            modalRef.componentInstance.IsAccommodationRequired = this.eventForm.controls.Event.value.IsAccommodationRequired;
            modalRef.componentInstance.eventForm = this.eventForm;
            modalRef.componentInstance.streamList = this.streamList;
            modalRef.componentInstance.isAgeRequired = this.isAgeRequired;
            modalRef.componentInstance.SalutationList = this.SalutationList;
            modalRef.componentInstance.Genders = this.Genders;
            modalRef.componentInstance.memberId = this.selectedMemberId;
            modalRef.result.then(
                result => {

                    if (result.result == true) {
                        let participantData
                        participantData = JSON.parse(localStorage.getItem("participant"))
                        this.calculatePrice();
                        if (participantData == null || participantData.length == 0) {
                            console.log("here");
                            this.isParticipantsEmpty = true

                        } else {
                            console.log(participantData.length, "result-----modal", this.EventDetails.MaxParticipantsPerRegistration)

                            this.isParticipantsEmpty = false
                            this.totalParticipants = participantData.length
                            if (this.EventDetails.MaxParticipantsPerRegistration != null && this.totalParticipants == this.EventDetails.MaxParticipantsPerRegistration) {
                                this.addPartButton = true;
                            } else {
                                this.addPartButton = false;

                            }
                        }
                    }
                });
        }


    }
    fecthParticipantlist() {
        const modalRef = this.modalService.open(EventParticipantlistComponent, {
            size: "lg",
            backdrop: 'static',
            keyboard: false,
        });
        modalRef.componentInstance.eventId = this.eventForm.controls.Event.value.EventId;
        modalRef.componentInstance.IsAccommodationRequired = this.eventForm.controls.Event.value.IsAccommodationRequired;
        modalRef.componentInstance.eventForm = this.eventForm;
        modalRef.componentInstance.streamList = this.streamList;
        modalRef.componentInstance.isAgeRequired = this.isAgeRequired;
        modalRef.componentInstance.SalutationList = this.SalutationList;
        modalRef.componentInstance.Genders = this.Genders;
        modalRef.componentInstance.memberId = this.memberId;
        modalRef.result.then(
            result => {
                console.log("result-----modal close from participant list", result)
                if (result.result == true) {
                    let participantData
                    participantData = JSON.parse(localStorage.getItem("participant"))
                    this.calculatePrice();
                    if (participantData == null || participantData.length == 0) {
                        console.log("here");
                        this.isParticipantsEmpty = true
                        this.totalParticipants = 0
                        this.eventForm.get('PaymentProcessParams').get('NetAmountToPay').setValue('');

                    } else {
                        this.isParticipantsEmpty = false
                        this.totalParticipants = participantData.length
                        if (this.EventDetails.MaxParticipantsPerRegistration != null && this.totalParticipants == this.EventDetails.MaxParticipantsPerRegistration) {
                            this.addPartButton = true;
                        } else {
                            this.addPartButton = false;

                        }
                    }
                }
            });
    }
    nextStep() {

        let validity = this.validateFileds();
        console.log(validity[0], this.TabActiveIndex);
        if (this.TabActiveIndex < 3) {
            if (validity[0] == 'paymentMethod') {
                this.paymentMethodError = true;
            } else {
                this.paymentMethodError = false;
            }
            if (validity[0] == undefined && !this.additionalFieldRequiredMsg && this.isParticipantsEmpty == false && this.errorFound == false) {
                this.TabActiveIndex++;
            }
        }
        if (this.TabActiveIndex == 1 && !this.additionalFieldRequiredMsg) {
            if (this.EventDetails?.SpecialRequest == '' && this.customFields.length == 0) {
                this.TabActiveIndex++;
            }
        }
    }
    prevStep() {
        if (this.TabActiveIndex <= 3 && this.TabActiveIndex != 0) {
            if (this.TabActiveIndex == 2 && this.EventDetails?.SpecialRequest == '' && this.customFields.length == 0) {
                this.TabActiveIndex = this.TabActiveIndex - 2;
            } else {
                this.TabActiveIndex--;
            }

        }

    }
    formInit() {
        this.eventForm = this.formBuilder.group({
            CountryId: [this.countryId],
            MemberId: null,
            Event: this.getEventData(),
            PaymentProcessParams: this.getPaymentParams(),
            PersonalDetails: this.getPersonalDetails(),
            ParticipantDetails: this.getParticipantDetails(),
            AddNewCard: [false],
            BillingAddress: this.getBillingAddress(),
            IsThreeDSecure: [null]

        });
        this.count = this.count + 1
    }
    getBillingAddress(): FormGroup {
        let fbGroup = this.formBuilder.group({
            AddressId: [''],
            Address1: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
            Address2: ['', Validators.maxLength(100)],
            City: ['', Validators.compose([Validators.required, Validators.pattern("[a-zA-Z0-9&' ,.\-]*$"), Validators.maxLength(100)])],
            StateId: [this.state, Validators.required],
            CountryId: [this.countryId, Validators.required],
            Zip: [''],
        })
        return fbGroup;
    }
    getAllPrimaryCountry() {
        let params = {
            url: 'frontend-site/primary-country-list'
        }
        this._crud.getData(params).subscribe(data => {
            // console.log("prome cutry list----", data['data']);
            this.PrimaryCountryList = data['data'];
            console.log(this.PrimaryCountryList, 'list pri')
            this.getCountryList(this.primaryCountryId);

        })
    }
    getGenderList() {
        this.Genders = [];
        for (let i = 0; i < this.GenderList.length; i++) {
            this.Genders.push({ Id: this.GenderList[i].GenderId, Name: this.GenderList[i].GenderName })
        }
    }
    getEventData() {
        return this.formBuilder.group({
            EventId: this.eventId,
            EventTypeId: this.eventTypeId,
            IsAccommodationRequired: [0]
        })
    }
    getPaymentParams() {
        return this.formBuilder.group({
            RegistrationAmount: [''],
            InvoiceId: [''],
            DiscountAmount: [''],
            CurrencyISOCode: this.Currency,
            NetAmountToPay: [''],
            IsAccomadationRequired: [''],
            PaymentMethodId: ['', Validators.required],
            ProceedWithoutPaymentProcess: [null],
            PaymentFields: this.getPaymentFields(),
        });
    }
    getPaymentFields(): FormGroup {
        let fbGroup = this.formBuilder.group({
            AccountNumber: [null],
            RoutingNumber: [null],
            AccountHolder: [null],
            BankName: [null],
            AccountType: [null],
            Nonce: [null],
            ChooseCard: [''],
            SaveCardForFuture: ["1"]

        })

        return fbGroup;
    }
    getPersonalDetails(): FormGroup {
        return this.formBuilder.group({
            SalutationId: [this.salutationId],
            FirstName: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
            LastName: ['', [Validators.required, Validators.maxLength(100)]],
        })
    }
    getParticipantDetails() {
        return this.formBuilder.group({
            Key: [0],
            IsSystemMember: [0],
            ParticipantDetail: this.formBuilder.array([
                this.initParticipantDetailsField()
            ]),
        })
    }
    initParticipantDetailsField() {
        return this.formBuilder.group({
            StreamId: ['', Validators.required],
            SalutationId: [null],
            Fname: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
            Lname: ['', [Validators.required, Validators.maxLength(100)]],
            Age: ['', Validators.compose([Validators.required, Validators.pattern(/^[0-9]*$/)])],
            Gender: ['', Validators.required],
            Cost: ['', Validators.required]
        });
    }
    getSalutation() {
        let params = {}
        params["url"] = "frontend-site/donation/fetch-salutation";
        this._crud.getData(params).subscribe(data => {
            this.SalutationList = [];
            for (let i = 0; i < data['data'].length; i++) {
                this.SalutationList.push({ Id: data['data'][i].SalutationId, Name: data['data'][i].SalutationName });
            }
        })
    }
    getAllPaymentMethods(countryId, fromChange: number = null) {
        let params = {}
        params["params"] = {
            sourceType: 3,
            countryId: countryId,
            sourceId: this.eventId,
            type: ''
        };
        params["url"] = "frontend-site/fetch-available-payment-methods";
        this._crud.getData(params).subscribe(data => {
            if (fromChange != null) {
                this.formInit();

            }
            this.secondAddressChecked = false;
            this.selectedPaymentMethod = '';
            this.PaymentMethods = data['data'];
            this.countryPaymentId = this.PaymentMethods[0]['CountryPaymentSettingId'];
            this.getThreedsecure();
            if(this.Currency=='INR'){
                this.PaymentMethods.forEach((element, i) => {
                    if (element.PaymentMethod == 'Paypal') {
                this.PaymentMethods.splice(i, 1);
                    }               
                })  
            }
            this.PaymentMethods.forEach((element, i) => {
                if (element.PaymentMethod == 'ACH') {
                    if ((this.countryId == 'BprLdASASGl0' && this.Currency == 'USD') || (this.countryId == 'gQFUOwHXczkw' && this.Currency == 'CAD')) {
                        // element.text = "Bank Transfer (ACH)"
                    } else {
                        this.PaymentMethods.splice(i, 1);
                    }

                }
            });
            this.PaymentMethods.forEach(element => {
                if (element.PaymentMethod == 'ACH') {
                    element.text = "Bank Transfer (ACH)"
                } else if (element.PaymentMethod == 'Credit/Debit') {
                    element.text = "Credit / Debit Card"
                }else if (element.PaymentMethod == 'gpay') {
                    element.text = "Google Pay"}
                else if (element.PaymentMethod == 'paypal') {
                    element.text = "PayPal"}
                else {
                    element.text = element.PaymentMethod;
                    // this.text = (element.BankAccountDetails).replaceAll("|", "");
                }
            });

        });

        // this.createBraintreeUI();
        let braintree = this.PaymentMethods.filter(x => { return x.UniqueName == "credit_or_debt" })
            if(braintree.length != 0) {
                this.brainTreeToken = braintree[0]?.TokenizationKey;
            }
            
            let braintreePaypal = this.PaymentMethods.filter(x => { return x.UniqueName == "paypal" })
            if(braintreePaypal.length != 0) {
                this.brainTreepaypalToken = braintreePaypal[0].TokenizationKey;
                this.clientTokenPaypal = braintreePaypal[0].CountryPaymentSettingId;
    
            }
    }

    getCountryList(PrimaryCountryId) {
        // console.log("dddd",PrimaryCountryId);
        this.SelectedPaymentOptionId = null;
        this.PaymentOptionValueId = null;
        this.paymentGatewayErrorMsg = null;
        this.selectedFrequencyName = null;
        this.InitialMethodId = null;
        this.primaryCountryId = PrimaryCountryId;
        let selectedCountry = this.PrimaryCountryList.filter(x => { return x.CountryId == PrimaryCountryId })
        this.selectedPrimaryCountry = selectedCountry[0];
        this.selectedPrimaryCountryId = PrimaryCountryId;
        let params = {
            url: 'frontend-site/fetch-countries/' + PrimaryCountryId
        }
        this._crud.getData(params).subscribe(data => {
            this.CountryList = data['data'];
            this.customCountry = [];
            for (let i = 0; i < this.CountryList.length; i++) {
                this.customCountry.push({ Id: data['data'][i].CountryId, Name: data['data'][i].Name });
            }
            let country;
            if (this.CountryList.some(x => {
                return x.CountryId == this.countryId
            })) {
                country = this.CountryList.filter(x => { return x.CountryId == this.countryId })
            }
            else {
                this.countryId = this.CountryList[0].CountryId
                this.countryName = this.CountryList[0].Name
            }
            this.getEventDetails(this.countryId);
        });
    }
    // public datePickerFieldType;
    getEventDetails(countryId) {
        let params = {}
        params["url"] = 'frontend-site/member-event/fetch-event?EventType=' + this.eventType + '&EventName=' + this.eventName
        params["params"] = {
            CountryId: countryId,
            EventDate: this.eventDate
        }
        this._crud.getData(params).subscribe(data => {
            if (data['status'] == 'success') {
                this.EventStatus = data;
                this.EventDetails = data['data'];
                if (this.EventDetails.IsAccomodationRequired == 0) {
                    this.isAccomodationRequired = true;
                } else {
                    this.isAccomodationRequired = false;
                    if (this.EventDetails.IsAccomodationRequired == 1) {
                        this.eventForm.controls['Event'].controls['IsAccommodationRequired'].setValue(1);

                    }
                }
                this.Currency = this.EventDetails.EventCurrencyCode;
                this.googlePayClick()
                this.eventForm.controls.PaymentProcessParams.controls.CurrencyISOCode.setValue(this.Currency);
                this.eventId = this.EventDetails.EventId;
                this.eventTypeId = this.EventDetails.EventTypeId;
                this.eventForm.controls['Event'].controls['EventId'].setValue(this.eventId);
                this.eventForm.controls['Event'].controls['EventTypeId'].setValue(this.eventTypeId);
                this.eventStartDate = this._crud.spaceSplit(new Date(this.EventDetails.EventStartDate).toDateString());
                this.startDay = this.eventStartDate[2];
                this.startMonth = this.eventStartDate[1];
                this.startYear = this.eventStartDate[3];
                this.eventEndDate = this._crud.spaceSplit(new Date(this.EventDetails.EventEndDate).toDateString());
                this.endDay = this.eventEndDate[2];
                this.endMonth = this.eventEndDate[1];
                this.endYear = this.eventEndDate[3];
                this.Streams = this.EventDetails.Stream;
                if (this.Streams.some(x => {
                    return x.IsAgeMandatory == 1
                })) {
                    console.log("age mandatory");
                    this.isAgeRequired = true;
                } else {
                    console.log("age not mandatory");
                    this.isAgeRequired = false;

                }
                this.customFieldsCheckbox = [];
                this.EventDetails.SpecialRequest.forEach(control => {
                    this.customFieldsCheckbox.push(new CustomCheckBox({
                        key: control.EventSpecialRequestId,
                        label: control.SpecialRequestLabel,
                        value: false,
                        required: false,
                        order: 1,
                        type: 'checkbox',
                        data: control
                    }))
                });
                console.log("custm filed checkbox---special rew---", this.customFieldsCheckbox);
                this.customFields = [];
                this.EventDetails.CustomField.forEach(control => {
                    if (control.FieldType == 'text_field') {
                        this.customFields.push(new CustomTextbox({
                            key: control.EventCustomFieldId,
                            label: control.FieldText,//(control.IsRequired == 1) ? '*' + control.FieldText : control.FieldText,
                            value: '',
                            required: (control.IsRequired == 1) ? true : false,
                            order: 1,
                            type: 'text',
                        }))
                    } else if (control.FieldType == 'text_area') {
                        this.customFields.push(new CustomTextarea({
                            key: control.EventCustomFieldId,
                            label: control.FieldText,//(control.IsRequired == 1) ? '*' + control.FieldText : control.FieldText,
                            value: '',
                            required: (control.IsRequired == 1) ? true : false,
                            order: 1,
                            type: 'text'
                        }))
                    } else if (control.FieldType == 'date_picker') {
                        this.datePickerId = control.EventCustomFieldId;
                        // this.datePickerFieldType = control.FieldType;
                        this.customFields.push(new CustomTextboxDatepicker({
                            key: control.EventCustomFieldId,
                            fieldType: control.FieldType,
                            label: control.FieldText, //label: (control.IsRequired == 1) ? '*' + control.FieldText : control.FieldText,
                            value: '',
                            required: (control.IsRequired == 1) ? true : false,
                            order: 1,
                            type: 'text'
                        }))
                    }
                });
                // console.log("customFields----adda--", this.customFields);
                this.dynamicForm = this.ctrl.toFormGroup(this.customFields);
                // console.log("customFields----adda-dynamicForm-", this.dynamicForm);
                this.specialRequestForm = this.ctrl.toFormGroup(this.customFieldsCheckbox);
                this.streamList = [];
                for (let i = 0; i < this.Streams.length; i++) {
                    this.streamList.push({
                        Id: this.Streams[i].StreamId, Name: this.Streams[i].EventStream, MaximumAge: this.Streams[i].MaximumAge,
                        MinimumAge: this.Streams[i].MinimumAge, MaxParticipantCountPerRegistration: this.Streams[i].MaxParticipantCountPerRegistration
                    })
                }
                this.getAllPaymentMethods(this.countryId);
            } else {
                this.EventStatus = data;
            }

        });
    }
    onLoadPaymentData = (
        event: google.payments.api.PaymentData
      ): void => {
      }
      onClickPreventDefault = (event: Event): void => {
		
       
      };
      onPaymentDataAutherized:google.payments.api.PaymentAuthorizedHandler=(PaymentData)=>{
        console.log("payment autherized", PaymentData.paymentMethodData.tokenizationData.token );
        var array = PaymentData.paymentMethodData.tokenizationData.token.split(" ");
        
        var datas =array[0].split('{')
        var arrays=datas[2].split(':')
        var value =arrays[2].split('" "');
      
        var values = value[0].split('","')
        var final= values[0].split('"\"')
        console.log(final[0].split('"'));
        var finalvalue=final[0].split('"')
        this.nonce=finalvalue[1]
        console.log("nonce",this.nonce);
      this.submitForm()
    //     this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(this.nonce);
    //    this.formSumbit()
        // } else {
        //     this.buttonStatus = false;
        //     console.log("enale", this.buttonStatus);
        //     if (this.captcharesponse == "yes") {
        //         this.captcha = false;
        //     } else {
        //         this.captcharesponse = ''
        //         this.captcha = true;
        //     }
        // }
        this.googlePayClick()
        return{
            transactionState:"SUCCESS"
        }
      

      }
	googlePayClick(){
        console.log("mid","rkr",this.merchantId,this.tokenizationkey);
      
        this. paymentRequest ={
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
              {
                type: "CARD",
                parameters: {
                    billingAddressRequired: true,
                    billingAddressParameters: {
                      format: "FULL",
                      phoneNumberRequired: true
                    },
        
                  allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                  allowedCardNetworks: ["AMEX", "VISA", "MASTERCARD"]
                },
                tokenizationSpecification: {
                  type: "PAYMENT_GATEWAY",
                
                  parameters: {
                    "gateway": "braintree",
                    "braintree:apiVersion": "v1",
                    "braintree:sdkVersion": braintree.client.VERSION,
                    "braintree:merchantId": this.merchantId,
                    "braintree:clientKey": this.tokenizationkey
                  }
                }
              }
            ],
            merchantInfo: {
              merchantId:this.merchantId,
              merchantName: "braintree"
            },
            transactionInfo: {
              totalPriceStatus: "FINAL",
              totalPriceLabel: "Total",
              totalPrice: this.eventForm.controls.PaymentProcessParams.controls.NetAmountToPay.value,
              currencyCode:this.Currency,
              countryCode: "US"
            },   
              
            callbackIntents:['PAYMENT_AUTHORIZATION']
          
              
          };
        
    }
    changeAccomodation(e) {
        console.log("inside chnage accomdation", e)
        let accomodateValue = e == true ? 1 : 0;
        this.eventForm.controls['Event'].controls['IsAccommodationRequired'].setValue(accomodateValue);
        // commenting this calculation call ,check later,css radio button clr issue
        this.calculation();
    }
    calculation() {
        let participantData
        participantData = JSON.parse(localStorage.getItem("participant"))
        // if (this.eventForm.controls.ParticipantDetails.controls.ParticipantDetail.controls[0].controls.StreamId.value != '') {
        if (participantData == null || participantData.length == 0) {
            console.log("here");
            // this.isParticipantsEmpty = true

        }
        else {
            let param = {};
            param['url'] = 'frontend-site/member-event/calculate-price';
            param['params'] = {};
            param['params']['EventId'] = this.eventForm.controls.Event.value.EventId,
                param['params']['IsAccommodationRequired'] = this.eventForm.controls.Event.value.IsAccommodationRequired;
            param['params']['ParticipantData'] = [];

            param['params']['SpecialRequest'] = [];
            let index = 0;
            if (this.eventForm.controls.ParticipantDetails.controls.ParticipantDetail.controls.length == 0) {
                this.emptyParticipant = "Please add details for atleast one participant";
                this.errorMessage = '';
            } else {
                this.emptyParticipant = "";
            }
            // for (const formGroup of this.eventForm.controls.ParticipantDetails.controls.ParticipantDetail.controls) {
            //     if (!(formGroup.get('StreamId').value == '')) {
            //         param['params']['ParticipantData'].push({
            //             "Key": index,
            //             'IsSystemMember': '0',
            //             'MemberId': null,
            //             'ParticipantDetail': {
            //                 'StreamId': formGroup.get('StreamId').value,
            //                 'Fname': formGroup.get('Fname').value,
            //                 'Lname': formGroup.get('Lname').value,
            //                 'SalutationId': formGroup.get('SalutationId').value,
            //                 'Age': formGroup.get('Age').value,
            //                 'Gender': formGroup.get('Gender').value
            //             }
            //         });
            //     }
            //     index++;
            // }
            let participantData
            participantData = JSON.parse(localStorage.getItem("participant"))
            // for (const e of participantData) {
            participantData.forEach((e, ind) => {
                if (!(e[0].ParticipantDetail.StreamId == '')) {
                    param['params']['ParticipantData'].push({
                        "Key": index,
                        'IsSystemMember': '0',
                        'MemberId': null,
                        'ParticipantDetail': {
                            'StreamId': e[0].ParticipantDetail.StreamId,
                            'Fname': e[0].ParticipantDetail.Fname,
                            'Lname': e[0].ParticipantDetail.Lname,
                            'SalutationId': e[0].ParticipantDetail.SalutationId,
                            'Age': e[0].ParticipantDetail.Age,
                            'Gender': e[0].ParticipantDetail.Gender,
                        }
                    });
                }
                index++;
                // }
            });
            for (let i in this.specialRequestForm.value) {
                param['params']['SpecialRequest'].push({
                    "Id": i,
                    "Value": (this.specialRequestForm.value[i]) ? 1 : 0
                });
            }
            // if (this.eventForm.controls.ParticipantDetails.controls.ParticipantDetail.controls[0] != undefined &&
            // this.eventForm.controls.ParticipantDetails.controls.ParticipantDetail.controls[0].controls.StreamId.value != '') {
            this._crud.postData(param)
                .subscribe(data => {
                    if (data['status'] != undefined && data['status'] == 'error') {
                        this.errorFound = true;
                        this.errorMessage = data['msg'];
                        this.emptyParticipant = '';
                        this.eventForm.get('PaymentProcessParams').get('NetAmountToPay').setValue('');
                    } else {
                        this.errorFound = false;
                        this.errorMessage = '';

                        for (let i in data['data']['Data']) {
                            console.log(data['data']['Data'][i].ParticipantDetail.Price)
                            // this.eventForm.get('ParticipantDetails').get('ParticipantDetail')['controls'][data['data']['Data'][i].Key].get('Cost').setValue(data['data']['Data'][i].ParticipantDetail.Price);
                            participantData[data['data']['Data'][i].Key][0].ParticipantDetail.Cost = data['data']['Data'][i].ParticipantDetail.Price;

                        }
                        this.eventForm.get('PaymentProcessParams').get('NetAmountToPay').setValue(data['data'].TotalPrice);


                        if (data['data'].TotalPrice > 0) {
                            this.zeroAmount = 0;
                            for (let i in data['data']['Data']) {
                                // this.eventForm.get('ParticipantDetails')['controls'][data['data']['Data'][i].Key].get('Cost').setValue(data['data']['Data'][i].ParticipantDetail.Price);
                                participantData[data['data']['Data'][i].Key][0].ParticipantDetail.Cost = data['data']['Data'][i].ParticipantDetail.Price;

                            }
                            this.eventForm.get('PaymentProcessParams').get('NetAmountToPay').setValue(data['data'].TotalPrice);

                        } else if (data['data'].TotalPrice == 0) {
                            console.log("zero amount");
                            this.zeroAmount = 1;
                            this.selectedPaymentMethod = 'zero'
                        }
                        else {
                            $('.m-checkbox #' + this.SelectedRequestId).prop('checked', false);
                            this.specialRequestForm.controls[this.SelectedRequestId].setValue('');

                        }
                    }

                    setTimeout(() => {
                    }, 0);
                }, error => {
                    console.log("Some error tiggered" + error)
                });
            // } 
            // else {
            //     this.SelectedRequestId.get('PaymentProcessParams').get('NetAmountToPay').setValue('');
            // }
        }
    }
    calculatePrice() {
        let participantData
        participantData = JSON.parse(localStorage.getItem("participant"))
        // if (this.eventForm.controls.ParticipantDetails.controls.ParticipantDetail.controls[0].controls.StreamId.value != '') {
        if (participantData == null || participantData.length == 0) {
            console.log("here");
            this.isParticipantsEmpty = true
            this.errorFound = false;
            this.errorMessage = ''
            //     for (let i in this.eventForm.controls.ParticipantDetails.controls.ParticipantDetail.controls) {
            //         // if (this.DonationForm.controls.ParticipantDetails.controls.ParticipantDetail.controls[i].controls.Age.value != '') {
            //         if (this.isAgeRequired == true ? this.eventForm.controls.ParticipantDetails.controls.ParticipantDetail.controls[index].controls.Age.value != '' : this.eventForm.controls.ParticipantDetails.controls.ParticipantDetail.controls[index].controls.Age.value == '') {
            //             if (this.eventForm.controls.ParticipantDetails.controls.ParticipantDetail.controls[index].controls.Fname.value == '' ||
            //                 this.eventForm.controls.ParticipantDetails.controls.ParticipantDetail.controls[index].controls.Lname.value == '') {
            //                 this.errorMessage = "Please fill all the fields";
            //                 this.emptyParticipant = '';
            //                 return this.errorFound = true;
        } else {
            this.isParticipantsEmpty = false
            let param = {};
            param['url'] = 'frontend-site/event/calculate-price';
            param['params'] = {};
            param['params']['EventId'] = this.eventForm.controls.Event.value.EventId,
                param['params']['IsAccommodationRequired'] = this.eventForm.controls.Event.value.IsAccommodationRequired;
            // param['params']['ParticipantData'] = this.DonationForm.controls.ParticipantDetails.controls.ParticipantDetail.value;
            param['params']['ParticipantData'] = [];
            // param['params']['CustomFieldValues'] = [];
            // for (let x in this.dynamicForm.value) {
            //     param['params']['CustomFieldValues'].push({
            //         "Id": x,
            //         "Value": this.dynamicForm.value[x]
            //     })
            // }
            param['params']['SpecialRequest'] = [];
            let index = 0;
            // for (const formGroup of this.eventForm.controls.ParticipantDetails.controls.ParticipantDetail.controls) {
            //     if (!(formGroup.get('StreamId').value == '')) {
            //         param['params']['ParticipantData'].push({
            //             "Key": index,
            //             'IsSystemMember': '0',
            //             'MemberId': null,
            //             'ParticipantDetail': {
            //                 'StreamId': formGroup.get('StreamId').value,
            //                 'Fname': formGroup.get('Fname').value,
            //                 'Lname': formGroup.get('Lname').value,
            //                 'SalutationId': formGroup.get('SalutationId').value,
            //                 'Age': formGroup.get('Age').value,
            //                 'Gender': formGroup.get('Gender').value
            //             }
            //         });
            //     }
            //     index++;
            // }

            // for (const e of participantData) {
            participantData.forEach((e, ind) => {
                if (!(e[0].ParticipantDetail.StreamId == '')) {
                    param['params']['ParticipantData'].push({
                        "Key": index,
                        'IsSystemMember': '0',
                        'MemberId': null,
                        'ParticipantDetail': {
                            'StreamId': e[0].ParticipantDetail.StreamId,
                            'Fname': e[0].ParticipantDetail.Fname,
                            'Lname': e[0].ParticipantDetail.Lname,
                            'SalutationId': e[0].ParticipantDetail.SalutationId,
                            'Age': e[0].ParticipantDetail.Age,
                            'Gender': e[0].ParticipantDetail.Gender,

                        }
                    });
                }
                index++;
                // }
            });

            for (let i in this.specialRequestForm.value) {
                param['params']['SpecialRequest'].push({
                    "Id": i,
                    "Value": (this.specialRequestForm.value[i]) ? 1 : 0
                });
            }

            // if (this.eventForm.controls.ParticipantDetails.controls.ParticipantDetail.controls[0].controls.StreamId.value != '') {
            this._crud.postData(param)
                .subscribe(data => {

                    console.log("calcualte prce----------->", data);
                    if (data['status'] != undefined && data['status'] == 'error') {

                        this.errorFound = true;
                        this.errorMessage = data['msg'];
                        this.emptyParticipant = '';
                        this.eventForm.get('PaymentProcessParams').get('NetAmountToPay').setValue('');
                    } else {
                        this.errorFound = false;
                        this.errorMessage = '';
                        // issue place form get
                        for (let i in data['data']['Data']) {
                            // console.log("data1-----------",data['data']['Data'][i]);
                            // console.log("data1  i -----------",i);
                            // this.eventForm.get('ParticipantDetails').get('ParticipantDetail')['controls'][data['data']['Data'][i].Key].get('Cost').setValue(data['data']['Data'][i].ParticipantDetail.Price);
                            participantData[data['data']['Data'][i].Key][0].ParticipantDetail.Cost = data['data']['Data'][i].ParticipantDetail.Price;

                        }
                        this.eventForm.get('PaymentProcessParams').get('NetAmountToPay').setValue(data['data'].TotalPrice);
                        // console.log(this.eventForm.get('PaymentProcessParams').get('NetAmountToPay').value);
                        // this.discountCalculate();
                        if (data['data'].TotalPrice > 0) {
                            for (let i in data['data']['Data']) {

                                // this.eventForm.get('ParticipantDetails').get('ParticipantDetail')['controls'][data['data']['Data'][i].Key].get('Cost').setValue(data['data']['Data'][i].ParticipantDetail.Price);
                                participantData[data['data']['Data'][i].Key][0].ParticipantDetail.Cost = data['data']['Data'][i].ParticipantDetail.Price;

                            }
                            this.eventForm.get('PaymentProcessParams').get('NetAmountToPay').setValue(data['data'].TotalPrice);
                            // this.discountCalculate();
                            this.zeroAmount = 0;
                        } else if (data['data'].TotalPrice == 0) {
                            console.log("zero amount");
                            this.zeroAmount = 1;
                            this.selectedPaymentMethod = 'zero'
                        }
                        else {

                            $('.m-checkbox #' + this.SelectedRequestId).prop('checked', false);
                            this.specialRequestForm.controls[this.SelectedRequestId].setValue('');

                        }
                    }

                    setTimeout(() => {

                    }, 0);
                }, error => {
                    console.log("Some error tiggered" + error)
                });
        }

        // }
        // }

        //     }
        // }
    }
    // setFname(index) {
    //     this.eventForm.controls['PersonalDetails'].controls['FirstName'].setValue(this.eventForm.get('ParticipantDetails').get('ParticipantDetail')['controls'][0]['controls'].Fname.value);
    //     // document.getElementById("firstname").classList.add("is-focused");
    //     this.calculatePrice(index);
    // }
    // setLname(index) {
    //     this.eventForm.controls['PersonalDetails'].controls['LastName'].setValue(this.eventForm.get('ParticipantDetails').get('ParticipantDetail')['controls'][0]['controls'].Lname.value);
    //     // document.getElementById("lastname").classList.add("is-focused");
    //     this.calculatePrice(index);
    // }
    // setTitle() {
    //     this.eventForm.controls['PersonalDetails'].controls['SalutationId'].setValue(this.eventForm.get('ParticipantDetails').get('ParticipantDetail')['controls'][0]['controls'].SalutationId.value);

    // }
    addParticipantDetailsFiled(): void {
        const control = <FormArray>this.eventForm.controls.ParticipantDetails.controls.ParticipantDetail;
        control.push(this.initParticipantDetailsField());
    }
    removeParticipantDetailsFiled(i: number): void {
        if (i == 0) {
            if (this.eventForm.controls['PersonalDetails'].controls['FirstName'].value == this.eventForm.get('ParticipantDetails').get('ParticipantDetail')['controls'][0]['controls'].Fname.value &&
                this.eventForm.controls['PersonalDetails'].controls['LastName'].value == this.eventForm.get('ParticipantDetails').get('ParticipantDetail')['controls'][0]['controls'].Lname.value) {
                this.eventForm.controls['PersonalDetails'].controls['FirstName'].setValue('');
                this.eventForm.controls['PersonalDetails'].controls['LastName'].setValue('');
                this.eventForm.controls['PersonalDetails'].controls['SalutationId'].setValue(null);
                document.getElementById("firstname").classList.remove("is-focused");
                document.getElementById("lastname").classList.remove("is-focused");
                document.getElementById("salutation_id").classList.remove("is-focused");


            }
        }
        const control = <FormArray>this.eventForm.controls.ParticipantDetails.controls.ParticipantDetail;
        control.removeAt(i);
        this.calculation();
    }
    specialRequestChange(event) {
        console.log("special requst change----", event);
        this.selectedFrequencyId = event.key;
        this.calculation();
    }
    onPaymentMethodChange(paymentMethod) {
        this.paymentId=paymentMethod
        console.log("this.selectedPaymentMethod=========",this.selectedPaymentMethod,paymentMethod)
        this.isLoading = false;
        this.paymentMethodError = false;
        this.addNewCard = false;
        this.eventForm.controls.AddNewCard.setValue(false);
        let PaymentFields = this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'];
        let selectedId = this.PaymentMethods.filter(x => { return x.PaymentMethodId == paymentMethod });
        this.paymentSettingId = selectedId[0].CountryPaymentSettingId;
        this.InitialMethodId = selectedId[0].PaymentMethodId;
        this.merchantId=   selectedId[0].UniqueId
        this.tokenizationkey=selectedId[0].TokenizationKey
        this.googlePayClick()
        this.selectedPaymentMethod = selectedId[0].UniqueName;
        let details = this.PaymentMethods.filter(x => { return x.PaymentMethodId == paymentMethod });
        this.paymentName=selectedId[0].UniqueName
        this.selectedPaymentname = selectedId[0].PaymentMethod;
        if (this.selectedPaymentMethod == "ach" || this.selectedPaymentMethod == "credit_or_debt"  || this.selectedPaymentMethod == "paypal" || this.selectedPaymentMethod == "gpay") {
            // console.log("11111");
            if (this.selectedPaymentMethod == "credit_or_debt") {
                PaymentFields.get('ChooseCard').setValidators(Validators.required);

                // this.getClientToken();
            } 
            else if(this.selectedPaymentMethod == "paypal") {
                this.getClientToken()
            }
            else {
                this.loaderAchSaved = true

            }
            // this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(1);
            this.eventForm.controls['PaymentProcessParams'].controls['ProceedWithoutPaymentProcess'].setValue(0);
        } else {
            // console.log("2222");
            if(details[0].BankAccountDetails!=null){
            this.text = (details[0].BankAccountDetails).replaceAll("|", "");
            }
            this.selectedPaymentMethod = "manual_payment"
            // this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(0);
            this.eventForm.controls['PaymentProcessParams'].controls['ProceedWithoutPaymentProcess'].setValue(1);
        }
        if (this.selectedPaymentMethod == "ach") {
            PaymentFields.get('ChooseCard').setValidators(Validators.required);

            PaymentFields.get('AccountHolder').setValidators(Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z '&-]+$/)]));
            PaymentFields.get('AccountNumber').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{6,17}$/)]));
            // PaymentFields.get('BankName').setValidators(Validators.compose([Validators.required, Validators.maxLength(100)]));
            PaymentFields.get('AccountType').setValidators([Validators.required]);
            if (this.primaryCountryId == 'BprLdASASGl0') {
                // PaymentFields.get('RoutingNumber').setValidators(Validators.compose([Validators.required, Validators.pattern('(?:[0-9]●?){8}[0-9]')]));
                PaymentFields.get('RoutingNumber').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{9,9}$/)]));
                PaymentFields.get('RoutingNumber').updateValueAndValidity();
                this.ValidationMessage.RoutingNumber = [
                    { type: "required", message: 'Please enter a valid routing number' },
                    { type: 'pattern', message: 'Please enter a valid routing number with nine digits' }
                ]
            } else if (this.primaryCountryId == 'gQFUOwHXczkw') {
                PaymentFields.get('RoutingNumber').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{8,8}$/)]));
                PaymentFields.get('RoutingNumber').updateValueAndValidity();
                this.ValidationMessage.RoutingNumber = [
                    { type: "required", message: 'Please enter a valid routing number' },
                    { type: 'pattern', message: 'Please enter a valid routing number with eight digits' }
                ]
            } else {
                PaymentFields.get('RoutingNumber').setValidators(Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')]));
                PaymentFields.get('RoutingNumber').updateValueAndValidity();
                this.ValidationMessage.RoutingNumber = [
                    { type: "required", message: 'Please enter your bank routing number' },
                    { type: 'pattern', message: 'Please enter a valid routing number using only numbers' }
                ]
            }
        }
        else {
            PaymentFields.get('AccountHolder').setValidators(null);
            PaymentFields.get('RoutingNumber').setValidators(null);
            PaymentFields.get('AccountNumber').setValidators(null);
            PaymentFields.get('BankName').setValidators(null);
            PaymentFields.get('AccountType').setValidators(null);
        }
        PaymentFields.get('AccountHolder').updateValueAndValidity();
        PaymentFields.get('RoutingNumber').updateValueAndValidity();
        PaymentFields.get('AccountNumber').updateValueAndValidity();
        PaymentFields.get('BankName').updateValueAndValidity();
        PaymentFields.get('AccountType').updateValueAndValidity();
        console.log(selectedId);
        // commmenting this code for now, need for fetching saved cards
        if (this.selectedPaymentMethod != "credit_or_debt") {
            let params = {};
            params['url'] = 'frontend-site/member/client-token/' + selectedId[0].CountryPaymentSettingId + '/' + this.selectedMemberId;
            params['params'] = {};
            return this._crud.getData(params)
                .subscribe(data => {
                    if (data['status'] == "success") {
                        // console.log("ach saved cards-------",data['data']['SavedCards']);
                        if (data['data']['SavedCards'] != null && data['data']['SavedCards'].length > 0) {
                            // console.log("saved card present---------------")
                            this.IsSavedAchPresent = true;
                            this.savedAchCardButtonShow = true;
                            this.savedCards = data['data']['SavedCards'];
                            this.showNewCardOption = false;
                            this.loaderAchSaved = false

                        } else {
                            this.savedAchCardButtonShow = false;
                            this.showNewCardOption = true;
                            this.IsSavedAchPresent = false;
                            this.addNewCard = true
                            console.log("trueeeeeeeeeeeeeeeee")
                            this.loaderAchSaved = false

                        }
                    }
                }, error => {
                    console.log("Some error tiggered" + error)
                });
        } else {
            this.savedCreditCard()
            // let params = {};
            // params['url'] = 'frontend-site/member/credit-card-exist' + '/' + this.routeParam.memberId;
            // params['params'] = {};
            // return this._crud.getData(params).subscribe(response => {
            //     console.log("saved credit card exit for use-----", response);
            //     this.savedCreditCardButtonShow = response['data'];
            // })
        }
    }


    AddNewAchAccount() {
        this.showNewCardOption = true;
        this.isSavedCard = true
        this.selectedSavedCard = false;
        this.IsSavedAchPresent = false;
        //clearing new accunt card ach values on tab switch
        //if values are present in the new card form animate the placeholdr up,to avoid overlaping 
        if (this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder').value) {
            // console.log("accunt holder",document.getElementById("ach_person_name"))
            // document.getElementById("ach_person_name").classList.add("is-focused");
            this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder').setValue('');
            // this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder').setValidators(null);
        }
        if (this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber').value) {
            // console.log("accunt holder routing")
            // document.getElementById("ach_routing_number").classList.add("is-focused");
            this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber').setValue('');
            // this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber').setValidators(null);
        }
        if (this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber').value) {
            //    / console.log("accunt number",document.getElementById("ach_account_number")) 
            // document.getElementById("account_number").classList.add("is-focused");
            this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber').setValue('');
            // this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber').setValidators(null);

        }
        if (this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType').value) {
            this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType').setValue('');
        }
    }
    listsavedAchAccount() {
        this.IsSavedAchPresent = true;
        this.showNewCardOption = false;
    }

    listreditCard() {
        this.shownewCreditcardOption = false;
        this.showSavedCreditcardOption = true;
    }
    onSelectedSavedcardChange(cardDetails) {
        // console.log("inside saved card change------->",cardDetails);
        this.showNewCardOption = false;
        this.selectedSavedCard = true;
        this.addNewCard = false;
        this.eventForm.controls['AddNewCard'].setValue(false)
    }
    getClientToken() {
        console.log("craeting clint token")
        let selectedId = this.PaymentMethods.filter(x => { return x.UniqueName == this.selectedPaymentMethod });
        this.clientToken = selectedId[0].CountryPaymentSettingId
        console.log("client token", this.clientToken);

        let params = {};
        params['url'] = 'frontend-site/member/client-token/' + selectedId[0].CountryPaymentSettingId + '/' + this.selectedMemberId;
        params['params'] = {};
        this._crud
            .getData(params)
            .subscribe(data => {
                console.log(data['data'].ClientToken,this.selectedPaymentMethod, "maped clint response")
                // return res.data.ClientToken;
                this.hostedClientToken = data['data'].ClientToken;
                if(this.selectedPaymentMethod == "credit_or_debt") {
                    this.createBraintreeUI();
                }
                else if(this.selectedPaymentMethod == "paypal") {
                    console.log("tokennnnnnnnnnnn", this.hostedClientToken)
                    this.createPayapalUI(this.hostedClientToken);
                }
            });

    }
    savedCreditCard() { // member/saved-cards/{member_id}
        console.log("craeting clint token")
        this.loaderCreditsaved = true
        let params = {};
        params['url'] = 'frontend-site/member/saved-cards/' + this.selectedMemberId + '/' + this.paymentSettingId;
        params['params'] = {};
        this._crud
            .getData(params)
            .subscribe(data => {
                console.log(data['data']['SavedCards'], "maped clint response")
                this.savedCreditCards = data['data']['SavedCards']
                if (data['data']['SavedCards'] != null && this.savedCreditCards.length > 0) {
                    this.shownewCreditcardOption = true
                    this.loaderCreditsaved = false

                } else {
                    this.shownewCreditcardOption = false
                    this.addNewCard = true
                    this.getClientToken()

                }
                // return res.data.ClientToken;
            });
    }

    successPopup(data) {
        if (data['status'] == 'success') {
            console.log('in success')
            this.response = { title: 'Thank You', message: 'Your registration has been processed successfully' }
        }
        else {
            // this.response = {title: data['status'],message: data['msg']}
            this.response = { title: 'Error', message: data['msg'] }
            console.log('in error')
        }
        const modalRef = this.modalService.open(SuccessPopupComponent, { size: "md" });
        modalRef.componentInstance.user = this.response;
        modalRef.result.then(
            result => {
                //   console.log("result-----modal",result)
                if (result.result && data['status'] == "success") {
                    //redirecting to eventlisting screen on sucees event registration
                    this.router.navigateByUrl("/memberDashboard/myeventregistration/" + this.selectedMemberId)
                    // /clearing all forms.
                    this.eventForm.reset();
                    this.selectedPaymentMethod = "";
                    $('.focus-label.is-focused').removeClass('is-focused');
                    this.Currency = null;
                    this.formInit();
                    this.ngOnInit();
                } 
                else {
                    this.eventForm.controls['PaymentProcessParams'].controls['PaymentMethodId'].setValue(0);
                    // this.router.navigateByUrl("/memberDashboard/myeventregistration/" + this.selectedMemberId)
                    // this.selectedPaymentMethod = "";
                    // this.eventForm.controls['PaymentProcessParams'].controls['PaymentMethodId'].setValue(0);
                }
            });
    }
    onPaymentStatus(response): void {
        // console.log(response);
    }
    public isSavedCard: boolean = false;
    payButtonStatus(response): void {
        if ($(".braintree-method.braintree-methods-initial").length == 0) {
            this.isSavedCard = response;
        }
    }

    RequestPostParams(withpayment = null, nonce = null, requestType?: any) {
        // console.log(this.eventForm);
        //   this.enableManualPay();
        this.postDataJsonPrepare = {};
        if(withpayment.type=="gpay"){
            this.postDataJsonPrepare['IsThreeDSecure'] = false;
        }else{
            this.postDataJsonPrepare['IsThreeDSecure'] = this.threeDValue;
        }
     
        this.postDataJsonPrepare['MemberId'] = this.selectedMemberId;
        this.postDataJsonPrepare['Event'] = {};
        this.postDataJsonPrepare['Event']['EventId'] = this.eventForm.controls.Event.controls.EventId.value;
        this.postDataJsonPrepare['Event']['EventTypeId'] = this.eventForm.controls.Event.controls.EventTypeId.value;
        this.postDataJsonPrepare['Event']['IsAccommodationRequired'] = (this.eventForm.controls.Event.controls.IsAccommodationRequired.value) ? 1 : 0;
        this.postDataJsonPrepare['CustomFieldValues'] = [];
        if (this.dynamicForm.invalid) {
            console.log("dynmic form id not valid")
            this.additionalFieldRequiredMsg = true;
            console.log("dynamic form is not valid inside reqat form", this.additionalFieldRequiredMsg)
        }
        for (let x in this.dynamicForm.value) {
            // console.log("x-------dynmic form----",x,this.datePickerId,this.dynamicForm.value)
            this.postDataJsonPrepare['CustomFieldValues'].push({
                "Id": x,
                // "Value": (this.datePickerId == x ? new DatePipe('en-US').transform(this.dynamicForm.value[x].singleDate.formatted, 'MMM-dd-yyyy') : this.dynamicForm.value[x])
                // code to handle multiple datepiker values
                // "Value": ((this.dynamicForm.value[x]?.singleDate?.formatted) ? new DatePipe('en-US').transform(this.dynamicForm.value[x].singleDate.formatted, 'MMM-dd-yyyy') : this.dynamicForm.value[x])
                "Value": ((this.dynamicForm.value[x]?.singleDate?.formatted) ? this.dynamicForm.value[x].singleDate.formatted : this.dynamicForm.value[x])

            })
        }

        this.postDataJsonPrepare['ParticipantDetails'] = [];
        let index = 0;
        // for (const formGroup of this.eventForm.controls.ParticipantDetails.controls.ParticipantDetail.controls) {

        //     this.postDataJsonPrepare['ParticipantDetails'].push({
        //         "Key": index,
        //         'IsSystemMember': '0',
        //         'MemberId': this.selectedMemberId,
        //         'ParticipantDetail': {
        //             'StreamId': formGroup.get('StreamId').value,
        //             'Fname': formGroup.get('Fname').value,
        //             'Lname': formGroup.get('Lname').value,
        //             'SalutationId': formGroup.get('SalutationId').value,
        //             'Age': formGroup.get('Age').value,
        //             'Gender': formGroup.get('Gender').value
        //         }
        //     });
        //     index++;
        // }
        let participantData
        participantData = JSON.parse(localStorage.getItem("participant"))
        // for (const e of participantData) {
        participantData.forEach((e, ind) => {
            if (!(e[0].ParticipantDetail.StreamId == '')) {
                this.postDataJsonPrepare['ParticipantDetails'].push({
                    "Key": index,
                    'IsSystemMember': '0',
                    'MemberId': this.selectedMemberId,
                    'ParticipantDetail': {
                        'StreamId': e[0].ParticipantDetail.StreamId,
                        'Fname': e[0].ParticipantDetail.Fname,
                        'Lname': e[0].ParticipantDetail.Lname,
                        'SalutationId': e[0].ParticipantDetail.SalutationId,
                        'Age': e[0].ParticipantDetail.Age,
                        'Gender': e[0].ParticipantDetail.Gender,
                    }
                });
            }
            index++;
            // }
        });
        this.postDataJsonPrepare['SpecialRequest'] = [];

        for (let x in this.specialRequestForm.value) {
            this.postDataJsonPrepare['SpecialRequest'].push({
                "Id": x,
                "Value": (this.specialRequestForm.value[x]) ? "1" : "0"
            })
        }

        this.postDataJsonPrepare['PaymentProcessParams'] = {};
        if (this.zeroAmount == 1) {
            this.postDataJsonPrepare['PaymentProcessParams']['ProceedWithoutPaymentProcess'] = 1;

        } else {

            this.postDataJsonPrepare['PaymentProcessParams']['ProceedWithoutPaymentProcess'] = this.eventForm.controls['PaymentProcessParams'].controls['ProceedWithoutPaymentProcess'].value;

        }

        this.postDataJsonPrepare['PaymentProcessParams']['RegistrationAmount'] = this.eventForm.controls.PaymentProcessParams.controls.NetAmountToPay.value;
        this.postDataJsonPrepare['PaymentProcessParams']['InvoiceId'] = "";
        this.postDataJsonPrepare['PaymentProcessParams']['DiscountAmount'] = this.eventForm.controls.PaymentProcessParams.controls.DiscountAmount.value;
        this.postDataJsonPrepare['PaymentProcessParams']['CurrencyISOCode'] = this.eventForm.controls.PaymentProcessParams.controls.CurrencyISOCode.value;
        this.postDataJsonPrepare['PaymentProcessParams']['NetAmountToPay'] = this.eventForm.controls.PaymentProcessParams.controls.NetAmountToPay.value;
        this.postDataJsonPrepare['PaymentProcessParams']['IsAccomadationRequired'] = (this.eventForm.controls.PaymentProcessParams.controls.IsAccomadationRequired.value) ? 1 : 0;
        this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields'] = {};
        this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields']['BillingAddress'] = {};
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["AddressId"] = this.eventForm.controls.BillingAddress.controls.AddressId.value
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["Address1"] = this.eventForm.controls.BillingAddress.controls.Address1.value
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["Address2"] = this.eventForm.controls.BillingAddress.controls.Address2.value
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["City"] = this.eventForm.controls.BillingAddress.controls.City.value
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["StateId"] = this.eventForm.controls.BillingAddress.controls.StateId.value
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["CountryId"] = this.countryId
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["Zip"] = this.eventForm.controls.BillingAddress.controls.Zip.value

        this.postDataJsonPrepare["PaymentProcessParams"][
            "PaymentMethodId"
        ] = this.InitialMethodId;
        if (withpayment.type == "ach") {
            // if (this.selectedSavedCard == false) {
            if (this.savedCardLoding == false) {
                this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    "AccountHolder"
                ] = this.eventForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountHolder.value;
                this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    "AccountNumber"
                ] = this.eventForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountNumber.value;
                this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    "RoutingNumber"
                ] = this.eventForm.controls.PaymentProcessParams.controls.PaymentFields.controls.RoutingNumber.value;
                this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    "AccountType"
                ] = this.eventForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountType.value;

                if (this.eventForm.controls.PaymentProcessParams.controls.PaymentFields.controls.SaveCardForFuture.value) {
                    this.postDataJsonPrepare["PaymentProcessParams"][
                        "SaveCardForFuture"
                    ] = "1";
                } else {
                    this.postDataJsonPrepare["PaymentProcessParams"][
                        "SaveCardForFuture"
                    ] = "0";
                }
            }
            else {
                this.postDataJsonPrepare["PaymentProcessParams"][
                    "PaymentGatewayTokenId"
                ] = this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value;
            }
        } else if (withpayment.type == "credit_or_debt") {
            if (this.savedCardLoding == false) {
                console.log('in if save', nonce)
                this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    "Nonce"
                ] = nonce;
            } else {
                console.log('inside else')
                if(requestType == 'submit') {
                    console.log('inifco')
                    this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["Nonce"] = nonce;
                }
                else {
                    console.log('inifelse')
                        this.postDataJsonPrepare["PaymentProcessParams"][
                            "PaymentGatewayTokenId"
                        ] = this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value;
                }
                

            }
            if (this.eventForm.controls.PaymentProcessParams.controls.PaymentFields.controls.SaveCardForFuture.value) {
                this.postDataJsonPrepare["PaymentProcessParams"][
                    "SaveCardForFuture"
                ] = "1";
            } else {
                this.postDataJsonPrepare["PaymentProcessParams"][
                    "SaveCardForFuture"
                ] = "0";
            }
            // if (this.selectedSavedCard == false) {
            // if (this.savedCardLoding == false) {
            //     this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
            //         "Nonce"
            //     ] = this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].value

            //     if (this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['SaveCardForFuture'].value) {
            //         this.postDataJsonPrepare["PaymentProcessParams"][
            //             "SaveCardForFuture"
            //         ] = "1";
            //     } else {
            //         this.postDataJsonPrepare["PaymentProcessParams"][
            //             "SaveCardForFuture"
            //         ] = "0";
            //     }
            // } else {
            //     // this.postDataJsonPrepare["PaymentProcessParams"][
            //     //     "PaymentGatewayTokenId"
            //     // ] = this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value;
            //     if (nonce == null) {
            //         this.postDataJsonPrepare["PaymentProcessParams"][
            //             "PaymentGatewayTokenId"
            //         ] = this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value;
            //     } else {
            //         this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields']['Nonce'] = nonce;
            //     }
            // }
        }
        else if (withpayment.type == "gpay" || withpayment.type == "paypal") {
            if (this.savedCardLoding == false) {
                console.log('in if save', nonce)
                this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    "Nonce"
                ] = this.nonce;
            } else {
                console.log('inside else')
                if(requestType == 'submit') {
                    console.log('inifchjno')
                    this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["Nonce"] = this.nonce;
                }
                else {
                    console.log('jj')
                        this.postDataJsonPrepare["PaymentProcessParams"][
                            "PaymentGatewayTokenId"
                        ] = this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value;
                }
                

            }
          }
        return this.postDataJsonPrepare;
    }

    enableManualPay() {
        if (!this.eventForm.controls.PartialPayment.value) {
            this.isManualPaymentMethod = true;
            this.manualPaymentMethod = 1;
        } else if (this.eventForm.controls.PartialPayment.value) {
            this.isManualPaymentMethod = false;
            this.manualPaymentMethod = 1;
        }
    }
    public savedCardLoding: boolean = false;

    submitForm(type = null) {
        console.log("inside submit----", type)
        if(this.selectedPaymentMethod=='gpay' || this.selectedPaymentMethod=='paypal'){
            this.eventForm.controls['IsThreeDSecure'].setValue(false);
        }else{
        this.eventForm.controls['IsThreeDSecure'].setValue(this.threeDValue);
        }
        this.Step1Fields = []
        // this.eventForm.controls['ParticipantDetails'].controls['ParticipantDetail'].controls.forEach((element, index) => {
        //     if (!(element.controls['Fname'].valid)) {
        //         this.Step1Fields.push(element.controls['Fname'])
        //         element.controls['Fname'].markAsDirty();

        //     }
        //     if (!(element.controls['Lname'].valid)) {
        //         this.Step1Fields.push(element.controls['Lname'])
        //         element.controls['Lname'].markAsDirty();

        //     }

        // });
        console.log(this.Step1Fields.length)
        // if (!this.IsSavedAchPresent) {
        //     this.Step2Fields = [
        //         this.eventForm.controls['Event'].controls['EventId'],
        //         this.eventForm.controls['Event'].controls['EventTypeId'],
        //         this.eventForm.controls['Event'].controls['IsAccommodationRequired'],
        //         this.eventForm.controls['ParticipantDetails'].controls['Key'],
        //         this.eventForm.controls['ParticipantDetails'].controls['IsSystemMember'],
        //         this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountHolder'],
        //         this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['RoutingNumber'],
        //         this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountNumber'],
        //         this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountType'],
        //     ]
        // } else {
        //     this.Step2Fields = [
        //         this.eventForm.controls['Event'].controls['EventId'],
        //         this.eventForm.controls['Event'].controls['EventTypeId'],
        //         this.eventForm.controls['Event'].controls['IsAccommodationRequired'],
        //         this.eventForm.controls['ParticipantDetails'].controls['Key'],
        //         this.eventForm.controls['ParticipantDetails'].controls['IsSystemMember'],
        //         this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard']

        //     ]
        // }
        // new code for step2 fields
        if (type == 'savedcard') {
            console.log("choose card-------")
            this.savedCardLoding = true;
            this.Step2Fields = [
                this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard']
            ]
        } else if (type == 'savedCreditcard') {
            this.savedCardLoding = true;
            console.log("choose card-------")
            this.Step2Fields = [
                this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard']
            ]
        }
        else {
            this.savedCardLoding = false;

            console.log("new card-------")
            this.Step2Fields = [

                // this.subscriptionForm.controls['DeliveryAddress'].controls['Address1'],
                // this.subscriptionForm.controls['DeliveryAddress'].controls['Address2'],
                // this.subscriptionForm.controls['DeliveryAddress'].controls['City'],
                // this.subscriptionForm.controls['DeliveryAddress'].controls['CountryId'],
                // this.subscriptionForm.controls['DeliveryAddress'].controls['StateId'],
                // this.subscriptionForm.controls['DeliveryAddress'].controls['Zip'],

                // this.subscriptionForm.controls['IsGiftTo'],
                // this.subscriptionForm.controls['SubscribeFor'],
                // this.subscriptionForm.controls['NoOfCopiesRequired'],
                // this.subscriptionForm.controls['GiftTo'].controls['FirstName'],
                // this.subscriptionForm.controls['GiftTo'].controls['LastName'],
                // this.subscriptionForm.controls['GiftTo'].controls['PrimaryPhone'],
                // this.subscriptionForm.controls['GiftTo'].controls['SecondaryPhone'],
                // this.subscriptionForm.controls['GiftTo'].controls['Email'],

                this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountHolder'],
                this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['RoutingNumber'],
                this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountNumber'],
                this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountType'],
                // this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'],
                // this.subscriptionForm.controls['SubscriptionType'],
            ]
        }

        // console.log(this.eventForm, "TEST");
        const invalid = []
        const controls = this.Step2Fields;
        let i: any;
        for (i in controls) {
            controls[i].markAsDirty();
            if (controls[i].invalid) {
                invalid.push(i);
                // invalid.focus();
            }
        }
        console.log("invalid-----", invalid)
        if ((invalid[0] == undefined || this.dynamicForm.invalid) && this.Step1Fields.length == 0) {
            if (this.dynamicForm.invalid) {
                this.additionalFieldRequiredMsg = true;
            }
            console.log("invalid is undefined");
            if (this.errorFound == undefined && this.errorMessage == undefined) {
                // console.log("this.errorFound && this.errorMessage is undefined");
                this.emptyParticipant = "Please add details for atleast one participant"
            } else if (this.errorFound == false && this.errorMessage == '') {
                this.emptyParticipant = '';
                if (this.dynamicForm.valid) {
                    this.isLoading = true;
                    this.loadingGpay=true
                    // console.log("Api call position");

                    let params = {}
                    params["url"] = "frontend-site/member-event/register";
                    if(this.threeDValue) {
                        params["params"] = this.RequestPostParams(
                            { type: this.paymentName },
                            this.braintreeNonce, 'submit'
                        );
                    }
                    else {
                        params["params"] = this.RequestPostParams({
                            type: this.paymentName
                        },this.braintreeNonce);
                    }
                        
                    this._crud.postData(params).subscribe(data => {
                        this.isLoading = false;
                        this.loadingGpay=false;
                        this.paypalLoad = false;
                        // console.log('func5');
                        // console.log('form submission response', data);
                        this.formResult = data
                        if (this.formResult['status'] == "success") {
                            this.successPopup(this.formResult);
                            // this.eventForm.reset();
                            $('.focus-label.is-focused').removeClass('is-focused');
                            this.selectedPaymentMethod = "";
                            // this.getCountryList(this.UserLocation.PrimaryCountryId);
                        } else {
                            this.successPopup(this.formResult);

                        }

                    })
                } else {
                    this.additionalFieldRequiredMsg = true;
                    console.log("dynamic form is not valid inside submit form", this.additionalFieldRequiredMsg)
                }

            }
        } else if (this.errorFound == undefined && this.errorMessage == undefined) {
            this.emptyParticipant = "Please add details for atleast one participant"
            // console.log("emptyatall");
            this.focusCall();

        } else {
            this.focusCall();

        }


    }
    focusCall() {


        if (this.eventForm.controls['PaymentProcessParams'].invalid && this.showNewCardOption) {

            for (const i of Object.keys(this.eventForm.controls['PaymentProcessParams'].controls)) {
                if (this.eventForm.controls['PaymentProcessParams'].controls[i].controls != undefined && this.eventForm.controls['PaymentProcessParams'].controls[i].invalid) {
                    for (const k of Object.keys(this.eventForm.controls['PaymentProcessParams'].controls[i].controls)) {
                        if (this.eventForm.controls['PaymentProcessParams'].controls[i].controls[k].invalid) {
                            const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + k + '"]');
                            console.log(this.eventForm.controls['PaymentProcessParams'].controls[i], "####4444444");

                            console.log(this.eventForm.controls['PaymentProcessParams'].controls[i].controls[k], "####4444444");
                            invalidControl.focus();
                            break;
                        }
                    }
                }
            }
        }
    }
    firstNameFetch(fname, i) {
        var regex1 = /^[a-zA-Z-.,' ]{1,100}$/
        var isValid1 = regex1.test(fname);
        if (!isValid1) {
            console.log("not valid fnmae---", this.eventForm.controls['ParticipantDetails'].controls['ParticipantDetail'].controls[i].controls['Fname']);
            var regex2 = /^(?=.*[0-9]).*$/
            var isValid2 = regex2.test(fname);
            // var regex3 = /^(?=.*[!@#%()<>{}]).*$/
            var regex3 = /^(?=.*[!~^&*_=+@#%()<>\[\]:;"?\/\|{}]).*$/
            var isValid3 = regex3.test(fname);

            if (isValid2 && isValid3) {
                console.log("contain nubers and spcl");
                this.eventForm.controls['ParticipantDetails'].controls['ParticipantDetail'].controls[i].controls['Fname'].setErrors({ 'hasnumSpcl': true });
            } else if (isValid2) {
                console.log("contain nubers");
                this.eventForm.controls['ParticipantDetails'].controls['ParticipantDetail'].controls[i].controls['Fname'].setErrors({ 'hasnuber': true });
            }
            else if (isValid3) {
                console.log("contain spcl");
                this.eventForm.controls['ParticipantDetails'].controls['ParticipantDetail'].controls[i].controls['Fname'].setErrors({ 'hasspecl': true })
            } else if (fname.length > 100) {
                console.log("limit reached");
                this.eventForm.controls['ParticipantDetails'].controls['ParticipantDetail'].controls[i].controls['Fname'].setErrors({ 'maxlength': true })
            }
        } else {
            console.log("valid name");
        }

    }
    lastNameFetch(lname, i) {
        var regex1 = /^[a-zA-Z-.,' ]{1,100}$/
        var isValid1 = regex1.test(lname);
        if (!isValid1) {
            console.log("not valid fnmae---");
            var regex2 = /^(?=.*[0-9]).*$/
            var isValid2 = regex2.test(lname);
            var regex3 = /^(?=.*[!~^&*_=+@#%()<>\[\]:;"?\/\|{}]).*$/
            var isValid3 = regex3.test(lname);
            if (isValid2 && isValid3) {
                console.log("contain nubers and spcl");
                this.eventForm.controls['ParticipantDetails'].controls['ParticipantDetail'].controls[i].controls['Lname'].setErrors({ 'hasnumSpcl': true });
            } else if (isValid2) {
                console.log("contain nubers");
                this.eventForm.controls['ParticipantDetails'].controls['ParticipantDetail'].controls[i].controls['Lname'].setErrors({ 'hasnuber': true });
            }
            else if (isValid3) {
                console.log("contain spcl");
                this.eventForm.controls['ParticipantDetails'].controls['ParticipantDetail'].controls[i].controls['Lname'].setErrors({ 'hasspecl': true })
            } else if (lname.length > 100) {
                console.log("limit reached");
                this.eventForm.controls['ParticipantDetails'].controls['ParticipantDetail'].controls[i].controls['Lname'].setErrors({ 'maxlength': true })
            }
        } else {
            console.log("valid name");
        }
    }
    tokenizeUserDetails(type = null) {
        this.cancelStatus = false
        this.eventForm.controls['IsThreeDSecure'].setValue(this.threeDValue);
        if (this.selectedPaymentMethod == 'credit_or_debt') {
            // if(type == 'savedCreditcard'){
            //     this.submitForm(type)
            // }
            if (type == 'savedCreditcard') {
                if (this.threeDValue == true) {
                    let lengthPri = this.threedCountryList.filter(x => { return x == this.primaryCountryId })
                    let lengthCoun = this.threedCountryList.filter(x => { return x == this.countryId })
                    if (lengthPri.length > 0 && lengthCoun.length > 0) {
                        this.isLoading = true;
                        let Params = {}
                        Params["params"] = {
                            Token: this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value,
                            Amount: this.eventForm.controls['PaymentProcessParams'].controls['NetAmountToPay'].value
                        }
                        Params["url"] = "frontend-site/fetch-nonce-from-token";
                        this._crud.getData(Params).subscribe((res) => {
                            console.log('respo', res);
                            if (res) {
                                console.log('in res')
                                this.getPaymentMethodNonceSavedcards(res);

                            }
                        })
                        // this.isLoading = true
                        // let selectedId = this.PaymentMethods.filter(x => { return x.PaymentMethodId == this.selectedPaymentMethod });
                        // let param = {
                        //     url: "frontend-site/client-token/" + selectedId[0].CountryPaymentSettingId
                        // }
                        // this._crud.getData(param).subscribe(data => {
                        //     // console.log("clint token data-----------",data.ClientToken)
                        //     let token = data['data'].ClientToken;
                        //     console.log("token----", token)





                        //     let params = {
                        //         url: "frontend-site/fetch-payment-method-nonce/" + this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value
                        //     }
                        //     this._crud.getData(params).subscribe(res => {
                        //         this.isLoading = true;
                        //         this.savedCardLoding = true;
                        //         console.log("cause list----", res['data']);
                        //         var threeDSecureParameters = {
                        //             amount: this.eventForm.controls['PaymentProcessParams'].controls['NetAmountToPay'].value,
                        //             nonce: res['data']['PaymentMethodNonce'], // Example: hostedFieldsTokenizationPayload.nonce
                        //             bin: res['data']['Bin'], // Example: hostedFieldsTokenizationPayload.details.bin

                        //             onLookupComplete: function (data, next) {
                        //                 // use `data` here, then call `next()`
                        //                 next();
                        //             }
                        //         };
                        //         braintree.client.create({
                        //             authorization: token
                        //         }).then((clientInstance) => {
                        //             braintree.threeDSecure.create({
                        //                 version: 2, // Will use 3DS2 whenever possible
                        //                 client: clientInstance
                        //             }).then((threeDSecureInstance) => {
                        //                 this.threeDSecure = threeDSecureInstance;

                        //                 const self = this
                        //                 this.threeDSecure.on('customer-canceled', function () {
                        //                     // the customer canceled the 3D Secure challenge
                        //                     self.cancelStatus = true
                        //                 });
                        //                 this.threeDSecure.verifyCard(threeDSecureParameters).then((response) => {
                        //                     // submit authenticated response.nonce to the server from here
                        //                     console.log("3d secure response", response)
                        //                     this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(response.nonce);

                        //                     if (this.cancelStatus == true) {
                        //                         // this.disableMakePayment = false
                        //                         this.isLoading = false
                        //                     } else {
                        //                         this.submitForm(type)
                        //                     }

                        //                 }).catch(function (error) {
                        //                     // Handle error
                        //                     // self.disableMakePayment = false
                        //                     console.log('in catch')
                        //                     self.isLoading = false
                        //                     console.log("verify card error handling", error.details.originalError.details.originalError.error.message)
                        //                     let popup = {}
                        //                     popup['status'] = "error"
                        //                     popup['msg'] = error.details.originalError.details.originalError.error.message
                        //                     self.successPopup(popup);

                        //                 });
                        //             });
                        //         });
                        //     });
                        // })
                    } else {
                        this.submitForm(type);
                    }

                } else {
                    this.submitForm(type);
                }
            }
            else {
                // this.hostedFieldsInstance.tokenize({ cardholderName: this.cardholdersName }).then((payload) => {
                //     console.log('payload', payload);
                //     // this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(payload.nonce);
                //     // this.submitForm(type)
                //     // submit payload.nonce to the server from here
                //     let lengthPri = this.threedCountryList.filter(x => { return x == this.primaryCountryId })
                //     let lengthCoun = this.threedCountryList.filter(x => { return x == this.countryId })
                //     if (lengthPri.length > 0 && lengthCoun.length > 0) {

                //         //   if(this.primaryCountryId == 'BprLdASASGl0' && this.countryId == 'BprLdASASGl0'){
                //         this.isLoading = true
                //         var threeDSecureParameters = {
                //             amount: this.eventForm.controls['PaymentProcessParams'].controls['NetAmountToPay'].value,
                //             nonce: payload.nonce, // Example: hostedFieldsTokenizationPayload.nonce
                //             bin: payload.details.bin, // Example: hostedFieldsTokenizationPayload.details.bin

                //             onLookupComplete: function (data, next) {
                //                 // use `data` here, then call `next()`
                //                 next();
                //             }
                //         };
                //         const self = this
                //         this.threeDSecure.on('customer-canceled', function () {
                //             // the customer canceled the 3D Secure challenge
                //             self.cancelStatus = true
                //         });
                //         this.threeDSecure.verifyCard(threeDSecureParameters).then((response) => {
                //             // submit authenticated response.nonce to the server from here
                //             console.log("3d secure response", response)
                //             this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(response.nonce);
                //             if (this.cancelStatus == true) {
                //                 //   this.buttonStatus = false
                //                 this.isLoading = false
                //             } else {
                //                 this.submitForm(type)
                //             }

                //         }).catch(function (error) {
                //             // Handle error
                //             //   self.buttonStatus = false
                //             self.isLoading = false
                //             console.log("verify card error handling", error.details.originalError.details.originalError.error.message)
                //             let popup = {}
                //             popup['status'] = "error"
                //             popup['msg'] = error.details.originalError.details.originalError.error.message
                //             self.successPopup(popup);

                //         });
                //     } else {
                //         console.log('payload --> with out 3d security', payload);
                //         this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(payload.nonce);
                //         this.submitForm(type)
                //     }
                if (this.hostedFieldsInstance == undefined) {
                    // this.buttonStatus = false;

                } else {
                    this.hostedFieldsInstance.tokenize({ cardholderName: this.cardholdersName }).then((payload) => {
                        console.log('payload', payload);
                        this.braintreeNonce = payload.nonce;
                        // this.finalSubmitForm(value)
                        // submit payload.nonce to the server from here

                        if(this.threeDValue == true) {
							console.log('in three')
                            // if(this.selectedFrequencyName == 'Onetime') {
                                let lengthPri = this.threedCountryList.filter(x => { return x == this.primaryCountryId })
                                let lengthCoun = this.threedCountryList.filter(x => { return x == this.countryId })
                                if (lengthPri.length > 0 && lengthCoun.length > 0) {
									console.log('in length')
                                    //   if(this.primaryCountryId == 'BprLdASASGl0' && this.countryId == 'BprLdASASGl0'){
                                    this.isLoading = true
                                    var threeDSecureParameters = {
                                        amount: this.eventForm.controls['PaymentProcessParams'].controls['NetAmountToPay'].value,
										nonce: payload.nonce, // Example: hostedFieldsTokenizationPayload.nonce
										bin: payload.details.bin, // Example: hostedFieldsTokenizationPayload.details.bin
        
                                        onLookupComplete: function (data, next) {
                                            // use `data` here, then call `next()`
                                            next();
                                        }
                                    };
                                    const self = this
                                    this.threeDSecure.on('customer-canceled', function () {
                                        // the customer canceled the 3D Secure challenge
                                        self.cancelStatus = true
                                    });
                                    this.threeDSecure.verifyCard(threeDSecureParameters).then((response) => {
                                        // submit authenticated response.nonce to the server from here
										this.braintreeNonce = response.nonce;
                                        console.log("3d secure response", response.nonce)
                                        this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(response.nonce);
        
                                        if (this.cancelStatus == true) {
                                            // this.disableMakePayment = false
                                            self.isLoading = false
                                        } else {
                                            this.submitForm(type)
                                        }
        
                                    }).catch(function (error) {
                                        // Handle error
                                        // self.disableMakePayment = false
                                        self.isLoading = false
                                        console.log("verify card error handling", error.details.originalError.details.originalError.error.message)
                                        let popup = {}
                                        popup['status'] = "error"
                                        popup['msg'] = error.details.originalError.details.originalError.error.message
                                        self.successPopup(popup);
        
                                    });
                                } 
								else {
									console.log('in else length')
									this.submitForm(type)
								}
                               
                            // }
                            // else {
                            //     console.log('in recu')
                            //     this.getPaymentMethodNonce(payload.nonce);
                            // }
                            
                        }
                        else {
							console.log('in else')
                            console.log('payload --> with out 3d security', payload);
                            this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(payload.nonce);
							this.submitForm(type);
                        }
                }).catch((error) => {
                    //   console.log('error_detail', error)
                    this.brainTreeError = '';
                    // perform custom validation here or log errors
                    // if (this.donationForm.controls['Amount'].value == null && this.otheramoutValidation == false && this.maxOtherAmount == false) {
                    //     this.donationForm.controls['Amount'].setValue(this.donationForm.controls['OtherAmount'].value)
                    //     this.donationForm.controls['CausePaymentOptionValueId'].setValue(null);
                    // }
                    let Step2Fields = [
                        //   this.eventForm.controls['Amount'],
                        //   this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['BillingDate'],
                        //   this.eventForm.controls['PersonalDetails'].controls['FirstName'],
                        //   this.eventForm.controls['PersonalDetails'].controls['LastName'],
                        //   this.eventForm.controls['PersonalDetails'].controls['PrimaryPhone'],
                        //   this.eventForm.controls['PersonalDetails'].controls['SecondaryPhone'],
                        //   this.eventForm.controls['PersonalDetails'].controls['Email'],
                        //   this.eventForm.controls['PersonalDetails'].controls['BillingAddress'].controls['Address1'],
                        //   this.eventForm.controls['PersonalDetails'].controls['BillingAddress'].controls['Address2'],
                        //   this.eventForm.controls['PersonalDetails'].controls['BillingAddress'].controls['City'],
                        //   this.eventForm.controls['PersonalDetails'].controls['BillingAddress'].controls['CountryId'],
                        //   this.eventForm.controls['PersonalDetails'].controls['BillingAddress'].controls['StateId'],
                        //   this.eventForm.controls['PersonalDetails'].controls['BillingAddress'].controls['Zip'],
                        //   this.eventForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['Address1'],
                        //   this.eventForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['Address2'],
                        //   this.eventForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['City'],
                        //   this.eventForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['CountryId'],
                        //   this.eventForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['StateId'],
                        //   this.eventForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['Zip'],
                        //   this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountHolder'],
                        //   this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['RoutingNumber'],
                        //   this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountNumber'],
                        //   this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountType'],
                    ]
                    //   console.log(this.eventForm, "TEST");
                    const invalid = []
                    const controls = Step2Fields;
                    let i: any;
                    for (i in controls) {
                        controls[i].markAsDirty();
                        if (controls[i].invalid) {
                            invalid.push(i);
                        }
                    }
                    switch (error.code) {

                        case 'HOSTED_FIELDS_FIELDS_EMPTY':
                            // occurs when none of the fields are filled in
                            console.error('All fields are empty! Please fill out the form.');
                            // this.brainTreeError = 'All fields are empty! Please fill out the form.'
                            this.noFieldsCardNumber = 1;
                            this.noFieldsDate = 1;
                            this.noFieldsCvv = 1;
                            break;
                        case 'HOSTED_FIELDS_FIELDS_INVALID':
                            // occurs when certain fields do not pass client side validation
                            console.error('Some fields are invalid:', error.details.invalidFieldKeys);
                            // this.brainTreeError = 'Some fields are invalid'
                            // you can also programtically access the field containers for the invalid fields
                            // error.details.invalidFields.forEach(function(fieldContainer) {
                            //     fieldContainer.className = 'invalid';
                            // });
                            error.details.invalidFieldKeys.forEach(element => {
                                console.log(element);
                                if (element == 'number') {
                                    this.noFieldsCardNumber = 1;
                                } if (element == 'cvv') {
                                    this.noFieldsCvv = 1;
                                } if (element == 'expirationDate') {
                                    this.noFieldsDate = 1;
                                }
                            });
                            break;
                        case 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE':
                            // occurs when:
                            //   * the client token used for client authorization was generated
                            //     with a customer ID and the fail on duplicate payment method
                            //     option is set to true
                            //   * the card being tokenized has previously been vaulted (with any customer)
                            // See: https://developers.braintreepayments.com/reference/request/client-token/generate/#options.fail_on_duplicate_payment_method
                            console.error('This payment method already exists in your vault.');
                            this.brainTreeError = 'This payment method already exists in your vault.'
                            break;
                        case 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED':
                            // occurs when:
                            //   * the client token used for client authorization was generated
                            //     with a customer ID and the verify card option is set to true
                            //     and you have credit card verification turned on in the Braintree
                            //     control panel
                            //   * the cvv does not pass verfication (https://developers.braintreepayments.com/reference/general/testing/#avs-and-cvv/cid-responses)
                            // See: https://developers.braintreepayments.com/reference/request/client-token/generate/#options.verify_card
                            console.error('CVV did not pass verification');
                            break;
                        case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
                            // occurs for any other tokenization error on the server
                            console.error('Tokenization failed server side. Is the card valid?');
                            this.brainTreeError = 'Tokenization failed server side. Is the card valid?'
                            break;
                        case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
                            // occurs when the Braintree gateway cannot be contacted
                            console.error('Network error occurred when tokenizing.');
                            this.brainTreeError = 'Network error occurred when tokenizing.'
                            break;
                        default:
                            console.error('Something bad happened!');
                            this.brainTreeError = 'Something bad happened!  '
                    }
                });
            }
            }

        }
        else {
            this.submitForm(type);
        }
    }

    getPaymentMethodNonceSavedcards(response: any) {
		let selectedId = this.PaymentMethods.filter(x => { return x.UniqueName == this.selectedPaymentMethod });
		console.log('in response')
		var threeDSecureParameters = {
			amount: response['data'].Amount,
			nonce: response['data'].PaymentMethodNonce, // Example: hostedFieldsTokenizationPayload.nonce
			bin: response['data'].Bin, // Example: hostedFieldsTokenizationPayload.details.bin

			onLookupComplete: function (data, next) {
				// use `data` here, then call `next()`
				// determine if you want to call next to start the challenge,
				// if not, call cancelVerifyCard
				next();
			},
		};
		let param = {
			url: "frontend-site/client-token/" + selectedId[0].CountryPaymentSettingId
		};
		this._crud.getData(param).subscribe((data) => {
            console.log('data23',data)
			let token = data['data'].ClientToken;
			braintree.client
				.create({
					authorization: token,
				})
				.then((clientInstance) => {
					braintree.threeDSecure
						.create({
							version: 2, // Will use 3DS2 whenever possible
							client: clientInstance,
						})
						.then((threeDSecureInstance) => {
							this.threeDSecure = threeDSecureInstance;

							const self = this;
							this.cancelStatus = false;
							this.threeDSecure.on("customer-canceled", function () {
								// the customer canceled the 3D Secure challenge
								self.cancelStatus = true;
							});
							this.threeDSecure
								.verifyCard(threeDSecureParameters)
								.then((res) => {
									// submit authenticated response.nonce to the server from here
									this.braintreeNonce = res.nonce;
									console.log(
										"2nd nonce after otp------",
										res,
										this.braintreeNonce,
										this.cancelStatus
									);
									if (self.cancelStatus == false) {
										console.log('inside4')
										this.submitForm();
									}
									else {
										console.log('in else cancel1')
										this.isLoading = false;
									}
								})
								.catch(function (error) {
									console.log(
										"verify card error handling",
										error.details.originalError.details.originalError.error
											.message
									);
									Helpers.sweetAlert({
										title: "Error",
										msg: error.details.originalError.details.originalError.error
											.message,
										type: "error",
									});
								});
						});
				});
		})
	}
    validateFileds() {
        if (this.TabActiveIndex == 0) {
            //in this section we need number of participant added by the user to show validation
            this.Step2Fields = []
            this.calculatePrice();
        } else if (this.TabActiveIndex == 1) {
            //additionl information page
            if (!this.dynamicForm.valid) {
                console.log("dynmaic form not valid")
                this.findCustomInvalidControls();
            } else {
                this.additionalFieldRequiredMsg = false
            }

        } else if (this.TabActiveIndex == 2) {
            // payment page
            this.Step2Fields = [
                this.eventForm.controls['PaymentProcessParams'].controls['PaymentMethodId'],
                this.eventForm.controls['BillingAddress'].controls['Address1'],
                this.eventForm.controls['BillingAddress'].controls['Address2'],
                this.eventForm.controls['BillingAddress'].controls['City'],
                this.eventForm.controls['BillingAddress'].controls['StateId'],
                this.eventForm.controls['BillingAddress'].controls['Zip'],
            ]
        }
        const invalid = [];
        const controls = this.Step2Fields;
        let i: any;
        for (i in controls) {
            controls[i].markAsDirty();
            if (controls[i].invalid) {
                if (this.TabActiveIndex == 2) {
                    invalid.push("paymentMethod");
                } else {
                    invalid.push(i);
                }

            }
        }
        return invalid;
    }
    findCustomInvalidControls() {
        console.log("indu find invalid----", this.customFields)
        const invalid = [];
        const invalidCustom = [];
        const controls = this.dynamicForm.controls;
        for (const name in controls) {
            // console.log("indu find invalid----",name)
            if (controls[name].invalid) {
                invalid.push(name);
            }
            console.log("indu find invalid----", invalid)
        }
        if (invalid.length > 0) {
            this.additionalFieldRequiredMsg = true;
            this.customFields.forEach(element => {
                // console.log("custom labei----",element.key)
                for (const labelkey in invalid) {
                    // console.log("keyy----",invalid[labelkey])
                    if (invalid[labelkey] == element.key) {
                        // invalidCustom.push(element.label);
                        invalidCustom.push((element.label).replace("*", ""));  // remove * from error msg
                    }
                }
            });
            console.log("invalid---custom----", invalidCustom);
            var requiredMsg = this.getValidationMsg(invalidCustom);
            this.additionInfoMsg = requiredMsg;
        } else {
            this.additionalFieldRequiredMsg = false;
        }
        // return invalid;
    }
    getValidationMsg(msgArry) {
        // console.log("get validtion msg array----", msgArry);
        if (msgArry.length > 1) {
            var lbit = msgArry[msgArry.length - 1];
            msgArry[msgArry.length - 1] = ' and ' + lbit;
            // console.log("aand adde msg array", msgArry);
            // console.log("tostring msg array----", msgArry.toString());
            return msgArry.join(', ').replace(",  and", " and") + ' are required. Please fill in all the required fields';

        } else if (msgArry.length == 1) {
            return msgArry.toString() + ' is required';
        } else {
            return '';
        }
    }
    customFieldChange(event) {
        console.log("customfield rq: change", event);
        if (event.currentvalue != "") {
            if (event.controlType == "textboxdatepicker") {
                console.log("currnt value------", event.currentvalue)
                this.selectedCustomDate = new DatePipe('en-US').transform(event.currentvalue, 'dd-MMM-yyyy');
            }
            this.findCustomInvalidControls();
        }
    }

    createPayapalUI(token?: any) {
        // Create a client.
        let mm = this
        console.log('mm.',mm.Currency)
        braintree.client.create({
            authorization: token,
        }, function (clientErr, clientInstance) {
            
            // Stop if there was a problem creating the client.
            // This could happen if there is a network error or if the authorization
            // is invalid.
            if (clientErr) {
                console.error('Error creating client:', clientErr);
                return;
            }

            // Create a PayPal Checkout component.
            braintree.paypalCheckout.create({
                client: clientInstance,
            }, function (paypalCheckoutErr, paypalCheckoutInstance) {
                paypalCheckoutInstance.loadPayPalSDK({
                    currency: mm.Currency,
                    vault: true,
                }, function () {
                    // if(mm.DonationForm.valid) {
                        
                    console.log('inside', clientInstance._configuration.gatewayConfiguration.paypal.clientId)
                    paypal.Buttons({
                        fundingSource: paypal.FUNDING.PAYPAL,

                        createBillingAgreement: function () {
                            
                                return paypalCheckoutInstance.createPayment({
                                    flow: 'vault', // Required
                                });

                        },
                        
                        style: {
                            label: 'pay',
                            layout: 'horizontal',
                            color: 'white',
                            shape: 'rect',
                            height: 40,

                        },


                        onApprove: function (data, actions) {
                                return paypalCheckoutInstance
                                    .tokenizePayment(data)
                                    .then(payload => {
                                        console.log(payload.nonce);
                                        mm.nonce = payload.nonce;
                                        mm.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(mm.nonce);
                                        mm.paypalLoad = true;
                                        mm.submitForm()
                                    });


                        },


                        onCancel: function (data) {
                            console.log('PayPal payment canceled', JSON.stringify(data));
                        },

                        onError: function (err) {
                            console.error('PayPal error', err);
                        }
                    }).render('#paypal-button').then(function () {
                        // The PayPal button will be rendered in an html element with the ID
                        // `paypal-button`. This function will be called when the PayPal button
                        // is set up and ready to be used
                    });
                    // }


                });

            });

        });
    }


    createBraintreeUI() {
        let fields = {}
        if(!this.isCvvhide) {
            fields = {
                number: {
                    selector: '#card-number',
                    placeholder: 'Card Number'
                },
                cvv: {
                    selector: '#cvv',
                    placeholder: 'CVV'
                },
                expirationDate: {
                    selector: '#expiration-date',
                    // placeholder: 'Expiration Date (MM/YY)'
                    placeholder: 'MM/YY'
                }
            }
        }
        else {
            fields = {
                number: {
                    selector: '#card-number',
                    placeholder: 'Card Number'
                },
                expirationDate: {
                    selector: '#expiration-date',
                    // placeholder: 'Expiration Date (MM/YY)'
                    placeholder: 'MM/YY'
                }
            }
        }
        // console.log("braintree");
        braintree.client.create({
            authorization: this.hostedClientToken
        }).then((clientInstance) => {
            this.loaderCreditsaved = false
            this.loaderCreditNew = false
            braintree.hostedFields.create({
                client: clientInstance,
                styles: {
                    // Style all elements
                    // 'input': {
                    //   'font-size': '16px',
                    //   'color': '#3A3A3A'
                    // },
                    '.invalid': {
                        'color': '#D0041D'
                    },

                    // Styling element state
                    // 'input': {
                    //     // 'font-family': '"Merriweather", serif !important',
                    //     'padding': "0 10px 0 20px !important",
                    //     'letter-spacing': "0.025em !important",
                    //     "font-weight": "300 !important",
                    //     "font-size": "14px !important",
                    // },
                    'input': {
                        'font-size': '16px!important',
                        "font-weight": '500!important',
                        'font-family': "'Poppins', sans-serif !important",
                        'color': '#0D0D0D',
                        ':placeholder': {
                            'font-weight': '500!important',
                            'color': '#9F9F9F!important',
                        }
                    },

                    // Media queries
                    // Note that these apply to the iframe, not the root window.
                    // '@media screen and (max-width: 700px)': {
                    //     'input': {
                    //         'font-size': '14px'
                    //     }
                    // }
                },

                // The hosted fields that we will be using
                // NOTE : cardholder's name field is not available in the field options
                // and a separate input field has to be used incase you need it
                fields: fields
            }).then((hostedFieldsInstance) => {
                this.hostedFieldsInstance = hostedFieldsInstance;
                hostedFieldsInstance.on('focus', (event) => {
                    const field = event.fields[event.emittedBy];
                    const label = this.findLabel(field);
                    if (label['htmlFor'] == 'card-number') {
                        this.noFieldsCardNumber = 0;
                    }
                    if (label['htmlFor'] == 'expiration-date') {
                        this.noFieldsDate = 0;
                    }
                    if (label['htmlFor'] == 'cvv') {
                        this.noFieldsCvv = 0;
                    }
                    // console.log(this.noFieldsCardNumber);
                    // label.classList.remove('filled'); // added and removed css classes
                    // can add custom code for custom validations here
                    // this.cdRef.detectChanges();
                });
                hostedFieldsInstance.on('blur', (event) => {
                    const field = event.fields[event.emittedBy];
                    // const label = this.findLabel(field); // fetched label to apply custom validations
                    // can add custom code for custom validations here
                });

                hostedFieldsInstance.on('empty', (event) => {
                    const field = event.fields[event.emittedBy];
                    // can add custom code for custom validations here
                });

                hostedFieldsInstance.on('validityChange', (event) => {
                    const field = event.fields[event.emittedBy];
                    const label = this.findLabel(field);
                    if (field.isPotentiallyValid) { // applying custom css and validations
                        // label.classList.remove('invalid');
                    } else {
                        // label.classList.add('invalid');
                    }
                    // can add custom code for custom validations here
                });
            });
            braintree.threeDSecure.create({
                version: 2, // Will use 3DS2 whenever possible
                client: clientInstance
            }).then((threeDSecureInstance) => {
                this.threeDSecure = threeDSecureInstance;
            });
        }).catch(error => {
            // console.log('error', error);
            if (error.name == 'BraintreeError') {
                // this.paymentGatewayErrorMsg = "Payment Gateway is not configured"
            }
        })
        // this.clearCardDetails();
    }
    // Fetches the label element for the corresponding field
    findLabel(field: braintree.HostedFieldsHostedFieldsFieldData) {
        return document.querySelector('.hosted-field--label[for="' + field.container.id + '"]');
    }
    isAddNewCard(value) {
        console.log(value);
        this.loaderCreditNew = true
        this.eventForm.controls.PaymentProcessParams.controls.PaymentFields.controls['ChooseCard'].reset(); //setValue('')
        value == true ? this.addNewCard = true : this.addNewCard = false;
        if (this.selectedPaymentMethod == 'credit_or_debt' && value == true) {
            this.getClientToken()
        }

    }
    getThreedsecureCountries() {
        let params = {}
        params["url"] = "frontend-site/3d-secure-countries";
        this._crud.getData(params).subscribe(data => {
            this.threedCountryList = data['data'];
            console.log('list', this.threedCountryList);

        })
    }
    billingZipValidation(value) {
        let countryCode = localStorage.getItem('countryCode')
        if (countryCode == 'CA') {
            var arr = value
            if (arr.length == 6 && !arr.match(" ")) {
                console.log("true 1")
            } else if (arr.length == 7 && arr[3] == " " && arr.split(" ").length == 2) {
                console.log("true 2")
            } else {
                this.eventForm.controls['BillingAddress'].controls['Zip'].setErrors({ 'pattern': true })
                console.log("false ")

            }
        } else if (countryCode == 'GB') {//uk
            var arr = value
            if (arr.length == 5 && !arr.match(" ")) {
                console.log("true 1")
            } else if (arr.length > 5 && arr.length < 9) {
                if (arr.match(" ")) {
                    if ((arr[2] == " " || arr[3] == " " || arr[4] == " ") && arr.split(" ").length == 2) {
                        console.log("true 2", arr.split(" ").length)

                    } else {
                        this.eventForm.controls['BillingAddress'].controls['Zip'].setErrors({ 'pattern': true })
                        console.log("false ")
                    }
                }
            } else {
                this.eventForm.controls['BillingAddress'].controls['Zip'].setErrors({ 'pattern': true })
                console.log("false ")

            }
        } else if (countryCode == 'IE') {//ireland
            var arr = value
            if (arr.length == 7 && !arr.match(" ")) {
                console.log("true 1")
            } else if (arr.length == 8 && arr[3] == " " && arr.split(" ").length == 2) {
                console.log("true 2")
            } else {
                this.eventForm.controls['BillingAddress'].controls['Zip'].setErrors({ 'pattern': true })
                console.log("false ")

            }
        }
    }
    getAllStates(countryId) {
        let params = {}
        params["params"] = {
            CountryId: countryId
        }
        params["url"] = "frontend-site/fetch-states/" + countryId;
        this._crud.getData(params).subscribe(data => {

            this.StatesList = data['data'];
        })

    }
    getSelectedMemberAddress(memberId) {
        let param = {
            url: "frontend-site/member-donation/fetch-address", params: { MemberId: memberId }
        }
        this._crud.getData(param).subscribe(res => {

            // console.log("member data----",res['data']);
            if (res['data']) {
                this.hideBillingAddress = true;
                this.eventForm.controls.BillingAddress.controls.AddressId.setValue(res['data']['AddressId']);
                this.eventForm.controls.BillingAddress.controls.Address1.setValue(res['data']['Address1']);
                this.eventForm.controls.BillingAddress.controls.Address2.setValue(res['data']['Address2']);
                this.eventForm.controls.BillingAddress.controls.City.setValue(res['data']['City']);
                this.eventForm.controls.BillingAddress.controls.StateId.setValue(res['data']['StateId']);
                this.eventForm.controls.BillingAddress.controls.Zip.setValue(res['data']['Zip']);
                this.eventForm.controls.BillingAddress.controls.CountryId.setValue(this.countryId);


                document.getElementById("Add1").classList.add("is-focused");
                if (res['data']['Address2']) {
                    document.getElementById("Add2").classList.add("is-focused");
                } else {
                    document.getElementById("Add2").classList.remove("is-focused");
                }
                document.getElementById("City").classList.add("is-focused");
                if (res['data']['Zip']) {
                    document.getElementById("Code").classList.add("is-focused");
                } else {
                    document.getElementById("Code").classList.remove("is-focused");
                }
            } else {
                this.hideBillingAddress = false;
                this.eventForm.controls.BillingAddress.controls.AddressId.setValue('');
                this.eventForm.controls.BillingAddress.controls.Address1.setValue('');
                this.eventForm.controls.BillingAddress.controls.Address2.setValue('');
                this.eventForm.controls.BillingAddress.controls.City.setValue('');
                this.eventForm.controls.BillingAddress.controls.StateId.setValue(null);
                this.eventForm.controls.BillingAddress.controls.Zip.setValue('');
                document.getElementById("Add1").classList.remove("is-focused");
                document.getElementById("Add2").classList.remove("is-focused");
                document.getElementById("City").classList.remove("is-focused");
                document.getElementById("Code").classList.remove("is-focused");
            }


        });
        let countryCode = localStorage.getItem('countryCode')

        if (countryCode == 'US') {
            this.eventForm.controls['BillingAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{5,5}$/)]));
            this.eventForm.controls['BillingAddress'].get('Zip').updateValueAndValidity();
            this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code with five digits" }];
        } else if (countryCode == 'CA') {//} else if (this.countryId == 'gQFUOwHXczkw') { canada
            this.eventForm.controls['BillingAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)[0-9A-Za-z\s]{6,7}$/)]));
            this.eventForm.controls['BillingAddress'].get('Zip').updateValueAndValidity();
            this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code" }];
        } else if (countryCode == 'AU') {//  australia
            this.eventForm.controls['BillingAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{4,4}$/)]));
            this.eventForm.controls['BillingAddress'].get('Zip').updateValueAndValidity();
            this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code" }];
        } else if (countryCode == 'GB') {//uk
            this.eventForm.controls['BillingAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)[0-9A-Za-z\s]{5,8}$/)]));
            this.eventForm.controls['BillingAddress'].get('Zip').updateValueAndValidity();
            this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code" }];
        } else if (countryCode == 'IE') {//ireland
            this.eventForm.controls['BillingAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)[0-9A-Za-z\s]{7,8}$/)]));
            this.eventForm.controls['BillingAddress'].get('Zip').updateValueAndValidity();
            this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code" }];
        } else {
            this.eventForm.controls['BillingAddress'].get('Zip').setValidators(Validators.pattern(/^(?!0+$)[0-9A-Za-z\s\-]{3,10}$/));
            this.eventForm.controls['BillingAddress'].get('Zip').updateValueAndValidity();
            this.ValidationMessage.Zip = [{ type: "pattern", message: "Please enter a valid postal code" }];
        }
    }
    getThreedsecure() {
        let params = {}
        // let selectedId = this.PaymentMethods.filter(x => { return x.UniqueName == this.selectedPaymentMethod });
        // console.log('iiid',selectedId);
        console.log('paymentid', this.paymentSettingId);

        this.memberId = localStorage.getItem('memberId');
        console.log('memberid', this.memberId);
        params["url"] = "frontend-site/check-3d-secure/" + this.countryPaymentId + '/' + this.memberId;
        this._crud.getData(params).subscribe(data => {
            this.threeDValue = data['data'];
            console.log('list-------------value', this.threeDValue);

        })
    }
}
