import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { CustomValidator } from '../../../../_services/custom-validation/CustomValidator.validator';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';
declare let $: any;
@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['../../../../../assets/member/css/style.css', './change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

	changePasswordForm;
	curntPass = "password";
	password;
	postDataJsonPrepare
	@Input() memberId;
	isLoading: boolean = false;
	totalChar: boolean = false;
	atleaatAlpha: boolean = false;
	atleastDigit: boolean = false;
	isSpfMember: boolean;
	singleDonationExist: boolean = false;
	ValidationMessage: any = {
		currentPassword: [{ type: "required", message: "Current Password is required" },
		{ type: "pattern", message: "Numbers,space and special characters not allowed" },
		{ type: "minlength", message: "Please enter valid password " }

		],
		newPassword: [{ type: "required", message: "New Password is required" },
		{ type: "pattern", message: "Password didn't meet required criteria" },
		{ type: "minlength", message: "Please enter valid password " }

		],
		confirmPassword: [{ type: "required", message: "Confirm Password is required" },
			// {type: "pattern",message: "Numbers,space and special characters not allowed"},
			// { type: "minlength", message: "Please enter valid confirm password " }
		]
	};

	constructor(public formBuilder: FormBuilder, public _crud: CommonCrudService, public activeModal: NgbActiveModal, public modalService: NgbModal) {
		this.formInit();
	}

	ngOnInit(): void {
		//call api to get curnt password of the mmeberid
		//tab switch toggle password
		$('input[name=cpwd]').on('keydown', function (evt) {
			console.log(evt.originalEvent.key,'evt.originalEvent.key')
			if (evt.originalEvent.key === 'Tab') {
				evt.preventDefault();
				if ($('.dropdown').find('.dropdown-menu').is(":hidden")) {
					$('.dropdown-toggle').dropdown('toggle');
				}
			}
		});
		this.isSpfMember = (localStorage.getItem('IsSpfMember') == 'true' ? true : false);
		let currentUser = JSON.parse(localStorage.getItem("currentUser"));
		this.singleDonationExist = currentUser.singleDonationExist;
	}
	animateLabel(event) {
		event.parentNode.classList.add('is-focused');
	}

	animateLabelRemove(e) {
		// console.log(e.value, "AHGKND");
		if (e.value == '') {
			e.parentNode.classList.remove('is-focused');
		}
	}
	formInit() {
		this.changePasswordForm = this.formBuilder.group({
			currentPassword: ['', Validators.compose([Validators.required])],
			newPassword: ['', Validators.compose([Validators.required])],
			confirmPassword: ['', Validators.compose([Validators.required])]
		});
	}
	checkPassword(cpassword = null) {
		// console.log("inside confirm pasword-----",cpassword);
		// CustomValidator.passwordsMatch(this.password,cpassword);
		if (cpassword) {
			this.changePasswordForm.get('confirmPassword').setValidators(Validators.compose([Validators.required, CustomValidator.passwordsMatch(this.password, cpassword).bind(this)]));
			this.changePasswordForm.get('confirmPassword').updateValueAndValidity();
			this.ValidationMessage.confirmPassword = [{ type: "passwordsMatch", message: "The passwords you entered do not match" }];
		}

	}
	passwordFetch(pass) {
		//show toogle box check
		if ($('.dropdown').find('.dropdown-menu').is(":hidden")) {
			$('.dropdown-toggle').dropdown('toggle');
		}
		this.password = pass;
		//atleast 8 or more characters
		var regex1 = /^(?=.{8,}$).*$/
		//  var regex1 = /^(?=.*\w).{8,}$/
		var isValid1 = regex1.test(this.password);
		if (!isValid1) {
			this.totalChar = false;
			this.changePasswordForm.controls['newPassword'].setErrors({ 'pattern': true });
			console.log("not 8 or more Characters.");
		} else {
			this.totalChar = true;
			// console.log("Dcontains contain at least 8 Characters.");
		}
		//upper case and lower case characters
		var regex2 = /^(?=.*[a-z]).*$/
		var regex3 = /^(?=.*[A-Z]).*$/
		var isValid2 = (regex2.test(this.password) && regex3.test(this.password));
		if (!isValid2) {
			this.atleaatAlpha = false;
			this.changePasswordForm.controls['newPassword'].setErrors({ 'pattern': true });
			console.log("not upper and lower case.");
		} else {
			console.log('in')
			this.atleaatAlpha = true;
			// console.log("yes upper and lower case.");
		}
		//atleast one digit
		var regex4 = /^(?=.*[0-9]).*$/
		var isValid3 = regex4.test(this.password);
		if (!isValid3) {
			this.atleastDigit = false;
			this.changePasswordForm.controls['newPassword'].setErrors({ 'pattern': true });
			console.log("not one digit");
		} else {
			this.atleastDigit = true;
			// console.log("yes one digit");
		}
		this.checkPassword(this.changePasswordForm.controls['confirmPassword'].value);
		// console.log("new pass-----",this.password);
	}
	save() {
		let Step2Fields = [
			this.changePasswordForm.controls['currentPassword'],
			this.changePasswordForm.controls['newPassword'],
			this.changePasswordForm.controls['confirmPassword'],

		]
		const invalid = []
		const controls = Step2Fields;
		let i: any;
		for (i in controls) {
			controls[i].markAsDirty();
			if (controls[i].invalid) {
				invalid.push(i);
			}
		}
		if (invalid[0] == undefined) {
			this.isLoading = true;
			let param = {};
			param['params'] = {};
			param['url'] = "frontend-site/member/change-password/" + this.memberId;
			param['params'] = this.RequestPostParams();
			this._crud.postData(param).subscribe(res => {
				console.log("res---", res);
				this.isLoading = false;
				this.successPopup(res);
			})
		}
	}
	RequestPostParams() {
		this.postDataJsonPrepare = {};
		this.postDataJsonPrepare.Old_Password = this.changePasswordForm.controls['currentPassword'].value;
		this.postDataJsonPrepare.New_Password = this.changePasswordForm.controls['newPassword'].value;
		this.postDataJsonPrepare.Confirm_Password = this.changePasswordForm.controls['confirmPassword'].value;
		return this.postDataJsonPrepare;
	}
	successPopup(data) {
		let response = { title: data['status'], message: data['msg'] }
		const modalRef = this.modalService.open(SuccessPopupComponent, { size: "md" });
		modalRef.componentInstance.user = response;
		modalRef.result.then(
			result => {
				// console.log("result-----modal",result)
				if (result.result) {
					// /clearing all forms.
					this.activeModal.close({ 'result': true });
				}
			});
	}
}
