import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MakeeventregpaymentComponent } from './makeeventregpayment.component';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../main.component';
import { MainModule } from '../main.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GooglePayButtonModule } from "@google-pay/button-angular";
const routes: Routes = [
  {
      'path': '',
      'component': MainComponent,
      'children': [
          {
              'path': '',
              'component': MakeeventregpaymentComponent,
          },
      ],
  },
];


@NgModule({
  declarations: [MakeeventregpaymentComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes), 
    MainModule,
    ReactiveFormsModule,
    GooglePayButtonModule,
    FormsModule
  ]
})
export class MakeeventregpaymentModule { }
