import { NgModule } from '@angular/core';
// import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardThemeComponent } from './memberDashboard/dashboard-theme/dashboard-theme.component';
import { DashboardThemeRoutingModule } from './memberDashboard/dashboard-theme/dashboard-theme-routing.module';
import { CommonCrudService } from './_services/common-crud.service';
import { Helpers } from './helpers';
import { SharedService } from './_services/shared.service';
import { CustomControlService } from './_directives/custom-fields/custom-fields.service';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthService } from '../app/memberDashboard/dashboard-theme/auth/services/auth.service';
import { AuthGuard } from '../app/memberDashboard/dashboard-theme/auth/guards/auth.guard';
import { UserIdleModule } from 'angular-user-idle';
import { NgImageSliderModule } from 'ng-image-slider';

@NgModule({
  declarations: [
    AppComponent,
    DashboardThemeComponent
  ],
  imports: [
    // BrowserModule,
    AppRoutingModule,
    DashboardThemeRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgImageSliderModule,
    UserIdleModule.forRoot({idle: 600, timeout: 5})
  ],
  providers: [CommonCrudService,Helpers,SharedService,CustomControlService,AuthService,AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
