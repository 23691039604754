<!-- <iframe
  [src]= 'urldummy'
  frameborder="0"
  webkitallowfullscreen
  mozallowfullscreen
  allowfullscreen
></iframe> -->

<!-- <div class="contact-alert" [ngClass]="{'themePurpleSpf':isSpfMember == true, 'themeGreenNonSpf': (singleDonationExist == true && isSpfMember == false),'themeBrownSpf' : (singleDonationExist == false && isSpfMember == false)}"> -->
    <button type="button" class="close close-button-aligner" (click)="close()">
      <span aria-hidden="true">
          &times;
      </span>
  </button>
    <!-- <div class="popup-inner"> -->
    
        <div class="contact-form">
            <div class="row">
                <iframe [src]='safeURL' style=" position:relative; background-color: black;" frameborder="0" allow="autoplay" allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>
            </div>
        </div>
    <!-- </div> -->
<!-- </div> -->