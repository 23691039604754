import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../../_services/shared.service';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getMultipleValuesInSingleSelectionError } from '@angular/cdk/collections';
import { SavedAccountsComponent } from '../saved-accounts/saved-accounts.component';
import * as braintree from 'braintree-web';
import { map } from 'rxjs/operators';
@Component({
    selector: 'app-update-reccuring-donation',
    templateUrl: './update-reccuring-donation.component.html',
    styleUrls: ['../../../../../assets/member/css/style.css', './update-reccuring-donation.component.scss']
})
export class UpdateReccuringDonationComponent implements OnInit {
    routeParam;
    sourceId;
    memberId;
    recurringDonationData:any=[];
    createForm: any;
    addNewAcc: boolean = false;
    hostedFieldsInstance: braintree.HostedFields;
    brainTreeToken: string;
    brainTreeError: string;
    cardholdersName: string;
    noFieldsCardNumber: number;
    noFieldsDate: number;
    noFieldsCvv: number;
    savedCards = [];
    selectedSavedCardId;
    postDataJsonPrepare;
    NetAmonutToPay;
    PaymentMethods;
    countryId;
    electronicPaymnt: boolean = false;
    isNotManual: boolean = false;
    response;
    selectedSavedCard = {};
    selectedSavedCardIndex = 0;
    isSpfMember:boolean=false;
    singleDonationExist:boolean=false;
    userName;
    cancelStatus: boolean = false;
  threeDSecure:  braintree.threeDSecure;
    //   AccountTypeList = [{"type":"Checking","value":"checking"},{"type":"Saving","value":"Saving"}];
    AccountTypeList = [
        {
            value: 'checking',
            name: 'Checking'
        },
        {
            value: 'savings',
            name: 'Savings'
        }
    ];
    accountType;
    isLoading: boolean = false;
    ValidationMessage: any = {
        'AccountNumber': [
            { type: "required", message: 'Please enter your bank account number' },
            { type: 'pattern', message: 'Please enter a valid account number' }
        ],
        'RoutingNumber': [],
        'AccountHolder': [
            { type: "required", message: 'Please enter the name on your bank account' },
            { type: 'pattern', message: 'Please enter a valid name on account' }
        ]
    }
    Step2Fields: any[];
    clientToken: any;
    loaderCreditNew: boolean;
    threedCountryList: any;
    primaryCountryId: string;

    constructor(public sharedService: SharedService, public formBuilder: FormBuilder, public _crud: CommonCrudService,
        public activeRoute: ActivatedRoute, public router: Router, public modalService: NgbModal) {
        this.countryId = localStorage.getItem('countryId')

        this.formInit();
    }

    ngOnInit(): void {
        this.getThreedsecureCountries();
        this.sharedService.fetchPathData('payment', 'recurringdonation');
        this.routeParam = this.activeRoute.snapshot.params;
        console.log("params-----", this.routeParam);
        this.sourceId = this.routeParam.donationId;
        this.memberId = this.routeParam.memberId;
        let params = {
            url: "frontend-site/member-donation/get/" + this.sourceId,
        }
        this._crud.getData(params).subscribe(res => {
            this.recurringDonationData = res['data']['DonationDetails'];
            this.userName = this.recurringDonationData.FirstName + ' ' +this.recurringDonationData.LastName;
            if (this.recurringDonationData.PaymentMethodIdentifier == 'credit_or_debt' || this.recurringDonationData.PaymentMethodIdentifier == 'ach') {
                console.log("is not manuall----", this.countryId)
                this.isNotManual = true;
            }
            this.createForm.controls.Amount.setValue(this.recurringDonationData['Amount']);
            this.NetAmonutToPay = this.recurringDonationData['Amount'];
            //this should be replced by saved card
            //   this.createForm.controls.savedCard.setValue(this.recurringDonationData['PaymentDetails']['AccountHolderName']);
            document.getElementById("amount").classList.add("is-focused");
            //   document.getElementById("savedCard").classList.add("is-focused");
            this.getAllPaymentMethods(this.countryId)
            this.onPaymentMethodChange(this.recurringDonationData.PaymentMethodId, this.recurringDonationData.CountryPaymentGatewaySettingId);

        });
    }
    formInit() {
        this.createForm = this.formBuilder.group({
            Amount: ['', Validators.required],
            savedCard: [''],
            AccountHolder: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z '&-]+$/)])],
            PaymentMethods: [''],
            AccountNumber: [null, Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{6,17}$/)])],
            RoutingNumber: [null, Validators.required],
            BankName: [null],
            AccountType: [null, Validators.required],
            SaveCardForFuture: ["1"]
        });
        this.routingNumberValidation();
    }
    routingNumberValidation() {
        if (this.countryId == 'BprLdASASGl0') {
            // console.log("hiiiiiiiiiiii usa");

            this.createForm.get('RoutingNumber').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{9,9}$/)]));
            this.createForm.get('RoutingNumber').updateValueAndValidity();
            this.ValidationMessage.RoutingNumber = [
                { type: "required", message: 'Please enter a valid routing number' },
                { type: 'pattern', message: 'Please enter a valid routing number with nine digits' }
            ]
        } else if (this.countryId == 'gQFUOwHXczkw') {
            // console.log("hiiiiiiiiiiii canada");
            this.createForm.get('RoutingNumber').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{8,8}$/)]));
            this.createForm.get('RoutingNumber').updateValueAndValidity();
            this.ValidationMessage.RoutingNumber = [
                { type: "required", message: 'Please enter a valid routing number' },
                { type: 'pattern', message: 'Please enter a valid routing number with eight digits' }
            ]
        } else {
            this.createForm.get('RoutingNumber').setValidators(Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')]));
            this.createForm.get('RoutingNumber').updateValueAndValidity();
            this.ValidationMessage.RoutingNumber = [
                { type: "required", message: 'Please enter your bank routing number' },
                { type: 'pattern', message: 'Please enter a valid routing number using only numbers' }
            ]
        }
    }

    animateLabel(event) {
        event.parentNode.classList.add('is-focused');
    }

    animateLabelRemove(e) {
        if (e.value == '') {
            e.parentNode.classList.remove('is-focused');
        }
    }
    PaymentProceedFinal() {
        //   console.log("data", this.createForm.value);
        if (this.recurringDonationData.PaymentMethodIdentifier == 'ach') {
            // case : 1 ach
            this.Step2Fields = [
                this.createForm.controls['AccountNumber'],
                this.createForm.controls['RoutingNumber'],
                this.createForm.controls['AccountType'],
                this.createForm.controls['AccountHolder'],

            ]
            const invalid = []
            if (this.addNewAcc) {
                const controls = this.Step2Fields;
                let i: any;
                for (i in controls) {
                    controls[i].markAsDirty();
                    if (controls[i].invalid) {
                        invalid.push(i);
                    }
                }
            }

            if (invalid[0] == undefined) {
                this.isLoading = true;
                let params = {};
                params['url'] = 'frontend-site/member-donation/update/' + this.sourceId;
                params['params'] = this.RequestPostParams({ type: 'ach' }, null);
                params['error'] = 'paymentMethods';
                this._crud.postData(params)
                    .subscribe(data => {
                        this.isLoading = false;
                        this.successPopup(data);
                    }, error => {
                        console.log("Some error tiggered" + error)
                    });
            }

        }
         else {
            //case : 2 manul methods
            //showing a popup for user information,need to check with backend for api
            this.isLoading = true;
            let params = {};
            params['url'] = 'frontend-site/member-donation/update/' + this.sourceId;
            params['params'] = this.RequestPostParams({ type: 'manual' }, null);
            this._crud.postData(params)
                .subscribe(data => {
                    this.isLoading = false;
                    // this.successPopup(data);
                    const modalRef = this.modalService.open(SuccessPopupComponent, { size: "lg" });
                    if (data['status'] == 'success') {
                        let status = "We have received your request";
                        let msg = "Kindly notify your financial institution to make the necessary changes to complete the request"
                        this.response = { title: status, message: msg };
                    } else {
                        this.response = { title: data['status'], message: data['msg'] }
                    }

                    modalRef.componentInstance.user = this.response;
                    modalRef.result.then(
                        result => {
                            //   console.log("result-----modal",result)
                            if (result.result && data['status'] == 'success') {
                                this.router.navigateByUrl('/memberDashboard/settings/' + this.memberId)
                            }
                        });
                }, error => {
                    console.log("Some error tiggered" + error)
                });

        }
        

    }
    paymentfromSavedCard(nonce){
        let param = {};
        param['params'] = this.RequestPostParams({ type: 'credit_or_debt' }, nonce);
        param['url'] = 'frontend-site/member-donation/update/' + this.sourceId;
        // param['params'] = this.RequestPostParams({ type: 'ach' },null);
        param['error'] = 'paymentMethods';
        this._crud.postData(param)
            .subscribe(data => {
                this.successPopup(data);
            }, error => {
                console.log("Some error tiggered" + error)
            });
    }
    PaymentProceedFinalCreditCard(value = null) {
        if (this.addNewAcc) {
            this.hostedFieldsInstance.tokenize({ cardholderName: this.cardholdersName }).then((payload) => {
                console.log('payload', payload);
                this.primaryCountryId = localStorage.getItem('primaryCountryId')
                let lengthPri =  this.threedCountryList.filter(x => { return x == this.primaryCountryId })
                let lengthCoun =  this.threedCountryList.filter(x => { return x == this.countryId })
                if( lengthPri.length > 0 && lengthCoun.length > 0){
    
              //   if(this.primaryCountryId == 'BprLdASASGl0' && this.countryId == 'BprLdASASGl0'){
                  this.isLoading = true
                  var threeDSecureParameters = {
                      amount: this.createForm.controls.Amount.value,
                      nonce: payload.nonce, // Example: hostedFieldsTokenizationPayload.nonce
                      bin: payload.details.bin, // Example: hostedFieldsTokenizationPayload.details.bin
                     
                      onLookupComplete: function (data, next) {
                          // use `data` here, then call `next()`
                          next();
                        }
                      };
                      const self = this
                      this.threeDSecure.on('customer-canceled', function () {
                          // the customer canceled the 3D Secure challenge
                      self.cancelStatus = true
                        });
                      this.threeDSecure.verifyCard(threeDSecureParameters).then((response) => {
                                        // submit authenticated response.nonce to the server from here
                                        console.log("3d secure response", response)
                                     
                                        //   this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(response.nonce);
    
                                          if(this.cancelStatus == true){
                                            //   this.disableMakePayment = false
                                                    this.isLoading = false
                                            }else{
                                            //   this.submitForm(type)   
                                            this.paymentfromSavedCard(response.nonce)                       
                                                          }
                                        
                      }).catch(function (error) {
                          // Handle error
                        //   self.disableMakePayment = false
                          self.isLoading = false
                          console.log("verify card error handling",error.details.originalError.details.originalError.error.message)
                          let popup={}
                          popup['status'] = "error"
                          popup['msg'] = error.details.originalError.details.originalError.error.message
                          self.successPopup(popup);
                           
                                          });
                  }else{
                      console.log('payload --> with out 3d security', payload);      
                    //   this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(payload.nonce);
                    //   this.submitForm(type)                          
                    this.paymentfromSavedCard(payload.nonce)                       

                  }
            //          let param = {};
            // param['params'] = this.RequestPostParams({ type: 'credit_or_debt' }, payload.nonce);
            // param['url'] = 'frontend-site/member-donation/update/' + this.sourceId;
            // // param['params'] = this.RequestPostParams({ type: 'ach' },null);
            // param['error'] = 'paymentMethods';
            // this._crud.postData(param)
            //     .subscribe(data => {
            //         this.successPopup(data);
            //     }, error => {
            //         console.log("Some error tiggered" + error)
            //     });
                // submit payload.nonce to the server from here
            }).catch((error) => {
                // this.buttonStatus = false;
                console.log('error_detail', error)
                this.brainTreeError = '';
               
                switch (error.code) {

                    case 'HOSTED_FIELDS_FIELDS_EMPTY':
                        // occurs when none of the fields are filled in
                        console.error('All fields are empty! Please fill out the form.');
                        // this.brainTreeError = 'All fields are empty! Please fill out the form.'
                        this.noFieldsCardNumber = 1;
                        this.noFieldsDate = 1;
                        this.noFieldsCvv = 1;
                        break;
                    case 'HOSTED_FIELDS_FIELDS_INVALID':
                        // occurs when certain fields do not pass client side validation
                        console.error('Some fields are invalid:', error.details.invalidFieldKeys);
                        // this.brainTreeError = 'Some fields are invalid'
                        // you can also programtically access the field containers for the invalid fields
                        // error.details.invalidFields.forEach(function(fieldContainer) {
                        //     fieldContainer.className = 'invalid';
                        // });
                        error.details.invalidFieldKeys.forEach(element => {
                            console.log(element);
                            if (element == 'number') {
                                this.noFieldsCardNumber = 1;
                            } if (element == 'cvv') {
                                this.noFieldsCvv = 1;
                            } if (element == 'expirationDate') {
                                this.noFieldsDate = 1;
                            }
                        });
                        break;
                    case 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE':
                        // occurs when:
                        //   * the client token used for client authorization was generated
                        //     with a customer ID and the fail on duplicate payment method
                        //     option is set to true
                        //   * the card being tokenized has previously been vaulted (with any customer)
                        // See: https://developers.braintreepayments.com/reference/request/client-token/generate/#options.fail_on_duplicate_payment_method
                        console.error('This payment method already exists in your vault.');
                        this.brainTreeError = 'This payment method already exists in your vault.'
                        break;
                    case 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED':
                        // occurs when:
                        //   * the client token used for client authorization was generated
                        //     with a customer ID and the verify card option is set to true
                        //     and you have credit card verification turned on in the Braintree
                        //     control panel
                        //   * the cvv does not pass verfication (https://developers.braintreepayments.com/reference/general/testing/#avs-and-cvv/cid-responses)
                        // See: https://developers.braintreepayments.com/reference/request/client-token/generate/#options.verify_card
                        console.error('CVV did not pass verification');
                        break;
                    case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
                        // occurs for any other tokenization error on the server
                        console.error('Tokenization failed server side. Is the card valid?');
                        this.brainTreeError = 'Tokenization failed server side. Is the card valid?'
                        break;
                    case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
                        // occurs when the Braintree gateway cannot be contacted
                        console.error('Network error occurred when tokenizing.');
                        this.brainTreeError = 'Network error occurred when tokenizing.'
                        break;
                    default:
                        console.error('Something bad happened!');
                        this.brainTreeError = 'Something bad happened!  '
                }
            });
        }
            else{
                this.isLoading = true;
                let lengthPri =  this.threedCountryList.filter(x => { return x == this.primaryCountryId })
                let lengthCoun =  this.threedCountryList.filter(x => { return x == this.countryId })
                console.log(lengthPri,"@@@@@@@",lengthCoun);
                if( lengthCoun.length > 0){
                    // this.isLoading = true
                    let param = {
                        url: "frontend-site/client-token/" + this.clientToken
                    }
                    this._crud.getData(param).subscribe(data => {
                        // console.log("clint token data-----------",data.ClientToken)
                        let token = data['data'].ClientToken;
                        console.log("token----", token)
        
                let params = {
                    url: "frontend-site/fetch-payment-method-nonce/" + this.selectedSavedCard['PayMethodToken']
                }
                this._crud.getData(params).subscribe(res => {
                    // this.isLoading=true;
                    // this.savedCardLoding=true;
                    console.log("cause list----", res['data']);
                    var threeDSecureParameters = {
                        amount: this.createForm.controls.Amount.value,
                        nonce:res['data']['PaymentMethodNonce'], // Example: hostedFieldsTokenizationPayload.nonce
                        bin: res['data']['Bin'], // Example: hostedFieldsTokenizationPayload.details.bin
                       
                        onLookupComplete: function (data, next) {
                            // use `data` here, then call `next()`
                            next();
                          }
                        };
                        braintree.client.create({
                            authorization: token
                        }).then((clientInstance) => {
                    braintree.threeDSecure.create({
                        version: 2, // Will use 3DS2 whenever possible
                        client: clientInstance
                      }).then((threeDSecureInstance) => {
                          this.threeDSecure = threeDSecureInstance;
                   
                        const self = this
                        this.threeDSecure.on('customer-canceled', function () {
                            // the customer canceled the 3D Secure challenge
                        self.cancelStatus = true
                          });
                        this.threeDSecure.verifyCard(threeDSecureParameters).then((response) => {
                                          // submit authenticated response.nonce to the server from here
                                          console.log("3d secure response", response)
                                        //   this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(response.nonce);
      
                                            if(this.cancelStatus == true){
                                                // this.buttonStatus = false
                                                      this.isLoading = false
                                              }else{
                                                  this.finalSubmitForm(response.nonce)  
                                                                                                              }
                                          
                        }).catch(function (error) {
                            // Handle error
                            // self.buttonStatus = false
                            self.isLoading = false
                            console.log("verify card error handling",error.details.originalError.details.originalError.error.message)
                            let popup={}
                            popup['status'] = "error"
                            popup['msg'] = error.details.originalError.details.originalError.error.message
                            self.successPopup(popup);
                             
                                            });
                                        });
                                    });
                });
            })
            }else{
                this.finalSubmitForm(null)
            }
                ////////////////
                // let params = {};
                // params['url'] = 'frontend-site/member-donation/update/' + this.sourceId;
                // params['params'] = this.RequestPostParams({ type: 'credit_or_debt' }, null);
                // params['error'] = 'paymentMethods';
                // this._crud.postData(params)
                //     .subscribe(data => {
                //         this.isLoading = false;
                //         this.successPopup(data);
                //     }, error => {
                //         console.log("Some error tiggered" + error)
                //     });
            }
        

    }
    finalSubmitForm(nounce){
        console.log("SSSSSSSSSSSSSSSSSSS",nounce)
        let params = {};
        params['url'] = 'frontend-site/member-donation/update/' + this.sourceId;
        params['params'] = this.RequestPostParams({ type: 'credit_or_debt' }, nounce);
        params['error'] = 'paymentMethods';
        this._crud.postData(params)
            .subscribe(data => {
                this.isLoading = false;
                this.successPopup(data);
            }, error => {
                console.log("Some error tiggered" + error)
            });
    }
    addNew() {
        this.addNewAcc = true;
        this.isSavedCard = true;
        this.electronicPaymnt = true;
        if(this.recurringDonationData.PaymentMethodIdentifier == 'credit_or_debt'){
            this.loaderCreditNew = true
            this.getClientToken(this.clientToken);

        }
    }
    listCards(paymentMethod) {
        console.log("paymentMethod",paymentMethod)
        this.savedCreditCard();
        let selectedId = this.PaymentMethods.filter(x => { return x.UniqueName == "credit_or_debt"});
        selectedId[0].CountryPaymentSettingId;
        this.addNewAcc = false;
        this.isSavedCard = false;
        this.electronicPaymnt = false;
        const modalRef = this.modalService.open(SavedAccountsComponent, { size: "lg" });
        modalRef.componentInstance.CountryPaymentSettingId =  paymentMethod == 'ach'?this.recurringDonationData.CountryPaymentGatewaySettingId :selectedId[0].CountryPaymentSettingId;
        // modalRef.componentInstance.savedCardList = this.savedCards;
        modalRef.componentInstance.paymentMethodType = paymentMethod;
        modalRef.componentInstance.settingId = this.recurringDonationData.CountryPaymentGatewaySettingId;
        modalRef.componentInstance.memberId = this.memberId;
        modalRef.componentInstance.sourceId = this.sourceId;
        modalRef.componentInstance.recurringDonationData = this.recurringDonationData;
        modalRef.componentInstance.NetAmonutToPay = this.NetAmonutToPay;
        modalRef.componentInstance.brainTreeToken = this.brainTreeToken;
        modalRef.result.then(
            result => {
                  console.log("result-----modal",result,this.savedCards)
                if (result.result) {
                    // /clearing all forms.
                    this.selectedSavedCardId = result.id;
                    this.selectedSavedCardIndex = result.index;
                    if(this.recurringDonationData.PaymentMethodIdentifier == 'ach'){
                        this.selectedSavedCard = this.savedCards[this.selectedSavedCardIndex];

                    }else{
                        this.selectedSavedCard = {
                            'PayMethodToken': this.savedCards[this.selectedSavedCardIndex].PaymentGatewayToken,
                            'AccountHolder': this.savedCards[this.selectedSavedCardIndex].CardHolderName,
                            'MaskedAccountNumber': '****' + this.savedCards[this.selectedSavedCardIndex].Last4
                        }
                    }
                    
                    console.log("11111111saved cardId ----->", this.selectedSavedCard)

                }
            });
    }
    
    findLabel(field: braintree.HostedFieldsHostedFieldsFieldData) {
        return document.querySelector('.hosted-field--label[for="' + field.container.id + '"]');
    }
    onPaymentMethodChange(paymentMethod, settingId) {
        let params = {};
        params['url'] = 'frontend-site/member/client-token/' + settingId + '/' + this.memberId;
        params['params'] = {};
        return this._crud.getData(params)
            .subscribe(data => {
                if (data['status'] == "success") {
                      console.log("ach saved cards-------",data['data']['SavedCards']);
                    if (data['data']['SavedCards'].length > 0) {
                        let matchedCardArray = data['data']['SavedCards'].filter(x => { return x.PayMethodToken == this.recurringDonationData['PaymentDetails']['PayMethodToken'] });
                        console.log("matched card array= ", matchedCardArray)
                        if (matchedCardArray.length > 0) {
                            console.log("saved card present---------------", data['data']['SavedCards'].filter(x => { return x.PayMethodToken == this.recurringDonationData['PaymentDetails']['PayMethodToken'] }))
                            // let selectedindex = data['data']['SavedCards'].filter(x => { return x.PayMethodToken == this.recurringDonationData['PaymentDetails']['PayMethodToken'] })
                            this.selectedSavedCard = matchedCardArray[0];
                            this.selectedSavedCardId = matchedCardArray[0]['PayMethodToken'];
                        } else {
                            this.selectedSavedCard = data['data']['SavedCards'][this.selectedSavedCardIndex];
                            this.selectedSavedCardId = data['data']['SavedCards'][this.selectedSavedCardIndex]['PayMethodToken'];
                        }
                        // this.IsSavedAchPresent = true;
                        this.savedCards = data['data']['SavedCards'];
                        
                        //   select the last payment choosed card ..need to backend work
                        //   this.selectedSavedCard = data['data']['SavedCards'][this.selectedSavedCardIndex];
                        //   this.selectedSavedCardId = data['data']['SavedCards'][this.selectedSavedCardIndex]['PayMethodToken'];
                        //   this.showNewCardOption = false;
                        //   console.log("221111111saved cardId ----->",this.selectedSavedCard)

                    } else {
                        console.log("hereeeeeeeee",this.recurringDonationData)
                        this.selectedSavedCard = {
                            'PayMethodToken': this.recurringDonationData.PaymentDetails.PayMethodToken,
                            'AccountHolder': this.recurringDonationData.PaymentDetails.AccountHolderName,
                            'MaskedAccountNumber': '****' + this.recurringDonationData.PaymentDetails.LastFourDigit
                        }
                        console.log("3333311111saved cardId ----->", this.selectedSavedCard)

                    }
                    if(this.recurringDonationData.PaymentMethodIdentifier == 'credit_or_debt'){
                        // this.getClientToken(this.clientToken);

                        // this.savedCreditCard()
                    }

                }
            }, error => {
                console.log("Some error tiggered" + error)
            });
    }
    savedCreditCard(){ // member/saved-cards/{member_id}
        console.log("craeting clint token",this.PaymentMethods)
        let params = {};
        let selectedId = this.PaymentMethods.filter(x => { return x.UniqueName == "credit_or_debt"});
    params['url'] = 'frontend-site/member/saved-cards/' + this.memberId  + '/' + selectedId[0].CountryPaymentSettingId;
        // params['url'] = 'frontend-site/member/saved-cards/' + this.memberId;
        params['params'] = {};
        this._crud
            .getData(params)
            .subscribe(data => {
                 console.log(data['data']['SavedCards'],"maped clint response")
                 this.savedCards = data['data']['SavedCards']
                // return res.data.ClientToken;
            }); 
    }
    RequestPostParams(paymentFields = null, nonce = null) {
        console.log(nonce)
        this.postDataJsonPrepare = {};
        this.postDataJsonPrepare['PaymentProcessParams'] = {};
        this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields'] = {};
        this.postDataJsonPrepare['Frequency'] = this.recurringDonationData['RecurringFrequencyId'];
        this.postDataJsonPrepare['Amount'] = this.createForm.controls.Amount.value;
        this.postDataJsonPrepare['PaymentProcessParams']['PaymentMethodId'] = this.recurringDonationData['PaymentMethodId'];
        this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields']['BillingDate'] = this.recurringDonationData['recurringDate'];
        if (paymentFields.type == 'ach') {
            if (this.addNewAcc) {
                this.postDataJsonPrepare['MemberId'] = this.memberId;
                this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields']['AccountHolder'] = this.createForm.controls.AccountHolder.value;
                this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields']['AccountNumber'] = this.createForm.controls.AccountNumber.value;
                this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields']['RoutingNumber'] = this.createForm.controls.RoutingNumber.value;
                this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields']['AccountType'] = this.createForm.controls.AccountType.value;
                this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields']['BankName'] = this.createForm.controls.BankName.value;
            } else {
                this.postDataJsonPrepare['PaymentProcessParams']['PaymentGatewayTokenId'] = this.selectedSavedCardId ? this.selectedSavedCardId : null;
            }
        } else if (paymentFields.type == 'credit_or_debt') {
            console.log("here",this.addNewAcc)
            if (this.addNewAcc) {

            this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields']['Nonce'] = nonce;
            }else{
                if(nonce == null){
                    this.postDataJsonPrepare['PaymentProcessParams']['PaymentGatewayTokenId'] = this.selectedSavedCard['PayMethodToken'];

                }else{
                    this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields']['Nonce'] = nonce;  
                }

            }
        }
        if (this.createForm.controls.SaveCardForFuture.value) {
            this.postDataJsonPrepare['PaymentProcessParams']['SaveCardForFuture'] = "1"
        } else {
            this.postDataJsonPrepare['PaymentProcessParams']['SaveCardForFuture'] = "0";
        }


        return this.postDataJsonPrepare;

    }
    onNotify(data: any): void {
        // this.paymentSuccessDirective = data;
        // this.TabActiveIndex = 0;
        this.ngOnInit();
    }
    onNotifyPayment(data: any): void {
        //new code for redirection to the member donation view page from recuring page
        if (this.memberId) {
            // this._router.navigate(['/members/'+this.memberId+'/member-donation/details/' + this.DonationId]);
        } else {
            // this._router.navigate(['/donations/donation-view/' + this.DonationId]);
        }
    }
    successPopup(data) {
        if(data['status'] == 'success'){
            this.response = {title: 'Success',message: 'Your donation has been updated'}
        }
        else{
            this.response = {title: data['status'],message: data['msg']}
        }        const modalRef = this.modalService.open(SuccessPopupComponent, { size: "md" });
        modalRef.componentInstance.user = this.response;
        modalRef.result.then(
            result => {
                //   console.log("result-----modal",result)
                if (result.result && data['status'] == 'success') {
                    this.router.navigateByUrl('/memberDashboard/settings/' + this.memberId)
                }
            });
    }
    // getClientToken() {
    //     let params = {};
    //     params['url'] = 'frontend-site/member/client-token/' + this.recurringDonationData.CountryPaymentGatewaySettingId + '/' + this.memberId;
    //     params['params'] = {};
    //     return this._crud
    //         .getBrainTreeToken(params)
    //         .pipe(map((res: any) => {
    //             // console.log(res.data.ClientToken,"maped clint response")
    //             return res.data.ClientToken;
    //         }));
    // }
    onAmountChange(amount) {
        // console.log("amount chnage-----",amount);
        this.NetAmonutToPay = amount;
    }
    createPurchase(nonce: string, chargeAmount: number) {
        let param = {};
        param['params'] = this.RequestPostParams({ type: 'credit_or_debt' }, nonce);
        param['url'] = 'frontend-site/member-donation/update/' + this.sourceId;
        // param['params'] = this.RequestPostParams({ type: 'ach' },null);
        param['error'] = 'paymentMethods';
        this._crud.postData(param)
            .subscribe(data => {
                this.successPopup(data);
            }, error => {
                console.log("Some error tiggered" + error)
            });

    }
    onPaymentStatus(response): void {
        // console.log(response);
    }
    public isSavedCard: boolean = false;
    payButtonStatus(response): void {
        this.isSavedCard = response;
    }
    getAllPaymentMethods(countryId) {
        let params = {}
        params["params"] = {
            sourceType: 1,
            countryId: countryId,
            sourceId: this.recurringDonationData['CauseId'],
            type:null
        }
        params["url"] = "frontend-site/fetch-available-payment-methods";
        this._crud.getData(params).subscribe(data => {
            // console.log(data['data'], 'get all PaymenthMethods');
            // this.formInit();
            this.PaymentMethods = data['data'].PaymentMethods;
            this.PaymentMethods.forEach(element => {
                if (element.PaymentMethod == 'ACH') {
                    element.text = "Bank Transfer (ACH)"
                } else if (element.PaymentMethod == 'Credit/Debit') {
                    element.text = "Credit / Debit Card"
                } else {
                    element.text = element.PaymentMethod;
                    // this.text = element.BankAccountDetails;
                }
            });
            // console.log(this.PaymentMethods, 'this.PaymentMethods');
            let braintree = data['data'].PaymentMethods.filter(x => { return x.UniqueName == "credit_or_debt" })
            this.brainTreeToken = braintree[0].TokenizationKey;
            this.clientToken = braintree[0].CountryPaymentSettingId;
            // this.createBraintreeUI();
            
        });
    }
    clearCardDetails() {
        if (this.hostedFieldsInstance) {
            this.hostedFieldsInstance.clear('number');
            this.hostedFieldsInstance.clear('cvv');
            this.hostedFieldsInstance.clear('expirationDate');
        }
        
    }
    getClientToken(CountryPaymentSettingId){
        let params = {
            url: "frontend-site/client-token/" + CountryPaymentSettingId
        }
        this._crud.getData(params).subscribe(data => {
            // console.log("clint token data-----------",data.ClientToken)
            let token = data['data'].ClientToken;
            console.log("token----",token)
            this.clearCardDetails();
            this.createBraintreeUI(token);
            
             })
    }
        createBraintreeUI(token) {
            console.log("hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii")
            braintree.client.create({
                authorization: token
            }).then((clientInstance) => {
                this.loaderCreditNew = false
                braintree.hostedFields.create({
                    client: clientInstance,
                    styles: {
                        // Style all elements
                        // 'input': {
                        //   'font-size': '16px',
                        //   'color': '#3A3A3A'
                        // },
                        '.invalid': {
                            'color': '#D0041D'
                        },
    
                        // Styling element state
                        'input': {
                            'font-size': '16px!important',
                            "font-weight": '500!important',
                            'font-family': "'Poppins', sans-serif !important",
                            'color': '#0D0D0D',
                            ':placeholder': {
                                'font-weight': '500!important',
                                'color': '#9F9F9F!important',
                            }
                        },
    
                        // Media queries
                        // Note that these apply to the iframe, not the root window.
                        // '@media screen and (max-width: 700px)': {
                        //     'input': {
                        //         'font-size': '14px'
                        //     }
                        // }
                    },
    
                    // The hosted fields that we will be using
                    // NOTE : cardholder's name field is not available in the field options
                    // and a separate input field has to be used incase you need it
                    fields: {
                        number: {
                            selector: '#card-number',
                            placeholder: 'Card Number'
                        },
                        cvv: {
                            selector: '#cvv',
                            placeholder: 'CVV'
                        },
                        expirationDate: {
                            selector: '#expiration-date',
                            // placeholder: 'Expiration Date (MM/YY)'
                            placeholder: 'MM/YY'
                        }
                    }
                }).then((hostedFieldsInstance) => {
                    console.log(hostedFieldsInstance);
                    this.hostedFieldsInstance = hostedFieldsInstance;
                    hostedFieldsInstance.on('focus', (event) => {
                        const field = event.fields[event.emittedBy];
                        const label = this.findLabel(field);
                        if (label['htmlFor'] == 'card-number') {
                            this.noFieldsCardNumber = 0;
                        }
                        if (label['htmlFor'] == 'expiration-date') {
                            this.noFieldsDate = 0;
                        }
                        if (label['htmlFor'] == 'cvv') {
                            this.noFieldsCvv = 0;
                        }
    
                        // label.classList.remove('filled'); // added and removed css classes
                        // can add custom code for custom validations here
                    });
                    hostedFieldsInstance.on('blur', (event) => {
                        const field = event.fields[event.emittedBy];
                        // const label = this.findLabel(field); // fetched label to apply custom validations
                        // can add custom code for custom validations here
                    });
    
                    hostedFieldsInstance.on('empty', (event) => {
                        const field = event.fields[event.emittedBy];
                        // can add custom code for custom validations here
                    });
    
                    hostedFieldsInstance.on('validityChange', (event) => {
                        const field = event.fields[event.emittedBy];
                        const label = this.findLabel(field);
                        if (field.isPotentiallyValid) { // applying custom css and validations
                            // label.classList.remove('invalid');
                        } else {
                            // label.classList.add('invalid');
                        }
                        // can add custom code for custom validations here
                    });
                });
                braintree.threeDSecure.create({
                    version: 2, // Will use 3DS2 whenever possible
                    client: clientInstance
                  }).then((threeDSecureInstance) => {
                      this.threeDSecure = threeDSecureInstance;
                });
            }).catch(error => {
                console.log('error', error);
                if (error.name == 'BraintreeError') {
                    // this.paymentGatewayErrorMsg = "Payment Gateway is not configured"
                }
            })
        }
        getThreedsecureCountries(){
            let params = {}
            params["url"] = "frontend-site/3d-secure-countries";
            this._crud.getData(params).subscribe(data => {
                this.threedCountryList = data['data'];
                console.log('list', this.threedCountryList);
          
            }) 
          }

}
